<template>
  <article>
    <div class="switch vat-selector">
      <label for>IVA:</label>
    </div>
    <select v-model="selectedVat"  placeholder="Ingrese IVA" class="browser-default" data-testid="vatInputId">
      <option v-for="vat in getTaxValues()" :value="vat" :key="vat">
        {{ displayTaxValue(vat) }}
      </option>
    </select>
  </article>
</template>
<script>
// import commons from "~/mixins/commons";
export default {
  // mixins: [commons],
  props: {
    rootVat: {
      type: String,
      default: () => {
        return null;
      },
    },
    exempt: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    nonTaxed: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      product: {
        exempt: this.exempt,
        nonTaxed: this.nonTaxed,
        rootVat: this.rootVat,
      },
    };
  },
  computed: {
    selectedVat: {
      get() {
        if (this.exempt) {
          return "Exento";
        } else if (this.nonTaxed) {
          return "No Gravado";
        }
        return this.rootVat;
      },
      set(newValue) {
        if (newValue === "Exento") {
          this.product.exempt = true;
          this.product.nonTaxed = false;
          this.product.rootVat = "0.00";
        } else if (newValue === "No Gravado") {
          this.product.exempt = false;
          this.product.nonTaxed = true;
          this.product.rootVat = "0.00";
        } else {
          this.product.exempt = false;
          this.product.nonTaxed = false;
          this.product.rootVat = newValue;
        }
      },
    },
  },
  watch: {
    product: {
      handler(val) {
        this.$emit("onVatChanged", val);
      },
      deep: true,
    },
  },
  methods: {
    cleanSelectedVat(isNewProduct, rootVat, isExempt, isNonTaxed) {
      if (isNewProduct) {
        this.product = {
          exempt: false,
          nonTaxed: false,
          rootVat: null,
        };
      } else {
        this.product = {
          exempt: isExempt ?? false,
          nonTaxed: isNonTaxed ?? false,
          rootVat: rootVat,
        };
      }
    },
    displayTaxValue(value) {
      if (typeof value == "number" || String(value).length < 6) {
        return `${value} %`;
      }
      return value;
    },
    getTaxValues() {
      if (
        this.$store.getAuthUser.taxValues === null ||
        this.$store.getAuthUser.taxValues === undefined
      ) {
        return this.arrayIvaForDefault;
      }
      let taxValues = this.$store.getAuthUser.taxValues.split(",");
      if (process.env.VUE_APP_INSTANCE_CODE == "AR") {
        taxValues.push("Exento");
        taxValues.push("No Gravado");
      }
      return taxValues;
    },
  },
};
</script>
<style scoped>
.vat-selector {
  display: flex;
  justify-content: space-between;
}
.switch-exempt-selector {
  display: inline-flex;
  font-size: 13px;
}
.switch-exempt-selector #switch {
  width: 43px;
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  height: 21px;
  top: -1px;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin: 0 16px;
}
.switch label .lever:before,
.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  left: 0;
  top: -1px;
}

.switch label .lever[data-v-7d8f341d] {
  content: "";
  display: inline-block;
  position: relative;
  width: 27px;
  height: 15px;
  top: -1px;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin: 0 16px;
}
</style>
