<template>
  <div>
    <div class="itemForm">
      <div v-for="printer in printerSections" :key="printer.id" class="settings">
        <!-- Selección de tipo de impresora -->
        <div class="inline-fields option">
          <div class="settings-div">
            <label class="settings-label" for>{{ printer.label }}</label>
          </div>
          <select class="browser-default" v-model="selectedPrinters[printer.id].type"
            @change="handlePrinterSelection(printer.id)">
            <option v-for="option in printerOptions" :value="option.type" :key="option.type">
              {{ option.label }}
            </option>
          </select>
        </div>

        <!-- Campo de entrada para IP si es impresora de red -->
        <div v-if="isNetworkPrinter(printer.id)" class="inline-fields">
          <div class="settings-div">
            <label class="settings-label">Ingrese la IP:</label>
          </div>
          <input
            class="search-input"
            :id="`${printer.id}-search-input`"
            type="text"
            maxlength="15"
            placeholder="IP"
            v-model="selectedPrinters[printer.id].ip"
            @input="validateAndFilterIP(printer.id)"
          />
          <span v-if="ipErrors[printer.id]" class="error-message">{{ ipErrors[printer.id] }}</span>
        </div>

         <!-- Generador de ticket de prueba -->
        <div v-if="selectedPrinters[printer.id].type" class="confirm-permissions">
          <article class="btn-main btn-main-certificate">
              <a class="btn-active" @click="printer.testFunction">
                <p>Prueba de impresión</p>
              </a>
            </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import PrinterCommons from "../../shared/mixins/printerCommons.vue";
import TicketCommons from "../mixins/ticketCommons.vue";

export default {
  mixins: [TicketCommons, PrinterCommons],
  data() {
    return {
      selectedPrinters: {
        counter: { type: "", ip: "" },
        kitchen: { type: "", ip: "" },
        additional: { type: "", ip: "" },
      },
      printerOptions: [
        { type: "", label: "Ninguna" },
        // { type: "net", label: "Impresora por red" },
        { type: "usb", label: "Impresora por USB" },
      ],
      printerSections: [
        {
          id: "counter",
          label: "Comandera Mostrador",
          testFunction: () => this.printTestTicket("counter"),
        },
        // {
        //   id: "kitchen",
        //   label: "Comandera Cocina",
        //   testFunction: () => this.printTestTicket("kitchen"),
        // },
        // {
        //   id: "additional",
        //   label: "Comandera Adicional",
        //   testFunction: () => this.printTestTicket("additional"),
        // },
      ],
      ipErrors: {},
      ipValidationIntervals: {},
    };
  },
  computed: {
    savedPrinterConfig() {
      return JSON.parse(localStorage.getItem("printersConfig") || "{}");
    },
  },
  methods: {
    isNetworkPrinter(printerId) {
      return this.selectedPrinters[printerId].type === "net";
    },
    handlePrinterSelection(printerId) {
      const selectedType = this.selectedPrinters[printerId].type;
      const printerConfig = this.printerOptions.find((option) => option.type === selectedType) || {};
      this.selectedPrinters[printerId] = { ...printerConfig, ip: "" };
      this.savePrinterConfig(printerId);
    },
    updatePrinterConfig(printerId) {
      if (this.isNetworkPrinter(printerId)) {
        this.savePrinterConfig(printerId);
      }
    },
    async printTestTicket(printerType) {
      const printerFunctionMap = {
        counter: this.printInCounter,
        kitchen: this.printInKitchen,
        additional: this.printInAdditional,
      };

      const selectedPrintFunction = printerFunctionMap[printerType];

      if (selectedPrintFunction) {
        const testLabel = this.printerSections.find((section) => section.id === printerType).label;
        const isPrinted = await selectedPrintFunction(this.generateTestTicket(testLabel));
        if (isPrinted) {
          toast.success(`Prueba de impresión realizada con éxito en la ${testLabel}` );
        }
      }
    },
    savePrinterConfig(printerId) {
      const config = { ...this.savedPrinterConfig, [printerId]: this.selectedPrinters[printerId] };
      localStorage.setItem("printersConfig", JSON.stringify(config));
    },
    loadPrintersConfig() {
      const config = this.savedPrinterConfig;
      for (const printerId in config) {
        if (this.selectedPrinters[printerId]) {
          this.selectedPrinters[printerId] = config[printerId];
        }
      }
    },
    validateAndFilterIP(printerId) {
      this.selectedPrinters[printerId].ip = this.selectedPrinters[printerId].ip.replace(/[^0-9.]/g, '');
      if (this.ipValidationIntervals[printerId]) {
        clearTimeout(this.ipValidationIntervals[printerId]);
      }

      this.ipValidationIntervals[printerId] = setTimeout(() => {
        const ip = this.selectedPrinters[printerId].ip;
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})){3}$/;

        if (ip && !ipRegex.test(ip)) {
          this.ipErrors[printerId] = "Formato de IP inválido";
        } else {
          this.ipErrors[printerId] = null;
          this.updatePrinterConfig(printerId);
        }
      }, 1000); 
    },
  },
  created() {
    this.loadPrintersConfig();
  },
};
</script>

<style lang="scss" scoped>
.itemForm {
  width: 70% !important;
  > .settings {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    margin-bottom: 15px;
    > .option {
      width: 40%;
    }
  }
}
.inline-fields {
  position: relative;
}

.error-message {
  position: absolute;
  top: 100%;
  left: 0;
  color: rgb(228, 0, 0);
  font-size: 12px;
  margin-top: 4px;
}
</style>
