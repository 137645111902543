<template>
  <div class="order-item-bistro" :class="{ active: isActive }">
    <div class="main-data">
      <div class="product-name" @click="showEditQty" v-tooltip="prod.name">{{ prod.name }}</div>
      <CounterInputBistro v-if="!prod.weightable" :value="prod.quantity" :minValue="1" :maxValue="99" @update:value="handleChangeQuantity" />
      <div v-if="prod.weightable" class="product-kg-lt">{{ formattedQuantity }}</div>
      <div class="right-content">
        <div class="product-price" @click="showEditQty">{{ $filters.currency(computedPrice) }}
        <!-- <div class="product-price" @click="showEditQty">{{ $filters.currency(Number(prod.originPrice) * prod.quantity) }} -->
        </div>
        <ButtonBistro variant="icon-only" :icon="require('@assets/icon-trash.svg')" @handleClick="handleRemoveItem" v-tooltip="'Eliminar ítem'"/>
      </div>
      <div v-if="hasExtraData" class="toggle-icon">
        <ButtonBistro  variant="icon-only"
          :icon="showExtraData ? require('@assets/icon-arrow-up.svg') : require('@assets/icon-arrow-down.svg')"
          @click="toggleExtraData" />
      </div>
    </div>
    <div class="extra-data" v-show="showExtraData">
      <slot name="extraData"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import CounterInputBistro from "./CounterInputBistro.vue";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import { getFormattedQuantity, getPriceByOriginPriceOrKgLt } from "../../shared/repositories/products/productsService";

export default defineComponent({
  name: "OrderItemBistro",
  components: {
    CounterInputBistro,
    ButtonBistro,
  },
  props: {
    prod: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit, slots }) {
    const counter = ref(0);
    const showExtraData = ref(true);

    const formattedPrice = computed(() => {
      return props.prod.price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    });

    const formattedQuantity = computed(() => getFormattedQuantity(props.prod))

    const computedPrice = computed(() => getPriceByOriginPriceOrKgLt(props.prod))

    const toggleExtraData = () => {
      showExtraData.value = !showExtraData.value;
      console.log("done");
    };

    const showEditQty = () => {
      emit("handleChangeActive");
    };

    const hasExtraData = computed(() => !!slots.extraData);

    const handleRemoveItem = () => {
      emit("handleRemoveItem");
    };

    const handleChangeQuantity = (quantityToAdd) => {
      emit("handleChangeQuantity", quantityToAdd);
    };

    return {
      counter,
      showExtraData,
      formattedPrice,
      toggleExtraData,
      showEditQty,
      hasExtraData,
      handleRemoveItem,
      handleChangeQuantity,
      formattedQuantity,
      computedPrice
    };
  },
});
</script>

<style lang="scss">
.order-item-bistro {
  margin: 8px 0 0;

  .main-data {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .product-kg-lt {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-align: center;
      color: white;
      min-width: 100px;
    }

    .product-name,
    .product-price {
      color: $lightColorFont;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }

    .product-name {
      min-width: calc(100% - 280px);
      width: 100%;
    }

    .right-content {
      display: flex; 
      align-items: center;
      min-width: 150px;
      justify-content: flex-end;

      .product-price {
        font-weight: 700;
        min-width: 80px;
        max-width: 150px;
        text-align: right;
        margin: 0 8px;
      }

      .toggle-icon {
        min-width: 38px;
      }
    }
  }

  &.active {

    .product-name,
    .product-price {
      color: #4fedce;
    }
  }
}
</style>
