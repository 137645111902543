<template>
	<section class="mainContain-info">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Reportes</h1>
			</section>
		</article>
		<!-- LISTADO DE REPORTES -->
		<article class="mainEvents reportDashboard">
			<!-- LISTADO DE REPORTES -->
			<ul class="optionsReport">
				<!-- CIERRE DE CAJA -->
				<li class="tooltip">
					<router-link to="/dashboard/report/box">
						<figure class="iconFactu">
							<span class="icon-Bistro-_ArrowItem"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Histórico cierres de caja</span>
							<p>Reportes</p>
							<h3>Cierre de Caja</h3>
						</div>
					</router-link>
				</li>
				<!-- REPORTES DE VENTAS -->
				<li class="tooltip">
					<router-link to="/dashboard/report/sales">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Buy"></span>
						</figure>

						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Total de productos más vendidos. (No contempla descuentos)</span>
							<p>Reportes</p>
							<h3>Ranking de Ventas</h3>
						</div>
					</router-link>
				</li>
				<!-- REPORTES DE VENTAS POR DIAS -->
				<li class="tooltip">
					<router-link to="/dashboard/report/days">
						<figure class="iconFactu">
							<span class="icon-Bistro-_24hrs"></span>
						</figure>

						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de: totales de ventas, comandas diarias y sus medios de
								pagos</span>
							<p>Reportes</p>
							<h3>Ventas por días</h3>
						</div>
					</router-link>
				</li>
				<!-- DETALLE DE TRANSACCIONES -->
				<li class="tooltip">
					<router-link to="/dashboard/report/transactionDetailReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de cada una de las ventas y comandas, con ítems,
								descuentos vinculados, medios de pagos y usuarios del
								equipo</span>
							<p>Reportes</p>
							<h3>Detalle de Trnx.</h3>
						</div>
					</router-link>
				</li>
			</ul>
		</article>

		<OffLineOverlay />
		<help-center />
	</section>
</template>

<script>
import commons from "@/mixins/mixCommons"
import rolesCommons from "@/mixins/rolesCommons"
import productV2Commons from "@/mixins/productV2Commons"
import OffLineOverlay from "@/features/shared/components/OffLineOverlay.vue"

export default {
	mixins: [commons, productV2Commons, rolesCommons],
	data() {
		return {
			params: {},
		}
	},
	async mounted() {
		document.title = `${this.appTitle} - ReportesPDV`

		if (this.isUserLoggedIn() === false) {
            this.logout();
        } else if (navigator.onLine && !await this.checkSessionInBackendAsync()) {
            this.logout();
        } else if (navigator.onLine && this.hasToShowThisOption(10) === false) {
            this.handleUnauthorizedAccess()
        } else {
            this.onMounted()
        }
	},
	methods: {
		onMounted() {
			this.$store.updateTopMenu(0)
			this.$store.updateSideMenu(3)
			this.$store.hideElements({
				hideCalendar: false,
				hideFilters: false,
				hideTabs: false,
			})
			this.$store.updateCalendarType("range")
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				this.$store.
					updateTabSelected(
						this.getPeriodSelectionFromLocalStorage()
					)
				this.params["Period"] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				this.$store.initTabs()
			}
			this.toggleMerchantSelectorByLabel("showMerchantSelectionFilter")
			this.$store.CALL_MERCHANTS
			this.getContextFromBackendForProducts()
		},
	},
	components:{
		OffLineOverlay
	}
}
</script>
