const printByUsb = async (content) => {
    try {
        let device = await getConnectedUSBDevice();
        if (!device) {
            return false;
        }

        if (!device.opened) {
            await device.open();
        }
        const availableEndpoints = [];
        device.configuration.interfaces.forEach((iface) => {
            console.log(`Interface Number: ${iface.interfaceNumber}`);
            iface.alternates.forEach((alt) => {
                console.log(`  Alternate Setting: ${alt.alternateSetting}`);
                console.log(`  Endpoints:`);
                alt.endpoints.forEach((ep) => {
                    console.log(`    Endpoint Number: ${ep.endpointNumber}, Type: ${ep.type}`);
                    availableEndpoints.push(ep.endpointNumber);
                });
            });
        });

        await device.selectConfiguration(1);
        await device.claimInterface(0);
        await device.selectAlternateInterface(0, 0);

        const encoder = new TextEncoder();
        const data = encoder.encode(content);
        availableEndpoints.sort((a, b) => a - b);
        for (let i = 0; i < availableEndpoints.length; i++) {
            const endpoint = availableEndpoints[i];
            try {
                await device.transferOut(endpoint, data);
                break;
            } catch (error) {
                console.error("Error al imprimir:", error);
            }
        }
      
        
        await device.close();

        return true;
    } catch (error) {
        console.error("Error al imprimir:", error);
        return false;
    }
};

const getConnectedUSBDevice = async () => {
    try {
        const devices = await navigator.usb.getDevices();
        if (devices.length === 0) {
            return await requestDevice();
        }
        return devices[0];
    } catch (error) {
        console.error("Error al obtener dispositivos USB:", error);
        return await requestDevice();
    }
};

const requestDevice = async () => {
    try {
        return await navigator.usb.requestDevice({
            filters: [{ classCode: 7 }] // Printers
        });
    } catch (error) {
        console.error("Error al solicitar dispositivo USB:", error);
        return null;
    }
};

export { printByUsb };