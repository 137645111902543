import createIndexedDb from "../db.js";
import Dexie from "dexie";

const getCashboxes = async (shopCode) => {
  const db = createIndexedDb(shopCode);
  return await db.cashboxes.toArray();
};

const getLastCloseBox = (boxes) => {
  return boxes
    .filter((box) => box.movementType === "CIERRE DE CAJA")
    .sort((a, b) => b.createdAt - a.createdAt)[0]; // Ordenar de más reciente a más antiguo
};

const getCreatedAtLastCashBox = async (shopCode) => {
  const boxes = await getCashboxes(shopCode);
  const lastCloseBox = getLastCloseBox(boxes);

  return lastCloseBox?.createdAt;
};

const getFilteredMovements = async (
  shopCode,
  filterCondition,
  returnAllIfNoClose = true
) => {
  const boxes = await getCashboxes(shopCode);
  
  // Encontrar el último cierre de caja
  const lastCloseBox = getLastCloseBox(boxes);

  // Si no hay cierres de caja, retorna todo o vacío según se necesite
  if (!lastCloseBox) {
    return returnAllIfNoClose ? boxes : [];
  }

  // Filtrar cajas según la condición proporcionada
  return boxes.filter((box) => filterCondition(box, lastCloseBox));
};

// Movimientos posteriores al último cierre de caja
const getMovementsCashbox = async (shopCode) => {
  return getFilteredMovements(
    shopCode,
    (box, lastCloseBox) => box.createdAt > lastCloseBox.createdAt,
    true
  );
};

// Movimientos anteriores al último cierre de caja
const getMovementsCashboxToDelete = async (shopCode) => {
  return getFilteredMovements(
    shopCode,
    (box, lastCloseBox) => box.createdAt < lastCloseBox.createdAt,
    false
  );
};

const insertCashbox = async (shopCode, cashboxData) => {
  const db = createIndexedDb(shopCode);
  const cashboxId = await db.cashboxes.add(cashboxData);
  return { id: cashboxId, ...cashboxData };
};

const updateCashbox = async (shopCode, box) => {
  const db = createIndexedDb(shopCode);
  const clonedCashbox = Dexie.deepClone(box);
  await db.cashboxes.put(clonedCashbox);
};

const deleteCashbox = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  await db.cashboxes.delete(order.id);
};

export default {
  insertCashbox,
  getCashboxes,
  updateCashbox,
  deleteCashbox,
  getMovementsCashbox,
  getMovementsCashboxToDelete,
  getCreatedAtLastCashBox,
};
