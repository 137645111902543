export const ASYNC_STATUS = {
  PENDING: "pending",
  SYNCED: "synced",
};

export const ORDER_STATUS = {
  CLOSED: "CLOSE",
  ACTIVE: "active",
  VOID: "VOID",
  VOID_OLD: "VOID_OLD",
};

export const ORDER_TYPE = {
  COMANDA: "COMANDA",
  VOID: "VOID",
  VOID_OLD: "VOID_OLD",
};


