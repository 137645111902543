<template>
	<!-- CONTENIDO -->
	<section class="mainContain-info">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Ventas por Combo</h1>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<!-- DESCARGAR EXCEL -->
				<a :href="`/api/report/${getUrlToExcel}/?${currentQueryString}`" target="_blank"
					class="waves-effect waves-light btn-link hide-mobile">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Detalle</span>
				</a>
			</section>
		</article>

		<!-- LISTADO DE PRODUCTOS -->
		<article class="mainEvents salesDashboard">
			<!-- TIPO DE TRANSACCION -->
			<section :class="{ 'active': showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction FilterMobile active">

					<!-- SEARCH -->
					<div class="searchDashboard">
						<!-- MENSAJE -->
						<div class="itemMessage">
							<div class="text">
								<h5>Filtra tus combos</h5>
								<p>Por nombre</p>
							</div>
							<a @click="search = !search" v-if="!search" id="btnSearchSales">
								<span class="icon-Bistro-_Search"></span>
							</a>
						</div>

						<!-- INPUT -->
						<div v-if="search" class="item">
							<a @click="search = !search" id="btnCloseSearch">
								<span class="icon-Bistro-_Close"></span>
							</a>

							<input v-model="criteria" type="text" class="inputFilter"
								placeholder="Buscar por nombre ...">
						</div>
					</div>


				</div>

				<!-- BTN ACTIVE FILTER MOBILE -->
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>

			<!-- MENSAJE ERROR -->
			<div v-if="reports?.length === 0 && loaded" class="mainEvents-history rankingVentas reportError">
				<h4>No hay información disponible de combos consumidos</h4>
			</div>

			<!-- LISTADO DE FACTURAS -->
			<ul v-else-if="reports?.length > 0" class="comboReport mainEvents-combo-sales-report-row">
				<!-- Historial 1 -->
				<li v-for="(item, index) in reports" :key="index">
					<figure class="iconHistory">
						<span :class="{ 'icon-Bistro-_Check': true }"></span>
					</figure>

					<!-- OPERACION -->
					<div class="items">
						<p>{{ item.productName }}</p>
					</div>

					<!-- DESCRIPCION -->
					<div class="items">
						<p>En combo: {{ item.quantitySoldByCombo }}</p>
						<p>Por unidad: {{ item.quantitySoldByUnit }}</p>
					</div>
					<!-- TOTAL -->
					<div class="items">
						<h3><currency-style :valor="$filters.currency(item.amountSold)" /></h3>
					</div>
				</li>
			</ul>
			<div v-else class="comboReport mainEvents-combo-sales-report-row">
				<article v-for="i in 4" :key="i" :class="sectionLoading">
				</article>
			</div>
		</article>

		<help-center />
	</section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import rolesCommons from '@/mixins/rolesCommons'
import authCommons from '@/mixins/authCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import computeds from '@/mixins/menuOptions'
import selectedPdvs from '@/mixins/select_pdvs_get_data'
import { parseToQuery } from '@/utils/utils'
import CurrencyStyle from '@/components/CurrencyStyle'
import HelpCenter from '@/components/HelpCenter'
import axios from 'axios'

export default {
	mixins: [authCommons, commons, computeds, selectedPdvs, rolesCommons, bistroV2Commons],
	data() {
		return {
			search: false,
			showFilter: false,
			loaded: false,
			params: {},
			currentQueryString: '',
			reports: [],
			criteria: ''

		}
	},
	async mounted() {
		document.title = `${this.appTitle} - Ventas por Combos`
		this.$store.setHasMounted(false)
		if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
				this.$store.setLoadingSkeleton(true)
			}
		}
	},
	watch: {
		criteria() {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.params['Name'] = this.criteria
				this.getData()
			}, 500)
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
		tab() {
			if (this.tab.id !== 6 && this.loaded) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
	},
	methods: {
		async onMounted() {
			this.$store.hideElements({
				hideCalendar: false,
				hideFilters: false,
				hideTabs: false
			})
			this.$store.updateSideMenu(3)
			this.$store.updateCalendarType('range')
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
			}
			this.$store.setHasMounted(true)

			this.getData()
		},
		getData() {
			if(!this.$store.hasMounted) return 

			this.$store.initLoading(this)
			let ids = this.selectedPdvs.map(item => {
				return item.shopCode
			})
			if (ids.length > 0) {
				this.params['MerchantFilter'] = ids
			} else {
				delete this.params['MerchantFilter']
			}
			this.currentQueryString = parseToQuery(this.params)
			axios.get(`/api/report/${this.getUrl}?${parseToQuery(this.params)}`)
				.then(res => {
					this.reports = res.data.items
					this.$store.finishLoading(this)
					this.loaded = true
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		}
	},
	computed: {
		getUrl() {
			return 'combo_report_dataV2'
		},
		getUrlToExcel() {
			return 'combo_report_excelV2'
		}
	},
	components: {
		CurrencyStyle,
		HelpCenter
	},
}
</script>
<style scoped>
.skeleton-card {
	margin-bottom: 10px;
}
</style>
