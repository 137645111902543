<template>
	<section v-if="cost" class="cost-section">
		<div class="cost-section-row">
			<div class="cost-col-2">
				<!-- <badge type="new" /> -->
			</div>
			<div class="switch">
				<label>
					<input :checked="getCostTypeValue(costTypes.key)" @change="onChangeCostType(costTypes.key)"
						type="checkbox" />
					<span class="lever lever-width"></span>
				</label>
			</div>
			<div class="label">
				{{ costTypes.label }}
				<div>
					Los ingredientes se rebajarán automáticamente del stock al momento de
					realizar un ingreso de stock de tipo Producción
				</div>
			</div>
		</div>
		<div class="cost-section-row" v-show="hasToShowThiscomponent">
			<div class="cost-col-3">
				<h4>Receta Principal</h4>
			</div>
			<article class="cost-section-row-panel-no-padding">
				<div class="primary-production-recipe">
					<div class="cost-form">
						<div class="itemForm">
							<label class="cost-title" for>Costo de producción, calculado del producto por
								{{ getMeasureUnit }}:</label>
						</div>
						<div class="itemFormPrice">
							<a><currency-style
									:valor="$filters.currency(getTotalCostBySelectedPrimaryProductionRecipeIngredients)" /></a>
						</div>
					</div>

					<article v-for="(ingredient, index) in ingredientsSelected" :key="index"
						class="itemForm selectImage cost-ingredient-row">
						<div class="itemFormOrigins">
							<a>{{ ingredient.name }}</a>
						</div>
						<div class="itemFormPrice">
							<a><currency-style :valor="$filters.currency(ingredient.cost)" /></a>
							<div>
								{{ getIngredientMeasureUnit(ingredient.measureUnit) }}
							</div>
						</div>
						<div class="itemFormMenu">
							<label>Cantidad:</label>
							<div class="itemFormMenuAmount">
								<input v-model="ingredient.quantity" type="number" @wheel="$event.target.blur()"
									placeholder="Cantidad" step="0.001" />
							</div>
						</div>
						<div class="itemFormTrash">
							<a class="deleteProduct" @click.prevent="
		deletePrimaryProductionRecipeIngredient(ingredient)
		">
								<span class="icon-Bistro-_Trash_bin"></span>
							</a>
						</div>
					</article>

					<div class="itemForm selectImage">
						<select v-if="getAvailableIngredients.length > 0"
							@change="onSelectPrimaryProductionRecipeIngredient($event)" class="browser-default"
							v-model="ingredientIndexSelection">
							<option :value="ingredientIndexSelection" selected>
								Agregar ingrediente
							</option>
							<option
								v-for="(ingredient, index) in getAvailableIngredients.sort((a, b) => a.name.localeCompare(b.name))"
								:value="index" :key="index">
								{{ ingredient.name }}
							</option>
						</select>
					</div>
				</div>
			</article>
		</div>
		<div class="cost-section-row" v-show="cost.itemCost.costType === costTypes.key">
			<div class="cost-col-3">
				<h4>Receta Alternativa</h4>
			</div>
			<article class="alternate-production-recipe">
				<div class="switch">
					<label>
						<input :checked="hasToShowAlternateProductionRecipe" @change="onProductionRecipeTypeChange()"
							type="checkbox" />
						<span class="lever lever-width"></span>
					</label>
				</div>
				<div v-if="hasToShowAlternateProductionRecipe">
					<article v-for="(ingredient, index) in alternateIngredientsSelected" :key="index"
						class="itemForm selectImage cost-ingredient-row">
						<div class="itemFormOrigins">
							<a>{{ ingredient.name }}</a>
						</div>
						<div class="itemFormPrice">
							<a><currency-style :valor="$filters.currency(ingredient.cost)" /></a>
							<div>
								{{ getIngredientMeasureUnit(ingredient.measureUnit) }}
							</div>
						</div>
						<div class="itemFormMenu">
							<label>Cantidad:</label>
							<div class="itemFormMenuAmount">
								<input v-model="ingredient.quantity" type="number" @wheel="$event.target.blur()"
									placeholder="Cantidad" step="0.001" />
							</div>
						</div>
						<div class="itemFormTrash">
							<a class="deleteProduct" @click.prevent="
		deleteAlternateProductionRecipeIngredient(ingredient)
		">
								<span class="icon-Bistro-_Trash_bin"></span>
							</a>
						</div>
					</article>

					<div class="itemForm selectImage">
						<select v-if="getAvailableAlternateIngredients.length > 0"
							@change="onSelectAlternateProductionRecipeIngredient($event)" class="browser-default"
							v-model="alternateIngredientIndexSelection">
							<option :value="alternateIngredientIndexSelection" selected>
								Agregar ingrediente
							</option>
							<option v-for="(ingredient, index) in getAvailableAlternateIngredients" :value="index"
								:key="index">
								{{ ingredient.name }}
							</option>
						</select>
					</div>
				</div>
			</article>
		</div>
	</section>
</template>

<script>
// import Badge from "../components/BadgeComp";
import CurrencyStyle from "../components/CurrencyStyle";
import currencyCommons from "../mixins/currencyCommons.vue";
import recipeSelectorCommons from "../mixins/recipeSelectorCommons.vue";
export default {
	mixins: [currencyCommons, recipeSelectorCommons],
	components: {
		// Badge,
		CurrencyStyle,
	},
	props: {
		costProp: {
			type: Object,
			default: () => {
				return {};
			},
		},
		ingredients: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	watch: {
		ingredientsSelected() {
			let cost = this.getCurrentCost;
			cost.itemCost.primaryProductionRecipe = this.ingredientsSelected;
			this.$store.setCurrentCost(cost);
		},
		alternateIngredientsSelected() {
			let cost = this.getCurrentCost;
			cost.itemCost.alternateProductionRecipe =
				this.alternateIngredientsSelected;
			this.$store.setCurrentCost(cost);
		},
		recipeType() {
			let cost = this.getCurrentCost;
			cost.itemCost.recipeType = this.recipeType;
			this.$store.setCurrentCost(cost);
		},
		cost() {
			this.hasToShowAlternateProductionRecipe = (this.cost?.itemCost?.hasToShowAlternateProductionRecipe) > 0;
			this.ingredientsSelected = this.cost?.itemCost?.primaryProductionRecipe ?? []
			this.alternateIngredientsSelected = this.cost?.itemCost?.alternateProductionRecipe ?? []
		},
		hasToShowThiscomponent(value) {
			let cost = this.getCurrentCost;
			cost.itemCost.recipeType = value ? "productionCost" : "primary";
			this.$store.setCurrentCost(cost);
		},
		costProp(value) {
			this.cost = value;
		}
	},
	data() {
		return {
			hasToShowAlternateProductionRecipe: false,
			ingredientsSelected: [],
			alternateIngredientsSelected: [],
			recipeType: "productionCost",
			isLoading: false,
			costTypes: {
				key: 2,
				label: "Costo según receta de producción",
			},
			ingredientIndexSelection: -1,
			alternateIngredientIndexSelection: -1,
			cost: null
		};
	},
	mounted() {
		this.cost = this.getCurrentCost;
	},
	methods: {
		onProductionRecipeTypeChange() {
			this.hasToShowAlternateProductionRecipe =
				!this.hasToShowAlternateProductionRecipe;


			let cost = this.getCurrentCost;
			cost.itemCost.hasToShowAlternateProductionRecipe =
				this.hasToShowAlternateProductionRecipe;
			this.$store.setCurrentCost(cost);
		},
		onSelectPrimaryProductionRecipeIngredient(event) {
			let index = event.target.value;
			if (index === "-1") return;

			const i = this.getAvailableIngredients[index];

			this.ingredientsSelected.push({
				ingredientId: i.id,
				name: i.name,
				cost: i.cost + (i.cost * (i.ullagePercentage ?? 0) / 100),
				measureUnit: i.measureUnit,
				sku: i.sku,
				quantity: "0",
			});

			this.$nextTick(() => {
				// Se asegura que Vue proceso todas las actualizaciones reactivas pendientes antes de reiniciar el selector
				this.ingredientIndexSelection = -1;
			});
		},
		onSelectAlternateProductionRecipeIngredient(event) {
			let index = event.target.value;
			if (index === "-1") return;
			const i = this.getAvailableAlternateIngredients[index];

			this.alternateIngredientsSelected.push({
				ingredientId: i.id,
				name: i.name,
				cost: i.cost + (i.cost * (i.ullagePercentage ?? 0) / 100),
				measureUnit: i.measureUnit,
				sku: i.sku,
				quantity: "0",
			});

			this.$nextTick(() => {
				// Se asegura que Vue proceso todas las actualizaciones reactivas pendientes antes de reiniciar el selector
				this.alternateIngredientIndexSelection = -1;
			});
		},
		deletePrimaryProductionRecipeIngredient(ingredient) {
			const index = this.ingredientsSelected.indexOf(ingredient);
			if (index >= 0) {
				this.ingredientsSelected.splice(index, 1);
			}
		},
		deleteAlternateProductionRecipeIngredient(ingredient) {
			const index = this.alternateIngredientsSelected.indexOf(ingredient);
			if (index >= 0) {
				this.alternateIngredientsSelected.splice(index, 1);
			}
		},
	},
	computed: {
		getCurrentCost() {
			return this.$store.getCurrentCost;
		},
		hasToShowThiscomponent() {
			return this.getCurrentCost.itemCost.costType === this.costTypes.key;
		},
		getAvailableIngredients() {
			if (
				this.ingredients === null ||
				this.ingredients === undefined ||
				this.ingredients.length < 1
			)
				return [];

			let resp = [...this.ingredients];
			if (this.cost === undefined || this.cost === null) {
				return resp;
			}
			if (
				this.ingredientsSelected === undefined ||
				this.ingredientsSelected === null ||
				this.ingredientsSelected.length < 1
			) {
				return this.filterSameIngredientWeAreEditing();
			}
			resp = this.filterSameIngredientWeAreEditing();
			this.ingredientsSelected.forEach((x) => {
				const index = resp.findIndex((t) => t.sku === x.sku);
				if (index > -1) {
					resp.splice(index, 1);
				}
			});

			return resp;
		},
		getAvailableAlternateIngredients() {
			if (
				this.ingredients === null ||
				this.ingredients === undefined ||
				this.ingredients.length < 1
			)
				return [];

			let resp = [...this.ingredients];
			if (this.cost === undefined || this.cost === null) {
				return resp;
			}
			if (
				this.alternateIngredientsSelected === undefined ||
				this.alternateIngredientsSelected === null ||
				this.alternateIngredientsSelected.length < 1
			) {
				return this.filterSameIngredientWeAreEditing();
			}

			resp = this.filterSameIngredientWeAreEditing();
			this.alternateIngredientsSelected.forEach((x) => {
				const index = resp.findIndex((t) => t.sku === x.sku);
				if (index > -1) {
					resp.splice(index, 1);
				}
			});

			return resp;
		},

		getTotalCostBySelectedPrimaryProductionRecipeIngredients() {
			let totalCost = 0;
			if (this.cost === undefined || this.cost === null)
				return totalCost.toString();
			if (
				this.ingredientsSelected === undefined ||
				this.ingredientsSelected === null ||
				this.ingredientsSelected.length < 1
			)
				return totalCost.toString();

			this.ingredientsSelected.forEach((x) => {
				totalCost += x.quantity * x.cost;
			});

			return totalCost.toString();
		},
		getTotalCostBySelectedAlternateProductionRecipeIngredients() {
			let totalCost = 0;
			if (this.cost === undefined || this.cost === null)
				return this.getParseWithCurrencySymbol(totalCost.toString(), null);
			if (
				this.alternateIngredientsSelected === undefined ||
				this.alternateIngredientsSelected === null ||
				this.alternateIngredientsSelected.length < 1
			)
				return this.getParseWithCurrencySymbol(totalCost.toString(), null);

			this.alternateIngredientsSelected.forEach((x) => {
				totalCost += x.quantity * x.cost;
			});

			return this.getParseWithCurrencySymbol(totalCost.toString(), null);
		},
	},
};
</script>
<style scoped>
.cost-section-row {
	margin: 20px 0px;
}

.alternate-production-recipe {
	display: flex;
	width: 80%;
	flex-direction: column;
}

.alternate-production-recipe .switch {
	margin: 0px 0px 20px 0px;
}
</style>
