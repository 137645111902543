<script>
import axios from "axios";

export default {
  computed: {
    getLogoHRefUrl() {
      return this.getRedirectUrl();
    },
    hasToShowStockAdmin() {
      if (
        this.$store.getAuthUser?.roles?.includes("ProductsStockAdministration") ||
        this.$store.getAuthUser?.roles?.includes("Products") ||
        this.$store.getAuthUser?.roles?.includes("Administrador")
      ) {
        return true;
      } else return false;
    },
    hasToShowCostAndIngredients() {
      if (
        this.$store.getAuthUser?.roles?.includes("ProductsCost") ||
        this.$store.getAuthUser?.roles?.includes("Products") ||
        this.$store.getAuthUser?.roles?.includes("Administrador")
      ) {
        return true;
      } else return false;
    },
    hasToShowProductAdmin() {
      if (
        this.$store.getAuthUser?.roles?.includes("ProductsAdministration") ||
        this.$store.getAuthUser?.roles?.includes("Products") ||
        this.$store.getAuthUser?.roles?.includes("Administrador")
      ) {
        return true;
      } else return false;
    },
    hasToShowProductsTab() {
      if (
        this.hasToShowStockAdmin ||
        this.hasToShowCostAndIngredients ||
        this.hasToShowProductAdmin ||
        this.canEditProductAvailability
      ) {
        return true;
      } else return false;
    },
    hasToShowUsersTab() {
      if (
        this.$store.getAuthUser?.roles?.includes("Users") ||
        this.$store.getAuthUser?.roles?.includes("UsersAdministration")
      )
        return true;
      else return false;
    },
    hasToShowSettingsTab() {
      if (
        this.$store.getAuthUser?.roles?.includes("Users") ||
        this.$store.getAuthUser?.roles?.includes("SettingsAdministration")
      )
        return true;
      else return false;
    },
    hasToShowPosTab() {
      const roles = this.$store.getAuthUser?.roles ?? (localStorage['currentUserData'] ? JSON.parse(localStorage['currentUserData']).roles : []);      if (roles.includes("PosAdministration"))
        return true;

      return false;
    },
    canEditProductAvailability() {
      return (
        this.$store.getAuthUser?.roles?.includes("ProductsAvailability") ||
        this.$store.getAuthUser?.roles?.includes("Administrador")
      );
    },
    hasToShowSettingsPosTab() {
      return this.hasPosAdminRole
    },
    hasToShowProductsPosTab() {
      return this.hasPosAdminRole
    },
    hasToShowReportPosTab() {
      return this.hasPosAdminRole
    },
    hasToShowCashPosTab() {
      return this.hasPosAdminRole
    },
    hasToShowBillingPosTab() {
      return !this.hasPosAdminRole
    },
    hasToShowItemPosTab(){
      return this.hasPosAdminRole
    },
    hasPosAdminRole() {
      if (
        this.$store.getAuthUser?.roles?.includes("PosAdministration") ||
        this.userData?.roles?.includes('PosAdministration')
      )
        return true;
      else return false;
    },
    canEditProducts() {
      return (
        this.$store.getAuthUser?.roles?.includes("Products") ||
        this.$store.getAuthUser?.roles?.includes("ProductsAdministration") ||
        this.$store.getAuthUser?.roles?.includes("Administrador")
      );
    },
    hasToShowEngineeringMenu() {
      return this.hasToShowCostAndIngredients;
    },
    canEditCosts() {
      return this.hasToShowCostAndIngredients;
    },
  },
  methods: {
    async redirectLoggedInUser() {
      await this.getUserData()
      location.href = this.getRedirectUrl();
    },
    async getUserData() {
      let response = JSON.parse(localStorage['currentUserData']);
      if (navigator.onLine) {
        response = await axios.post("/api/auth/getCurrentUserData", {});
      }
      this.userData = response.data
    },
    getRedirectUrl() {
      if (this.hasToShowThisOption(0) && !this.hasToShowProductsPosTab) {
        // Products
        return "/productsV2";
      } else if (this.hasToShowThisOption(11)) {
        // PDV index
        return "/pdv/orders";
      } else if (this.hasToShowThisOption(1)) {
        // Clientes
        return "/clientsV2";
      } else if (this.hasToShowThisOption(2)) {
        // Dashboard
        return "/dashboard/indexV2";
      } else if (this.hasToShowThisOption(3)) {
        // Providers
        return "/providersV2";
      } else if (this.hasToShowThisOption(5)) {
        // Caja
        return "/box";
      } else if (this.hasToShowThisOption(6)) {
        // Usuarios
        return "/usersV2";
      } 
      else if (this.hasToShowThisOption(7)) {
        // Eventos
        return "/eventsV2";
      } 
      else if (this.hasToShowThisOption(9)) {
        // PDV
        return "/pdv/orders";
      } 
      else {
        return "/accessNotAllowed";
      }
    },
    hasToShowThisOption(optionId) {
      if (!this.$store.getAuthUser && !this.userData) return false;
      if (
        (this.$store.getAuthUser?.roles === undefined ||
        this.$store.getAuthUser?.roles === null) && !this.userData
      ) {
        return true;
      }
      if (this.$store.getAuthUser?.roles?.size === 0) {

        return true;
      }
      if (this.hasToShowPosTab && optionId === 9) {

        return true;
      }
      if (!this.hasToShowPosTab && optionId === 9) {

        return false;
      }
      if (this.$store.getAuthUser?.roles?.includes("Initial")) {

        return true;
      }
      if (this.$store.getAuthUser?.roles?.includes("Tester")) {

        return true;
      }
      if(this.hasToShowItemPosTab && ( optionId === 2 || optionId === 10) ){
        return true
      }
      if (this.$store.getAuthUser?.roles?.includes("Administrador") && !this.hasToShowItemPosTab) {

        return true;
      }
      if (
       ( this.$store.getAuthUser?.roles?.includes("SalesReports") &&
        optionId === 2) || (this.userData?.roles?.includes("SalesReports") && optionId === 2)
      ) {
        return true;
      }
      if (
        this.$store.getAuthUser?.roles?.includes("Customers") &&
        optionId === 1 && !this.hasToShowItemPosTab
      ) {
        return true;
      }

      if (this.hasToShowProductsTab && optionId === 0 && !this.hasToShowProductsPosTab) {
        return true;
      }
      else if (this.hasToShowProductsPosTab && optionId === 0) {
        return true;
      }


      if (
        this.$store.getAuthUser?.roles?.includes("Providers") &&
        optionId === 3 && !this.hasToShowItemPosTab
      ) {
        return true;
      }
      if (this.$store.getAuthUser?.roles?.includes("Cashbox") && optionId === 5) {
        return true;
      }
      if ((this.$store.getAuthUser?.roles?.includes("Cashbox") && optionId === 10) ||
      (this.userData?.roles?.includes("Cashbox") && optionId === 10)) {

        return true;
      }
      if (this.hasToShowUsersTab && optionId === 6 && !this.hasToShowItemPosTab) {
        return true;
      }
      if (this.$store.getAuthUser?.roles?.includes("Events") && optionId === 7 && !this.hasToShowItemPosTab) {
        return true;
      }
      if (this.hasToShowSettingsTab && optionId === 8) {
        return true;
      }
      if (this.hasToShowSettingsPosTab && optionId === 8) {
        return true;
      }
      if (this.hasToShowProductsPosTab && optionId === 11) {
        return true;
      }

      return false;
    },
  },
};
</script>
