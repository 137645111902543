import { db } from "../../../../firebase";
import { ref, get, set } from "firebase/database";

const setLastUpdateTimestamp = (shopCode, lastUpdateTimestamp) => {
  localStorage.setItem(`lastUpdateTimestamp_${shopCode}`, lastUpdateTimestamp);
};
const cacheShopProducts = (shopCode, products, lastUpdateTimestamp) => {
  localStorage.setItem(`products_${shopCode}`, JSON.stringify(products));
  setLastUpdateTimestamp(shopCode, lastUpdateTimestamp);
};
const cacheShopCategories = (shopCode, categories, lastUpdateTimestamp) => {
  localStorage.setItem(`categories_${shopCode}`, JSON.stringify(categories));
  setLastUpdateTimestamp(shopCode, lastUpdateTimestamp);
};

const getLastUpdateTimestamp = async (shopCode) => {
  try {
    if(shopCode){
      const timestampRef = ref(db, `products/${shopCode}/lastUpdateTimestamp`);
      const snapshot = await get(timestampRef);
      if (!snapshot.exists()) {
        // Si el timestamp no existe, guardar la fecha actual en Firebase
        const currentTimestamp = Date.now();
        await set(timestampRef, currentTimestamp);
        return currentTimestamp;
      }
      
      // Si el timestamp existe, devolver su valor
      return snapshot.val();
    }
    return null
  } catch (error) {
    console.error("Error obteniendo lastUpdateTimestamp:", error);
    return null;
  }
};

const getCachedProducts = (shopCode) => {
  const cachedProducts = localStorage.getItem(`products_${shopCode}`);

  if (cachedProducts) return JSON.parse(cachedProducts);

  return null;
};
const getCachedCategories = (shopCode) => {
  const cachedCategories = localStorage.getItem(`categories_${shopCode}`);

  if (cachedCategories) return JSON.parse(cachedCategories);

  return null;
};

const shouldFetchFromFirebase = (lastUpdateTimestamp, shopCode) => {
  const localLastUpdateTimestamp = localStorage.getItem(`lastUpdateTimestamp_${shopCode}`);
  return !lastUpdateTimestamp || lastUpdateTimestamp != localLastUpdateTimestamp;
};


const createLastUpdateTimestamp = async (shopCode) => {
  try {
    const timestampRef = ref(db, `products/${shopCode}/lastUpdateTimestamp`);
    const timestamp = Date.now();
    await set(timestampRef, timestamp);
    console.log("lastUpdateTimestamp creado exitosamente");
 
  } catch (error) {
    console.error("Error al crear lastUpdateTimestamp:", error);
  }
};

const getIdListFromFirebase = async (shopcode) => {
  const productsRef = ref(db, `products/${shopcode}/idList`)
  const products = await get(productsRef)
  const productsSnapshot = products.val()
  return productsSnapshot
}

const getListProductsFromFirebase = async (idList) => {
  const productsListRef = ref(db, `listProducts/${idList}`)
  const productsList = await get(productsListRef)
  const productsListSnapshot = productsList.val()
  return productsListSnapshot
}

const getMenuesFromFirebase = async (idList) => {
  const menuesRef = ref(db, `listProducts/${idList}/menues`)
  const menues = await get(menuesRef)
  const menuesSnapshot = menues.val()
  return menuesSnapshot
}

const getFromDb = (shopCode) => {
  return new Promise((resolve, reject) => {
    const productsRef = ref(db, "products/" + shopCode);
    get(productsRef).then(
      (snapshot) => {
        const list = snapshot.val();
        if (!list?.idList) {
          reject(new Error("idList not found"));
          return;
        }

        const listProductsRef = ref(db, "listProducts/" + list.idList);
        get(listProductsRef).then(
          (snapshot) => {
            const resp = snapshot.val();
            if (resp == null) {
              resolve([]);
            } else {
              resolve(resp);
            }
          },
          (error) => reject(error)
        );
      },
      (error) => reject(error)
    );
  });
};

// const getOrigins = async (shopCode) => {
//     try {
//         const resp = await getFromDb(shopCode);
//         if (resp.origins !== undefined && resp.origins !== null) {
//             return resp.origins;
//         } else {
//             return [];
//         }
//     } catch (error) {
//         console.error('Error getting origins:', error);
//         return [];
//     }
// };

const getProducts = async (shopCode) => {
  if (!navigator.onLine){
    console.log("Categorías obtenidas del local");
    return getCachedProducts(shopCode);
  }

  // Primero, intenta obtener el `lastUpdateTimestamp` desde Firebase
  const lastUpdateTimestamp = await getLastUpdateTimestamp(shopCode);
  //  verificar si shouldFetchFromFirebase es valido
  const hasToGoToFirebaseToGetProducts = shouldFetchFromFirebase(
    lastUpdateTimestamp,
    shopCode
  );
  if (!hasToGoToFirebaseToGetProducts) {
    const cachedProducts = getCachedProducts(shopCode);
    //Si encuentra productos en cache los devuelve, si no hay productos sale del if y va a firebase
    if (cachedProducts) {
      console.log("Productos obtenidos del local");
      return cachedProducts;
    }
  }

  // Si no existe lastUpdateTimestamp o no estan los productos en el localStore, consulta Firebase
  try {
    const resp = await getFromDb(shopCode);
    if (resp.products !== undefined && resp.products !== null) {
      const prods = Object.entries(resp.products)
        .map(([id, product]) => ({ id, ...product }))
        .filter((product) => product.origins && product.origins.length > 0);

      cacheShopProducts(shopCode, prods, lastUpdateTimestamp);
      return prods;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error getting products:", error);
    return [];
  }
};

const getCategories = async (shopCode) => {
  if (!navigator.onLine){
    console.log("Categorías obtenidas del local");
    return getCachedCategories(shopCode);
  }

  // Primero, intenta obtener el `lastUpdateTimestamp` desde Firebase
  const lastUpdateTimestamp = await getLastUpdateTimestamp(shopCode);
  console.log(lastUpdateTimestamp);

  // Verificar si debería actualizar las categorías desde Firebase
  const hasToGoToFirebaseToGetCategories = shouldFetchFromFirebase(
    lastUpdateTimestamp,
    shopCode
  );
  if (!hasToGoToFirebaseToGetCategories) {
    const cachedCategories = getCachedCategories(shopCode);
    if (cachedCategories) {
      console.log("Categorías obtenidas del local");
      return cachedCategories;
    }
  }

  // Si no existe lastUpdateTimestamp o no están las categorías en el caché local, consulta Firebase
  try {
    const categoriesRef = ref(db, `products/${shopCode}/categories`);
    const snapshot = await get(categoriesRef);
    const categories = snapshot.val();
    if (categories) {
      cacheShopCategories(shopCode, categories, lastUpdateTimestamp);
      return categories;
    }
    console.log("No se encontraron categorías en Firebase.");
    return [];
  } catch (error) {
    console.error("Error obteniendo categorías:", error);
    return [];
  }
};

export default {
  getProducts,
  getCategories,
  createLastUpdateTimestamp,
  getIdListFromFirebase,
  getListProductsFromFirebase,
  getMenuesFromFirebase
};
