function formatDate() {
  const timeZone = new Date().getTimezoneOffset()
  return new Date(new Date().getTime() - timeZone * 60 * 1000).toISOString().split(".")[0] + "Z";
}

//toma la fecha dada de la funcion formatDate y la formatea
function formatDateTime(isoDate) {
  const date = new Date(isoDate);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

function getTicketCount(shopCode) {
  const count = localStorage.getItem(`ticket_counter_${shopCode}`);
  return count ? parseInt(count) : 0;
}

function getAndroidId(shopCode) {
  return localStorage.getItem(`androidId_${shopCode}`);
}
//   android "864161f09c812d8a"
function checkAndroidIsDefined(shopCode) {
  if(!shopCode) return false
  const androidId = getAndroidId(shopCode);
  if (!androidId) {
    setTimeout(() => {
      /* eslint-disable */
      const modalElement = document.getElementById("modalUnauthorized");
      if (modalElement) {
        const modalInstance = M.Modal.init(modalElement, {
          dismissible: false, // No permite cerrar el modal
        });
        modalInstance.open();
      }
      /* eslint-enable */
    }, 800);
    return false
  }
  return true;
}

function incrementTicketCount(shopCode) {
  let currentValue = getTicketCount(shopCode);
  currentValue += 1;
  localStorage.setItem(`ticket_counter_${shopCode}`, currentValue.toString());
  return currentValue;
}

function isOlderThan(createdAt, days) {
  const now = Date.now();
  const daysInMilliseconds = days * 24 * 60 * 60 * 1000;
  const difference = now - createdAt;

  return difference > daysInMilliseconds;
}

module.exports = {
  formatDate,
  formatDateTime,
  incrementTicketCount,
  getAndroidId,
  isOlderThan,
  checkAndroidIsDefined,
};
