<script>
import axios from 'axios';
import { toast } from "vue3-toastify";
import { parseToQuery } from '@/utils/utils';

export default {
    computed: {
        shopCode() {
            return this.$store.getRefreshPDVList;
        }
    },
    methods: {
        getListByShopCode() {
            this.PDVList = [];
            const merchantTags = this.$store.getMerchantTags ?? JSON.parse(localStorage['merchantTags']).merchantTags;
            merchantTags.forEach((shop,) => {
                //cambiar shopCode por PDV cuando se agrege en el back 
                if (shop.shopCode) {
                    this.PDVList.push({
                        shopCode: shop.shopCode,
                        listName: `${shop.merchantName}`,
                    });
                }
                this.$store.setPDVList(this.PDVList);
            });
        },   
        async getFiscalData(shopCode) {
            if( !navigator.onLine ) return
            try {
                const { data } = await axios.get(`/api/pdv/getFiscalData?${parseToQuery({ ShopCode: shopCode })}`);
                if(data?.error){
                    toast.error(data.message);
                    localStorage[`userDataPDV_${shopCode}`] = '';
                }else{
                    if(process.env.VUE_APP_INSTANCE_CODE == "AR") {
                        const vatCondition = await this.getPosContextFromBackend(shopCode)
                        data.vatCondition = vatCondition
                    } 
                    localStorage[`userDataPDV_${shopCode}`] = JSON.stringify(data);
                }
            } catch (err) {
                toast.error(err.response);
            }
        },
        async getPosContextFromBackend(shopCode) {
            const lastUpdate = Number(localStorage[`lastUpdateUserProfile_${shopCode}`]);
            const oneHour = 60 * 60 * 1000;
            if (!lastUpdate || (Date.now() - lastUpdate) > oneHour) {
                try {
                const { data } = await axios.get(`/api/users/userProfile`);

                const timestampLastUpdate = Date.now();
                localStorage[`lastUpdateUserProfile_${shopCode}`] = timestampLastUpdate;
                const shop = data.shops.find(shop => shop.code === shopCode);
                const vatCondition = shop?.fiscalData?.vatCondition;

                localStorage[`vatCondition_${shopCode}`] = vatCondition;
                return vatCondition;
                } catch (err) {
                toast.error(err.response);
                }
            } else {
                return localStorage[`vatCondition_${shopCode}`];
            }
        },
        async getContextFromPDV(shopCode) {
            if(!navigator.onLine || !shopCode) return 
            try {
                this.$store.initLoading(this);
                const { data } = await axios.get(`/api/pdv/getContextFromPDV/?${parseToQuery({ ShopCode: shopCode })}`);
                this.$store.finishLoading(this); 
                if (data.responseCode !== 0) {
                    localStorage[`androidId_${shopCode}`] = ''
                    console.log(data.responseMessage)
                } else {
                    localStorage.setItem(`androidId_${shopCode}`, data.androidId);
                }
            } catch (error) {
                localStorage[`androidId_${shopCode}`] = ''
                this.$store.errorLoading(this);
                console.error("Error en getContextFromPDV:", error);
            }
        },
    }
}
</script>