export let initialPie = {
	tooltip: {
		trigger: 'item',
		formatter: function ({ name, value, percent }) {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
			case 'AR':
			default:
				return `${name}<br/> ${Number(value).toLocaleString('es-AR', {style: 'currency', currency: 'ARS', minimumFractionDigits: 2})} (${percent}%)`
			case 'MX':
				return `${name}<br/> ${Number(value).toLocaleString('es-MX', {style: 'currency', currency: 'MXN', minimumFractionDigits: 2})} (${percent}%)`
			case 'UY':
				return `${name}<br/> ${Number(value).toLocaleString('es-UY', {style: 'currency', currency: 'UYU', minimumFractionDigits: 2})} (${percent}%)`
			case 'ES':
				return `${name}<br/> ${Number(value).toLocaleString('es-ES', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})} (${percent}%)`
			case 'ENERGY_POS_SV':
				return `${name}<br/> ${Number(value).toLocaleString('es-SV', {style: 'currency', currency: 'SVC', minimumFractionDigits: 2})} (${percent}%)`
			case 'CL':
			case 'PROVENTAS':
				return `${name}<br/> ${Number(value).toLocaleString('es-CL', {style: 'currency', currency: 'CLP', minimumFractionDigits: 0})} (${percent}%)`
			}
		}
	}
}

export let dataPie = {
	legend: {
		data: []
	},
	series: [
		{
			type: 'pie',
			radius: ['40%', '65%'],
			avoidLabelOverlap: false,
			label: {
				normal: {
					show: false,
					position: 'center'
				}
			},
			labelLine: {
				normal: {
					show: false
				}
			},
			data: []
		}
	]
}
