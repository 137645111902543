<template>
  <div id="modalTokuDebt" class="modal">
    <div class="modal-header modal-header-debt">
      <div class="btnClose">
        <a
          href="#!"
          @click="clear()"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <!-- BODY MODAL -->
    <div class="modal-body modalDebt">
      <form action class="formGeneral">
        <article class="article articleDebt-mainMX">
          <h2 v-if="user.user !== undefined">Hola {{ user.user.name }}!</h2>
          <h4>{{ getMessageForOneDebt }}</h4>

          <div
            v-for="(item, index) in shopDebts"
            :key="index"
            class="articleDebt-mainMX-rowMX switch"
            style="width: 100%; margin: auto; text-align: left"
          >
            <label class="switch-content">
              <div class="left-content">
                <input
                  type="checkbox"
                  :id="index"
                  :checked="index == selectedDebtIndex"
                  @change="onDebtSelected(index)"
                />
                <span class="lever lever-width"></span>

                <strong
                  ><currency-style :valor="$filters.currency(getNetAmount(item.amount))" /></strong>
                <!-- <currency-style :valor="getNetAmount(item.amount) | currency" /> -->
                + IVA -> TOTAL
                <strong
                  ><currency-style :valor="$filters.currency(item.amount)"/></strong>
                <!-- <currency-style :valor="item.amount | currency" /> -->
              </div>
              <div class="right-content">
                {{ item.shopName }} - {{ item.shopCode }}
              </div>
            </label>
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="article articleDebt">
        <article class="btn-main">
          <SpinnerComp v-if="isLoading" />
          <a
            v-else
            :class="!hasSelectedDebt ? 'btn-disabled' : 'btn-active'"
            style="background-color: #37c866"
            @click="createPaymentLink"
            type="submit"
          >
            <p>RECARGAR AHORA</p>
          </a>
        </article>
      </article>

      <div class="switch switch-content">
        <label class="switch-content">
          <input v-model="emailBilling" type="checkbox" />
          <span class="lever lever-width"></span>
          <div>Solicito que me envíen las facturas correspondientes</div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
//import $ from "jquery";
//import commons from "~/mixins/commons";
import mixCommons from "../mixins/mixCommons";
import SpinnerComp from "../components/SpinnerComp";
//import CurrencyStyle from "~/components/CurrencyStyle";
import CurrencyStyle from "../components/CurrencyStyle";
export default {
  mixins: [mixCommons],
  props: {
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      selectedDebtIndex: -1,
      emailBilling: false,
    };
  },
  computed: {
    getMessageForOneDebt() {
      let currentDate = new Date();
      let leftDays = Infinity;
      let message = "";
      this.shopDebts.forEach((item) => {
        if (!item.dueDate) {
          return;
        }
        let dateArray = item.dueDate.split("/");
        let date = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);

        if (date < currentDate) {
          console.log("servicio vencido");
          message = "Tu servicio ya está vencido";
        }

        console.log("item.amount en el modalTokuDebt", item.amount);

        let diff = date - currentDate;
        console.log(`Diff-> `, diff);
        if (diff < leftDays) {
          leftDays = diff;
        }
      });
      if (!message) {
        leftDays = Math.ceil(leftDays / (1000 * 60 * 60 * 24)); // Convert miliseconds to days
        message = `Tu servicio vence en ${leftDays} día${
          leftDays > 1 ? "s" : ""
        }!`;
      }
      return message;
    },
    user() {
      return this.$store.getAuthUser ?? JSON.parse(localStorage['currentUserData']);
    },
    shopDebts() {
      let debt = this.$store.getDebt;
      let shopDebts = debt?.shopDebts ?? [];
      return shopDebts.filter((x) => x.amount > 0);
    },
    hasSelectedDebt() {
      return this.selectedDebtIndex > -1;
    },
    isLoading() {
      return this.$store.getLoading;
    },
  },
  methods: {
    clear() {
      //$("#modalTokuDebt").modal("close");
    },
    createPaymentLink() {
      let debt = this.shopDebts[this.selectedDebtIndex] ?? null;

      if (debt) {
        this.$emit(`onCreatingTokuPaymentLink`, {
          amountToPay: debt.amount,
          selectedPaymentId: debt.id,
          shopCode: debt.shopCode,
          dueDate: debt.dueDate,
          emailBilling: this.emailBilling,
        });
      } else {
        this.showError("Por favor, seleccione una deuda");
      }
    },
    onDebtSelected(index) {
      if (this.selectedDebtIndex == index) {
        this.selectedDebtIndex = -1;
        return;
      }

      this.selectedDebtIndex = index;
    },
    getNetAmount(amount) {
      // return amount / 1.16;
      return parseFloat((amount / 1.16).toFixed(2));
    },
  },
  components: {
    CurrencyStyle,
    SpinnerComp,
  },
};
</script>
