<script>
import { printByUsb } from '../../pdv/services/printer/usbPrinterService.js';
import { printByBluetooth } from '../../pdv/services/printer/bluetoothPrinterService.js';
import { printByNet } from '../../pdv/services/printer/netPrinterService.js';
import { toast } from "vue3-toastify";

export default {
    data() {
        return {
            printerCommands: {
                ESC_INIT: "\x1B\x40", // Inicializar impresora
                ESC_ALIGN_CENTER: "\x1B\x61\x01", // Alineación centrada
                ESC_BOLD_ON: "\x1B\x45\x01", // Activar negrilla
                ESC_BOLD_OFF: "\x1B\x45\x00", // Desactivar negrilla
                GS_DOUBLE_STRIKE_ON: "\x1B\x47\x01", // Doble golpe activado (negrilla gruesa)
                GS_DOUBLE_STRIKE_OFF: "\x1B\x47\x00", // Doble golpe desactivado
                GS_TEXT_SIZE: "\x1D\x21", // Comando de tamaño de texto
                TEXT_NORMAL: "\x00", // Tamaño normal
                TEXT_DOUBLE_HEIGHT: "\x01", // Doble alto
                TEXT_DOUBLE_WIDTH: "\x10", // Doble ancho
                TEXT_QUAD_SIZE: "\x11", // Doble alto y ancho
            },
        }
    },
    methods: {
        setFontLarge(content) {
            return `${this.printerCommands.ESC_INIT}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_QUAD_SIZE}${content}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_NORMAL}`;
        },
        setFontCenter(content) {
            return `${this.printerCommands.ESC_INIT}${this.printerCommands.ESC_ALIGN_CENTER}${content}${this.printerCommands.ESC_ALIGN_CENTER}`;
        },
        setFontBold(content) {
            return `${this.printerCommands.ESC_INIT}${this.printerCommands.ESC_BOLD_ON}${this.printerCommands.GS_DOUBLE_STRIKE_ON}${content}${this.printerCommands.ESC_BOLD_OFF}${this.printerCommands.GS_DOUBLE_STRIKE_OFF}`;
        },
        setFontLargeAndBold(content) {
            return `${this.printerCommands.ESC_INIT}${this.printerCommands.ESC_BOLD_ON}${this.printerCommands.GS_DOUBLE_STRIKE_ON}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_QUAD_SIZE}${content}${this.printerCommands.ESC_BOLD_OFF}${this.printerCommands.GS_DOUBLE_STRIKE_OFF}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_NORMAL}`;
        },
        setFontDoubleHeight(content) {
            return `${this.printerCommands.ESC_INIT}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_DOUBLE_HEIGHT}${content}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_NORMAL}`;
        },
        setFontDoubleWidth(content) {
            return `${this.printerCommands.ESC_INIT}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_DOUBLE_WIDTH}${content}${this.printerCommands.GS_TEXT_SIZE}${this.printerCommands.TEXT_NORMAL}`;
        },
        async printInCounter(content) {
            const config = await this.getPrinterConfig();

            // Salir si config o config.counter no existen o label es "Ninguna"
            if (!config?.counter || config.counter.label === "Ninguna") return;

            return await this.printTicket(content, config.counter);
        },
        async printInKitchen(content) {
            const config = (this.getPrinterConfig()).kitchen;
            if (config.label != 'Ninguna') {
                return await this.printTicket(content, config);
            }
        },
        async printInAdditional(content) {
            const config = (this.getPrinterConfig()).additional;
            if (config.label != 'Ninguna') {
                return await this.printTicket(content, config);
            }
        },
        async printTicket(content, config) {
            let result = false;
            this.$store.initLoading(this);
            switch (config.type) {
                case 'usb': { result = await printByUsb(content, config.value); break; }
                case 'bluetooth': { result = await printByBluetooth(content, config.value); break; }
                case 'net': { result = await printByNet(content, config); break; }
                default: { console.error('Unknown printer type: ', config.type) }
            }
            if (result == false) {
                toast.error(
                    "No se pudo conectar con la impresora. Por favor, verifique su conexión."
                );
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
            this.$store.finishLoading(this);
            return result;
        },
        setupPrinters() {
            // TODO: setup printers from api
            const printerConfig = localStorage["printersConfig"];
            if (!printerConfig) {
                localStorage["printersConfig"] = 
                '{"counter": {"type": "usb", "label": "Impresora por USB", "ip": ""}}';
            }
        },
        getPrinterConfig() {
            const configString = localStorage.getItem("printersConfig");
            if (!configString) {
                this.setupPrinters()
            }   
            return JSON.parse(localStorage["printersConfig"]);
        }
    }
}
</script>