<template>
    <div v-if="isVisible" class="modal-overlay">
		<div id="new-modal-payment" class="modal-header modal-content">
			<div>
				<h4>Anular {{ title }}</h4>
				<p>Fácil y Simple</p>
			</div>
			<div class="btnClose">
				<button
					type="button"
					class="modal-close waves-effect waves-green btn-flat"
					@click="closeVoidModal($event)"
					>
					<span class="icon-Bistro-_Close"></span>
				</button>
			</div>
		
			<hr class="space-bar"/>
			<div class="modal-body">
				<p class="paragraph-left">¿Cómo desea devolver el monto?</p>
				<div>
					<label>
						<input type="radio" v-model="selectedOption" value="original" />
						Medio de pago original
					</label>
					<label>
						<input type="radio" v-model="selectedOption" value="cash" />
						Efectivo
					</label>
				</div>
				<div v-if="selectedOption === 'cash' && !hasEnoughFunds" class="paragraph-left">
					<p>La caja no cuenta con suficiente efectivo para hacer la devolución.</p>
					<p>¿Desea continuar de todas formas?</p>
				</div>
			</div>
			<div class="modal-footer">
				<ButtonBistro variant="border-line-red shadow thin-button" text="Cancelar" @click="closeVoidModal($event)" />
				<ButtonBistro variant="solid-primary-blue shadow thin-button" text="Confirmar" :disabled="!selectedOption" @click="confirm" class="confirm-button"/>
			</div>
		</div>
    </div>
</template>
  
<script>
import ButtonBistro from '@/features/shared/components/ButtonBistro.vue';

	export default {
		props: {
			isVisible: { 
				type: Boolean, 
				default: false 
			},
			title: {
				type: String,
				default: ""
			},
			hasEnoughFunds: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				selectedOption: null,
			};
		},
		methods: {
			closeVoidModal(event) {
				event.stopPropagation();
				this.selectedOption = null
				this.$emit("closeModalVoid", false);
			},
			confirm() {
				if (this.selectedOption) {
					this.$emit("confirm", this.selectedOption)
					this.$emit("closeModalVoid", false)
				}
			},
		},
		components: {
			ButtonBistro,
		},
	};
</script>
  
<style scoped lang="scss">
    .modal-overlay {
		font-family: 'Rubik-Medium';
		top: 0;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;

		.modal-content {
			padding: 20px;
			border-radius: 8px;
			width: 700px;
			font-size: 1rem;

			.btnClose {
				top: 10px; 
				right: 10px; 
			}

			.modal-footer {
				background-color: white;
				display: flex;
				justify-content: center;
				gap: 1rem;
			}
		}
		
		.modal-body {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-content: flex-start;
			padding: 10px 0 40px 70px;

			label {
				display: flex;
				align-items: center;
				font-size: 16px;
				margin: 10px 0;

				input[type="radio"] {
					appearance: none;
					width: 18px;
					height: 18px;
					border: 2px solid #00a4e0;
					border-radius: 50%;
					margin-right: 10px;
					position: relative;
					opacity: 1;
					cursor: pointer;

					&:checked {
						background-color: #00a4e0;
						border-color: #00a4e0;
					}

					&:checked::before {
						content: "";
						display: block;
						width: .8rem;
						height: .8rem;
						outline: 2px solid white;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
    }
    
    .confirm-button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    
    .btnClose button {
        background: none; 
        border: none; 
        font-size: 24px; 
        cursor: pointer; 
		color: #e8453c;
    }

    .btnClose button:hover {
        color: #ff4d4f; 
    }

	.space-bar {
		margin: 15px 0;
	}

	.paragraph-left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
</style>
  