<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import { parseToQuery } from "../utils/utils";
import currencyCommons from "../mixins/currencyCommons.vue";
import rolesCommons from "../mixins/rolesCommons.vue";
import errorCommons from "../mixins/mixErrorCommons.vue";
export default {
  mixins: [currencyCommons, rolesCommons, errorCommons],
  data() {
    return {
      stockTabs: [
        {
          id: 1,
          title: "Administración de productos",
          param: "product",
          show: true,
          canBeHide: true,
          badge: null,
        },
        {
          id: 2,
          title: "Administración de stock",
          param: "stock",
          show: true,
          canBeHide: true,
          badge: null,
        },
        {
          id: 3,
          title: "Costos de Mercaderías",
          param: "costo",
          show: false,
          canBeHide: true,
          badge: null,
        },
      ],
      stockMovementMethods: ["Manual", "Masiva"],
      stockMovementTypes: ["Ingresos", "Egresos", "Ajustes"],
      internalChangeLogEntryStockOptions: null,
      changeLogRemovalStockOptions: [
        {
          text: "Merma/Desperdicio",
          hasToShow: true,
        },
        {
          text: "Movimiento de Sucursal",
          hasToShow: true,
        },
        {
          text: "Marketing",
          hasToShow: true,
        },
        {
          text: "Consumo Interno",
          hasToShow: true,
        },
        {
          text: "Otros",
          hasToShow: true,
        },
      ],
      changeLogAdjustStockOptions: [
        {
          text: "Por Inventario",
          hasToShow: true,
        },
        {
          text: "Otros",
          hasToShow: true,
        },
      ],
      currentSkuStock: "",
      selectedList: null,
      isConfirmStockMovementsActionSelected: false,
      stockItemList: [],
      selectedBoundShop: undefined
    };
  },
  computed: {
    changeLogEntryStockOptions() {
      if (this.internalChangeLogEntryStockOptions == null) {
        this.editChangelogEntry(false, false);
      }
      return this.internalChangeLogEntryStockOptions;
    },
    hasToShowIngredientsSection() {
      /* eslint-disable */
      if (this.selectedList == null || this.selectedList == undefined) return;

			const list = this.$store.getShopsThatHaveToShowIngredients;
			const result =
				list &&
				list[0] &&
				list.findIndex((t) => t.shopCode === this.selectedList.shopCode) >= 0;

			this.stockTabs[2].show =
				result && this.hasToShowCostAndIngredients;
			this.$store.setHasToShowIngredientsSection( result);
			return result;
         /*eslint-enable*/
		},
    stockTabSelected() {
      /* eslint-disable */
      let tabSelected = this.$store.getProductActionSelected;
      const hasToShowProductTab =
        this.hasToShowProductAdmin || this.canEditProductAvailability;
      const hasToShowStockTab = this.hasToShowStockAdmin;
      const hasToShowCostTab = this.hasToShowCostAndIngredients; // && hasIngredientsOnInTst;

      this.stockTabs[0].show = hasToShowProductTab;
      this.stockTabs[1].show = hasToShowStockTab;
      this.stockTabs[2].show = hasToShowCostTab;

      if (tabSelected == "product" && hasToShowProductTab == false) {
        //No puedo seleccionar productos? y quiero ir ahi?
        tabSelected = "stock";
        this.changeProductActionTo(tabSelected);
      }

      if (tabSelected == "stock" && hasToShowStockTab == false) {
        //No puedo seleccionar stock? y quiero ir ahi?
        tabSelected = "costo";
        this.changeProductActionTo(tabSelected);
      }

      if (tabSelected == "costo" && hasToShowCostTab == false) {
        //No puedo seleccionar costos? y quiero ir ahi?
        tabSelected = "product"; //Lo dejo en productos nuevamente.
        this.changeProductActionTo(tabSelected);
      }

      return this.stockTabs.filter((x) => x.param === tabSelected)[0];
      /* eslint-enable */
    },
    getEditStockMovementList() {
      //return this.$store.editStockMovementList;
      return this.$store.getEditStockMovementList;
    },
    getSelectedMovementType() {
      return this.$store.getEditStockMovementList.selectedMovementType;
    },
    getStockItemList() {
      return this.stockItemList;
    },
    isShowingCentralizedStock() {
      return this.$store.getShowingCentralizedStock;
    },
    hasToShowCentralizedStock() {
      //Esta funcion se ejecuta cada vez que cambio de comercio
      if (this.selectedList == null || this.selectedList == undefined) return false;
      //Esta lista tiene (ShopCode y booleano que indica si tiene que aparecer o no el stock centralizado)
      const list = this.$store.getShopsThatHaveToShowCentralizedStock;
      //Hago comprobaciones 
      if (list === null || list === undefined)
        return false;
      if (list.length == 0)
        return false;
      //Si paso las comprobaciones filtro por el index que tenga el shopCode del comercio actual
      var index = list.findIndex((t) => t.shopCode === this.selectedList.shopCode);
      if (index == -1)
        return false;
      return list[index].hasToShowCentralizedStock;
    },
    getCurrentShopCode() {
      return this.selectedList.shopCode;
    }
  },
  methods: {
    editChangelogEntry(hasToShowAlternateRecipe, hasToShowProductionCost) {
      this.internalChangeLogEntryStockOptions = [
        {
          text: "Entrega de Proveedor",
          hasToShow: true,
        },
        {
          text: "Movimiento de Sucursal",
          hasToShow: true,
        },
        {
          text: "Producción",
          hasToShow: hasToShowProductionCost,
          options: [
            {
              text: "Receta principal",
              value: "productionCost",
              id: 1,
              hasToShow: hasToShowProductionCost,
            },
            {
              text: "Receta alternativa",
              value: "altCost",
              id: 2,
              hasToShow: hasToShowAlternateRecipe && hasToShowProductionCost,
            },
          ]
        },
        {
          text: "Otros",
          hasToShow: true,
        },
      ]
    },
    changeProductActionTo(action) {
      this.$store.setShowingCentralizedStock(false); //Cuando cambio de pesta;a tengo que poner esto en false para que no me muestre la pesta;a de stock centralizado cuando vuelva a tocar 'Administracion de Stock'
      this.$store.setProductActionSelected(action);
    },
    isModifiedProductStock(sku) {
      if (
        this.getEditStockMovementList === null ||
        this.getEditStockMovementList === undefined ||
        this.getEditStockMovementList.stocks === undefined
      ) {
        return null;
      }

      const s = this.getEditStockMovementList.stocks.filter(
        (t) => t.sku === sku
      );
      if (s === undefined || s === null || s.length === 0) {
        return null;
      }

      return s;
    },
    getProductStock(prod) {
      const s = this.isModifiedProductStock(prod.sku);
      // var processedStock = prod.stock.replace(',', '')
      var processedStock = s[0].currentStock;
      var processedQuantity = s[0].quantity;

      if (prod.weightable === false) {
        switch (this.getSelectedMovementType) {
          case "Ingresos":
            return Number(processedStock) + processedQuantity;
          case "Egresos":
            return Number(processedStock) - processedQuantity;
          case "Ajustes":
            return processedQuantity;
          default:
            return processedStock;
        }
      } else {
        switch (this.getSelectedMovementType) {
          case "Ingresos":
            return Number(Number(processedStock) + processedQuantity).toFixed(
              3
            );
          case "Egresos":
            return Number(Number(processedStock) - processedQuantity).toFixed(
              3
            );
          case "Ajustes":
            return Number(processedQuantity).toFixed(3);
          default:
            return Number(processedStock).toFixed(3);
        }
      }
    },
    getOptionStock(op) {
      const s = this.isModifiedProductStock(op.sku);
      var processedStock = s[0].currentStock;
      var processedQuantity = s[0].quantity;

      switch (this.getSelectedMovementType) {
        case "Ingresos":
          return Number(processedStock) + processedQuantity;
        case "Egresos":
          return Number(processedStock) - processedQuantity;
        case "Ajustes":
          return processedQuantity;
        default:
          return processedStock;
      }
    },
    getTitleLastColumnByMovementType() {
      switch (this.getSelectedMovementType) {
        case "Ingresos":
          return { title: "Cant. Ingresada", class: "" };
        case "Egresos":
          return { title: "Cant. Egresada", class: "" };
        case "Ajustes":
          return { title: "Stock Ajustado", class: "" };
        default:
          return "";
      }
    },
    getTitlePreLastColumnByMovementType() {
      return "Stock Actual";
    },
    getItemTitleBySectionKey() {
      switch (this.getEditStockMovementList.config.sectionKey) {
        case "stock-aditionals":
        case "modified-stock-aditionals":
          return "Adicional";
        case "stock-products":
        case "modified-stock-products":
        default:
          return "Producto";
      }
    },
    getTitleByMovementType() {
      return this.getSelectedMovementType + " de stock";
    },
    getTitleByMovementTypeForConfirmStockMovementsPage() {
      return "Detalle de movimientos realizados";
    },
    onStockModalClosed() {
      this.currentSkuStock = "";
    },
    GetFinalStockByTransaction(transaction) {
      var processedStock = transaction.currentStock.replace(",", "");
      var processedQuantity = transaction.quantity;

      if (transaction.measureUnit === "") {
        switch (this.getSelectedMovementType) {
          case "Ingresos":
            return Number(processedStock) + processedQuantity;
          case "Egresos":
            return Number(processedStock) - processedQuantity;
          case "Ajustes":
            return processedQuantity;
          default:
            return processedStock;
        }
      } else {
        switch (this.getSelectedMovementType) {
          case "Ingresos":
            return `${Number(
              Number(processedStock) + processedQuantity
            ).toFixed(3)}${transaction.measureUnit}`;
          case "Egresos":
            return `${Number(
              Number(processedStock) - processedQuantity
            ).toFixed(3)}${transaction.measureUnit}`;
          case "Ajustes":
            return `${Number(processedQuantity).toFixed(3)}${transaction.measureUnit
              }`;
          default:
            return `${Number(processedStock).toFixed(3)}${transaction.measureUnit
              }`;
        }
      }
    },
    deleteStockMovementItem(index) {
      var stockMovementList = this.getEditStockMovementList;
      stockMovementList.stocks.splice(index, 1);
      this.setEditStockMovementList(stockMovementList);

      this.selectConfirmStockMovementsPage();

      if (stockMovementList.stocks.length === 0) {
        this.goBeforeConfirmStockMovementsPage();
      }
    },
    onEditProductStock(newEditProdStock) {
      var stockMovementList = this.getEditStockMovementList;

      const s = stockMovementList.stocks.filter(
        (t) => t.sku === this.product.sku
      );
      if (s !== null && s !== undefined && s.length > 0) {
        const index = stockMovementList.stocks.indexOf(s[0]);
        stockMovementList.stocks.splice(index, 1);
      }

      if (newEditProdStock.changeLog !== "") {
        if (newEditProdStock.changeLog !== "Otros") {
          newEditProdStock.comments = "";
        }
        if (newEditProdStock.changeLog === "Movimiento de Sucursal" && newEditProdStock.selectedBoundShop !== "") {
          newEditProdStock.changeLog = newEditProdStock.changeLog + '(' + newEditProdStock.selectedBoundShop + ')'
        }
        if (this.product.weightable === true) {
          newEditProdStock.measureUnit = this.product.measureUnit;
          newEditProdStock.quantity = Number(
            (Math.round(newEditProdStock.quantity * 1000) / 1000).toFixed(
              3
            )
          );
        } else {
          newEditProdStock.measureUnit = "";
          newEditProdStock.quantity = Number(
            (Math.round(newEditProdStock.quantity * 100) / 100).toFixed(0)
          );
        }

        stockMovementList.stocks.push(newEditProdStock);
      }

      this.setEditStockMovementList(stockMovementList);

      this.closeModalById("#modalEditProductStock");
      this.editProductStock = {};
      this.clearProduct();
    },
    getProductTypeBySection(sectionKey) {
      switch (sectionKey) {
        case "stock-aditionals":
          return 1;
        case "stock-products":
        default:
          return 0;
      }
    },
    goBeforeConfirmStockMovementsPage() {
      this.isConfirmStockMovementsActionSelected = false;
    },
    onSelectedMovementType(
      selectedMovementType,
      selectedMovementChangeLog,
      movementChangeLogComments,
      selectedBoundShop
    ) {
      this.setEditStockMovementList({
        shopCode: this.selectedList.shopCode,
        selectedMovementType: selectedMovementType,
        stocks: [],
        config: this.selectStockConfig,
        changeLogPerDefault: selectedMovementChangeLog,
        changeLogCommentsPerDefault: movementChangeLogComments,
        selectedBoundShop: selectedBoundShop
      });

      this.selectStockConfig = {};
      this.closeModalById("#modalStockMovementType");
    },
    onLoadStockMovementsByExcel(syncStock, selectedMovementType) {
      this.setEditStockMovementList({
        shopCode: syncStock.shopCode,
        selectedMovementType: selectedMovementType,
        stocks: syncStock.stocks,
        code: syncStock.code,
        config: this.selectStockConfig,
      });
      this.selectStockConfig = {};
      this.closeModalById("#modalStockMovementType");

      this.isConfirmStockMovementsActionSelected = true;
    },
    openStockMovementTypeModal(stockMovementMethod, stockSectionKey) {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.selectStockConfig = {
        movementMethod: stockMovementMethod,
        sectionKey: stockSectionKey
      };

      this.openModalById("#modalStockMovementType");
    },
    selectConfirmStockMovementsPage() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      var stockMovementList = this.getEditStockMovementList;

      if (stockMovementList.stocks.length === 0) {
        toast.error("No posee ningún movimiento de stock para confirmar.");
        return;
      }

      stockMovementList.stocks.forEach((stock) => {
				if (typeof stock.currentStock !== "string") {
					stock.currentStock = String(stock.currentStock);
				}
			});

      this.$store.initLoading(this);
      axios
        .post(`/api/productsV2/upsertGuidStockMovement`, stockMovementList)
        .then((res) => {
          this.$store.finishLoading(this);

          this.isConfirmStockMovementsActionSelected = true;
          stockMovementList.code = res.data.code;
          this.setEditStockMovementList(stockMovementList);
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
        });
    },
    onConfirmStockMovements() {
      var stockMovementList = this.getEditStockMovementList;

      if (stockMovementList.stocks.length === 0) {
        toast.error("No posee ningún movimiento de stock para confirmar.");
        return;
      }

      this.$store.initLoading(this);
      axios
        .post(
          `/api/productsV2/syncStockMovements`,
          this.getEditStockMovementList
        )
        .then(() => {
          this.$store.finishLoading(this);

          this.showSuccess("Movimientos confirmados exitosamente!");
          this.productSearchInput=""
          this.refreshProductList(this.selectedList);
          this.removeSelectedMovementType();
          setInterval(
            this.getCurrentStock(
              this.selectedList.id,
              this.selectedList.shopCode
            ),
            20000
          );
          setInterval(this.getIngredients(this.selectedList.shopCode), 20000);
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
        });
    },
    openConfirmForRemoveStockMovementsModal() {
      if (
        this.getEditStockMovementList === null ||
        this.getEditStockMovementList.selectedMovementType === undefined
      ) {
        toast.error("Debe seleccionar un tipo de movimiento previamente.");
        return;
      }

      //$("#modalConfirmForRemoveStockMovements").modal("open");

      /* eslint-disable */
      var modalConfirmForRemoveStockMovements = document.getElementById(
        "modalConfirmForRemoveStockMovements"
      );
      var instanceModal = M.Modal.init(modalConfirmForRemoveStockMovements);
      instanceModal.open();
      /* eslint-disable */
    },
    onConfirmRemoveStockMovement() {
      this.productCategorySearchSelect = "";
      this.removeSelectedMovementType();
      //$("#modalConfirmForRemoveStockMovements").modal("close");

      /* eslint-disable */
      var modalConfirmForRemoveStockMovements = document.getElementById(
        "modalConfirmForRemoveStockMovements"
      );
      var instanceModal = M.Modal.getInstance(
        modalConfirmForRemoveStockMovements
      );
      instanceModal.close();
      /* eslint-disable */
    },
    removeSelectedMovementType() {
      this.isConfirmStockMovementsActionSelected = false;
      this.setEditStockMovementList({});
    },
    getCurrentStock(listId, shopCode, onFinished) {
      var params = {};
      params["listId"] = listId;
      params["shopCode"] = shopCode;
      axios
        .get(`/api/productsV2/getCurrentStock/?${parseToQuery(params)}`)
        .then((res) => {
          if (res.data.responseCode == 0) {
            this.stockItemList = res.data.stockItems;
            if (onFinished) onFinished();
          } else {
            this.showErrors(res);
            this.stockItemList = [];
          }
        })
        .catch((err) => {
          this.showErrors(err.response);
        });
    },
    getStockBySku(sku, isWeightable, stockItemList) {
      if (stockItemList == [] || stockItemList == null) return "0";
      
      const stockItem = stockItemList.filter((x) => x.sku == sku);
      if (!stockItem[0]) return "0";
      
      if (isWeightable) return stockItem[0].stock;
      else return Number(stockItem[0].stock);
    },
    hasToShowStockAlertProduct(sku, isWeightable, stockItemList, minimumStockProduct) {
      const stock = this.getStockBySku(sku, isWeightable, stockItemList);

      if (minimumStockProduct == 0 || minimumStockProduct == undefined) {
        return false;
      } else {
        if (stock > minimumStockProduct) {
          return false;
        }
        else {
          return true;
        }
      }

    },
    getStockCostBySku(sku, stockItemList) {
      if (stockItemList == []) return null;

      const stockItem = stockItemList.filter((x) => x.sku == sku);
      // if (!stockItem[0]?.cost) {
      // 	return "Costo: N/A";
      // } else 
      if (!stockItem[0]) {
        return "Costo: N/A";
      } else
        if (stockItem[0].costType == "FIXED") {
          return `Costo Fijo`;
          //return `Costo Fijo: ${this.getParseWithCurrencySymbol(
          //	stockItem[0].cost.toString(),
          //	null
          //)}`; 
        } else if (
          stockItem[0].costType == "PER_INGREDIENTS" &&
          stockItem[0].recipeType == "productionCost"
        ) {
          // return `Costo Producción: ${this.getParseWithCurrencySymbol(
          // 	stockItem[0].cost.toString(),
          // 	null
          // )}`;
          return `Costo de Producción`;
        } else if (
          stockItem[0].costType == "PER_INGREDIENTS" &&
          stockItem[0].recipeType == "altCost"
        ) {
          // return `Costo Prod Alt: ${this.getParseWithCurrencySymbol(
          // 	stockItem[0].cost.toString(),
          // 	null
          // )}`;
          return `Costo de Prod Alt`;
        } else if (stockItem[0].costType == "PER_INGREDIENTS") {
          // return `Costo Ingred: ${this.getParseWithCurrencySymbol(
          // 	stockItem[0].cost.toString(),
          // 	null
          // )}`;
          return `Costo por Ingredientes`;
        } else {
          // return `Costo: ${this.getParseWithCurrencySymbol(
          // 	stockItem[0].cost.toString(),
          // 	null
          // )}`;
          return `Costo`;
        }
    },
    getStockItemBySku(sku, stockItemList) {
      if (stockItemList == []) return null;
      const stockItem = stockItemList.filter((x) => x.sku == sku);
      return stockItem;
    },
    handleStockModal(product, key, department) {
      this.clearProduct();
      let clone = Object.assign(product, {});
      this.product = Object.assign(this.product, clone);
      this.getCurrentStock(this.selectedList.id, this.selectedList.shopCode);

      if (this.getSelectedMovementType !== undefined) {
        this.editProductStock = {
          name: product.name,
          department: department,
          currentStock: this.getStockBySku(
            product.sku,
            product.weightable ?? false,
            this.stockItemList
          ),
          sku: product.sku,
          quantity: 0,
          changeLog: this.getLastChangeLog(),
          comments: this.getLastComments(),
          movementType: this.getMovementTypeEnumByMovementTypeText(),
          selectedBoundShop: this.getSelectedBoundShop(),
          productType: this.getProductTypeByDepartment(department)
        };
        this.$refs.ModalEditProductStock.setShopCode(this.getSelectedShop);
        this.openModalById("#modalEditProductStock");
      } else {
        this.currentSkuStock = product.sku;
        this.isNewProduct = false;
        this.currentProductKey = key;

        this.$refs.ModalStock.setShopCode(this.getSelectedShop);
        this.openModalById("#modalStock");
      }
    },
    getProductTypeByDepartment(department){
			switch (department) {
				case "Adicionales":
					return 1;
				case "Ingredientes":
					return 2;
				default:
					return 0;
			}
		}, 
    getLastChangeLog() {
      var stockMovementList = this.getEditStockMovementList;

      if (stockMovementList.changeLogPerDefault) {
        return stockMovementList.changeLogPerDefault;
      }

      if (stockMovementList.stocks.length === 0) {
        return "";
      }

      return stockMovementList.stocks[stockMovementList.stocks.length - 1]
        .changeLog;
    },
    getLastComments() {
      var stockMovementList = this.getEditStockMovementList;

      if (stockMovementList.changeLogCommentsPerDefault) {
        return stockMovementList.changeLogCommentsPerDefault;
      }

      if (stockMovementList.stocks.length === 0) {
        return "";
      }

      let comments =
        stockMovementList.stocks[stockMovementList.stocks.length - 1].comments;
      if (comments === undefined) {
        return "";
      }

      return stockMovementList.stocks[stockMovementList.stocks.length - 1]
        .comments;
    },
    getSelectedBoundShop() {
      var stockMovementList = this.getEditStockMovementList;
      if (stockMovementList.selectedBoundShop) {
        return stockMovementList.selectedBoundShop;
      }

      if (stockMovementList.stocks.length === 0) {
        return "";
      }

      let boundShop =
        stockMovementList.stocks[stockMovementList.stocks.length - 1].selectedBoundShop;
      if (boundShop === undefined) {
        return "";
      }

      return stockMovementList.stocks[stockMovementList.stocks.length - 1]
        .selectedBoundShop;
    },
    getMovementTypeEnumByMovementTypeText() {
      switch (this.getSelectedMovementType) {
        case "Ingresos":
          return 1;
        case "Egresos":
          return 2;
        case "Ajustes":
          return 3;
        default:
          return 1;
      }
    },
    onDownloadStockReport() {
      this.$store.initLoading(this);

      var params = {};
      params["listId"] = this.selectedList.id;
      params["shopCode"] = this.selectedList.shopCode;
      params["shopName"] = this.selectedList.name;

      axios
        .get(`/api/productsV2/generateStockReport/?${parseToQuery(params)}`)
        .then((res) => {
          if (res.data.responseCode === 0) {
            if (res.data.reportUrl) {
              var download = document.createElement("a");
              download.href = res.data.reportUrl;
              download.download = `${res.data.reportName}.xlsx`;
              download.click();
            } else {
              this.showSuccess(res.data.responseMessage);
            }
            this.$store.finishLoading(this);
          } else {
            this.showError(res.data.responseMessage);
            this.$store.errorLoading(this);
          }
        })
        .catch((err) => {
          this.$store.errorLoading(this);
        });

      this.$store.finishLoading(this);
    },
    getCentralizedStockShopAndCategories(shopCode) //Cuando se presiono el boton de stock centralizado se hace esta llamada
    {
      var params = {};
      params["CurrentShopCode"] = shopCode;
      this.$store.initLoading(this);
      axios
        .get(`/api/productsV2/getCentralizedStockShopAndCategories/?${parseToQuery(params)}`)
        .then((res) => {
          this.$store.finishLoading(this);
          if (res.data.responseCode == 0) {
            this.$store.loadCategoriesAndShopNames(res.data);
          } else {
            console.error(res.data.responseMessage)
          }
        })
        .catch((err) => {
          this.$store.errorLoading(this);
        });
    },
    getCentralizedStockProducts(categories, shopNames, currentPage, countPerPage, shopCode) {
      var params = {};
      params["SelectedCategories"] = encodeURIComponent(JSON.stringify(categories));
      params["SelectedShops"] = JSON.stringify(shopNames);
      params["CurrentPage"] = currentPage;
      params["CountPerPage"] = countPerPage;
      params["CurrentShopCode"] = shopCode;

      this.$store.initLoading(this);
      axios
        .get(`/api/productsV2/getCentralizedStockProducts/?${parseToQuery(params)}`)
        .then((res) => {
          console.log(res);
          this.$store.finishLoading(this);
          if (res.data.responseCode == 0) {
            this.$store.loadCentralizedStockProducts(res.data);
          } else {
            console.error(res.data.responseMessage);
          }
        })
        .catch((err) => {
          this.$store.errorLoading(this);
        });
    },
    onDownloadCentralizedStock(shopCode) //Cuando se presiono el boton de stock centralizado se hace esta llamada
    {
      var params = {};

      //Agarro del store los parametros para crear la peticion al back
      var categories = this.$store.getCentralizedStockAllCategories;
      var selectedCategories = this.$store.getCentralizedStockSelectedCategories
      var shopNames = this.$store.getCentralizedStockShopNames;
      var selectedShopNames = this.$store.getCentralizedStockSelectedShopNames;

      params["SelectedCategories"] = categories.every(category => selectedCategories.includes(category)) ?? true ? null : encodeURIComponent(JSON.stringify(selectedCategories));
      params["SelectedShops"] = shopNames.every(shop => selectedShopNames.includes(shop)) ?? true ? null : JSON.stringify(selectedShopNames);
      params["CurrentShopCode"] = shopCode;

      console.log('params', params);

      this.$store.initLoading(this);
      let win = window.open(
        `/api/productsV2/centralizedStockReport/?${parseToQuery(params)}`
      );
      win.focus();

      this.$store.finishLoading(this);
    },
    getItems(list, selectedItems) {
      list.forEach(element => {
        if (!selectedItems.includes(element)) {
          return JSON.stringify(selectedCategories);
        }
      });

      return null;

      // categories.every(category => selectedCategories.includes(category)) ? null : JSON.stringify(selectedCategories);
    }
  },
};
</script>
