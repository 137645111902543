<template>

  <section class="product-collapse-item">
    <article :class="dynamicSkeletonClass" :style="`z-index: ${zIndex} !important`">
      <section class="mainFilter-title pointer" @click="onExpandSection()">
        <div v-if="!isProductSectionLoading" class="border-right fix-title tooltip">
          <span v-if="tooltip" class="tooltiptext">{{ tooltip }}</span>
          <h1>
            <span>{{ itemSection.label }}</span>
            <BistroBadge :type="badgeType" />
          </h1>
          <!-- <div>
						<p v-if="warningMessage" class="stock-warning">
							<label>{{warningMessage}}</label>
						</p>
					</div> -->
        </div>

        <div v-if="!hasToHideCounter && !isProductSectionLoading" class="totalFilter fix-totalFilter">
          <p>
            <strong>{{
      sortedList
        ? sortedList.length
        : processedList
          ? processedList.length
          : 0
    }}</strong>

            <br />{{ itemSection.label }}
          </p>
        </div>
      </section>

      <section v-if="canClick && !isProductSectionLoading" class="mainFilter-filter showForMobile">
        <input v-if="mobile === false && isEnabledFilterByInput && hasToShowCollapseTab
      " class="search-input" v-model="filterInput" :placeholder="defaultPlaceholder" type="text" />

        <SelectComponent v-if="mobile === false && getCategories.length > 0 && hasToShowCollapseTab
      " :options="getCategories" :initialValue="selectedCategoryFilter" @onSelectOption="onSelectOption" />

        <article class="menues" v-if="itemSection.buttonName && canEdit">
          <div @click="onOpenAndCloseDropdownById(`dropdown-${itemSection.name}`)" class="btn-main">
            <a class="waves-effect waves-light plus-sign">
              <p v-if="mobile">+</p>
              <p v-else>{{ itemSection.buttonName }}</p>
            </a>
          </div>
          <ul :id="`dropdown-${itemSection.name}`" class="dropdown-content dropMenues">
            <li v-for="(button, index) in itemSection.dropdownOptionList" :key="index">
              <a @click="onClickDropdownButton(button.name)">{{
      button.label
    }}</a>
            </li>
          </ul>
        </article>

        <div @click="onExpandSection()" class="arrow" :class="{ expanded: hasToShowCollapseTab }"></div>
      </section>
    </article>

    <article v-if="itemSection.name.includes('cost-') == false"
      v-show="hasToShowCollapseTab && processedList && processedList.length > 0" class="listItems">
      <section v-for="(item, index) in processedList" :key="index" class="listItems-product tooltip">
        <a v-if="itemSection.name === 'menues'" @click="onOpenMenuModal(item, index)"
          class="waves-effect waves-light cardMain">
          <div class="item widthLargeColumnItem inline">
            <h2>{{ item.name }}</h2>
         
            <figure v-if="isEnergyPOSInstance"  class="barcode" @click="onGenerateBarcode($event,item)">
              <img class="icon-Bistro-_Barcode" src="@/assets/barcode.svg" alt="Generar etiquetas" />
              <div class="tooltip">Generar etiquetas</div>
            </figure>
            <figure v-else class="qr" @click="onDownloadQr($event, item, index)">
              <span class="icon-Bistro-_QR"></span>
            </figure>
            
          </div>
        </a>

        <a v-else-if="itemSection.name === 'products'" @click="onOpenModal(item[1], item[0])"
          class="waves-effect waves-light cardMain">
          <figure>
            <img v-if="item[1] && item[1].image" :src="item[1].image" alt="" />
            <img v-else src="@/assets/img/imageProduct@2x.png" alt="" />
          </figure>

          <div class="item" :class="{ itemWithAlarm: !isExistPriceForThisProduct(item[1]) }">
            <h2>{{ item[1].name }}</h2>
            <h2>{{ item[1].department }}</h2>
            <p>SKU: {{ item[1].sku }}</p>
          </div>

          <div
            v-if="isExistPriceForThisProduct(item[1]) && hasToShowStockAlertProduct(item[1].sku, item[1].weightable, stockItemsInTab, item[1].minimunStockQuantity)"
            title="Stock menor al mínimo">
            <img width="25px" height="25px" src="../assets/img/icon-error.png" />
          </div>

          <div v-if="!isExistPriceForThisProduct(item[1])"
            title="Este producto no esta incluido en ninguna de sus cartas.">
            <img width="25px" height="25px" src="@/assets/img/icon-alert.png" />
          </div>
        </a>

        <a v-else-if="itemSection.name === 'aditionals'" @click="onOpenModal(item.aditional, 'Adicional')"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.aditional.name }}</h2>
            <p>({{ item.categoriesString }})</p>
            <p>
              <strong class="green"><currency-style :valor="$filters.currency(item.aditional.price)" /></strong>
            </p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'tastes'" @click="onOpenModal(item.taste, 'Gusto')"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.taste.name }}</h2>
            <p>({{ item.categoriesString }})</p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'categories'" @click="onOpenModal(item)"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <div class="category-cardview">
              <h2>{{ item.name }}</h2>
              <div class="category-printer-sem-group">
                <div class="category-printer-sem">
                  <div :class="item.hasToPrintOnCounter
      ? 'category-printer-sem-true'
      : 'category-printer-sem-false'
      "></div>
                  <label>Mostrador</label>
                </div>
                <div class="category-printer-sem">
                  <div :class="item.hasToPrintInKitchen
      ? 'category-printer-sem-true'
      : 'category-printer-sem-false'
      "></div>
                  <label>Cocina</label>
                </div>
                <div class="category-printer-sem">
                  <div :class="item.hasToPrintOnAdditionalPrinter
      ? 'category-printer-sem-true'
      : 'category-printer-sem-false'
      "></div>
                  <label>Adicional</label>
                </div>
                <p class="right" v-if="item.orderNumber">
                  Orden: {{ item.orderNumber }}
                </p>
              </div>
            </div>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'combos'" @click="onOpenModal(item[1], item[0])"
          class="waves-effect waves-light cardMain">
          <figure>
            <img v-if="item[1] && item[1].image" :src="item[1].image" alt="" />
            <img v-else src="@/assets/img/imageProduct@2x.png" alt="" />
          </figure>

          <div class="item" v-if="item[1]">
            <h2>{{ item[1].name }}</h2>
            <h2>{{ item[1].department }}</h2>
            <p>SKU: {{ item[1].sku }}</p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'ingredients'" @click="onOpenModal(item)"
          class="waves-effect waves-light cardMain">
          <div class="item widthLargeItem">
            <h2>{{ item.name }}</h2>
            <p>SKU: {{ item.sku }}</p>
          </div>

          <div v-if="item.hasLessStockThanMinimum" title="Stock menor al mínimo">
            <img width="25px" height="25px" src="@/assets/img/icon-error.png" />
          </div>
        </a>

        <a v-else-if="itemSection.name === 'modified-stock-products'"
          @click="onOpenModal(item[1], item[0], item[1].department)" class="waves-effect waves-light cardMain">
          <figure>
            <img v-if="item[1] && item[1].image" :src="item[1].image" alt="" />
            <img v-else src="@/assets/img/imageProduct@2x.png" alt="" />
          </figure>

          <div class="item">
            <h2>{{ item[1].name }}</h2>
            <h2>{{ item[1].department }}</h2>
            <p>SKU: {{ item[1].sku }}</p>

            <div v-if="isModifiedProductStock(item[1].sku)" class="modified-stock">
              <p class="greenText" v-if="item[1].measureUnit !== null && item[1].measureUnit !== ''
      ">
                Stock modificado: <strong>{{ getProductStock(item[1]) }}</strong>{{ item[1].measureUnit }}
              </p>
              <p class="greenText" v-else>
                Stock modificado:
                <strong>{{ getProductStock(item[1]) }}</strong>
              </p>
            </div>
            <div v-else>
              <p v-if="item[1].measureUnit !== null && item[1].measureUnit !== ''
      ">
                Stock actual:
                <strong>{{
      getStockBySku(item[1].sku, true, stockItemsInTab)
    }}</strong>{{ item[1].measureUnit }}
              </p>
              <p v-else>
                Stock actual:
                <strong>{{
      getStockBySku(item[1].sku, false, stockItemsInTab)
    }}</strong>
              </p>
            </div>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'stock-products'" @click="onOpenModal(item[1], item[0])"
          class="waves-effect waves-light cardMain">
          <figure>
            <img v-if="item[1] && item[1].image" :src="item[1].image" alt="" />
            <img v-else src="@/assets/img/imageProduct@2x.png" alt="" />
          </figure>

          <div class="item">
            <h2>{{ item[1].name }}</h2>
            <h2>{{ item[1].department }}</h2>
            <p>SKU: {{ item[1].sku }}</p>
            <p>
              Stock Actual:
              <strong>{{
      getStockBySku(item[1].sku, item[1].weightable, stockItemsInTab)
    }}</strong>
              {{ item[1].weightable ? item[1].measureUnit : `` }}
            </p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'stock-aditionals'" @click="onOpenModal(item.aditional, index)"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.aditional.name }}</h2>
            <p>({{ item.categoriesString }})</p>
            <p>SKU: {{ item.aditional.sku }}</p>
            <p>
              Stock Actual:
              <strong>{{
      getStockBySku(item.aditional.sku, false, stockItemsInTab)
    }}</strong>
            </p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'modified-stock-aditionals' &&
      item.aditional.sku !== undefined
      " @click="onOpenModal(item.aditional, index, 'Adicionales')" class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.aditional.name }}</h2>
            <p>({{ item.categoriesString }})</p>
            <p>SKU: {{ item.aditional.sku }}</p>

            <div v-if="isModifiedProductStock(item.aditional.sku)" class="modified-stock">
              <p class="greenText">
                Stock modificado:
                <strong>{{ getOptionStock(item.aditional) }}</strong>
              </p>
            </div>
            <div v-else>
              <p>
                Stock actual:
                <strong>{{
      getStockBySku(item.aditional.sku, false, stockItemsInTab)
    }}</strong>
              </p>
            </div>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'stock-ingredients'" @click="onOpenModal(item)"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.name }}</h2>
            <p>SKU: {{ item.sku }}</p>
            <p>
              Stock Actual:
              <strong>{{
      getStockBySku(item.sku, item.weightable, stockItemsInTab)
    }}</strong>{{ item.weightable ? item.measureUnit : `` }}
            </p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'modified-stock-ingredients'"
          @click="onOpenModal(item, index, 'Ingredientes')" class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.name }}</h2>
            <p>SKU: {{ item.sku }}</p>

            <div v-if="isModifiedProductStock(item.sku)" class="modified-stock">
              <p class="greenText">
                Stock modificado: <strong>{{ getOptionStock(item) }}</strong>{{ item.weightable ? item.measureUnit : ``
                }}
              </p>
            </div>
            <div v-else>
              <p>
                Stock actual:
                <strong>{{
      getStockBySku(item.sku, item.weightable, stockItemsInTab)
    }}</strong>{{ item.weightable ? item.measureUnit : `` }}
              </p>
            </div>
          </div>
        </a>
      </section>
    </article>
    <article v-else v-show="hasToShowCollapseTab && computedList && computedList.length > 0" class="listItems">
      <section v-for="(item, index) in computedList" :key="index" class="listItems-product tooltip">
        <a v-if="itemSection.name === 'cost-products'" @click="onOpenModal(item[1], item[0])"
          class="waves-effect waves-light cardMain">
          <figure>
            <img v-if="item[1] && item[1].image" :src="item[1].image" alt="" />
            <img v-else src="@/assets/img/imageProduct@2x.png" alt="" />
          </figure>
          <div class="item">
            <h2>{{ item[1].name }}</h2>
            <h2>{{ item[1].department }}</h2>
            <p >{{ getStockCostBySku(item[1].sku, stockItemsInTab) }}</p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'cost-aditionals'" @click="onOpenModal(item.aditional, index)"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.aditional.name }}</h2>
            <p>({{ item.categoriesString }})</p>
            <p>{{ getStockCostBySku(item.aditional.sku, stockItemsInTab) }}</p>
          </div>
        </a>

        <a v-else-if="itemSection.name === 'cost-ingredients'" @click="onOpenModal(item)"
          class="waves-effect waves-light cardMain">
          <div class="item widthFullItem">
            <h2>{{ item.name }}</h2>
            <p>{{ getStockCostBySku(item.sku, stockItemsInTab) }}</p>    
          </div>
        </a>
      </section>
    </article>
  </section>
</template>

<script>
import CurrencyStyle from "../components/CurrencyStyle";
import BistroBadge from "../components/BistroBadge";
import SelectComponent from "../components/SelectComponent";
import stockV2Commons from "../mixins/stockV2Commons";
import productV2Commons from "../mixins/productV2Commons";
import rolesCommons from "../mixins/rolesCommons";
import instanceCommons from "../mixins/instanceCommons.vue";


export default {
  mixins: [stockV2Commons, productV2Commons, rolesCommons,instanceCommons],
  props: {
    badgeType: {
      type: String,
      default: "none",
    },
    defaultPlaceholder: {
      type: String,
      default: "Filtrar producto",
    },
    warningMessage: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    canClick: {
      type: Boolean,
      default: true,
    },
    hasToOrderByCost: {
      type: Boolean,
      default: false,
    },
    hasToOrderAdditionalByCost: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    hasToOrderIngredientByCost: {
      type: Boolean,
      default: false,
    },
    itemSection: {
      type: Object,
      default: () => ({
        name: "",
        label: "",
        buttonName: "",
        dropdownOptionList: [],
      }),
    },
    expandTabName: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    isProductSectionLoading: {
			type: Boolean,
			default: false,
		},
    mobile: {
      type: Boolean,
      default: false,
    },
    categories: {
      // SOLO PARA EL FILTRO POR CATEGORIAS!
      type: Array,
      default() {
        return [];
      },
    },
    isEnabledFilterByInput: {
      // SOLO PARA EL FILTRO POR INPUT!!!
      type: Boolean,
      default: false,
    },
    originalSearchInput: {
      // SOLO PARA EL FILTRO POR INPUT!!!
      type: String,
      default: "",
    },
    originalCategoryFilter: {
      // SOLO PARA EL FILTRO POR CATEGORIAS!!!
      type: String,
      default: "",
    },
    stockItemsInTab: {
      // SOLO PARA EL MÓDULO DE STOCK (y ahora tambien para el modulo de productos para stock mínimo)!!!
      type: Array,
      default() {
        return [];
      },
    },
    zIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedCategoryFilter: "Todas las Categorías",
      sortedList: null,
      filterInput: "",

    };
  },
  watch: {
    stockTabSelected() {
      this.onWatchingListAndStockItemsInTab();
    },
    selectedCategoryFilter() {
      this.$emit("onSelectedCategoryFilter", this.selectedCategoryFilter);
    },
    filterInput() {
      this.$emit("onLoadFilterInput", this.filterInput);
    },
    originalSearchInput() {
      this.filterInput = this.originalSearchInput;
    },
    originalCategoryFilter() {
      this.selectedCategoryFilter = this.originalCategoryFilter;
    },
    list() {
      this.onWatchingListAndStockItemsInTab();
    },
    stockItemsInTab() {
      this.onWatchingListAndStockItemsInTab();
    },
  },
  computed: {

    dynamicSkeletonClass(){
			if(this.isProductSectionLoading)	{
				return {
					...{	
						mainFilter: true,
						mainFilterMobile: true,
					},
					'skeleton-card': true,
				};
			} else	{	
				return {
					mainFilter: true,
					mainFilterMobile: true,
					"mainFilter-activeHover": true,
					"mainFilter-active": this.hasToShowCollapseTab,
				};	//Si ya se cargaron todos los datos, lo puedo mostrar entonces
			}
		},
    processedList() {
      return this.filterProducts(this.list);      
    },
    computedList() {
      let resultList = this.list;
      if (this.sortedList) {
        resultList = this.sortedList;
      }

      return this.filterProducts(resultList);
    },

    getCategories() {
      const categories = [];

      if (!this.categories[0]) {
        return categories;
      }

      categories.push({
        name: "Todas las Categorías",
        value: "Todas las Categorías",
      });

      return categories.concat(this.categories);
    },
    hasToShowCollapseTab() {
      return this.expandTabName === this.itemSection.name;
    },
    hasToHideCounter() {
      return this.itemSection.hasToHideCounter;
    }
  },
  methods: {

    onGenerateBarcode(event, item) {
      event.stopPropagation();
      this.$emit("onGenerateBarcode", item)
    },
    filterProducts(resultList) {
      if (resultList?.length > 0 == false) return resultList;
      const isProductCostStockEntry =
        this.$store.editStockMovementList.changeLogPerDefault == "Producción" &&
        this.stockTabSelected.title == this.stockTabs[1].title; //Es la pantalla de stock?

      if (isProductCostStockEntry) {
        const prodsThatHaveProductCost =
          this.$store.getProductsThatHaveProductionCosts;
        if (!prodsThatHaveProductCost) return resultList;
        if (!(resultList?.length > 0)) return resultList;
        const filteredResults = resultList.filter((x) => {
          const prod = prodsThatHaveProductCost?.find(
            (y) =>
              y.sku === x[1]?.sku ||
              y.sku === x.sku ||
              y.sku === x.aditional?.sku
          );
          return prod !== undefined;
        });
        return filteredResults;
      } else {
        return resultList;
      }
    },
    onWatchingListAndStockItemsInTab() {
      if (this.hasToOrderByCost) {
        var processList = [];

        this.list.forEach((x) => {
          var stockInfo = this.stockItemsInTab.filter(
            (y) => y.sku == x[1].sku
          )[0];
          if (stockInfo?.cost) {
            x[1].cost = parseFloat(stockInfo.cost);
          } else {
            x[1].cost = null;
          }
          processList.push(x);
        });

        this.sortedList = processList.sort((a, b) => {
          return b[1].cost - a[1].cost;
        });
      }

      if (this.hasToOrderAdditionalByCost) {
        processList = [];
        this.list.forEach((x) => {
          var stockInfo = this.stockItemsInTab.filter(
            (y) => y.sku == x.aditional.sku
          )[0];
          if (stockInfo?.cost) {
            x.aditional.cost = parseFloat(stockInfo.cost);
          } else {
            x.aditional.cost = null;
          }
          processList.push(x);
        });

        this.sortedList = processList.sort((a, b) => {
          return b.aditional.cost - a.aditional.cost;
        });
      }

      if (this.hasToOrderIngredientByCost) {
        processList = [];
        this.list.forEach((x) => {
          var stockInfo = this.stockItemsInTab.filter((y) => y.sku == x.sku)[0];
          if (stockInfo?.cost) {
            x.cost = parseFloat(stockInfo.cost);
          } else {
            x.cost = null;
          }
          processList.push(x);
        });

        this.sortedList = processList.sort((a, b) => {
          return b.cost - a.cost;
        });
      }

      if (
        !this.hasToOrderByCost &&
        !this.hasToOrderIngredientByCost &&
        !this.hasToOrderAdditionalByCost
      ) {
        this.sortedList = null;
      }
    },
    onExpandSection() {
      if (this.canClick) this.$emit("onExpandSection", this.itemSection.name);
    },
    onClickDropdownButton(buttonName) {
      this.$emit("onClickDropdownButton", buttonName);
    },
    onOpenAndCloseDropdownById(id) {
      this.$emit("onOpenAndCloseDropdownById", id);
    },
    onOpenModal(item, index, extraInfo) {
      if (this.canEdit) this.$emit("onOpenModal", item, index, extraInfo);
    },

    onOpenMenuModal(item, index, extraInfo) {
      this.$emit("onOpenModal", item, index, extraInfo);
    },
    onDownloadQr(e, item, index) {
      e.stopPropagation();
      this.$emit("onDownloadQr", item, index);
    },
    onSelectOption(value) {
      this.selectedCategoryFilter = value;
    },
  },
  components: {
    BistroBadge,
    CurrencyStyle,
    SelectComponent,
  },
};
</script>
<style lang="stylus">

@import "@/assets/css/barcodeStyles.styl";

</style>
