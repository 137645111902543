<template>
  <li>
    <!-- <figure v-if="data.canSelectRow" class="iconHistory">
      <div class="switch">
        <label class="switchTitle">
          <SwitchToggle
            mode="inline"
            @change="toggleCheckbox(data)" />

        </label>
      </div>
    </figure> -->
    <figure :class="{ iconHistory: true}">
      <span
        :class="{
        salesNull: data.status === 1,
        yellow: data.status === 3,
        'icon-Bistro-_Check': true,
        }"
      ></span>
    </figure>

    <!-- FECHA -->
    <div class="items">
      <h5>{{ formattedDate }}</h5>
      <p>{{ formattedHour }}</p>
    </div>

    <!-- OPERACION -->
    <div class="items">
      <div v-if="data.numberMovementType && data.numberMovementType !== null">
        <div>
          <!-- <div class="fiscal-data">{{ data.movementType }}</div> -->
          <p
            v-if="data.numberMovementType >= 0"
            v-bind:class="
              $filters.cashbox(
            data.numberMovementType + 1,
            'class',
            data.paymentType + 1
          )
            "
          >
            {{
              $filters.cashbox(
                data.numberMovementType + 1,
                "text",
                data.paymentType + 1
              )
            }}
          </p>
        </div>
      </div>
      <!-- <div
        v-else-if="
        data.relatedFiscalInfo &&
        data.relatedFiscalInfo.billType !== null &&
        data.relatedFiscalInfo.billNumber !== null
        "
      >
        <div>
          <div class="fiscal-data">{{ data.relatedFiscalInfo.billType }}</div>
          <div class="fiscal-data">
            {{ data.relatedFiscalInfo.billNumber }}
          </div>
          <div class="fiscal-data">
            {{ data.relatedFiscalInfo.customerName }}
          </div>
          <div class="fiscal-data">
            {{ data.relatedFiscalInfo.customerFiscalKey }}
          </div>
        </div>
      </div> -->
      <!-- <div v-else>
        <p
          v-if="data.cashBoxItemType >= 0"
          v-bind:class="
            $filters.cashbox(
          data.cashBoxItemType + 1,
          'class',
          data.paymentType + 1
        )
          "
        >
          {{
            $filters.cashbox(
              data.cashBoxItemType + 1,
              "text",
              data.paymentType + 1
            )
          }}
        </p>
        <p v-if="data.customer">{{ data.customer }}</p>
        <p v-if="data.eventName">{{ data.eventName }}</p>
      </div> -->
    </div>

    <!-- DESCRIPCION -->
    <div class="items">
      <span
        v-if="data.paymentType !== null"
        :title="$filters.payment(data.paymentType + 1)"
        v-bind:class="$filters.payment(data.paymentType + 1, 'icon')"
      ></span>
      <p class="table-row-info">
        /{{ paymentInfo }}
      </p>
      <!-- OPCION MODAL -->
      <div v-if="modal" class="btn-main">
        <a @click="openModal(data)" class="waves-effect waves-light">
          <span class="icon-Bistro-_Search"></span>
          <p>VER DETALLE</p>
        </a>
      </div>
    </div>

    <!-- TOTAL -->
    <div :class="{ items: isRealSale, 'items-grey': !isRealSale }">
      <h3 v-if="data.amount" :class="{ 'negative-numbers': data.amount < 0 }">
        <currency-style :valor="$filters.currency(data.amount)" />
      </h3>
      <h3 v-else class="cero-numbers">
        <currency-style :valor="$filters.currency(0)" />
      </h3>
    </div>
  </li>
</template>
<script>
import mixCommons from "@/mixins/mixCommons";
import CurrencyStyle from "@/components/CurrencyStyle";
import modalCommons from "@/mixins/mixModals";
import { getOrderByUuid } from "@/features/shared/repositories/orders/ordersService"

export default {
  mixins: [mixCommons, modalCommons],
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    // canSelectRow: {
    //   type: Boolean,
    //   default: false,
    // },
    shopCode: {
      type: String
    }
  },
  data() {
    return {
      params: {
        SaleId: null,
        RelatedDeviceId: null,
      },
    };
  },
  computed: {
    isRealSale() {
      if (this.data) {
        return !(
          this.data.paymentType + 1 === 12 || this.data.paymentType + 1 === 13
        );
      } else {
        return true;
      }
    },
    formattedDate () {
      const date = new Date(this.data.date);
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    formattedHour () {
      return this.data.date.split('T')[1].replace('Z', '')
    },
    paymentInfo() {
      return this.constructPaymentInfo(this.data);
    }
  },
  methods: {
    // toggleCheckbox(data) {
    //   data.isChecked = !data.isChecked
    // },
    async openModal(info) {
      const order = await getOrderByUuid(this.shopCode, info.uuid)
      this.$store.updateModalInfoV2(order)
      this.openNonDismissibleModal('#modalResumSalesPos')
    },
    constructPaymentInfo(data) {
      const parts = [
        `#${data.ticketNumber}`,
        data.saleAuthCode ? `Cod.Aut.: ${data.saleAuthCode}` : null,
        data.salePaymentMethod,
        data.saleOrigin
      ];

      return parts.filter(Boolean).join(' - ');
    }
  },
  components: {
    CurrencyStyle,
    // SwitchToggle
  },
};
</script>

