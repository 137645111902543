<script>
import axios from "axios";
import { parseToQuery } from "@/utils/utils";
import authCommons from "@/mixins/authCommons";

export default {
  mixins: [authCommons],
  methods: {
    async initializeDataModals() {
      if (!navigator.onLine) {
        return;
      }
      axios
        .get(`/api/dashboardV2/news`)
        .then((res) => {
          this.showNewsIfPossible(res.data.news);
        })
        .catch((err) => {
          console.error(err.response);
        });

      await this.processDebtModals();
      this.processWizardModal();
    },
    async processDebtModals() {
      if (!(await this.checkSessionInBackendAsync())) {
        return;
      }

      if (this.$route.query.failure === null) {
        this.processDebtFailure();
      } else if (this.$route.query.success === null) {
        this.$store.setDebtPaymentStatus({
          status: "checking",
          uuid: this.$route.query.uuid,
        });
        this.openModalById("#modalDebt");

        this.params = {
          externalReference: this.$route.query.uuid,
          shopCode: null,
        };

        this.$store.initLoading(this);
        axios
          .get(`/api/dashboardV2/isPaymentOk/?${parseToQuery(this.params)}`)
          .then((res) => {
            if (res.data.responseCode == 0) {
              if (!res.data.isOk) {
                this.processDebtFailure(res.data.responseMessage);
              } else {
                this.processDebtSuccess();
              }
            } else {
              this.processDebtFailure(res.data.responseMessage);
            }
          })
          .catch((err) => {
            console.error(err.response);
            this.showError(
              `Algo salió mal. Por favor reintentá más tarde (Cod Err. 418)`
            );
            this.processDebtFailure();
          });
      } else {
        axios
          .get(`/api/dashboardV2/shopDebt`)
          .then((res) => {
            if (res.data.responseCode === 0) {
              if (res.data.shopDebts && res.data.shopDebts.length > 0) {
                this.$store.SET_DUE_DAYS(res.data.dueDays);
                this.showDebtModalIfPossible(res.data);
              }
            } else {
              console.error(`ShopDebt error ->`, res);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    showDebtModalIfPossible(debt) {
      this.$store.setDebt(debt);
      if (
        debt != null &&
        debt.shopDebts.filter((x) => x.amount > 0).length > 0
      ) {
        switch (debt.paymentType) {
          case 2: // TOKU
            this.openModalById("#modalTokuDebt");
            break;

          case 1: // MP
          default:
            this.openModalById("#modalDebt");
            break;
        }
      }
    },
    onCreatingPaymentLink(body) {
      const t = this;
      this.$store.initLoading(this);
      axios
        .post(`/api/dashboardV2/createPaymentLink`, body) /// OK
        .then((res) => {
          if (res.data.responseCode != 0) {
            this.$store.errorLoading(t);
            this.showError(res.data.responseMessage);
          } else {
            this.$store.finishLoading(t);
            window.location.href = res.data.url;
          }
        })
        .catch(() => {
          this.$store.errorLoading(t);
          this.showError(
            `Algo salió mal. Por favor reintentá más tarde (Cod Err. 417)`
          );
        });
    },
    showNewsIfPossible(news) {
      this.$store.setNews(news);
      if (news != null) {
        this.openModalById("#modalNews");
      }
    },
    processDebtFailure(message) {
      this.$store.setDebtPaymentStatus({
        status: "failure",
        uuid: this.$route.query.uuid,
        message:
          (message || `Algo salió mal y no pudimos procesar el pago`) +
          `. Id de pago: #${this.$route.query.uuid}. Por favor reintenta más tarde.`,
      });
      this.openModalById("#modalDebt");
      axios
        .get(`/api/dashboardV2/shopDebt`)
        .then((res) => {
          this.$store.setDebt(res.data);
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    processDebtSuccess() {
      const t = this;
      const body = {
        uuid: this.$route.query.uuid,
        status: "success",
        emailBilling: this.$route.query.emailBilling,
        paymentId: this.$route.query.payment_id,
        message: `Pago recibido correctamente! Los comercios que hayan saldado la deuda serán rehabilitados en unos instantes. Id de pago #${this.$route.query.uuid}`,
      };

      this.$store.setDebtPaymentStatus(body);
      this.openModalById("#modalDebt");
      this.$store.finishLoading(t);
      setInterval(() => {
        window.location.href = "/login";
      }, 10000);
    },
    onSetupWizardClosing() {
      this.closeModalById("#modal-setup-wizard-dashboard");
    },
    processWizardModal() {
      this.$store.initLoading(this);
      axios
        .get(`/api/wizardV2/`)
        .then((res) => {
          if (res.data.responseCode == 0) {
            if (res.data.isSetupWizardEnabled) {
              this.openModalById("#modal-setup-wizard-dashboard");
            }
            this.$store.finishLoading(this);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onCreatingTokuPaymentLink(body) {
      const t = this;
      this.$store.initLoading(this);
      axios
        .post(`/api/dashboardV2/createTokuPaymentLink`, body) /// OK
        .then((res) => {
          if (res.data.responseCode != 0) {
            this.$store.errorLoading(t);
            this.showError(res.data.responseMessage);
          } else {
            this.$store.finishLoading(t);
            window.open(res.data.url, "_blank");
          }
        })
        .catch((err) => {
          this.$store.errorLoading(t);
          console.error(err);
          this.showError(
            `Algo salió mal. Por favor reintentá más tarde (Cod Err. 417)`
          );
        });
    },
  },
};
</script>
