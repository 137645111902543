<template>
	<section class="mainContain-info">

		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<div class="fix-title">
					<h1>Reportes de Rentabilidad de Menú</h1>
				</div>
			</section>
			<section class="mainFilter-filter">
				<Spinner v-if="isLoading" />
				<div v-else @click="regenerateReport()" class="btn-main">
					<a class="waves-effect waves-light padding-button">
						<p>Generar reporte de mes anterior</p>
					</a>
				</div>
			</section>

		</article>


		<!-- LISTADO DE REPORTES -->
		<article v-if="reports && reports.length > 0" class="mainEvents reportDashboard">

			<!-- LISTADO DE REPORTES -->
			<!-- <ul class="providerDetail-facture"> -->
			<ul class="stockReport menuProfList">
				<!-- ASENTAMIENTOS -->
				<!-- VENTAS -->
				<a class="hidden" ref="donwload" :href="href" :download="pdfName">Download</a>
				<li v-for="(item, index) in reports" :key="index">
					<figure class="iconFactu">
						<span class="icon-Bistro-_FileDoble"></span>
					</figure>

					<!-- CONCEPTO -->
					<div class="items stockReportDetailTitle menuProfItem">
						<h3>Reporte de Rentabilidad de Menú</h3>
						<p>{{ item.yearAndMonth }}</p>
					</div>

					<!-- OPERACION -->
					<div class="items stockReportDetail menuProfItem">
						<p><strong>{{ item.shopName }}</strong></p>
						<p>{{ item.timestamp }}</p>
					</div>


					<!-- OPCIONES DE ESTATUS -->
					<div class="items downloadStockReport menuProfItem">

						<a :href="item.url" target="_blank" class="waves-effect waves-light btn-link" download>
							<span class="icon-Bistro-_Download"></span>
							<span>Descargar Reporte</span>
						</a>
					</div>
				</li>
			</ul>

		</article>
		<!-- MENSAJE ERROR -->
		<article v-else-if="reports.length === 0 && loaded" class="reportError">
			<h4>No hay reportes de rentabilidad de menú</h4>
		</article>
		<article v-else v-for="i in 4" :key="i" :class="sectionLoading" class="mainEvents reportDashboard" />

		<div v-if="reports && reports.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="totalCount" :countPerPage="countPerPage"
			class="list__pagination" @nextPage="pageChangeHandle('next')"
			@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>
		
		
		<help-center />
	</section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import rolesCommons from '@/mixins/rolesCommons'
import authCommons from '@/mixins/authCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import computeds from '@/mixins/menuOptions'
import selectPdvs from '@/mixins/select_pdvs'
import axios from 'axios'
import { parseToQuery } from '@/utils/utils'
import BasePagination from '@/components/BasePagination'
import Spinner from '@/components/SpinnerComp'
import HelpCenter from '@/components/HelpCenter'

export default {
	mixins: [authCommons, commons, computeds, rolesCommons, selectPdvs, bistroV2Commons],
	data() {
		return {
			params: {},
			reports: [],
			loaded: false,
			href: '',
			pdfName: '',
			currentPage: 1,
			countPerPage: 10,
			totalCount: 0,
			isLoading: false
		}
	},
	async mounted() {
		this.$store.setHasMounted(false)
		this.$store.setLoadingSkeleton(true)
		if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
			}
		}
	},
	watch: {
		tab() {
			if (this.tab.id !== 6) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		}
	},
	methods: {
		async onMounted() {
			document.title = `${this.appTitle} - Reporte de Rentabilidad de Menú`
			this.$store.setShowProductListSelectionFilter(true)
			this.$store.setLoadingSkeleton(true)
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true
			})
			this.$store.updateSideMenu(3)
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
			}
			this.$store.CALL_MERCHANTS()


			this.params['CurrentPage'] = this.currentPage
			this.params['CountPerPage'] = this.countPerPage
			this.$store.setHasMounted(true)

		},
		isMoreOfOnePage() {
			return (this.totalCount - this.countPerPage) > 0
		},
		getData() {
			if (!this.$store.hasMounted) return
			this.$store.initLoading(this)

			axios.get(`/api/report/${this.getUrl}?${parseToQuery(this.params)}`)
				.then(res => {
					this.reports = res.data.reports
					this.totalCount = res.data.totalCount
					this.$store.finishLoading(this)
					this.loaded = true
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		},
		regenerateReport() {
			this.isLoading = true
			this.$store.initLoading(this);
			let merchantTags = this.getMerchantTags();
			let shopCodes = []
			merchantTags.map(x => {
				shopCodes.push(x.shopCode)
			})
			let body = {
				merchantCodes: shopCodes
			}
			axios
				.post(`/api/report/generateMenuProfitabilityReportManually`, body)
				.then((res) => {
					this.isLoading = false
					if (res.data.responseCode === 0) {
						this.isLoading = false
						this.$store.finishLoading(this);
						setInterval(location.reload(), 1000);
					} else {
						this.hasNoErrors = false;
						this.isLoading = false
						this.$store.errorLoading(this);
						this.showError(res.data.responseMessage);
					}
				})
				.catch((err) => {
					this.hasNoErrors = false;
					this.isLoading = false
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
	},
	computed: {
		getUrl() {
			return 'menuProfitabilityReport'
		}
	},
	components: {
		BasePagination,
		Spinner,
		HelpCenter
	},

}
</script>
<style scoped>
.padding-button {
	padding: 0 10px 0 10px;
}

.skeleton-card {
	margin: 10px 25px;
}
</style>