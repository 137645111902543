<template>
    <div ref="modalBoxPosRef" :id="modalOption" class="modal modal-block-outside" style="overflow-y: auto !important"
        @keydown.esc="closeModal()"
        @keydown.enter="handleEnter"
        >
        <div class="modal-header">
            <div class="image-title">
                <h4>{{ title }}</h4>
                <p>Fácil y Simple</p>
            </div>
            <div class="btnClose">
                <a href="#!" @click="closeModal" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>

        <div class="modal-body">
            <form action class="formGeneral" v-if="!confirmation">
                <section class="formMain article-product">
                    <div class="itemForm " style="width: 100%">
                        <p>Ingrese la información {{ text }} </p>
                        <section>
                            <label>Monto {{ action }}:</label>
                            <div class="input-group">
                                <span v-if="declaredAmount" class="input-icon">$</span>
                                <input type="number" v-model="declaredAmount" class="v-text-field"
                                    :placeholder="`${$filters.currency(0)}   `" @input="validatePositiveNumber" />
                            </div>
                            <p v-if="errorMessage && this.operation != 'open' && this.operation != 'close'" class="error-message">{{ errorMessage }}</p>
                        </section>
                        <section v-if="this.operation == 'withdrawal'">
                            <label>Motivo:</label>
                            <div class="input-group">
                                <select v-model="reason" class="browser-default" placeholder="Selecionar">
                                    <option value disabled selected>
                                        Seleccionar
                                    </option>
                                    <option v-for="option in options" :value="option" :key="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                        </section>
                        <section v-if="this.operation == 'deposit' || this.operation == 'withdrawal'">
                            <label>Observaciones:</label>
                            <div class="input-group input-remarks ">
                                <input type="text" v-model="remarks" maxlength="50"
                                    placeholder="Ej. Ajuste de caja, cambio de efectivo, cobros..." />
                            </div>
                        </section>
                    </div>
                </section>
            </form>
            <section v-else>
                <div class="confirmation">
                    <p v-if="difference != 0" class="modal-description">
                        El monto declarado es <span>{{ differenceText }}</span> al monto registrado.
                        ¿Desea registrar un ajuste por la diferencia?
                    </p>
                    <section class="amount-info">
                        <div>
                            <span>Monto registrado:</span>
                            <h4 class="amount-value">
                                <CurrencyStyle :valor="$filters.currency(registeredAmount)" class="decimal-center"/>
                            </h4>
                        </div>
                        <div>
                            <span>Monto declarado:</span>
                            <h4 class="amount-value">
                                <CurrencyStyle :valor="$filters.currency(declaredAmount)" class="decimal-center"/>
                            </h4>
                        </div>
                        <div>
                            <span>Diferencia encontrada:</span>
                            <div :class="{ 'items-grey': difference > 0 }">

                                <h4 :class="{ 'negative-numbers': difference < 0, }">
                                    <CurrencyStyle :valor="$filters.currency(difference)" class="decimal-center"/>
                                </h4>

                            </div>

                        </div>
                    </section>
                </div>

            </section>


            <div class="mainEvents-filter ">
                <section class="containButtons">
                    <ButtonModals
                    @click="closeModal()"
                    :text="'Cancelar'"
                    :variant="'return btn-modal'"
                    />
                    <ButtonModals
                    @click="confirmationCashBox"
                    :text="'Confirmar'"
                    :variant="'main btn-modal'"
                     v-if="this.operation == 'close' || this.operation == 'open'"
                    />
                    <ButtonModals
                    @click="disableDeposit ? null : confirmationDeposit()"
                    :text="'Confirmar depósito'"
                    :variant="'main btn-modal'"
                    v-else-if="this.operation == 'deposit'"
                    />
                    <ButtonModals
                    @click="confirmationWithdrawal()"
                    :text="'Confirmar retiro'"
                    :variant="'main btn-modal'"
                    v-else-if="this.operation == 'withdrawal'"
                    />

                </section>
            </div>
        </div>
    </div>
</template>

<script>
import MixModals from '@/mixins/mixModals.vue';
import BoxPosCommons from '../mixins/BoxPosCommons.vue';
import CurrencyStyle from '@/components/CurrencyStyle.vue';
import { toast } from "vue3-toastify";
import ButtonModals from '@/features/shared/components/ButtonModals.vue';

export default {
    data() {
        return {
            confirmation: false,
            declaredAmount: '',
            errorMessage: "",
            depositAmount: '',
            remarks: '',
            disableDeposit: true,
            reason: '',
            options: ['Pago a proveedores', 'Compra de insumos', 'Retiro por cierre', 'Otros']
        };
    },
    props: {
        operation: {
            type: String,
            required: false,
            default: '',
        },
        modalOption: {
            type: String,
            default: () => {
                return '';
            },
        },

    },
    mixins: [MixModals, BoxPosCommons],
    methods: {
        confirmationCashBox() {

            if (this.confirmation) {
                const event = this.operation == 'open' ? 'onConfirmOpenAmount' : 'onConfirmCloseAmount';
                this.$emit(event, this.declaredAmount, this.difference);
                this.closeModal();
            }
            if (!this.confirmation) return this.confirmation = true;
        },
        confirmationDeposit() {
            this.$emit('onConfirmDepositAmount', this.declaredAmount, this.remarks)
            this.closeModal();
        },
        confirmationWithdrawal() {
            if(!this.declaredAmount)
            {
                toast.error("Debe declarar un monto para hacer el retiro");
                return;
            }
            if(!this.reason) {
                toast.error("Debe elegir un motivo para hacer el retiro");
                return;
            }
            this.$emit('onConfirmWithdrawal', this.declaredAmount, this.reason, this.remarks)
            this.closeModal();

        },
        closeModal() {
            this.confirmation = false;
            this.resetData()
            this.closeModalById(`#${this.modalOption}`);
        },
        handleEnter() {
            // Según la operación actual, ejecuta la acción correspondiente
            switch (this.operation) {
                case 'open':
                case 'close':
                    this.confirmationCashBox()
                    break
                case 'deposit':
                    if (!this.disableDeposit) {
                        this.confirmationDeposit()
                    }
                    break
                case 'withdrawal':
                    this.confirmationWithdrawal()
                    break
                default:
                    console.warn("No action defined for this operation:", this.operation)
            }

            this.$nextTick(() => {
                this.$refs.modalBoxPosRef?.focus() // Enfocar el botón de confirmar abrir/cerrar caja
            })
        },
        lastCashBoxData() {
            const cashBoxData = localStorage.getItem('cashBox');
            if (!cashBoxData) return null;

            const parsedData = JSON.parse(cashBoxData);
            return parsedData[parsedData.length - 1] || null;
        },
        resetData() {
            this.confirmation = false
            this.reason = ''
            this.declaredAmount = ''
            this.remarks = ''
        },
        validatePositiveNumber(event) {
            let value = event.target.value;
            
            if (value === "") {
                this.errorMessage = "El campo no puede estar vacío";
                this.disableDeposit = true;
                return;
            }

            // Dividir en parte entera y decimal
            const parts = value.split('.');
            
            // Limitar la parte entera a 9 dígitos
            if (parts[0] && parts[0].length > 9) {
                parts[0] = parts[0].slice(0, 9);
            }
            
            // Limitar la parte decimal a 2 dígitos
            if (parts[1]) {
                parts[1] = parts[1].slice(0, 2);
            }
            
            value = parts.join('.');

            // Validar número positivo
            if (/^\d*\.?\d+$/.test(value)) {
                this.errorMessage = "";
                this.disableDeposit = false;
                // Actualizar el v-model
                this.declaredAmount = parseFloat(value);
            } else {
                this.errorMessage = "Ingresa un valor positivo";
                this.disableDeposit = true;
                this.declaredAmount = 0;
            }
        },
        getCashAmountFromLastCashbox() {
            return this.$store.getCashBox?.openAmount ?? 0; //FIXME 
        },
        getCashAmountFromBackendInThisCashbox() {
            return this.$store.getCashBox?.totalAmountOfCashboxMovements ?? 0;
        }

    },
    computed: {
        differenceText() {
            return this.difference > 0 ? "mayor" : "menor";
        },
        registeredAmount () {
            switch(this.operation) {
                case 'close':
                    return this.getCashAmountFromBackendInThisCashbox();
                case 'open':
                    return this.getCashAmountFromLastCashbox();
                default:
                    return 0;
            }
        },
        difference() {
            switch(this.operation) {
                case 'close':
                    return this.declaredAmount - this.getCashAmountFromBackendInThisCashbox();
                case 'open':
                    return this.declaredAmount - this.getCashAmountFromLastCashbox();
                default:
                    return 0;
            }

        },
        title() {
            switch (this.operation) {
                case 'close':
                    return 'Cerrar caja';
                case 'open':
                    return 'Abrir Caja';
                case 'deposit':
                    return 'Depósito de caja';
                case 'withdrawal':
                    return 'Retiro de caja';
                default:
                    return '';
            }
        },
        text() {
            switch (this.operation) {
                case 'open':
                    return 'para la apertura de la caja';
                case 'close':
                    return 'para el cierre de caja';
                case 'deposit':
                    return 'del depósito que desea realizar';
                case 'withdrawal':
                    return 'del retiro que desea realizar';
                default:
                    return '';
            }
        },
        action() {
            switch (this.operation) {
                case 'open':
                    return 'de apertura';
                case 'close':
                    return 'de cierre';
                case 'deposit':
                    return 'a depositar';
                case 'withdrawal':
                    return 'del retiro';
                default:
                    return '';
            }
        },





    },
    components: {
        CurrencyStyle,
        ButtonModals
    }
};
</script>

<style scoped lang="scss">
.decimal-center{
    align-items: center;
}
.error-message {
    font-size: small;
    color: rgb(216, 32, 32);
    margin-top: 0;
}

.input-group {
    position: relative;
    display: flex;
    align-items: center;
    width: 40%;
    &.input-remarks{
        width: 80%; 
    }
}

.input-icon {
    position: absolute;
    left: 20px;
    font-size: 17px;
    margin-bottom: 8px;
    color: #000000;

}

div .mainEvents-filter {
    width: 100%;
}

.v-text-field {
    width: 50%;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: #000;
    text-align: right;
    padding: 0 25px !important;
}

#modalCloseBox,
#modalDepositBox,
#modalWithdrawalBox,
#modalOpenBox {
    width: 40% !important;
    position: fixed;
    top: 15% !important;
}

.formGeneral {
    margin-top: 20px !important;
}

.modal-description {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.amount-info {
    margin-top: 20px;

    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.browser-default{
    margin-bottom: 20px !important;
}



.amount-value {
    font-size: 16px;
    font-weight: bold;
}

.confirmation {
    padding: 20px 100px
}

.items-grey {
    color: #37c866;
}



.containButtons {
    padding: 15px;
    display: flex;
    justify-content: center;
    gap: 10px
}

.errorLoading-mainError {
    background-color: #fff;
    display: flex;
    align-items: center;

    p {
        white-space: nowrap;
    }
}
</style>