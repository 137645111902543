<template>
	<main>
		<article v-if="hasToShowReviews" class="mainFilter mainFilterMobile mainFilter-active mainFilter-activeHover">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<div class="border-right fix-title">
					<h1><span>Reseñas</span></h1>
					<figure class="qr pointer" @click="onDownloadQr()">
						<span class="icon-Bistro-_QR"></span>
					</figure>
				</div>
				<div class="totalFilter fix-totalFilter">
					<p>
						<strong>{{ reviewQuantity ? reviewQuantity : 0 }}</strong>
						<br />Reseñas
					</p>
				</div>
			</section>

			<section class="mainFilter-filter showForMobile pointer">
				<!-- Descargar detalle reseñas -->
				<div>
					<a v-if="mobile == false" :href="`/api/report/exportAllReviews?${excelParams}`"
						class="waves-effect waves-light btn-link filterRecord download">
						<span class="icon-Bistro-_Download"></span>
						<span>Descargar Detalle</span>
					</a>
				</div>

				<!-- <input v-if="mobile === false && hasToShowReviewsCollapseTab" class="search-input" v-model="filterReview" placeholder="Filtrar Reseña" type="text"/> -->
				<div @click="onExpandSection('reviews')" class="arrow"
					:class="{ expanded: hasToShowReviewsCollapseTab }"></div>
			</section>
		</article>

		<!-- LISTADO DE RESEÑAS -->

		<article v-if="hasToShowReviews" v-show="hasToShowReviewsCollapseTab" class="listItems listItems-reviews">
			<article :class="{ errorLoading: reviews && reviews.length === 0 }"
				class="mainEventsReviews salesDashboard">
				<!-- LISTADO  -->
				<ul v-if="reviews && reviews.length > 0" class="mainEventsReviews-history">
					<review-row v-for="(rev, index) in reviews" @onOpenReviewDetail="openReviewDetail" :review="rev"
						:key="index" />
				</ul>
			</article>

			<!-- PAGINADO -->

			<div v-if="reviews && reviews.length > 0 && isMoreOfOnePage()" class="base-paginatio">
				<BasePagination :current-page="currentPage" :totalCount="reviewsQuantity" :countPerPage="countPerPage"
					class="list__pagination" @nextPage="pageChangeHandle('next')"
					@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
			</div>
		</article>

		<article class="mainFilter mainFilterMobile mainFilter-active mainFilter-activeHover">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title pointer" @click="onExpandSection('clients')">
				<div class="border-right fix-title">
					<h1><span>Clientes</span></h1>
				</div>

				<div class="totalFilter fix-totalFilter">
					<p>
						<strong>{{
							selectedCustomerList ? selectedCustomerList.length : 0
						}}</strong>
						<br />Clientes
					</p>
				</div>
			</section>

			<section class="mainFilter-filter showForMobile">
				<!-- Descargar detalle clientes -->
				<input v-if="mobile === false && hasToShowClientsCollapseTab" class="search-input" v-model="filterInput"
					placeholder="Filtrar cliente" type="text" />
				<div class="btn-download-customers">
					<a v-if="mobile == false" :href="`/api/report/exportCustomers/?${excelParams}`"
						class="waves-effect waves-light btn-link filterRecord download">
						<span class="icon-Bistro-_Download"></span>
						<span>Descargar Detalle</span>
					</a>
				</div>
				<div @click="onExpandSection('clients')" class="arrow"
					:class="{ expanded: hasToShowClientsCollapseTab }"></div>
			</section>
		</article>

		<!-- LISTADO DE CLIENTES -->
		<article v-show="hasToShowClientsCollapseTab" v-if="selectedCustomerList && selectedCustomerList.length > 0"
			class="listItems">
			<section v-for="(customer, index) in selectedCustomerList" :key="index"
				class="listItems-product listItems-bigCard mainProvider">
				<a @click="onOpenClientsDetails(customer)" class="waves-effect waves-light cardMain">
					<figure class="nameInitial">
						<p>{{ getInitials(customer.name, customer.lastName) }}</p>
					</figure>

					<div class="item">
						<h2>{{ customer.name }} {{ customer.lastName }}</h2>
						<p>{{ customer.phone }}</p>

						<div v-if="parseFloat(customer.balance) > 0" class="balance balance-superavit">
							Saldo&nbsp;<currency-style :valor="$filters.currency(getBalance(customer))" />
						</div>


						<div v-else class="balance balance-deficit">
							Saldo&nbsp;<currency-style :valor="$filters.currency(getBalance(customer))" />
						</div>
						<!-- <div class="operation">
							<span v-bind:class="customer.paymentType + 1 | payment('icon')"></span>
							<p>{{ customer.lastSaleDate | date('date') }} | {{ customer.lastSaleDate | date('time') }}</p>
						</div> -->
					</div>
				</a>
			</section>
		</article>

		<modal-customer :customer="customer" />
		<modal-review :review="review" />
		<help-center />
	</main>
</template>
<script>
import axios from "axios";
import { parseToQuery } from "../utils/utils";
import mixCommons from "../mixins/mixCommons";
import authCommons from "../mixins/authCommons";
import rolesCommons from "../mixins/rolesCommons";
import bistroV2Commons from "../mixins/bistroV2Commons";
import ModalCustomer from "../components/ModalCustomerV2";
import HelpCenter from "../components/HelpCenter";
import ModalReview from "../components/ModalReview";
import bOrdersCommons from "../mixins/bOrdersCommons";
import ReviewRow from "../components/ReviewRow";
import BasePagination from "../components/BasePagination";
import { db } from "../firebase.js";
import { ref, get } from "firebase/database";
import QrGenerator from "../mixins/QrGenerator";
import CurrencyStyle from "../components/CurrencyStyle";
import MixModals from "@/mixins/mixModals.vue";


export default {
	mixins: [authCommons, QrGenerator, bOrdersCommons, mixCommons, bistroV2Commons, rolesCommons, MixModals],
	data() {
		return {
			// customerList: [],
			selectedCustomerList: [],
			reviews: {},
			reviewResponse: {},
			reviewShopCode: "",
			currentPage: 1,
			countPerPage: 30,
			reviewsQuantity: 0,
			selectedShopCode: null,
			filterInput: "",
			excelPaams: {},
			filterReview: "",
			customer: {},
			params: {
				shopCode: null,
				name: null,
				lastName: null,
			},
			customerParams: {
				shopCode: null,
			},
			reviewQr: "empty",
			isFirstLoad: true,
			isReloadingCustomers: false,
			expandTabName: null,
			filteredReviews: [],
			review: {},
			clientList: []
		};
	},
	async mounted() {
		document.title = `${this.appTitle}  - Clientes`;
		if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(1) === false) {
			this.handleUnauthorizedAccess()
		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
			}
		}
	},
	watch: {
		onCustomerListChanged() {

			this.selectedCustomerList = this.doFiltering(
				this.$store.getRefreshClientList
			);
			this.reviewShopCode = this.$store.getRefreshSelectedShopCode;
			this.getReviews(this.reviewShopCode);
		},
		onClientSelectedShopCodeChanged() {
			this.selectedShopCode = this.$store.getRefreshSelectedShopCode;
			this.excelParams = parseToQuery({ shopCode: this.selectedShopCode });
		},
		filterInput() {
			if (!this.isReloadingCustomers) {
				this.selectedCustomerList = this.doFiltering(
					this.$store.getRefreshClientList
				);
			}
		},
		filterReview() {
			this.filteredReviews = this.doFilteringReviews(this.reviews);
		},
	},
	computed: {
		filterReviews() {
			return this.filteredReviews;
		},
		reviewQuantity() {
			return this.reviewsQuantity;
		},
		hasMultipleQuantityOfClientList() {
			return this.clientList.length > 1;
		},

		onCustomerListChanged() {
			return this.$store.getRefreshClientList;
		},
		onClientSelectedShopCodeChanged() {

			return this.$store.getRefreshSelectedShopCode;
		},
		hasToShowClientsCollapseTab() {
			return this.expandTabName === "clients";
		},
		hasToShowReviewsCollapseTab() {
			return this.expandTabName === "reviews";
		},
	},
	methods: {
		getBalance(customer) {
			return customer?.balance ?? 0;
		},

		onMounted() {
			this.$store.updateSideMenu(-1);
			this.$store.updateTopMenu(1);
			this.$store.CALL_MERCHANTS();
			this.toggleMerchantSelectorByLabel("showClientListSelectionFilter");
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true,
			});
			this.params["CurrentPage"] = this.currentPage;
			this.params["CountPerPage"] = this.countPerPage;


			this.getListByShops();
		},
		isMoreOfOnePage() {
			return this.reviewsQuantity - this.countPerPage > 0;
		},
		getInitials(name, lastName) {
			let str = name + " " + lastName;
			return str
				.split(" ")
				.map((n) => n[0])
				.join("");
		},
		doFiltering(inputList) {
			inputList = inputList.filter(
				(x) => x.lastName?.includes(`*******`) === false
			);
			if (this.filterInput != "" && this.filterInput)
				return this.sortCustomersByNameAndLastName(
					inputList.filter(
						(x) =>
							x.name?.toUpperCase()?.includes(this.filterInput.toUpperCase()) ||
							x.phone?.toUpperCase()?.includes(this.filterInput.toUpperCase()) ||
							x.razonSocial
								?.toUpperCase()
								?.includes(this.filterInput.toUpperCase()) ||
							x.address
								?.toUpperCase()
								?.includes(this.filterInput.toUpperCase()) ||
							x.dni?.toUpperCase()?.includes(this.filterInput.toUpperCase()) ||
							x.email?.toUpperCase()?.includes(this.filterInput.toUpperCase()) ||
							x.lastName?.toUpperCase()?.includes(this.filterInput.toUpperCase())
					)
				);
			else return this.sortCustomersByNameAndLastName(inputList);
		},
		sortCustomersByNameAndLastName(arr) {
			arr.sort((a, b) => {
				// Handle null values for name and lastName
				const nameA = a.name || "";
				const nameB = b.name || "";
				const lastNameA = a.lastName || "";
				const lastNameB = b.lastName || "";

				// First, sort by name
				const nameComparison = nameA.localeCompare(nameB);

				// If names are the same, sort by lastName
				if (nameComparison === 0) {
					return lastNameA.localeCompare(lastNameB);
				}

				return nameComparison;
			});

			return arr
		},
		doFilteringReviews(reviews) {
			if (this.filterReview != "" && this.filterReview)
				return reviews.filter((x) =>
					x.name?.toUpperCase()?.includes(this.filterReview.toUpperCase())
				);
			else return reviews;
		},
		getListByShops(isFirst = true) {
			const t = this;
			t.isReloadingCustomers = true;
			t.clientList = [];
			this.$store.setClientList([]);
			this.$store.getMerchantTags.forEach((shop) => {
				t.$store.initLoading(this);
				t.selectedShopCode = shop.shopCode;
				this.customerParams = [];
				this.customerParams["ShopCode"] = shop.shopCode;
				axios
					.get(`/api/clientsV2/getCustomers?${parseToQuery(this.customerParams)}`)
					.then((res) => {
						if (res.data.responseCode == 0) {
							if (res.data.customers && res.data.customers.length > 0) {

								t.clientList.push({
									shopCode: shop.shopCode,
									listName: `${shop.merchantName}`,
									customers: res.data.customers,
								});
								this.$store.setClientList(t.clientList);
								if (isFirst) {
									t.selectedShopCode = shop.shopCode;
									t.excelParams = parseToQuery({ shopCode: this.selectedShopCode });
									this.$store.setClientSelectedShop(t.selectedShopCode);
									this.$store.setHasToSelectFirstClientItem(true);
									isFirst = false;
								}
								t.isReloadingCustomers = false;
								t.$store.finishLoading(this);
							} else {
								t.$store.finishLoading(this);
								t.getCustomersFromFirebase(t, shop, isFirst);
							}

						} else {
							t.showErrors(res);
							t.$store.finishLoading(this);
							t.getCustomersFromFirebase(t, shop, isFirst);
						}
					})
					.catch((err) => {
						console.error(err)
						this.showErrors(err.response);
						this.$store.finishLoading(this);
						t.getCustomersFromFirebase(t, shop, isFirst);
					});

			});
		},
		async pageChangeHandle(value) {
			console.log('BistroClientV2 pageChangeHandle')
			switch (value) {
				case "next":
					this.currentPage += 1;
					break;
				case "previous":
					this.currentPage -= 1;
					break;
				default:
					this.currentPage = value;
			}
			this.params["CurrentPage"] = this.currentPage;

			this.getReviews(this.reviewShopCode);
		},
		onExpandSection(sectionName) {
			if (this.expandTabName === sectionName) {
				this.expandTabName = "";
			} else {
				this.expandTabName = sectionName;
			}
		},
		async onDownloadQr() {
			//this.createReviewUUIDIfNotExist(reviewQr)
			let subdomain = this.getBOrdersSubDomainByInstance();
			await this.createQrWithLogo(
				`https://${subdomain}.bistrosoft.com/reviews?commerceId=${this.selectedShopCode}`
			)
				.then((url) => {
					var download = document.createElement("a");
					download.href = url;
					download.download = `Review_${this.selectedShopCode}.png`;
					download.click();
					//this.closeDropdown('review', this.reviews.length)
				})
				.catch((err) => {
					console.error(err);
				});
		},

		onOpenClientsDetails(customer) {
			let url = `clientsDetailsV2?cId=${customer.id}&shopCode=${this.onClientSelectedShopCodeChanged}`;
			if (customer.uuid) {
				url += `&cUuid=${customer.uuid}`;
			}
			this.$router.push(url);
		},
		openReviewDetail(review) {
			this.review = review;
			this.openModalById('#modalReview')


		},
		getReviews(shopCode) {
			this.params["ShopCode"] = shopCode;

			axios
				.get(`/api/clientsV2/getReviews?${parseToQuery(this.params)}`)
				.then((res) => {
					if (res.data.responseCode == 0) {
						this.reviews = res.data.reviews;
						this.reviewsQuantity = res.data.totalReviewCount ?? 0;
					} else {
						this.showErrors(res);
						this.reviewsQuantity = 0;
					}
				})
				.catch((err) => {
					this.showErrors(err.response);
				});
		},
		getCustomersFromFirebase(t, shop, isFirst) {
			this.$store.initLoading(this);
			const refDataCustomers = ref(db, "customers/" + shop.shopCode);
			let refDataTrigger = false;

			get(refDataCustomers).then((snapshot) => {
				if (!refDataTrigger) {
					refDataTrigger = true;
					const list = snapshot.val();


					if (list !== undefined && list !== null && list.length > 0) {
						t.clientList.push({
							shopCode: shop.shopCode,
							listName: `${shop.merchantName}`,
							customers: list,
						});
						this.$store.setClientList(t.clientList);

						if (isFirst) {
							t.selectedShopCode = shop.shopCode;
							t.excelParams = parseToQuery({ shopCode: t.selectedShopCode });
							this.$store.setClientSelectedShop(t.selectedShopCode);
							this.$store.setHasToSelectFirstClientItem(true);
							isFirst = false;
						}
					}
					t.isReloadingCustomers = false;
					this.$store.finishLoading(this);
				}
			});
		},
		createReviewUUIDIfNotExist(reviewQr) {
			if (reviewQr !== null && reviewQr !== "") {
				return;
			}
			reviewQr = this.createUUID();
		},



	},
	components: {

		BasePagination,
		ModalCustomer,
		ModalReview,
		ReviewRow,
		HelpCenter,
		CurrencyStyle
	},

};
</script>
