<template>
	<section class="mainContain-info menuEngineering-layout">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<section class="mainFilter-title">
				<h1>Ingeniería de menú</h1>
			</section>

			<section class="mainFilter-filter">
				<div class="filterRecord tooltip">
					<div class="item">
						<h4>{{ quantityStarItems }}</h4>
						<p>Estrella</p>
					</div>
					<div class="item">
						<h4>{{ quantityCowItems }}</h4>
						<p>Vaca</p>
					</div>
					<div class="item">
						<h4>{{ quantityEnigmaItems }}</h4>
						<p>Enigma</p>
					</div>
					<div class="item">
						<h4>{{ quantityDogItems }}</h4>
						<p>Perro</p>
					</div>
				</div>
				<a v-if="mobile == false && hasNoErrors" @click="onSuggestedPriceReportClicked"
					class="waves-effect waves-light btn-link filterRecord download">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Prec. Sugeridos</span>
				</a>

			</section>
		</article>

		<article :class="{ errorLoading: data.orders && data.orders.length === 0 }" class="mainEvents salesDashboard">
			<section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction">
					<ul class="containTransaction-lists">
						<li>
							<h4>Cartas</h4>
						</li>

						<SwitchToggle v-for="(bool, index) in data.menues" :key="index"
							v-model="data.menues[index]" :disabled="bool" :label="`Menu ${index}`" mode="list"
							@change="chargeFilterMenu(index)" />
					</ul>

					<ul class="containTransaction-lists">

						<SwitchToggle mode="list" v-model="categoryFilters" label="categorías" :title="true" />


						<SwitchToggle v-for="(bool, index) in data.categories" :key="index"
							v-model="data.categories[index]" :label="index" mode="list"
							@change="chargeFilterCategory(index, bool)" />
						
					</ul>
				</div>
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>

			<section v-if="hasNoErrors" class="mainEvents-history">
				<article class="menuEngineering">
					<div class="menuEngineering-chart">
						<chart :options="optionScatter" ref="area" auto-resize style="width: 850px; height: 500px">
						</chart>
						<p v-if="data.from">
							Las ventas y las mermas se calculan desde {{ data.from }} hasta
							{{ data.to }}.
							<a :href="data.reportUrl">Descargar reporte de rentabilidad de menú</a>
						</p>
						<p v-if="data.from" class="related-documentation">
							Para conocer más acerca de la ingeniería de menú y sus
							aplicaciones
							<a href="https://ingenieriademenu.com/menu-engineering-ingenieria-de-menus/">haga click
								aquí.</a>
						</p>
					</div>
				</article>
			</section>
			<section v-else class="mainEvents-history">
				<article class="menuEngineering menuEngineering-error">
					<h4>No hay datos para mostrar el gráfico de ingeniería de menú.</h4>
				</article>
			</section>
		</article>

		<help-center />
	</section>
</template>
<script>
import axios from "axios";
import mixCommons from "../mixins/mixCommons";
import rolesCommons from "../mixins/rolesCommons";
import authCommons from "../mixins/authCommons";
import bistroV2Commons from "../mixins/bistroV2Commons";
import { parseToQuery } from "../utils/utils";
import HelpCenter from "../components/HelpCenter";
import VChart from "vue-echarts";
import { initialScatter, dataScatter } from "../fixtures/charts/scatter";
import theme from "../fixtures/theme.json";

import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart } from "echarts/charts";
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
} from "echarts/components";
import SwitchToggle from "@/components/SwitchToggle.vue";


export default {
	mixins: [authCommons, mixCommons, bistroV2Commons, rolesCommons],
	head() {
		return {
			title: "Bistro - Ingeniería de menú",
		};
	},
	setup() {
		use([
			ScatterChart,
			CanvasRenderer,
			TitleComponent,
			TooltipComponent,
			GridComponent,
			LegendComponent,
		]);
		registerTheme("ovilia-green", theme);
	},
	data() {
		return {
			data: {},
			hasNoErrors: true,
			showFilter: false,
			search: false,
			excelParams: "",
			loaded: false,
			selectedShopCodes: [],
			isFirstLoad: true,
			firstEntry: true,
			criteria: "",
			optionScatter: initialScatter,
			params: {
				MenuName: null,
				FilteredCategories: [],
			},
			categoryFilters: true,
			menuEngineeringLists: []
		};
	},
	async mounted() {
		if (this.isUserLoggedIn() === false) {
			this.logout()
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted();
			}
		}
	},
	watch: {
		selectedPdvs() {
			this.processMerchantFilter();
		},
		categoryFilters() {
			for (var key in this.data.categories) {
				this.data.categories[key] = this.categoryFilters;
				this.chargeFilterCategory(key, this.categoryFilters);
			}
		},
		onMenuEngineeringListChanged() {
			this.hasNoErrors = true;
			this.selectedShopCode = this.onMenuEngineeringListChanged;
			this.data = {};
			this.params.MenuName = null;
			this.params.FilteredCategories = [];
			this.getChartInfo();
		},
	},
	computed: {
		hasItems() {
			return (
				this.data.items !== null &&
				this.data.items !== undefined &&
				this.data.items.length > 0
			);
		},
		quantityStarItems() {
			return this.hasItems
				? this.data.items.filter((x) => x.costType == 0).length
				: 0;
		},
		quantityCowItems() {
			return this.hasItems
				? this.data.items.filter((x) => x.costType == 1).length
				: 0;
		},
		quantityEnigmaItems() {
			return this.hasItems
				? this.data.items.filter((x) => x.costType == 2).length
				: 0;
		},
		quantityDogItems() {
			return this.hasItems
				? this.data.items.filter((x) => x.costType == 3).length
				: 0;
		},
		onMenuEngineeringListChanged() {
			return this.$store.getRefreshMenuEngineeringList;
		},
	},
	methods: {
		onMounted() {
			this.$store.updateSideMenu(-1);
			this.$store.updateTopMenu(2);
			this.toggleMerchantSelectorByLabel(
				"showMenuEngineeringListSelectionFilter"
			);
			this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true,
			});

			this.getListByShops();
		},
		onSuggestedPriceReportClicked() {
			this.$store.initLoading(this);
			axios
				.get(`/api/report/menuEngineering?${parseToQuery(this.params)}`)
				.then((res) => {
					if (res.data.responseCode === 0) {
						const link = document.createElement("a");
						link.download = "ReportePreciosSugeridos.xlsx";
						link.href = res.data.reportUrl;
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						this.$store.finishLoading(this);
					} else {
						this.hasNoErrors = false;
						this.$store.errorLoading(this);
						this.showError(res.data.responseMessage);
					}
				})
				.catch((err) => {
					this.hasNoErrors = false;
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		getListByShops() {
			let listOfShops = this.$store.getMerchantTags;

			if (this.selectedShopCodes.length !== 0) {
				listOfShops = this.selectedShopCodes;
			}

			this.menuEngineeringLists = [];
			listOfShops.forEach((x) =>
				this.menuEngineeringLists.push({
					name: `${x.merchantName}`,
					shopCode: x.shopCode,
				})
			);
			this.$store.setMenuEngineeringLists(this.menuEngineeringLists);
			this.selectedShopCode = this.menuEngineeringLists[0].shopCode;

			this.getChartInfo();
		},
		chargeFilterCategory(category, bool) {
			this.data.categories[category] = bool;

			var index = this.params.FilteredCategories.indexOf(category);
			if (bool) {
				this.params.FilteredCategories.splice(index, 1);
			} else if (index < 0) {	
				this.params.FilteredCategories.push(category);
			}
			this.getChartInfo();
		},
		chargeFilterMenu(menu) {
			this.data.menues[this.params.MenuName] = false;

			this.data.menues[menu] = true;
			this.params.MenuName = menu;

			this.params.FilteredCategories = [];
			this.excelParams = parseToQuery(this.params);
			this.getChartInfo();
		},
		processMerchantFilter() {
			let ids = this.selectedPdvs.map((item) => {
				return item.shopCode;
			});

			if (ids.length > 0) {
				this.params["shopCode"] = ids;
			} else {
				delete this.params["shopCode"];
			}
		},
		getChartInfo() {
			clearInterval(this.filterInterval);
			let graph = this.$refs.area?.chart;

			if (graph) {
				graph.showLoading({
					text: "",
					textColor: "#37c866",
					color: "#37c866",
					maskColor: "#efefef",
				});
			}
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval);

				this.$store.initLoading(this);

				this.params["ShopCode"] = this.selectedShopCode;

				axios
					.get(`/api/costV2/getChartInfo?${parseToQuery(this.params)}`)
					.then((res) => {
						if (res.data.responseCode === 0) {
							if (!res.data.chartInfo.items) {
								this.$store.errorLoading(this);
								this.hasNoErrors = false;
								this.showError(
									`Revise haber cargado los costos de los productos. Por ahora no hay datos para calcular la ingeniería de menú en el comercio ${this.selectedShopCode}`
								);
								return;
							} else {
								this.$store.finishLoading(this);
							}
							this.data = res.data.chartInfo;
							this.params.MenuName = this.data.selectedMenu;
							this.excelParams = parseToQuery(this.params);
							this.loadChart();
						} else {
							this.hasNoErrors = false;
							this.$store.errorLoading(this);
							this.showError(res.data.responseMessage);
						}
					})
					.catch((err) => {
						this.hasNoErrors = false;
						this.$store.errorLoading(this);
						this.showErrors(err);
					});
			}, 1000);
		},
		loadChart() {
			dataScatter.series[0].data = [];
			dataScatter.series[1].data = [];
			dataScatter.series[2].data = [];
			dataScatter.series[3].data = [];
			dataScatter.series[4].data = [];

			this.data.items.forEach((item) => {
				dataScatter.series[item.costType].data.push([
					item.profitability,
					item.popularityIndex,
					item.name,
				]);
			});

			setTimeout(() => {
				if (this.$refs.area?.chart) {
					this.$refs.area.chart.hideLoading();
					this.$refs.area.chart.setOption(dataScatter, true);
				}
			}, 1500);
		},
	},
	components: {
		chart: VChart,
		HelpCenter,
		SwitchToggle
	},
};
</script>
