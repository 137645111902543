<template>
    <main>
        <article class="titleProfile grid">
            <figure>
                <img src="@/assets/img/key@2x.png" alt="">
            </figure>
            <div class="item">
                <h1>Hola, Usuario</h1>
                <p>Cambiar tu contraseña es muy rápido</p>
            </div>
        </article>

        <!-- FORMULARIO DE CONTRASENA -->
        <form action="" class="formGeneral grid formPassword">
            <!-- DATOS GENERALES -->
            <article class="formMain">

                <!-- CONTRASENA ACTUAL -->
                <div class="itemForm">
                    <label for="">Contraseña Actual</label>
                    <input v-model="password.OldPassword" type="password" placeholder="Contraseña Actual">
                </div>

                <!-- NUEVA CONTRASENA -->
                <div class="itemForm">
                    <label for="">Nueva Contraseña</label>
                    <input v-model="password.NewPassword" type="password" placeholder="Nueva Contraseña">
                </div>

                <!-- REPETIR CONTRASENA -->
                <div class="itemForm">
                    <label for="">Repita nueva Contraseña</label>
                    <input v-model="password.ConfirmPassword" type="password" placeholder="Repita nueva Contraseña">
                </div>

            </article>

            <!-- SUBMIT -->
            <article class="btn-main">
                <a @click="changePass">
                    <p>Cambiar contraseña</p>
                </a>
            </article>
        </form>
        
    </main>
    <OffLineOverlay />
</template>
<script>
import axios from 'axios'
import mixCommons from '../mixins/mixCommons'
import authCommons from '../mixins/authCommons'
import { toast } from "vue3-toastify";
import OffLineOverlay from "@/features/shared/components/OffLineOverlay.vue";
export default {
    name: 'PasswordPage',
    mixins: [mixCommons, authCommons],
    head() {
        return {
            title: `${this.appTitle} - Cambio de Contraseña`
        }
    },
    data() {
        return {
            password: {
                OldPassword: '',
                NewPassword: '',
                ConfirmPassword: ''
            }
        }
    },
    mounted() {
        this.onMounted()
    },
    methods: {
        async onMounted() {
            if (!navigator.onLine && localStorage['currentUserData']) {
                this.setHideElements()
            return;
            }
            if (await this.checkSessionInBackendAsync()) {
                this.setHideElements()
            }
        },
        setHideElements(){
            this.$store.hideElements({
                hideCalendar: true,
                hideFilters: true,
                hideTabs: true
            });
            this.$store.updateTopMenu(8);
        },
        changePass() {
            if (this.password.NewPassword === this.password.ConfirmPassword) {
                this.$store.initLoading(this)
                axios.post(`/api/auth/change`, {
                    OldPassword: this.password.OldPassword,
                    NewPassword: this.password.NewPassword,
                    ConfirmPassword: this.password.ConfirmPassword
                })
                    .then(res => {
                        this.$store.finishLoading(this)
                        this.password = {
                            OldPassword: '',
                            NewPassword: '',
                            ConfirmPassword: ''
                        }
                        if (res.data.responseCode === 0) {
                            this.showSuccess('Su contraseña fue modificada exitosamente!')
                            setTimeout(() => {
                                this.logout()
                            }, 500)
                        }
                    })
                    .catch(err => {
                        this.$store.errorLoading(this)
                        this.showErrors(err.response)
                    })
            } else {
                toast.error('La confirmación de la nueva contraseña no coincide con la ingresada')
            }
        }



    },
    components:{
        OffLineOverlay
    }

}
</script>

<style scoped>
.offline-overlay {
    margin: 6.6rem 0 0 0px !important;
}
</style>