<template>
  <div id="helpCenter">
    <a
      :class="{ 'flotante-mobile': isMobile, flotante: !isMobile }"
      :href="getHelpCenterUrl()"
      target="_blank"
      ><img
        src="../assets/img/help_outline-24px.svg"
        title="Centro de Ayuda"
        alt="Centro de Ayuda"
    /></a>
  </div>
</template>

<script>
import mixResponsive from "../mixins/mixResponsive";

export default {
  mixins: [mixResponsive],
  props: {
    customUrl: {
      type: String,
      default: null,
    },
  },
  methods: {
    getHelpCenterUrl() {
      if (this.customUrl) {
        return this.customUrl;
      }

      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "PROVENTAS": {
          return "https://www.tasboletaelectronica.cl/contacto#FAQ";
        }
        case "ENERGY_POS_SV": {
          return "https://sv.energy-pos.com/centro-ayuda/";
        }
        default: {
          return "https://bistrosoft.com/centro-ayuda/";
        }
      }
    },
  },
};
</script>
