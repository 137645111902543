<template>
    <section class="section-width">
        <article :class="getMiscTabStyles">
    
            <section class="mainFilter-title pointer" @click="onExpandSection(`misc`)">
                <div class="fix-title">
                    <h1><span>Mis Pagos</span></h1>
                    <p class="clickHint" v-if="hasToShowMiscTab">
                        Clickea aquí para esconder la sección de pagos
                    </p>
                    <p class="clickHint" v-else>
                        Clickea aquí para mostrar la sección de pagos
                    </p>
                </div>
            </section>
            <section class="mainFilter-filter expand-arrow">
                <div @click="onExpandSection(`misc`)" class="arrow" :class="{ expanded: hasToShowMiscTab }"></div>
            </section>
        </article>

        <!--  								-->
        <!-- OPCIONES VARIAS BODY - START 	-->
        <!--  								-->
        <article v-show="hasToShowMiscTab">
            <form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
                <article class="formMain formNotMain">
                    <div class="profile-subtitle itemForm">
                        <div class="settings-div switch">
                            <p v-if="!hasNoLinkValue" class="settings-label settings-label-text">
                                Gestiona de forma segura y rápida los pagos pendientes de 
                                <strong>{{ shopsName }}</strong>.
                                Haz click en el botón a continuación para acceder a tu cuenta y completar el proceso de pago.
                            </p>
                            <p v-else class="settings-label settings-label-text">
                                No hemos podido cargar tus datos de pago. Por favor, recarga la página. Si el problema persiste, comunícate con nuestro equipo de Atención al Cliente.
                            </p>
                        </div>
                        <article v-if="!hasNoLinkValue" class="btn-main btn-main-certificate">
                            <a class="btn-active" @click="onClickTokuLink()">
                                <p>IR A MI CUENTA</p>
                            </a>
                        </article>
                    </div>
                </article>
            </form>
        </article>
        <!--  								-->
        <!-- OPCIONES VARIAS BODY - END 	-->
        <!--  								-->

        <!--                                -->
        <!--     PESTAÑA - MIS FACTURAS     -->
        <!--                                -->
        <article :class="getBillTabStyles">
            <section class="mainFilter-title pointer" @click="onExpandSection(`bills`)">
                <div class="fix-title">
                    <h1><span>Mis Facturas</span></h1>
                    <p class="clickHint" v-if="hasToShowBillTab">
                        Clickea aquí para esconder la sección de facturas
                    </p>
                    <p class="clickHint" v-else>
                        Clickea aquí para mostrar la sección de facturas
                    </p>
                </div>
            </section>
            <section class="mainFilter-filter expand-arrow">
                <div @click="onExpandSection(`bills`)" class="arrow" :class="{ expanded: hasToShowBillTab }"></div>
            </section>
        </article>
        <!--                                -->
        <!-- PESTAÑA - MIS FACTURAS (BODY)  -->
        <!--                                -->
        <article v-show="hasToShowBillTab">
            <form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
                <article class="formMain formNotMain">
                    <MyBills>
                    </MyBills>
                </article>
            </form>
        </article>
    </section>
</template>

<script>
import mixCommons from "@/mixins/mixCommons";
import productV2Commons from "@/mixins/productV2Commons";
import rolesCommons from "@/mixins/rolesCommons";
import MyBills from "@/features/myPayments/components/MyBills.vue";

export default {
    components: {
        MyBills
    },
    mixins: [
		mixCommons,
        rolesCommons,
        productV2Commons,
	],
    data() {
        return {
            shopCode: this.$store.getRefreshSelectedShopCode,
            expandTabName: "",
            listTokuLinks: [],
            hasNoLinkValue: true
        };
    },
    async mounted() {
		document.title = `${this.appTitle} - Mis pagos`;
        
        if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(8) === false) {
            this.handleUnauthorizedAccess()

		} else {
			await this.onMounted()
		}
	},
    methods: {
        async onMounted() {
            const t = this;

			this.toggleMerchantSelectorByLabel("showMyPaymentsListSelectionFilter");
			
            this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true,
			});

            var tokuLinks = await this.getTokuLinks();
            // Crear una lista de solo los shopCodes
            
            this.myPaymentsList = [];
            this.$store.setMyPaymentsList(t.myPaymentsList);

            this.myPaymentsList = tokuLinks.map((x) => {
                return {
                    name: `${x.merchantName}`,
                    code: x.shopCode,
                };
            });

            this.$store.setMyPaymentsList(t.myPaymentsList);


            if(tokuLinks?.length > 0)
                this.hasNoLinkValue = false;

            this.loadListOfTokuLinks(tokuLinks);
        },
        loadListOfTokuLinks(list) {
            this.listTokuLinks = list;
            this.checkUrlToku();
        },
        onClickTokuLink() {
            const result = this.listTokuLinks.find(item => item.shopCode == this.shops);
            
            if(result?.url)
            {
                window.open(result?.url, '_blank');    
            } else
            {
                this.hasNoLinkValue = true;
            }
        },
        onExpandSection(sectionName) {
			if (this.expandTabName === sectionName) {
				this.expandTabName = "";
			} else {
				this.expandTabName = sectionName;
			}
		},
        checkUrlToku(){
            const result = this.listTokuLinks.find(item => item.shopCode == this.shops);
            
            if(result != null)
            {
                if(result.url == null)
                {
                    this.hasNoLinkValue = true;
                } else
                {
                    this.hasNoLinkValue = false;
                }
            }
        }
    },
    computed: {
        getMiscTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowMiscTab,
			};
		},
        getBillTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowBillTab,
			};
		},
        hasToShowBillTab(){
            return this.expandTabName === "bills"
        },
        hasToShowMiscTab() {
			return this.expandTabName === "misc";
		},
        shops() {
			return this.$store.currentShopCodeMyPayments;
		},
        shopsName() {
			return this.$store.currentShopMyPayments;
		},
    },
    watch: {
        shops() {
            this.checkUrlToku();
        }
    }
}
</script>

<style scoped>
    .section-width {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
</style>