<template>
	<!-- CONTENIDO -->
	<section class="mainContain-info">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Ventas por días</h1>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<!-- OPCION MODAL -->
				<a :href="`/api/report/salesPerDaysV2/?${currentQueryString}`" target="_blank"
					class="waves-effect waves-light btn-link hide-mobile">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Detalle</span>
				</a>
			</section>
		</article>

		<!-- LISTADO DE PRODUCTOS V2-->
		<article class="mainEvents">
			<skeletonLoading v-if="componentLoading " />

			<div v-else-if="items.length === 0 && this.loaded === true" class="mainEvents-history reportMonth reportError">
				<h4>No hay ventas diarias registradas</h4>
			</div>
			<!-- LISTADO DE FACTURAS -->
			<ul v-else class="mainEvents-sales-per-day-row reportMonth">
				<!-- Historial 1 -->
				<li @click="openModalDetailV2(item)" v-for="(item, index) in items" :key="index"
					class=" waves-effect waves-light">
					<figure class="iconHistory">
						<span class="icon-Bistro-_Check"></span>
					</figure>

					<!-- FECHA -->
					<div class="items">
						<h5>{{ item.date }}</h5>
					</div>

					<!-- OPERACION -->
					<div class="items">
						<strong>Ventas:</strong>
						<currency-style :valor="$filters.currency(item.byType.fiscalSales)" />
					</div>
					<div class="items">
						<strong>Comandas:</strong>
						<currency-style :valor="$filters.currency(item.byType.orders)" />
					</div>
					<div class="items">
						<strong>Efectivo:</strong>
						<currency-style :valor="$filters.currency(item.byPaymentType.cash)" />
					</div>
					<div class="items">
						<strong>Tarjeta:</strong>
						<currency-style :valor="$filters.currency(item.byPaymentType.cards)" />
					</div>

					<!-- TOTAL -->
					<div class="items">
						<h3><currency-style :valor="$filters.currency(item.detail.total)" /></h3>
					</div>
				</li>
			</ul>
		</article>



		<modal-report :detail="reportDetail" />
		<modal-report-v2 :item="reportDetail" type="día" />
		<help-center />
	</section>
</template>
<script>
import axios from 'axios'
import computeds from '@/mixins/menuOptions'
import commons from '@/mixins/mixCommons'
import authCommons from '@/mixins/authCommons'
import rolesCommons from '@/mixins/rolesCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import selectedPdvs from '@/mixins/select_pdvs_get_data'
import ModalReportV2 from '@/components/ModalReportV2'
import { parseToQuery } from '@/utils/utils'
import HelpCenter from '@/components/HelpCenter'
import CurrencyStyle from '@/components/CurrencyStyle'
import SkeletonLoading from '@/components/SkeletonLoading.vue'

export default {
	mixins: [authCommons, commons, computeds, selectedPdvs, bistroV2Commons, rolesCommons],
	data() {
		return {
			currentQueryString: '',
			params: {},
			reportDetail: {},
			data: {},
			loaded: false,
			items: [],
		}
	},
	watch: {
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0 && this.dateCalendar !== this.dateFormat()) {
				this.params['Period'] = this.tab.param // &From=2020-05-06&Until=2020-05-16
				this.getData()
			}
		},
		tab() {
			if (this.tab.id !== 6 && this.loaded) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
	},
	async mounted() {
		document.title = `${this.appTitle} - Ventas por días`
		this.$store.setHasMounted(false)
		if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
			}
		}
	},
	methods: {
		async onMounted() {
			this.$store.hideElements({
				hideCalendar: false,
				hideFilters: false,
				hideTabs: false
			})
			this.$store.updateSideMenu(3);
			this.$store.updateCalendarType('range')
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
				this.params['Period'] = '&From=' + this.dateFrom(-30) + '&Until=' + this.dateFrom(0)
				await this.$store.UPDATE_CALENDAR(this.dateFormat())
			}
			this.$store.setHasMounted(true)
			this.getData()
		},
		getData() {
			if (!this.$store.hasMounted) return

			this.$store.initLoading(this)
			let ids = this.selectedPdvs.map(item => {
				return item.shopCode
			})
			if (ids.length > 0) {
				this.params['MerchantFilter'] = ids
			} else {
				delete this.params['MerchantFilter']
			}
			this.currentQueryString = parseToQuery(this.params)
			axios.get(`/api/report/salesDataPerDaysV2?${this.currentQueryString}`)
				.then(res => {
					this.items = res.data.items
					this.$store.finishLoading(this)
					this.loaded = true
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		},
		openModalDetailV2(detail) {
			this.reportDetail = detail
			setTimeout(() => {
				/* eslint-disable */
				var modalResumSales = document.getElementById("modalReportMonthV2");
				var instance = M.Modal.init(modalResumSales);
				instance.open();
				/* eslint-enable */

			}, 1000)
		},
		dateFrom(numberOfDaysToAdd) {
			var someDate = new Date()
			someDate.setDate(someDate.getDate() + numberOfDaysToAdd)

			var dd = someDate.getDate()
			var mm = someDate.getMonth() + 1
			var y = someDate.getFullYear()

			return y + '-' + mm + '-' + dd
		},
		dateFormat() {
			var monthNames = ['ene.', 'feb.', 'mar.', 'abr.', 'may.', 'jun.', 'jul.', 'ago.', 'sep.', 'oct.', 'nov.', 'dic.']

			var date = new Date()
			date.setDate(date.getDate())
			return date.getDate() + ' de ' + monthNames[date.getMonth()] + ' de ' + date.getFullYear()
		}
	},
	components: {
		CurrencyStyle,
		ModalReportV2,
		HelpCenter,
		SkeletonLoading
	},
}
</script>
<style scoped>
.container {
	max-width: 100% !important;
	width: 100%;
	margin: 0;
}
</style>
