import { createApp } from "vue";
import App from "./App.vue";
import { VueFire } from "vuefire";
import { firebaseApp } from "./firebase";
import { createPinia } from "pinia";
import router from "./router";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "materialize-css/dist/js/materialize.min.js";
import "@/assets/css/style.styl";
import "@/assets/css/materialize.css";
import M from "materialize-css/dist/js/materialize.min.js";
import { es } from "vuetify/lib/locale";
import '@mdi/font/css/materialdesignicons.css';


// Store
import globalStorePlugin from "./plugins/globalStorePlugin.js";

// Filters
import { payment } from "./filters/payment";
import { currency } from "./filters/currency";
import { stock } from "./filters/stock";
import { customerDetailCashbox } from "./filters/customerDetailCashbox";
import { cashbox } from "./filters/cashbox";
import { date } from "./filters/date";
import { time } from "./filters/time";
import VueGtm from '@gtm-support/vue-gtm';

// Toast
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es },
  },
  icons: {
    defaultSet: 'mdi',
    defaultSize: '24', // Set a default icon size
  },
});



const pinia = createPinia();

const app = createApp(App);
app.config.globalProperties.$M = M;
app.config.globalProperties.$filters = {
  payment,
  currency,
  stock,
  customerDetailCashbox,
  cashbox,
  date,
  time,
};

app
  .use(VueFire, {
    firebaseApp,
  })
  .use(pinia)
  .use(globalStorePlugin)
  .use(router)
  .use(vuetify)
  .use(Vue3Toasity, {
    autoClose: 3000,
  })

app.use(VueGtm, {
    id: 'GTM-PMCFHB5', // Replace with your GTM ID
    vueRouter: router, // Optional: If using Vue Router for SPA tracking
    enabled: true,
    debug: false, // Set to true for debugging in console
  });
  

app.mount("#app");

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('ServiceWorker registered: ', registration);
    }).catch(error => {
      console.log('ServiceWorker registration failed: ', error);
    });
  });
}
