<script>
import axios from "axios";
import moment from "moment";
import errorCommons from "../mixins/mixErrorCommons.vue";
import { toast } from "vue3-toastify";


export default {
  mixins: [errorCommons],
  data() {
    return {
      arrayIvaForDefault: ["10.50", "21.00"],
      availableVatConditions: [
        {
          name: "Responsable Inscripto",
          id: 1,
        },
        {
          name: "Exento",
          id: 2,
        },
        {
          name: "Monotributo",
          id: 3,
        },
      ],
      tabsFilter: [
        {
          id: 1,
          title: "Hoy",
          param: "Today",
          show: this.$store.getAuthUser || localStorage['currentUserData'],
          canBeHide: true,
        },
        {
          id: 2,
          title: "Ayer",
          param: "Yesterday",
          show: false,
          canBeHide: true,
        },
        {
          id: 3,
          title: "Semana",
          param: "Week",
          show:  this.$store.getAuthUser || localStorage['currentUserData'],
          canBeHide: true,
        },
        {
          id: 4,
          title: "Mes",
          param: "Month",
          show:  this.$store.getAuthUser || localStorage['currentUserData'],
          canBeHide: true,
        },
        {
          id: 5,
          title: "Todos",
          param: "All",
          show: false,
          canBeHide: true,
        },

        {
          id: 6,
          title: "Personalizado",
          param: "ConfigurablePeriod",
          show: false,
        },
        {
          id: 7,
          title: "Caja en curso",
          param: "CurrentCashBox",
          show:  this.$store.getAuthUser || localStorage['currentUserData'],
          canBeHide: true,
        },
        {
          id: 8,
          title: "Caja anterior",
          param: "PreviusCashBox",
          show:  this.$store.getAuthUser || localStorage['currentUserData'],
          canBeHide: true,
        },
      ],
      excludedOfflineRoutes: ['/dashboard/indexV2', '/dashboard/reportPDV', '/productspdv'],
      isOnline: navigator.onLine,
    };
  },
  mounted() {
    // INICIALIZAR NICESCROLL
    /* $('html').niceScroll(
        {cursorwidth: '9px', zindex: '9999', cursorborder: '0px', cursorborderradius: '0px', cursorcolor: '#24a5d3'}
      ) */
    // MODALS
    //$(".modal").modal();
    /* eslint-disable */
    var modal = document.querySelectorAll(".modal");
    M.Modal.init(modal);

    // DROPDOWN
    //$(".dropdown-trigger").dropdown();
    var dropdown = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(dropdown);
    // TOOLTIP
    //$(".tooltipped").tooltip();
    var tooltip = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(tooltip);
    /* eslint-enable */
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  computed: {
    /* eslint-disable */
    appTitle() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "PROVENTAS":
          return "Proventas";
        case "ENERGY_POS_SV":
          return "Energy POS";
        default:
          return "Bistro";
      }
    },
    isOffLine() {  
      return !navigator.onLine ? true : false
    },
    isMerchantEnabled(){
            return this.$store.getMerchantStatus
        },
    hasFiscalDataChangedAR() {
      if (this.previousFiscalKey !== this.currentFiscalKey) return true;
      if (this.previousLegalName !== this.currentLegalName) return true;
      if (this.previousLegalAddress !== this.currentLegalAddress) return true;
      if (this.previousPosId !== this.currentPosId) return true;
      if (this.previousVatCondition !== this.currentVatCondition) return true;
      if (this.previousGrossIncome !== this.currentGrossIncome) return true;
      if (this.previousActivitiesStartDate !== this.currentActivitiesStartDate)
        return true;
      else return false;
    },
    hasFiscalDataChangedMX() {
      if (this.previousFiscalKey !== this.currentFiscalKey) return true;
      if (this.previousLegalName !== this.currentLegalName) return true;
      if (this.previousTaxRegime !== this.currentTaxRegime) return true;
      if (this.previousZipCode !== this.currentZipCode) return true;
      else return false;
    },
    currentTaxRegime() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.taxRegime || "N/A";
    },
    previousTaxRegime() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.taxRegime || "N/A";
    },
    currentZipCode() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.zipCode || "N/A";
    },
    previousZipCode() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.zipCode || "N/A";
    },
    currentFiscalKey() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.fiscalKey || "N/A";
    },
    previousFiscalKey() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.fiscalKey || "N/A";
    },
    currentPosId() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.posId || "N/A";
    },
    previousPosId() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.posId || "N/A";
    },
    currentVatCondition() {
      if (!this.currentValues) return null;
      if (this.currentValues.fiscalData.vatCondition) {
        return this.availableVatConditions.filter(
          (x) => x.id === this.currentValues.fiscalData.vatCondition
        )[0].name;
      } else return "N/A";
    },
    previousVatCondition() {
      if (!this.previousValues) return null;
      if (this.previousValues.fiscalData.vatCondition) {
        return this.availableVatConditions.filter(
          (x) => x.id === this.previousValues.fiscalData.vatCondition
        )[0].name;
      } else return "N/A";
    },
    currentLegalName() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.legalName || "N/A";
    },
    previousLegalName() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.legalName || "N/A";
    },
    currentLegalAddress() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.legalAddress || "N/A";
    },
    previousLegalAddress() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.legalAddress || "N/A";
    },
    currentGrossIncome() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.grossIncome || "N/A";
    },
    previousGrossIncome() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.grossIncome || "N/A";
    },
    currentActivitiesStartDate() {
      if (!this.currentValues) return null;
      return this.currentValues.fiscalData.activitiesStartDate || "N/A";
    },
    previousActivitiesStartDate() {
      if (!this.previousValues) return null;
      return this.previousValues.fiscalData.activitiesStartDate || "N/A";
    },
    currentValues() {
      return this.$store.getProfileShops.filter(
        (x) => x.code == this.shopCode
      )[0];
    },
    previousValues() {
      return this.$store.getOriginalProfileShops.filter(
        (x) => x.code == this.shopCode
      )[0];
    },
    mobile() {
      return this.$store.getMobile;
    },
    isMX() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX":
          return true;
        default:
          return false;
      }
    },
    showBillingPage() {
      return this.$store.getAuthUser?.hasToShowBillingPage ?? false;
    },
    hasToShowReviews() {
      return this.$store.getAuthUser?.hasToShowReviews ?? false;
    },
    getProductActionSelected() {
      return this.$store.getProductActionSelected;
    },
    isProviderActionSelected() {
      return this.$store.getProviderActionSelected === "provider";
    },
    isProviderBillActionSelected() {
      return this.$store.getProviderActionSelected === "bills";
    },
    isProductActionSelected() {
      return this.$store.getProductActionSelected === "product";
    },
    showMerchantSelectionFilter() {
      return this.$store.getShowMerchantSelectionFilter;
    },
    showProductListSelectionFilter() {
      return this.$store.getShowProductListSelectionFilter;
    },
    showPDVListSelectionFilter() {
      return this.$store.getShowPDVListSelectionFilter;
    },
    showProviderListSelectionFilter() {
      return this.$store.getShowProviderListSelectionFilter;
    },
    showMyPaymentsListSelectionFilter() {
      return this.$store.getShowMyPaymentsListSelectionFilter;
    },
    showMenuEngineeringListSelectionFilter() {
      return this.$store.getShowMenuEngineeringListSelectionFilter;
    },
    showClientListSelectionFilter() {
      return this.$store.getShowClientListSelectionFilter;
    },
    showUserListSelectionFilter() {
      return this.$store.getShowUserListSelectionFilter;
    },
    showSettingListSelectionFilter() {
      return this.$store.getShowSettingListSelectionFilter;
    },
    /* eslint-enable */
    isOnLine() {
      return navigator.onLine;
    }
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        default:
          this.currentPage = value;
      }
      this.params["CurrentPage"] = this.currentPage;
      this.getData();  
    },
    getTaxValues() {
      if (
        this.$store.getAuthUser.taxValues === null ||
        this.$store.getAuthUser.taxValues === undefined
      ) {
        return this.arrayIvaForDefault;
      }
      
      let taxValues = this.$store.getAuthUser.taxValues.split(",")
      if (process.env.VUE_APP_INSTANCE_CODE == "AR") {
        taxValues.push("Exento")
        taxValues.push("No Gravado")
      }
      return taxValues;
    },
    checkMerchantStatus(){
            if(!navigator.onLine) return 
            axios
            .get(`/api/dashboardV2`)
            .then((res) => {
                if(res.data.responseCode === -1){
                    toast.error(res.data.responseMessage)
                    this.$store.setMerchantStatus(false)
                }
            })
            .catch((err) => {
            console.error(err.response);
            });
        },
    getIEPSTaxValues() {
      return [0, 3, 6, 7, 8, 9, 25, 26.5, 30, 30.4, 50, 53, 160];
    },
    toggleMerchantSelectorByLabel(label) {
      if (label == "showMerchantSelectionFilter")
        this.$store.setShowMerchantSelectionFilter(true);
      else this.$store.setShowMerchantSelectionFilter(false);

      if (label == "showProductListSelectionFilter")
        this.$store.setShowProductListSelectionFilter(true);
      else this.$store.setShowProductListSelectionFilter(false);

      if (label == "showProviderListSelectionFilter")
        this.$store.setShowProviderListSelectionFilter(true);
      else this.$store.setShowProviderListSelectionFilter(false);

      if (label == "showMenuEngineeringListSelectionFilter")
        this.$store.setShowMenuEngineeringListSelectionFilter(true);
      else this.$store.setShowMenuEngineeringListSelectionFilter(false);

      if (label == "showClientListSelectionFilter")
        this.$store.setShowClientListSelectionFilter(true);
      else this.$store.setShowClientListSelectionFilter(false);

      if (label == "showUserListSelectionFilter")
        this.$store.setShowUserListSelectionFilter(true);
      else this.$store.setShowUserListSelectionFilter(false);

      if (label == "showPDVListSelectionFilter")
        this.$store.setShowPDVListSelectionFilter(true);
      else this.$store.setShowPDVListSelectionFilter(false);

      if (label == "showSettingListSelectionFilter")
        this.$store.setShowSettingListSelectionFilter(true);
      else this.$store.setShowSettingListSelectionFilter(false);

      if (label == "showMyPaymentsListSelectionFilter")
        this.$store.setShowMyPaymentsListSelectionFilter(true);
      else this.$store.setShowMyPaymentsListSelectionFilter(false);
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne === "" && dateTwo === "") {
        formattedDates = "Seleccione fecha";
      } else {
        if (dateOne) {
          formattedDates = moment(dateOne).utcOffset(0).format("lll");
        }
        if (dateTwo) {
          formattedDates += " - " + moment(dateTwo).utcOffset(0).format("lll");
        }
      }
      return this.$store.updateDate(formattedDates);
    },
    logout() {
      if(this.isOffLine){
        this.deleteSession()
      } else{
        axios
          .get("/api/auth/logout") 
          .then(() => {
            this.deleteSession()  
        });
      }
    },
    deleteSession(){
      navigator.serviceWorker.controller?.postMessage({ action: 'clear-cache' });
      this.resetUser();
      this.setAutomaticLogin(false);
      this.resetPeriodSelection();
      location.href = "/login";
    },
    getKnowledgeBaseUrl() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX":
          return 'https://bistrosoft.com/ayuda/facturacion-electronica-mexico/'
        case "AR":
        case "ENERGY_POS_SV": ///FIXME Falta definir la URL
        default:
          return 'https://bistrosoft.com/ayuda/como-cargar-tus-datos-fiscales-para-poder-emitir-facturas-desde-tu-bistrosoft/'
      }
    },
    setUserData(userData) {
      localStorage["userData"] = JSON.stringify(userData);
    },
    setMerchantTags(merchantTags) {
      localStorage["merchantTags"] = JSON.stringify(merchantTags);
    },
    getMerchantTags() {
      const jsonMerchantTags = localStorage["merchantTags"];
      if (jsonMerchantTags) {
        return JSON.parse(jsonMerchantTags)?.merchantTags;
      }
      return null;
    },
    setUser() {
      localStorage["loggedUser"] = true;
    },
    resetUser() {
      localStorage["loggedUser"] = false;
      localStorage["merchantTags"] = null;
    },
    handleUnauthorizedAccess(){
        this.showError("No tiene acceso a este módulo");
        setTimeout(() => {
            this.logout();
        }, 1500);
    },
    
    isUserLoggedIn() {
      const result = localStorage["loggedUser"] === "true";
      if (result) {
        var merchantTags = this.getMerchantTags();
        if (!merchantTags) {
          return false;
        }
        this.$store.SET_MERCHANT_TAGS(merchantTags);
        const selectedMerhants = merchantTags.filter(
          (obj) => obj.selected === true
        );
        this.$store.resetSelectedMerchants();
        selectedMerhants.forEach((pdv) => this.$store.selectMerchant(pdv));
      }

      return result;
    },
    formatCurrency(value) {
      return (
        value?.toLocaleString("es-AR", {
          style: "currency",
          currency: "ARS",
        }) || "0"
      );
    },
    setAutomaticLogin(bool) {
      localStorage["isAutomaticLogin"] = bool;
    },
    isAutomaticLogin() {
      return localStorage["isAutomaticLogin"] === "true";
    },
    setPeriodSelection(period) {
      localStorage["periodSelection"] = JSON.stringify(period);
    },
    resetPeriodSelection() {
      // localStorage['periodSelection'] = '{"id":1,"title":"Hoy","param":"Today","show":true,"canBeHide":true}'
      localStorage["periodSelection"] =
        '{"id":7,"title":"Caja en curso","param":"CurrentCashBox","show":true,"canBeHide":true}';
    },
    getPeriodSelectionFromLocalStorage() {
      if (!localStorage["periodSelection"]) return null;
      try {
        return JSON.parse(localStorage["periodSelection"]);
      } catch (e) {
        return null;
      }
    },
    setEditStockMovementList(value) {
      if (value === null || value === undefined) {
        localStorage["editStockMovementList"] = null;
        this.$store.setStockMovements(null);
      } else {
        localStorage["editStockMovementList"] = JSON.stringify(value);
        this.$store.setStockMovements(value);
      }
    },
    getStockMovementList() {
      const string = localStorage["editStockMovementList"];
      if (string === undefined) return "";
      if (string === null || string === "") return string;

      var obj = JSON.parse(string);

      if (!obj.config) return "";

      return obj;
    },
  },
};
</script>
