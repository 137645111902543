<template>
    <article v-if="hasBillsToShow && !isLoading">
        <!-- TITULO -->
        <div class="bills-container-grid bills-container-title">
            <h2>Monto</h2>
            <h2>Mes</h2>
            <h2>Descargar</h2>
        </div>
        <!-- LISTA DE FACTURAS -->
         <div class="scrollable-div">
             <div v-for="bill in getBillsList" :key="bill.id">
                <div class="bills-container-grid bills-container-bill">
                    <label>
                        $ {{ bill.amount}}
                    </label>
                    <label>
                        {{ bill.billDate }}
                    </label>
                    <div class="bill-download-image">
                        <img class="bill-download" 
                             src="../assets/filetype-pdf.svg" 
                             @click="onDownloadBill(bill.pdfFilePath, bill.billDate, true)"
                             title="Descargar Pdf"
                        />
                        <img class="bill-download" 
                             src="../assets/filetype-xml.svg" 
                             @click="onDownloadBill(bill.xmlFilePath, bill.billDate, false)"
                             title="Descargar Xml"
                        />
                    </div>
                </div>
             </div>
         </div>

        <!-- PAGINACION -->
        <div v-if="totalCount > 0 && isMoreOfOnePage()" class="base-pagination">
            <BasePagination :current-page="currentPage" :totalCount="totalCount" :countPerPage="countPerPage"
                class="list__pagination" @nextPage="pageChangeHandle('next')"
                @previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
        </div>
    </article>
    <div v-if="!hasBillsToShow && !isLoading" class="bill-warning">
        <label>No se encontraron facturas disponibles para mostrar</label>
    </div>
    <div class="bill-spinner">
        <Spinner v-if="isLoading" />
    </div>
</template>

<script>
import BasePagination from "@/components/BasePagination";
import Spinner from "@/components/SpinnerComp";
import axios from "axios";
import { parseToQuery } from "@/utils/utils";
import rolesCommons from "@/mixins/rolesCommons.vue";
import errorCommons from "@/mixins/mixErrorCommons";

export default {
    mixins: [rolesCommons, errorCommons],
    components: {
        BasePagination,
        Spinner
    },
    props: {
    },
    data() {
        return {
            //Lista de facturas
            billList: [],
            //Para la paginacion
            currentPage: 1,
            countPerPage: 50,
            totalCount: 100,
            //Para cuando se hagan llamadas al back
            isLoading: true,
        };
    },
    mounted() {
        // this.getBillsByShopCode();
    },
    computed: {
        getBillsList(){
            return this.billList;
        },
        hasBillsToShow(){
            return this.billList?.length > 0
        },
        getCurrentShopCode(){
            return this.$store.currentShopCodeMyPayments
        }
    },
    methods: {
        async getBillsByShopCode() {
            //HAGO LA LLAMADA AL SERVIDOR PARA QUE LLAME AL BACK Y ME TRAIGA LAS FACTURAS
			var params = {};
			params["ShopCode"] = this.getCurrentShopCode;
            params["CountPerPage"] = this.countPerPage;
            params["CurrentPage"] = this.currentPage;
			this.isLoading = true;
			axios
				.get(
					`/api/dashboardV2/getBillsByShopCode?${parseToQuery(params)}`
				)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.isLoading = false;
                        this.billList = res.data.billList
                        this.totalCount = res.data.totalCount
					} else {
                        this.isLoading = false;
						this.showError(res.data.responseMessage);
					}
				})
				.catch(() => {
					this.showError(`No se pudo recuperar las facturas para el comercio ${this.getCurrentShopCode}"`);
				})
		},
        async onDownloadBill(filePath, name, isPdf) {
            let nameFixed = name.replace(" ", "_");
            if(filePath == null || filePath == "")
                this.showError("No se pudo descargar la factura.");
            try 
            {
                const response = await axios.get(
                    `/api/dashboardV2/downloadBill?${parseToQuery({ FilePath: filePath })}`, 
                    { responseType: 'blob' }
                );
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if(isPdf)
                    link.setAttribute('download', `Factura_${nameFixed}.pdf`);
                else
                    link.setAttribute('download', `Factura_${nameFixed}.xml`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                this.showError("No se pudo descargar la factura.");
            }
        },
        async pageChangeHandle(value) {
            //CADA VEZ QUE SE CLIQUEA EN EL PAGINATION SE VA A BUSACAR AL BACK LAS NUEVAS FACTURAS
            switch (value) {
                case "next":
                    this.currentPage += 1;
                    break;
                case "previous":
                    this.currentPage -= 1;
                    break;
                default:
                    this.currentPage = value;
            }
            await this.getBillsByShopCode()
        },
        isMoreOfOnePage() {
            return this.totalCount - this.countPerPage > 0;
        },
    },
    watch: {
        async getCurrentShopCode() { 
            //CADA VEZ QUE SE ACTUALICE EL SHOPCODE DE LA SECCION DE MIS PAGOS ENTRO ACA Y LLAMO AL BACK PARA RECUPERAR LAS FACTURAS
            await this.getBillsByShopCode()
        }
    },
};
</script>

<style scoped>
    .bills-container-grid {
        display: grid;
        grid-template-columns: 1.5fr 1.5fr 0.3fr;
        gap: 10px;
    }
    /* TITULO */
    .bills-container-title {
        padding-bottom: 2px;
        border-bottom: 1px solid #c7c7c7;
    }
    .bills-container-title h2 {
        font-size: 17px;
    }
    .bills-container-title h2:last-child {
        text-align: center;
    }
    /* FACTURAS */
    .bills-container-bill {
        padding-top: 6px;
    }
    .bills-container-bill label{
        font-size: 17px;
        color: black;
        font-family: 'Rubik-Regular', sans-serif;
    }
    .bill-download-image{
        display: flex;
        justify-content: space-evenly;
        scale: 0.9;
    }
    .bill-download-image img{
        cursor: pointer;
    }
    /* CARGO PERO NO TIENE BILLS */
    .bill-warning label{
        font-size: 13px;
        color: #000000DE;
        font-family: 'Rubik-Regular', sans-serif;
    }
    /* CARGANDO */
    .bill-spinner{
        display: flex;
        justify-content: center;
    }
</style>