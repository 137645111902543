<template>
	<div class="flagSelector">
		<div class="flags-desktop" v-if="!isMobile">
			<div
				class="flag"
				v-for="(flag, index) in flags"
				:key="index"
				@click="goToUrl(flag.url)"
				:class="{ selected: flag.selected }"
			>
				<img v-if="flag.hasToShow" :src="flag.src" :alt="flag.altText"/>
			</div>
		</div>

		<div class="flagsResponsiveList" v-if="isMobile">
			<div class="logo-container">
				<img
					src="@assets/img/logobistroblue.svg"
					:width="width"
					:height="height"
				/>
			</div>
			<button class="flag-button" @click="toggleMenu">
				<img :src="selectedFlag.src" :alt="selectedFlag.name" />
				<span class="arrow-down"></span>
			</button>
			<ul class="flag-dropdown" v-show="showMenu">
				<li
					v-for="(flag) in flags.filter(
						(f) => f.src !== selectedFlag.src
					)"
					:key="flag.code"
				>
					<a @click="selectFlag(flag)">
						<img :src="flag.src" :alt="'Bandera de ' + flag.name" />
						<span class="flag-name">{{ flag.name }}</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import responsive from "@/mixins/mixResponsive";

export default {
	mixins: [responsive],
	name: "FlagSelector",
	data() {
		const flags = [
			{
				src: "https://static.bistrosoft.com/WebAccess/images/Flags/Argentina.svg",
				url: "https://webaccess.bistrosoft.com",
				selected: this.isInstanceCode("AR"),
				hasToShow: this.hasToShow("AR"),
				altText: "Argentina",
			},
			{
				src: "https://static.bistrosoft.com/WebAccess/images/Flags/Mexico.svg",
				url: "https://mx.bistrosoft.com",
				selected: this.isInstanceCode("MX"),
				hasToShow: this.hasToShow("MX"),
				altText: "México",
			},
			{
				src: "https://static.bistrosoft.com/WebAccess/images/Flags/Spain.svg",
				url: "https://es.bistrosoft.com",
				selected: this.isInstanceCode("ES"),
				hasToShow: this.hasToShow("ES"),
				altText: "España",
			},
			{
				src: "https://static.bistrosoft.com/WebAccess/images/Flags/Uruguay.svg",
				url: "https://uy.bistrosoft.com",
				selected: this.isInstanceCode("UY"),
				hasToShow: this.hasToShow("UY"),
				altText: "Uruguay",
			},
			{
				src: "https://static.bistrosoft.com/WebAccess/images/Flags/ElSalvador.svg",
				url: "https://sv.energy-pos.com",
				selected: this.isInstanceCode("ENERGY_POS_SV"),
				hasToShow: this.hasToShow("ENERGY_POS_SV"),
				altText: "El Salvador",
			},
		];
		const selectedFlag = this.getSelectedFlag(process.env.VUE_APP_INSTANCE_CODE, flags);

		return {
			flags,
			selectedFlag,
			showMenu: false,
		};
	},
	methods: {
		goToUrl(url) {
			window.location.href = url;
		},
		toggleMenu() {
			const selectedFlag = this.flags.find((flag) => flag.selected);
			this.selectedFlag = selectedFlag ? selectedFlag : this.flags[0];
			this.showMenu = !this.showMenu;
		},
		selectFlag(flag) {
			this.flags.forEach((f) => (f.selected = false));
			flag.selected = true;
			this.selectedFlag = flag;
			this.showMenu = false;
			this.goToUrl(flag.url);
		},
		isInstanceCode(countryCode) {
			return process.env.VUE_APP_INSTANCE_CODE === countryCode;
		},
		hasToShow(countryCode) {
			const currentInstanceCode =  process.env.VUE_APP_INSTANCE_CODE;
			if(currentInstanceCode.includes("ENERGY_POS") && countryCode.includes("ENERGY_POS")) {
				return true;
			} 
			else if(!currentInstanceCode.includes("ENERGY_POS") && !countryCode.includes("ENERGY_POS")) {
				return true;
			} 
			else {
				return false;
			}
		},
		getSelectedFlag(countryCode, flags) {
			switch (countryCode) {
				case "MX":
					return flags[1];
				case "ES":
					return flags[2];
				case "UY":
					return flags[3];
				case "ENERGY_POS_SV":
					return flags[4];
				default:
					return flags[0];
			}
		},
	},
};
</script>

<style scoped lang="stylus"></style>