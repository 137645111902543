<template>
  <button :class="[buttonClass, { disabled: isDisabled }]" @click="handleClick" type="button">
    <span v-if="icon" class="button-icon">
      <img :src="icon" alt="Icon" />
    </span>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonBistro",
  props: {
    variant: {
      type: String,
      default: "solid-blue",
      validator: function (value) {
        const validVariants = [
        // return [
          "solid-blue",
          "solid-green",
          "solid-gray",
          "solid-light-gray",
          "solid-red",
          "solid-bright-red",
          "gradient-blue",
          "gradient-green",
          "gradient-gray",
          "gradient-red",
          "icon-only",
          "transparent",
          "solid-primary-blue",
          "blue-add-category",
          "border-line-red",
          "border-line-blue",
          "shadow"
        // ].includes(value);
        ]
        return value.split(" ").every((v) => validVariants.includes(v));

      },
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClass() {
      return `button-bistro ${this.variant}`;
    },
    isDisabled() {
      return this.$pdv.getIsDisabled;
    },
  },
  methods: {
    handleClick() {
      if (this.isDisabled) return;
      this.$emit("handleClick");
    },
  },
};
</script>

<style lang="scss">
.button-bistro {
  padding: 0 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: $lightColorBg;
  display: inline-flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  min-width: 100px;
  white-space: nowrap;
  min-width: 100px; 
  max-width: 100%;  
  white-space: nowrap; 
  flex-shrink: 0; 
  justify-content: center;
  font-family: 'Rubik-Medium';

  .button-icon {
    margin-right: 6px;
  }

  &.icon-only {
    background: transparent;
    height: 38px;
    padding: 0;
    min-width: 38px;
    justify-content: center;

    span {
      margin: 0;
    }
  }

  &.solid-blue {
    background-color: $solidBlue;
  }

  &.solid-green {
    background-color: $solidGreen;
  }

  &.solid-red {
    background-color: $solidRed;
  }

  &.solid-bright-red {
    background-color: $brightRed;
    font-size: 14px;
  }

  &.solid-gray {
    background-color: $solidGray;
  }

  &.solid-light-gray {
    background-color: $solidLightGray;
    border: 1px solid #acafa9;
    color: $darkColorFont;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 16px;
  }

  &.gradient-blue {
    background: $gradientBlue;
  }

  &.gradient-green {
    background: $gradientGreen;
  }

  &.gradient-red {
    background: $gradientRed;
  }

  &.gradient-gray {
    background: $gradientGray;
  }

  &.solid-primary-blue {
    background-color: $solidBrightBlue;
    color: white;
    font-size: 14px;
    &:hover{
      background-color: $solidBlue;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $solidBrightBlue;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.solid-gray:hover {
    background-color: #6d6d6d;
  }
  &.blue-add-category { 
    color: $solidBrightBlue;
    border: 1px dashed #c4c4c4;
    border-radius: 4px;         
    padding: 10px 20px;   
    margin: 10px auto;      
    cursor: pointer;
    width: 100%;
    background-color: #f4f4f4;

    &:hover {
      background-color: #f4f4f4;
      color: $solidBlue;
    }
   
  }
  &.disabled-add-category {
    color: #6d6d6d;
    border: 1px dashed #c4c4c4;
    margin: 10px auto;      
    width: 100%;
    
  }
  &.border-line-red {
    color: $brightRed;
    border: 1px solid $brightRed;
    background-color: #f4f4f4;
    font-size: 14px;
    &:hover {
      color: $solidRed;
      border: 1px solid $solidRed;
    }
  }
  &.border-line-blue {
    color: $solidBrightBlue;
    border: 1px solid $solidBrightBlue;
    background-color: #f4f4f4;
    font-size: 14px;
  }

  &.shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  &.thin-button {
    height: 40px;
    min-width: 180px;
  }
}
</style>
