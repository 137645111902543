<template>  
  <input 
    :value="displayValue" 
    @input="onInput" 
    @blur="onBlur"
    @keydown="onKeydown"
    :type="type" 
    :placeholder="placeholder" 
    class="search" 
    :class="[{ price: variant === 'price' }, { 'hide-search': modelValue }]" 
  />
</template>

<script>
import currencyCommons from "@/mixins/currencyCommons";
export default {
  mixins: [currencyCommons],
  name: "InputBistro",
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text",
      validator: function (value) {
        return ["text", "email", "number", "search"].includes(value)
      },
    },
    variant: {
      type: String,
      validator: function (value) {
        return value === undefined || value === "" || ["price"].includes(value)
      },
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      inputValue: this.modelValue, // Valor que el usuario está escribiendo
    };
  },
  computed: {
    displayValue() {
      // Mostrar el valor del usuario mientras escribe, formatear solo en "price" cuando desenfoca
      if (this.variant === "price" && this.inputValue !== null && this.inputValue !== "") {
        return this.inputValue // Mostrar lo que el usuario está escribiendo
      }
      return this.modelValue // Si no es "price", mostrar el valor sin cambios
    },
  },
  methods: {
    storeSearch(value) {
      localStorage[`searchQuery`] = value;
    },
    onInput(event) {
      const value = event.target.value;
      if (this.variant === "price") {
        // Filtrar el valor permitiendo solo números y comas
        let filteredValue = value.replace(/[^0-9,]/g, "");
        // Limitar a dos dígitos después de la coma
        filteredValue = filteredValue.replace(/(,\d{2})\d+/g, '$1')

        this.inputValue = filteredValue.slice(0,10);
        event.target.value = this.inputValue
      } else {
        this.inputValue = value;
        this.$emit("update:modelValue", this.inputValue); // Emite el valor limpio
      }

      if(this.type !== 'search') return
      this.storeSearch(this.inputValue)
    },
    onBlur() {
      // Formatear el valor solo al desenfocar el input
      if (this.variant === "price") {
        let formatValue = parseFloat(this.inputValue.replace(",", "."))

        if (isNaN(formatValue) || formatValue === "") {
          formatValue = "0";
        }
        this.inputValue = this.$filters.currency(formatValue).replace("$", "").trim()
        this.$emit("update:modelValue", formatValue);
      }
    },
    onKeydown(event) {
      if (event.key === "Enter") {
        event.target.blur() // Quitar el foco del input
      }
    },
    formatInitialValue() {
      // Formatear el valor inicial si el variant es "price"
      if (this.variant === "price" && this.modelValue !== null && this.modelValue !== "") {
        this.inputValue = this.$filters.currency(this.modelValue).replace(this.getCurrencySymbol, "").trim()
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (this.variant === "price" && newValue !== null && newValue !== "") {
          this.inputValue = this.$filters.currency(newValue).replace(this.getCurrencySymbol, "").trim();
        } else {
          this.inputValue = newValue;
        }
      }
    }
  },
  mounted() {
    this.formatInitialValue() // Formatear el valor inicial cuando el componente se monta
  },
};
</script>

<style scoped lang="scss">
input {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  color: $darkColorFont;
  height: 46px;
  width: 100%;
  max-width: 400px;
  padding: 0 10px;
  margin: 0;

  &[type="search"] {
    background-image: url(@assets/icon-search.svg);
    background-repeat: no-repeat;
    background-position: 12px center;
    padding-left: 40px;
    text-indent: 28px;  /* Ajuste para que el texto no se superponga con el ícono */
  }

  // &[type="number"] {
  &.price {
    background-image: url(@assets/img/dolar-symbol.png);
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 18px 18px;
    padding-left: 40px;
  }

  &.hide-search[type="search"] {
    background-image: none; 
  }

  &[type="text"] {
    color: black;
  }

  &:focus {
    border-color: $primaryColor;
  }
}
</style>
