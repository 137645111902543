<template>
  <div class="numeric-keyboard-bistro" tabindex="0" :class="{ disabled: isDisabled }">
    <span id="container_input_keyboard">
      <b>$</b>
      <input
        type="text"
        v-model="input"
        placeholder="Ingresa tu monto"
        @input="validateInput"
        class="input-number-keyboard"
      />
    </span>
    <p id="description_input_keyboard" v-show="description">
      {{ description }}
    </p>
    <div class="grid-numbers">
      <button v-for="num in numbers" :key="num" :value="num" @click="inputNumber(num)">
        {{ num }}
      </button>
      <button @click="inputDecimal">,</button>
      <button @click="deleteLastCharacter">
        <img src="@assets/icon-delete.svg" width="36" alt="Borrar" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumKeyboardBistro",
  props: {
    description: {
      type: String,
    },
    initialValue: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      input: String(this.initialValue),
    };
  },
  mounted() {
    this.input = this.input.replace(".", ",");
    const parts = this.input.split(",");
    const integerPart = parts[0]
    const decimalPart = parts[1] ? parts[1].slice(0, 2) : "";
    this.input = decimalPart ? `${integerPart},${decimalPart}` : integerPart;
    window.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  watch: {
    input: {
      handler(newValue) {
        const normalizeInput = newValue.replace(",", ".");
        this.$emit('handleChangeKeyboardInput', normalizeInput);
      },
      immediate: true // Esto asegura que el evento se emita también en la primera carga
    }
  },
  computed: {
    isDisabled() {
      return this.$pdv.getIsDisabled;
    },
    numbers() {
      return [7, 8, 9, 4, 5, 6, 1, 2, 3, 0];
    },
  },
  methods: {
    inputNumber(num) {
      const [ , decimalPart] = this.input.split(",");
      if (!decimalPart || decimalPart.length < 2) {
        this.input += num.toString();
      }
    },
    inputDecimal() {
      if (this.input === "") {
        this.input = "0,";
      } else if (!this.input.includes(",")) {
        this.input += ",";
      }
    },
    deleteLastCharacter() {
      this.input = this.input.slice(0, -1);
    },
    validateInput() {
      this.input = this.input.replace(/[^0-9,]/g, "");

      const parts = this.input.split(",");
      let integerPart = parts[0].slice(0, 10);
      let decimalPart = parts[1] || "";

      if (decimalPart.length > 2) {
      decimalPart = decimalPart.slice(0, 2);
      }

      this.input = decimalPart ? `${integerPart},${decimalPart}` : integerPart;
    },
    handleKeyDown(event) {
      if (event.key === ",") {
        event.preventDefault();
        this.inputDecimal();
      }
    },
  },
};
</script>

<style lang="scss">
.numeric-keyboard-bistro {
  display: flex;
  flex-direction: column;

  &.disabled {
    opacity: 0.6; 
    pointer-events: none; 
  }

  .grid-numbers {
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 8px));
    grid-gap: 12px;
  }

  button {
    height: clamp(60px, 5.5vw, 100px);
    font-size: clamp(18px, 2vw, 24px);
    border-radius: 4px;
    border: none;
    background-color: $lightColorBg;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;

    &:hover {
      background-color: #fbfbfb;
    }

    &:active {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    }

    &:focus {
      background-color: #fbfbfb;
    }
  }

  input {
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  #description_input_keyboard {
    margin-bottom: 16px;
    font-size: 16px;
    color: $darkColorFont;
  }

  #container_input_keyboard {
    margin-bottom: 8px;
    display: flex;
    align-items: normal;

    b {
      margin-right: 12px;
      font-size: 34px;
      line-height: 1;
    }
  }
}
.input-number-keyboard {
  height: 2rem !important;
  font-size: 1.5rem !important;
}
</style>
