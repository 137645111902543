<template>
  <div
    class="counter-input-bistro"
  >
    <button @click="decrement" :disabled="value === minValue">-</button>
    <input type="number" :value="value" readonly />
    <button @click="increment">+</button>
  </div>
</template>

<script>
import { toast } from "vue3-toastify"
import { defineComponent } from "vue";

export default defineComponent({
  name: "CounterInputBistro",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: { 
      type: Number,
      default: 99, // Límite máximo de unidades
    }
  },
  emits: ["update:value"],
  methods: {
    decrement() {
      if (this.value > this.minValue) {
        this.$emit("update:value", -1);
      }
    },
    increment() {
      if (this.value < this.maxValue) { // Evita que el valor supere el máximo
        this.$emit("update:value", 1)
      } else {
        toast.error("La cantidad máxima permitida por producto es 99")
      }
    },
  },
});
</script>

<style lang="scss">
.counter-input-bistro {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  background: $gradientBlue;
  border-radius: 4px;
  min-width: 100px;
  input[type="number"] {
    width: 30px;
    border: none !important;
    height: 38px;
    text-align: center;
    font-size: 16px;
    background: none;
    color: $lightColorBg;
    outline: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    &[type="number"] {
      -moz-appearance: textfield;
      margin: 0px 0px;
      height: unset;
    }
  }
  button {
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;
    color: $lightColorBg;
    min-width: 38px;
    height: 38px;
    font-size: 16px;
    border-radius: 4px;
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.alternate-counter {
  margin: 0 0 32px;
  .counter-input-bistro {
    min-width: 100%;
    gap: 8px;
    background: none;
    height: 54px;
    :nth-child(1) {
      order: 2;
    }
    :nth-child(2) {
      order: 1;
      height: 54px;
      border-radius: 4px;
      font-weight: 700;
      max-width: 170px;
      border: 1px solid $darkColorFont;
      color: $darkColorFont;
      background: $lightColorBg;
      text-align: left;
      padding: 0 16px;
      transition: all 0.3s ease;
      &:focus {
        border-color: $solidBlue;
      }
    }
    :nth-child(3) {
      order: 3;
    }
    button {
      background-color: $solidBlue;
      height: 54px;
      min-width: 54px;
      border-radius: 4px;
      font-size: 30px;
    }
  }
}
</style>
