import cashboxesIndexDB from "./cashboxesIndexedDBRepository";
import { ORDER_TYPE, ORDER_STATUS } from "../../utils/orderStatuses"

const getTotalMovements = async (shopCode) => {
  if (!shopCode) return [];
  return cashboxesIndexDB.getMovementsCashbox(shopCode) || [];
};

//Esta funcion me trae el total de efectivo que deberia tener la caja
const getTotalCashAmountFromCashboxMovements = async (shopCode) => {
  const data = await getTotalMovements(shopCode);
  // Filtrar los movimientos de tipo "EFECTIVO"
  const cashMovements = data.filter((item) => item.salePaymentMethod === 'EFECTIVO');
  return cashMovements.reduce((total, item) => total + (item.amount || 0), 0);
};

const getVoidsTuple = async (shopCode) => {
  const data = await getTotalMovements(shopCode);

    // Filtrar los movimientos de tipo "VOID"
    const filteredData = data.filter((item) => item.movementType === ORDER_TYPE.VOID);
  
    // Calcular el total de montos anulados
    const voidsAmount = filteredData.reduce((acc, item) => acc + (item.amount || 0), 0); 

    // Contar la cantidad de movimientos anulados
    const voidsCount = filteredData.length;

  // Retornar el objeto con los valores calculados
  return { voidsAmount, voidsCount };

};
const getOrdersTuple = async (shopCode) => {
  const data = await getTotalMovements(shopCode);

    // Filtrar los movimientos de tipo "VOID"
    const filteredData = data.filter((item) => item.movementType === ORDER_TYPE.COMANDA);
  
    // Calcular el total de montos anulados
    const ordersAmount = filteredData.reduce((acc, item) => acc + (item.amount || 0), 0); 

    // Contar la cantidad de movimientos anulados
    const ordersCount = filteredData.length;

  // Retornar el objeto con los valores calculados
  
  return { ordersAmount, ordersCount };

};


//TODO reutilizar la funcion y buscar por origen
// const getTotalOnlineCashAmountFromCashboxMovements = async (shopCode) => {
//   const data = await getTotalMovements(shopCode);
//   console.log("Datos de DATA QUE TIENE ??",data)
//   // Filtrar los movimientos de tipo "EFECTIVO"
//   const onlineCashMovements = data.filter((item) => item.salePaymentMethod === "ONLINE");
//   const result = onlineCashMovements.reduce((total, item) => total + (item.amount || 0), 0);
//   console.log("REsultado onlineCashMovements ", result)
//   return result
// };

// Función genérica para filtrar con múltiples condiciones
const getFilteredCashboxData = async (
  shopCode,
  filterKey,
  filterValue,
  reduceKey = null,
  additionalFilterKey = null,
  additionalFilterValue = null
) => {
  const data = await getTotalMovements(shopCode);

  const filteredData = data.filter(
    (item) =>
      item[filterKey] === filterValue &&
      // (!additionalFilterKey || item[additionalFilterKey] === additionalFilterValue)
      (!additionalFilterKey || item[additionalFilterKey] === additionalFilterValue || item[additionalFilterKey] === ORDER_STATUS.VOID)
  );

  if (reduceKey) {
    return filteredData.reduce((acc, item) => acc + (item[reduceKey] || 0), 0);
  }

  return filteredData;
};

// Función para filtrar todos los movimientos de CAJA ANTERIOR
const getMovementsPreviousCashBox = async (shopCode) => {
  const allMovements = await cashboxesIndexDB.getCashboxes(shopCode);
  // Filtrar los movimientos de tipo "CIERRE DE CAJA"
  const closureMovements = allMovements.filter(m => m.movementType === "CIERRE DE CAJA");
  // Verificar si hay al menos dos cierres de caja
  if (closureMovements.length < 2) {
    return allMovements
  }
  // Obtener el segundo cierre de caja
  const secondClosure = closureMovements[closureMovements.length - 2];
  // Filtrar los movimientos que ocurrieron posterioes del segundo cierre de caja
  const filteredMovements = allMovements.filter(movement => new Date(movement.date) > new Date(secondClosure.date));

  return filteredMovements;
};


// Función específica para contar elementos
const getCountForFilteredCashboxData = async (
  shopCode,
  filterKey,
  filterValue
) => {
  const data = await getFilteredCashboxData(shopCode, filterKey, filterValue);
  return data.length;
};

const getFilteredCashboxTotalOrders = async (shopCode) => {
  return getCountForFilteredCashboxData(shopCode, "movementType", ORDER_TYPE.COMANDA);
};

const getFilteredCashboxTotalWithdrawal = async (shopCode) => {
  return getFilteredCashboxData(shopCode, "movementType", "RETIRO", "amount");
};


const getFilteredCashboxTotalDeposit = async (shopCode) => {
  return getFilteredCashboxData(shopCode, "movementType", "DEPOSITO", "amount");
};

// Funciones específicas para "salePaymentMethod" y "movementType === COMANDA"
const getCashboxTotalForCash = async (shopCode) => {
  return getFilteredCashboxData(
    shopCode,
    "salePaymentMethod",
    "EFECTIVO",
    "amount",
    "movementType",
    ORDER_TYPE.COMANDA
  );
};


const getCashboxTotalForQR = async (shopCode) => {
  return getFilteredCashboxData(
    shopCode,
    "salePaymentMethod",
    "QR",
    "amount",
    "movementType",
    ORDER_TYPE.COMANDA
  );
};

const getCashboxTotalForOnline = async (shopCode) => {
  return getFilteredCashboxData(
    shopCode,
    "salePaymentMethod",
    "ONLINE",
    "amount",
    "movementType",
    ORDER_TYPE.COMANDA
  );
};

const getCashboxTotalForCard = async (shopCode) => {
  return getFilteredCashboxData(
    shopCode,
    "salePaymentMethod",
    "TARJETA",
    "amount",
    "movementType",
    ORDER_TYPE.COMANDA
  );
};


const getCashboxAuditsMovements = async (shopCode) => {
  if (!shopCode) return [];

  const data = await getTotalMovements(shopCode);

  // Filtrar los movimientos que sean de tipo "DEPOSITO" o "RETIRO"
  return data.filter(
    (item) => 
      item.movementType === "DEPOSITO"
     || item.movementType === "RETIRO"
     || item.movementType === "AJUSTE EN APERTURA DE CAJA"
     || item.movementType === "AJUSTE EN CIERRE CAJA"
     || item.movementType === ORDER_STATUS.VOID
  );

};


export {
  getCashboxTotalForCash,
  getCashboxAuditsMovements,
  getCashboxTotalForQR,
  getCashboxTotalForCard,
  getCashboxTotalForOnline,
  getFilteredCashboxTotalOrders,
  getFilteredCashboxTotalWithdrawal,
  getFilteredCashboxTotalDeposit,
  getTotalMovements,
  getTotalCashAmountFromCashboxMovements,
  getVoidsTuple,
  getOrdersTuple,
  getMovementsPreviousCashBox
  // getTotalOnlineCashAmountFromCashboxMovements
};



