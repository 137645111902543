<script>
import axios from "axios";
import currencyCommons from "../mixins/currencyCommons.vue";
import shopCommons from "@/features/shared/mixins/shopCommons.vue";
import { parseToQuery } from "../utils/utils";
import bOrdersCommons from "../mixins/bOrdersCommons";
import authCommons from "../mixins/authCommons";
import QrGenerator from "../mixins/QrGenerator";
import { toast } from "vue3-toastify";
import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";


import { db } from "../firebase.js";
import {
  ref,
  get,
  update,
  push,
  set,
  remove,
  off,
} from "firebase/database";

export default {
  /* eslint-disable */
  mixins: [authCommons, bOrdersCommons, QrGenerator, currencyCommons, shopCommons],
  data() {
    return {
      currentProduct: null,
      currentCombo: null,
      objectToRemove: null,
      isNewProduct: false,
      isNewCombo: false,
      deletingCombo: false,
      deletingProduct: false,
      isNewIngredient: false,
      availableBackups: [],
      additionalOptionType: "Adicional",
      excelParams: "",
      excelAditionalParams: "",
      isNewOption: false,
      optionType: "",
      menues: [],
      origins: [],
      originsForUse: [],
      menu: {
        key: "",
        name: "",
        origins: [],
      },
      productsByMenu: [],
      isNewMenu: false,
      category: {
        name: "",
        optionsGroups: [],
      },
      isNewCategory: false,
      oldCategoryName: "",
      combos: [],
      ingredients: [],
      ingredientsNoFilter: [],
      combosFromFirebase: [],
      checkedCategories: [],
      initialCheckedCategories: [],
      currentMenu: null,
      originsNodo: [],
      savingControl: false,
      deleteControl: 0,
      controlVariablex: false,
      onUpsertMenuNro: 0,
      parentShopCode: null
    };
  },
  computed: {
    isEnergyPOS() {
      return (
        process.env.VUE_APP_INSTANCE_CODE === "ENERGY_POS_AR" ||
        process.env.VUE_APP_INSTANCE_CODE === "ENERGY_POS_SV"
      );
    },
    getMenues() {
      return this.menues;
    },
    getCurrentSku() {
      if (this.currentProduct === null) {
        return "";
      }

      return this.currentProduct.sku;
    },
    getCurrentComboSku() {
      if (this.currentCombo === null) {
        return "";
      }

      return this.currentCombo.sku;
    },
    hasCentralizedProducts() {
      if (this.selectedList == null || this.selectedList == undefined) return false;
      const list = this.$store.getCentralizedProductsList;
      if (list === null || list === undefined)
        return false;
      if (list.length == 0)
        return false;
      var index = list.findIndex((t) => t.shopCode === this.selectedList.shopCode);
      if (index == -1)
        return false;
      return list[index].hasCentralizedProducts;
    },
    canEditProductsCheck() {
      if (this.hasCentralizedProducts) {
        return this.canModifyCentralizedProducts;
      } else {
        return this.canEditProducts
      }
    },
    getParentShopCode() {
      let shopbindings = this.$store.getCentralizedProductsList
      if (shopbindings !== undefined) {
        const parentShop = shopbindings.filter(x => x.shopCode === this.selectedList.shopCode)[0];
        if (parentShop !== undefined) {
          if (parentShop.canModifyCentralizedProducts) return parentShop.shopCode
          else return parentShop.parentShopCode
        }
        return undefined
      }
      return undefined
    },
    canModifyCentralizedProducts() {
      if(this.$store.getAuthUser?.roles?.filter(role => role.includes("ProductsAdministration") || role.includes("Administrador")).length == 0)
        return false;
      if (this.selectedList == null || this.selectedList == undefined) return false;
      const list = this.$store.getCentralizedProductsList;
      if (list === null || list === undefined)
        return false;
      if (list.length == 0)
        return false;
      var index = list.findIndex((t) => t.shopCode === this.selectedList.shopCode);
      if (index == -1)
        return false;
      return list[index].canModifyCentralizedProducts;
    }
  },
  methods: {
  getProductsInUse(products) {
    let array = [];
    products.forEach((p) => {
      if (p.currentPrice !== "") {
        array.push(p);
      }
    });
    return array;
  },
    getContextFromBackendForProducts(handler) {
      if(!navigator.onLine) return 
      this.$store.initLoading(this);
      axios
        .get(`/api/productsV2/getContextFromBackendForProducts`)
        .then((res) => {
          this.$store.finishLoading(this);

          if (res.data.responseCode == 0) {
            this.$store.loadBackendContext(res.data);
            if (handler !== null && handler !== undefined) {
              handler();
            }
          }
          else {
            console.error(res.data.responseMessage);
          }
        })
        .catch(() => {
          this.$store.errorLoading(this);
        });
    },

    async getTokuLinks() {
      this.$store.initLoading(this);

      var res = await axios
        .get(`/api/productsV2/getTokuLinks`)
        .catch(() => {
          this.$store.errorLoading(this);
        });
        this.$store.finishLoading(this);

        if (res.data.responseCode == 0) {
          return res.data.listTokuUrls;
        }
        else {
          console.error(res.data.responseMessage);
        }
        return null;
    },

    loadOrigins() {
      const refDataOrigins = ref(db, "origins");

      get(refDataOrigins).then((snapshot) => {
        this.originsNodo = snapshot.val();
      });
    },
    async onGenerateBarcode(menu) {
    // Llama a `getProductsByMenu` para obtener los productos asociados a la carta
    this.getProductsByMenu(menu.origins, () => {
      // Filtrar solo los productos en uso
      const filteredProducts = [];
      this.productsByMenu.forEach((category) => {
        const productsInUse = this.getProductsInUse(category.products);
        if (productsInUse.length > 0) {
          filteredProducts.push(...productsInUse); 
        }
      });

      if (filteredProducts.length === 0) {
        return;
      }

      // Crear un documento PDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 10;
      const labelWidth = 80;
      const labelHeight = 40;
      let x = margin;
      let y = margin;

      const currentDate = new Date().toLocaleDateString(); // Fecha actual

      filteredProducts.forEach((product) => {
        try {
          // Dibujar rectángulo para la etiqueta
          doc.rect(x, y, labelWidth, labelHeight);

          // Agregar nombre del producto
          doc.setFontSize(10);
          doc.text(product.name, x + 5, y + 8);

          // Agregar precio en la esquina superior derecha
          doc.setFontSize(14);
          doc.setFont("helvetica", "bold");
          const price = `$${parseFloat(product.currentPrice).toFixed(2)}`;
          const priceWidth = doc.getTextWidth(price);
          doc.text(price, x + labelWidth - priceWidth - 5, y + 14);

          // Generar código de barras
          const barcodeCanvas = document.createElement("canvas");
          JsBarcode(barcodeCanvas, product.sku, {
            format: "CODE128",
            width: 1.5,
            height: 15,
            displayValue: false,
          });
          const barcodeImage = barcodeCanvas.toDataURL("image/png");

          // Agregar código de barras al PDF
          const barcodeY = y + 15;
          doc.addImage(barcodeImage, "PNG", x + 10, barcodeY, labelWidth - 20, 15);

          // Agregar SKU debajo del código de barras
          doc.setFontSize(10);
          doc.setFont("helvetica", "normal");
          const skuWidth = doc.getTextWidth(product.sku);
          doc.text(product.sku, x + (labelWidth - skuWidth) / 2, barcodeY + 16);

          // Agregar fecha de generación en la esquina inferior derecha
          doc.setFontSize(8);
          doc.text(currentDate, x + labelWidth - 20, y + labelHeight - 5);

          // Ajustar posición para la siguiente etiqueta
          x += labelWidth + 5;
          if (x + labelWidth > pageWidth - margin) {
            x = margin;
            y += labelHeight + 5;
          }

          // Saltar de página si se llena la hoja
          if (y + labelHeight > pageHeight - margin) {
            doc.addPage();
            x = margin;
            y = margin;
          }
        } catch (error) {
          console.error("Error al generar etiqueta:", error);
        }
      });

      // Descargar el archivo PDF
      doc.save("etiquetas_productos.pdf");
      });
    }
    ,
    async onDownloadQr(menu, index) {
      this.createMenuUUIDIfNotExist(menu, index);

      let subdomain = this.getBOrdersSubDomainByInstance();

      await this.createQrWithLogo(
        `https://${subdomain}.bistrosoft.com/menu?commerceId=${this.selectedList.shopCode}&menuUUID=${menu.uuid}`
      )
        .then((url) => {
          var download = document.createElement("a");
          download.href = url;
          download.download = `Menu_${menu.name}_${this.selectedList.shopCode}.png`;
          download.click();
          this.closeDropdown("menu", this.menues.length);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async onDownloadQrPDV(shopCode) {
      const {subdomain, filteredMenue} = await this.getSubdomainAndFilteredMenu(shopCode)
      
      await this.createQrWithLogo(
        `https://${subdomain}.bistrosoft.com/menu?commerceId=${shopCode}&menuUUID=${filteredMenue.uuid}`
      )
        .then((url) => {
          let download = document.createElement("a")
          download.href = url
          download.download = `Menu_${filteredMenue.name}_${shopCode}.png`
          download.click()
        })
        .catch((err) => {
          console.error(err)
        });
    },
    async openMenuWeb(shopCode) {
      try {
        const { subdomain, filteredMenue } = await this.getSubdomainAndFilteredMenu(shopCode)
        const url = `https://${subdomain}.bistrosoft.com/menu?commerceId=${shopCode}&menuUUID=${filteredMenue.uuid}`
        window.open(url, "_blank")
      } catch (error) {
        console.error("No se pudo abrir la URL:", error.message)
      }
    },
    async getSubdomainAndFilteredMenu(shopCode){
      try {
        const productsRef = ref(db, `products/${shopCode}/idList`)
        const products = await get(productsRef)
        const idList = products.val()

        const listProductsRef = ref(db, `listProducts/${idList}/menues`)
        const listProducts = await get(listProductsRef)
        const menues = listProducts.val()

        const filteredMenue = menues.find(menu =>
          menu.origins.some(origin => origin.name === "Mostrador")
        )

        const subdomain = this.getBOrdersSubDomainByInstance()

        return { subdomain, filteredMenue }
      } catch (error) {
        console.error("Error obteniendo el subdomain y el menú filtrado:", error.message)
      }
    },
    createMenuUUIDIfNotExist(menu, index) {
      if (menu.uuid !== null && menu.uuid !== undefined) {
        return;
      }

      menu.uuid = this.createUUID();

      const t = this;

      const currentData = ref(db, `listProducts/${t.selectedList.id}`);

      get(currentData).then((snapshot) => {
        const resp = snapshot.val();
        if (resp !== null) {
          resp.menues.splice(index, 1);
          resp.menues.push({
            name: menu.name,
            origins: menu.origins,
            uuid: menu.uuid,
          });

          const dataMenues = ref(
            db,
            `listProducts/${t.selectedList.id}/menues/`
          );

          update(dataMenues, resp.menues);

          t.refreshProductList(t.selectedList, () => {
            t.finishUpsert(menu.origins);
          });
        }
      });
    },
    isExistPriceForThisProduct(prod) {
      if (
        prod.origins !== null &&
        prod.origins !== undefined &&
        prod.origins.length > 0
      ) {
        return true;
      }
      return false;
    },
    disableProductOnBackEnd(sku) {
      axios
        .post(`/api/productsV2/disableProductOnBackend`, {
          sku: sku,
          shopCode: this.getSelectedShop,
        })
        .then((res) => {
          if (res.data.responseCode === 1) {
            //this.showSuccess(res.data.responseMessage);
          } else if (res.data.responseCode !== 0) {
            //this.showError(res.data.responseMessage);
          }
        })
        .catch((err) => {
          this.showErrors(err.response);
        });
    },
    disableProductOnBackEndPos(sku) {
      axios
        .post(`/api/productsV2/disableProductOnBackend`, {
          sku: sku,
          shopCode: this.shopCode,
        })
        .then((res) => {
          if (res.data.responseCode === 1) {
            //this.showSuccess(res.data.responseMessage);
          } else if (res.data.responseCode !== 0) {
            //this.showError(res.data.responseMessage);
          }
        })
        .catch((err) => {
          this.showErrors(err.response);
        });
    },
    enableProductOnBackEnd(sku, shopCode) {
      axios
        .post(`/api/productsV2/enableProductOnBackend`, {
          sku: sku,
          shopCode: shopCode,
        })
        .then((res) => {
          if (res.data.responseCode === 1) {
            //this.showSuccess(res.data.responseMessage);
          } else if (res.data.responseCode !== 0) {
            //this.showError(res.data.responseMessage);
          }
        })
        .catch((err) => {
          this.showErrors(err.response);
        });
    },
    onConfirmRemove() {
      switch (this.objectToRemove.type) {
        case "Carta":
          this.deleteMenu(
            this.objectToRemove.object,
            this.objectToRemove.index
          );
          this.closeModalById("#modalMenu");
          break;
        case "Producto":
          this.deleteProduct(
            this.objectToRemove.object,
            this.objectToRemove.index
          );
          break;
        case "Adicional":
          this.deleteAditional(this.objectToRemove.object);
          break;
        case "Gusto":
          this.deleteTaste(this.objectToRemove.object);
          break;
        case "Categoría":
          this.deleteCategory(this.objectToRemove.object);
          break;
        case "Combo":
          this.deleteCombo(
            this.objectToRemove.object,
            this.objectToRemove.index
          );
          break;
        case "Ingrediente":
          this.deleteIngredient(
            this.objectToRemove.object,
            this.objectToRemove.index
          );
          break;
        default:
          break;
      }

      this.objectToRemove = null;
      this.closeModalById("#ModalOfConfirmationToRemove");
    },
    onMenuModalClosed() {
      this.currentMenu = null;
    },
    getProductsByMenu(origins, callback) {
      const t = this;
      t.productsByMenu = [];
      let productsByDepartment = [];

      const refDataListProducts = ref(db, "listProducts/" + t.selectedList.id);

      get(refDataListProducts).then((snapshot) => {
        const resp = snapshot.val();
        if (resp != null) {
          t.joinProductsOrCombosListToMenu(
            productsByDepartment,
            resp.products,
            origins,
            false
          );
          t.joinProductsOrCombosListToMenu(
            productsByDepartment,
            resp.combos,
            origins,
            true
          );
          t.productsByMenu =
            t.orderProductByDepartmentList(productsByDepartment);
          callback();
        } else {
          callback();
        }
      });
    },
    joinProductsOrCombosListToMenu(
      productsByDepartment,
      prodOrComboList,
      origins,
      isCombo
    ) {
      if (prodOrComboList === null || prodOrComboList === undefined) {
        return;
      }
      let fdsSyncInfoList = this.$store.getFdsSyncInformationList;
      let list = Object.entries(prodOrComboList);
      list.forEach((element) => {
        let key = element[0];
        let p = element[1];

        let currentPrice = "";
        let iva = "";
        let fdsSyncStatus = [];

        if (origins.length > 0) {
          if (
            p.origins !== undefined &&
            p.origins !== null &&
            p.origins.length > 0
          ) {
            origins.forEach((o) => {
              let fdsInfoForProduct = fdsSyncInfoList?.filter(x => x.productSku === p.sku
                && x.origin === o.name?.toUpperCase()
                && x.shopCode === this.selectedList.shopCode
              )
              let pOrigin = p.origins?.filter((t) => t.name === o.name);
              if (
                pOrigin !== null &&
                pOrigin !== undefined &&
                pOrigin.length > 0
              ) {
                currentPrice = pOrigin[0].price;
                iva = pOrigin[0].iva;
                if ((fdsInfoForProduct !== null || fdsInfoForProduct !== undefined) && fdsInfoForProduct?.length > 0) {
                  if (fdsInfoForProduct[0].syncStatus) {
                    fdsSyncStatus.push({
                      status: fdsInfoForProduct[0].syncStatus,
                      timestamp: fdsInfoForProduct[0].lastUpdateTimestamp,
                      lastError: fdsInfoForProduct[0].syncError,
                      originName: fdsInfoForProduct[0].origin,
                    })
                  }
                }
              }
            });
          }
        }

        let d = productsByDepartment.findIndex((d) => d.name === p.department);
        if (d !== -1) {
          // Categoria existente
          productsByDepartment[d].products.push({
            key: key,
            sku: p.sku,
            name: p.name,
            isProductAvailable: p.isProductAvailable,
            price: "",
            orderInMenu: p.orderInMenu,
            iva: iva,
            currentPrice: currentPrice,
            isCombo: isCombo,
            fdsSyncStatus: fdsSyncStatus,
          });
        } else {
          // Primero de cada categoria
          let array = [];
          array.push({
            key: key,
            sku: p.sku,
            name: p.name,
            isProductAvailable: p.isProductAvailable,
            orderInMenu: p.orderInMenu,
            price: "",
            iva: iva,
            currentPrice: currentPrice,
            isCombo: isCombo,
            fdsSyncStatus: fdsSyncStatus,
          });
          productsByDepartment.push({ name: p.department, products: array });
        }
      });
    },
    orderProductByDepartmentList(productsByDepartment) {
      productsByDepartment.forEach((element) => {
        element.products.sort(function (a, b) {
          if (!a) {
            return true;
          } else if (!b) {
            return false;
          }
          else return a?.name
            ?.toLowerCase()
            ?.localeCompare(b?.name?.toLowerCase(), "es", { sensitivity: "base" });
        });
      });

      productsByDepartment.sort(function (a, b) {
        if (!a) {
          return true;
        } else if (!b) {
          return false;
        }
        else return a?.name
          ?.toLowerCase()
          ?.localeCompare(b?.name?.toLowerCase(), "es", { sensitivity: "base" });
      });

      return productsByDepartment;
    },
    onImportDone() {
      // this.getCategories();
      //this.refreshProductList(this.selectedList);
      this.getIngredients(this.selectedList.shopCode);
    },
    deleteProduct(prod, key) {
      this.disableProductOnBackEnd(prod.sku);
      this.deletingProduct = true;
      const refSetnull = ref(
        db,
        "listProducts/" + this.selectedList.id + "/products/" + key + "/"
      );
      remove(refSetnull)
        .then(() => {
          this.refreshProductList(this.selectedList);
          toast.success(`El producto ${prod.name} fue eliminado con éxito!`);
          this.deletingProduct = false;
        })
        .catch((error) => {
          console.error("Error updating product:", error);
          this.deletingProduct = false;
        });
    },
    deleteCombo(combo, key) {
      this.deletingCombo = true;
      const setProductListsZM = ref(
        db,
        "listProducts/" + this.selectedList.id + "/combos/" + key + "/"
      );

      remove(setProductListsZM)
        .then(() => {
          this.refreshProductList(this.selectedList);
          toast.success(`El combo ${combo.name} fue eliminado con éxito aqui!`);
          this.deletingCombo = false;
        })
        .catch((error) => {
          console.error("Error updating product:", error);
          this.deletingCombo = false;
        });
    },
    deleteIngredient(ingredient, key) {
      const t = this;
      let params = {
        id: key,
      };

      axios
        .put("/api/ingredientsV2/delete", params)
        .then((res) => {
          if (res.data.responseCode === 0) {
            t.showSuccess(
              `El ingrediente ${ingredient.name} fue eliminado con éxito!`
            );
          } else {
            t.showError(res.data.responseMessage);
          }
          t.isLoading = false;
          this.onCloseIngredientModal();
        })
        .catch((err) => {
          t.showErrors(err.response);
          t.isLoading = false;
          this.onCloseIngredientModal();
        });
    },
    hasToUpgradeStockProduct() {
      if (this.currentProduct === null) {
        return false;
      }

      // if (this.currentProduct.sku !== prod.sku || this.currentProduct.name !== prod.name || this.currentProduct.department !== prod.department) {
      // 	return true
      // }

      // return false
      return true;
    },
    
    replaceSkuInExistantCombos(oldSku, newSku) {
      if (oldSku === newSku) return;
      const t = this;

      const currentListProducts = ref(
        db,
        `listProducts/${this.selectedList.id}/combos`
      );

      get(currentListProducts).then((snapshot) => {
        var resp = snapshot.val();
        if (resp != null) {
          let combos = Object.entries(resp);
          combos.forEach((item) => {
            item[1].sections.forEach((section) => {
              const indexOldSku = section.products.indexOf(oldSku);
              if (indexOldSku != -1) {
                section.products.splice(indexOldSku, 1);
                section.products.push(newSku);

                const updateListProducts = ref(
                  db,
                  `listProducts/${t.selectedList.id}/combos/${item[0]}/`
                );

                update(updateListProducts, item[1]);
              }
            });
          });
        }
      });
    },
    onUpsertProduct(prod, onFinished = null) {
      if (this.deletingProduct) {
        return;
      }

      this.getCategories();
      let c = this.categories.filter((t) => t.name === prod.department);
      if (c !== undefined && c.length > 0) {
        if (
          c[0].hasToPrintInKitchen === undefined ||
          c[0].hasToPrintInKitchen === null
        ) {
          c[0].hasToPrintInKitchen = true;
        }
        if (
          c[0].hasToPrintOnCounter === undefined ||
          c[0].hasToPrintOnCounter === null
        ) {
          c[0].hasToPrintOnCounter = true;
        }
        if (
          c[0].hasToPrintOnAdditionalPrinter === undefined ||
          c[0].hasToPrintOnAdditionalPrinter === null
        ) {
          c[0].hasToPrintOnAdditionalPrinter = true;
        }
        prod.hasToPrintInKitchen = c[0].hasToPrintInKitchen;
        prod.hasToPrintOnCounter = c[0].hasToPrintOnCounter;
        prod.hasToPrintOnAdditionalPrinter = c[0].hasToPrintOnAdditionalPrinter;

        prod.optionsGroups = null;
        if (c[0].optionsGroups !== undefined) {
          prod.optionsGroups = c[0].optionsGroups;
        }
      }

      if (prod.weightable) {
        prod.type = "PESABLE";
      } else if (
        prod.optionsGroups !== null &&
        prod.optionsGroups !== undefined &&
        prod.optionsGroups.length > 0
      ) {
        prod.type = "ADICIONAL";
        prod.measureUnit = "";
      } else {
        prod.type = "PRODUCTO";
        prod.measureUnit = "";
      }

      if (
        prod.origins !== undefined &&
        prod.origins !== null &&
        prod.origins.length > 0 &&
        prod.rootVat !== undefined &&
        prod.rootVat !== null &&
        prod.rootVat !== ""
      ) {
        prod.origins.forEach((element) => {
          element.iva = prod.rootVat;
        });
      }

      if (this.isNewProduct) {
        const refDataListProducts = ref(
          db,
          "listProducts/" + this.selectedList.id + "/products"
        );

        push(refDataListProducts, prod)
          .then(() => {
            off(refDataListProducts);
          })
          .catch((error) => {
            console.error("Error creating product:", error);
          });

        const hasToAvoidShowingProductInsertedModal = localStorage.getItem(
          `modalMenuProductInserted_${this.$store.getAuthUser.user.name}`
        );

        if (
          !hasToAvoidShowingProductInsertedModal ||
          hasToAvoidShowingProductInsertedModal == "false"
        )
          this.openModalById("#modalMenuProductInserted");
      } else {
        const refDataListProducts = ref(
          db,
          "listProducts/" +
          this.selectedList.id +
          "/products/" +
          this.currentProductKey +
          "/"
        );

        update(refDataListProducts, prod)
          .then(() => {
            off(refDataListProducts);
          })
          .catch((error) => {
            console.error("Error updatimg product:", error);
          });
      }

      if (!this.isNewProduct) {
        if (this.currentProduct && prod) {
          this.replaceSkuInExistantCombos(this.currentProduct.sku, prod.sku);
        }
      }

      if (this.hasToUpgradeStockProduct()) {
        let request = {
          shopCode: this.selectedList.shopCode,
          oldSku: this.currentProduct.sku,
          newSku: prod.sku,
          productName: prod.name,
          productCategory: prod.department,
          isWeighable: prod.weightable,
        };

        axios.post(`/api/productsV2/stockProduct`, request).catch((err) => {
          console.error(err.response);
        });
      }

      if (this.currentProduct && this.currentProduct.rootVat != prod.rootVat) {
        this.currentProduct.rootVat = prod.rootVat;
        this.generateShopEvent(
          "BISTROWEB_VAT_CHANGED",
          `${this.currentProduct.name} IVA: ${this.currentProduct.rootVat}`,
          this.selectedList.shopCode,
          this.$store.getAuthUser.user.name
        );
      }

      this.closeModalById("#modalProduct");
      this.closeModalById("#modalMenu");
      this.objectToRemove = null;
      this.isNewProduct = false;
      this.clearProduct();
      this.refreshProductList(this.selectedList);
      if (onFinished) {
        onFinished();
      }
    },
    onUpsertCombo(combo) {
      console.log("UPSERT", this.deletingCombo);

      if (this.deletingCombo) {
        return;
      }

      this.getCategories();

      if (
        combo.origins !== undefined &&
        combo.origins !== null &&
        combo.origins.length > 0 &&
        combo.rootVat !== undefined &&
        combo.rootVat !== null &&
        combo.rootVat !== ""
      ) {
        combo.origins.forEach((element) => {
          element.iva = combo.rootVat;
        });
      }

      if (this.isNewCombo) {
        const pushListProductsCombo = ref(
          db,
          "listProducts/" + this.selectedList.id + "/combos"
        );

        push(pushListProductsCombo, combo);
        const hasToAvoidShowingComboInsertedModal = localStorage.getItem(
          `modalMenuComboInserted_${this.$store.getAuthUser.user.name}`
        );

        if (
          !hasToAvoidShowingComboInsertedModal ||
          hasToAvoidShowingComboInsertedModal == "false"
        )
          this.openModalById("#modalMenuComboInserted");
      } else {
        const updateListProductsCombo = ref(
          db,
          "listProducts/" +
          this.selectedList.id +
          "/combos/" +
          this.currentComboKey +
          "/"
        );

        update(updateListProductsCombo, combo);
      }

      this.closeModalById("#modalCombo");
      this.objectToRemove = null;
      this.isNewCombo = false;
      this.clearCombo();

      this.refreshProductList(this.selectedList);
    },
    getOptionType() {
      if (this.optionType === "Adicional") {
        return "Adicionales";
      } else {
        return "Gustos";
      }
    },
    deleteAditional(aditional) {
      this.disableProductOnBackEnd(aditional.sku);

      this.optionType = "Adicional";
      this.calculateInitialCheckCategories(aditional);
      this.onUpsertOption(aditional, [], false);

      toast.success(`El adicional ${aditional.name} fue eliminado con éxito!`);
    },
    deleteTaste(taste) {
      this.optionType = "Gusto";
      this.calculateInitialCheckCategories(taste);
      this.onUpsertOption(taste, [], false);

      toast.success(`El gusto ${taste.name} fue eliminado con éxito!`);
    },
    onUpsertOption(option, checkedCategories, showMessages = true) {
      const t = this;
      let initialCheckedCategories = this.initialCheckedCategories;
      let listOfCheckedCategories = [];
      let optionType = t.getOptionType();
      const optionTypeToast = this.optionType;
      const isNewOptionToast = this.isNewOption;
      let errorMessage = "";
      let error = false;

      if (optionType === "Adicionales") {
        option.minQty = parseInt(option.minQty);
        option.maxQty = 99;
        option.price = (Math.round(option.price * 100) / 100).toFixed(2);
      } else {
        option.minQty = 0;
        option.maxQty = 99;
        option.price = "0.00";
        option.exempt = false;
        option.nonTaxed = false;
      }

      if (
        option.sku === undefined ||
        option.sku === null ||
        option.sku === ""
      ) {
        option.sku = `98${Math.floor(Math.random() * 999999999).toString()}`;
      }

      let listOfShops = this.$store.getMerchantTags;

      listOfShops.forEach((shop) => {
        const itemsDataProds = [];
        const currentDataProds = ref(db, "products/" + shop.shopCode);

        let currentDataTrigger = false;

        get(currentDataProds).then((snapshot) => {
          if (!currentDataTrigger) {
            currentDataTrigger = true;
            var list = snapshot.val();

            if (!itemsDataProds.includes(list?.idList)) {
              itemsDataProds.push(list?.idList);

              if (list !== null) {
                if (list?.idList === t.selectedList.id) {
                  initialCheckedCategories.forEach((category) => {
                    var c = list.categories.filter((t) => t.name === category);
                    if (c !== null && c !== undefined && c.length > 0) {
                      let obj = c[0];

                      let og = c[0].optionsGroups.filter(
                        (t) => t.name === optionType
                      );
                      if (og.length > 0) {
                        var o = og[0].options.filter(
                          (t) => t.name === option.name
                        );
                        if (o.length > 0) {
                          var index = og[0].options.indexOf(o[0]);
                          og[0].options.splice(index, 1);

                          index = c[0].optionsGroups.indexOf(og[0]);
                          c[0].optionsGroups.splice(index, 1);

                          if (og[0].options.length > 0) {
                            c[0].optionsGroups.push(og[0]);
                          }

                          var i = list.categories.indexOf(obj);
                          list.categories.splice(i, 1);

                          list.categories.push(c[0]);
                        }
                      }
                    }
                  });
                  checkedCategories.forEach((category) => {
                    var c = list.categories.filter((t) => t.name === category);

                    if (c !== null && c !== undefined && c.length > 0) {
                      let obj = c[0];
                      if (
                        c[0].optionsGroups === null ||
                        c[0].optionsGroups === undefined
                      ) {
                        c[0].optionsGroups = [];
                      }

                      let o = c[0].optionsGroups.filter(
                        (t) => t.name === optionType
                      );

                      if (o.length === 0) {
                        //Si la categoria era nueva, no va a tener optionGroups asociados asi que creo el option group y le paso esta opcion
                        c[0].optionsGroups.push({
                          name: optionType,
                          minQty:
                            c[0].hasToEnableFlavorLimit ?? false
                              ? c[0].minimumFlavorLimit
                              : option.minQty, //agregar minimo de flavor
                          maxQty:
                            c[0].hasToEnableFlavorLimit ?? false
                              ? c[0].maximumFlavorLimit
                              : option.maxQty, //agregar maximo de flavor
                          options: [
                            {
                              name: option.name,
                              minQty: Math.min(
                                option.minQty,
                                c[0].hasToEnableFlavorLimit ?? false
                                  ? c[0].maximumFlavorLimit
                                  : option.maxQty
                              ), //dejarlo como esta porque puede ser 0 asi no esta obligado a meter un gusto o adicional, eso lo limita el option group
                              maxQty:
                                c[0].hasToEnableFlavorLimit ?? false
                                  ? c[0].maximumFlavorLimit
                                  : option.maxQty, //setearle el maximo del flavor
                              price: option.price,
                              vat: option.vat,
                              sku: option.sku,
                              exempt: option.exempt,
                              nonTaxed: option.nonTaxed,
                            },
                          ],
                        });
                      } else {
                        //Si la categoria ya tenia option groups entro aca

                        let op = o[0].options.filter(
                          (t) =>
                            t.name?.toUpperCase() == option.name?.toUpperCase()
                        );

                        if (op.length === 0) {
                          //si no existe una opcion con ese nombre dentro del option group
                          var index = c[0].optionsGroups.indexOf(o[0]);
                          c[0].optionsGroups.splice(index, 1);

                          o[0].options.push({
                            name: option.name,
                            minQty: Math.min(
                              option.minQty,
                              c[0].hasToEnableFlavorLimit ?? false
                                ? c[0].maximumFlavorLimit
                                : option.maxQty
                            ),
                            maxQty:
                              c[0].hasToEnableFlavorLimit ?? false
                                ? c[0].maximumFlavorLimit
                                : option.maxQty, //le tengo que meter el maximo del option group en el que esta
                            price: option.price,
                            vat: option.vat,
                            sku: option.sku,
                            exempt: option.exempt,
                            nonTaxed: option.nonTaxed,
                          });

                          c[0].optionsGroups.push(o[0]);
                        } else {
                          errorMessage = `El adicional ${option.name} ya existe`;
                          error = true;
                        }
                      }
                      var i = list.categories.indexOf(obj);
                      list.categories.splice(i, 1);

                      list.categories.push(c[0]);
                      listOfCheckedCategories.push(c[0]);
                    }
                  });

                  const updateProductsCategories = ref(
                    db,
                    "products/" + t.selectedList.shopCode + "/categories"
                  );

                  set(updateProductsCategories, list.categories);
                  t.getCategories();
                }
              }
            } //cierra la condicion
          }
        });
      });

      const listProductsData = ref(
        db,
        "listProducts/" + t.selectedList.id + "/products"
      );

      let listProductsTrigger = false;
      get(listProductsData).then((snapshot) => {
        if (!listProductsTrigger) {
          listProductsTrigger = true;
          const resp = snapshot.val();
          if (resp !== null) {
            let prods = Object.entries(resp);
            prods.forEach((prod) => {
              if (
                prod[1].optionsGroups === null ||
                prod[1].optionsGroups === undefined
              ) {
                prod[1].optionsGroups = [];
              }
              if (
                initialCheckedCategories.filter((t) => t === prod[1].department)
                  .length > 0
              ) {
                let og = prod[1].optionsGroups.filter(
                  (t) => t.name === optionType
                );
                if (og !== undefined && og !== null && og.length > 0) {
                  var o = og[0].options.filter((t) => t.name === option.name);
                  if (o !== undefined && o !== null && o.length > 0) {
                    var index = og[0].options.indexOf(o[0]);
                    og[0].options.splice(index, 1);

                    index = prod[1].optionsGroups.indexOf(og[0]);
                    prod[1].optionsGroups.splice(index, 1);

                    if (og[0].options.length > 0) {
                      prod[1].optionsGroups.push(og[0]);
                    }
                  }
                }
              }
              if (
                checkedCategories.filter((t) => t === prod[1].department)
                  .length > 0
              ) {
                let c = null;
                if (listOfCheckedCategories.length > 0)
                  c = listOfCheckedCategories.filter(
                    (t) => t.name === prod[1].department
                  );
                let o = prod[1].optionsGroups.filter(
                  (t) => t.name === optionType
                );
                if (o.length === 0) {
                  prod[1].optionsGroups.push({
                    name: optionType,
                    minQty:
                      c[0].hasToEnableFlavorLimit ?? false
                        ? c[0].minimumFlavorLimit
                        : option.minQty,
                    maxQty:
                      c[0].hasToEnableFlavorLimit ?? false
                        ? c[0].maximumFlavorLimit
                        : option.maxQty,
                    options: [
                      {
                        name: option.name,
                        minQty: Math.min(
                          option.minQty,
                          c[0].hasToEnableFlavorLimit ?? false
                            ? c[0].maximumFlavorLimit
                            : option.maxQty
                        ),
                        maxQty:
                          c[0].hasToEnableFlavorLimit ?? false
                            ? c[0].maximumFlavorLimit
                            : option.maxQty,
                        price: option.price,
                        vat: option.vat,
                        sku: option.sku,
                        exempt: option.exempt,
                        nonTaxed: option.nonTaxed,
                      },
                    ],
                  });
                } else {
                  var i = prod[1].optionsGroups.indexOf(o[0]);
                  prod[1].optionsGroups.splice(i, 1);

                  o[0].options.push({
                    name: option.name,
                    minQty: Math.min(
                      option.minQty,
                      c[0].hasToEnableFlavorLimit ?? false
                        ? c[0].maximumFlavorLimit
                        : option.maxQty
                    ),
                    maxQty:
                      c[0].hasToEnableFlavorLimit ?? false
                        ? c[0].maximumFlavorLimit
                        : option.maxQty,
                    price: option.price,
                    vat: option.vat,
                    sku: option.sku,
                    exempt: option.exempt,
                    nonTaxed: option.nonTaxed,
                  });

                  prod[1].optionsGroups.push(o[0]);
                }
              }
              if (prod[1].type !== "PESABLE") {
                if (
                  prod[1].optionsGroups !== undefined &&
                  prod[1].optionsGroups !== null &&
                  prod[1].optionsGroups.length > 0
                ) {
                  prod[1].type = "ADICIONAL";
                } else {
                  prod[1].type = "PRODUCTO";
                }
              }
              
              // if (this.savingControl) return;

              // this.savingControl = true;

              
              const updateListProducts = ref(
                db,
                "listProducts/" + t.selectedList.id + "/products/" + prod[0]
              );
              
              
              set(updateListProducts, prod[1])
                .then(() => {
                  console.log("Product updated successfully");
                })
                .catch((error) => {
                  console.error("Error updating product:", error);
                });
              

             
            });
          }

          t.refreshProductList(t.selectedList);

          if (showMessages) {
            if (error) {
              toast.error(errorMessage);
            } else {
              if (isNewOptionToast) {
                toast.success(
                  `El ${optionTypeToast} ${option.name} se creó exitosamente!`
                );
                this.savingControl = false;
              } else {
                toast.success(
                  `El ${optionTypeToast} ${option.name} se guardó exitosamente!`
                );
                this.savingControl = false;
              }
            }
          }
        }
      });

      this.closeModalById("#modalOption");
      this.objectToRemove = null;
      this.clearOption();
    },
    deleteMenu(menu, index) {

      this.products.forEach((prod) => {

        let isModified = false;

        if (
          prod[1].origins !== undefined &&
          prod[1].origins !== null &&
          prod[1].origins.length > 0
        ) {
          menu.origins.forEach((o) => {

            let i = prod[1].origins.findIndex((t) => t.name === o.name);

            if (i !== -1 && i !== undefined && i !== null) {
              prod[1].origins.splice(i, 1);
              isModified = true;
            }
          });

          if (isModified) {
            //console.log("MODIFICADO", prod)
            const setLP = ref(db, "listProducts/" + this.selectedList.id + "/products/" + prod[0]);

            set(setLP, prod[1])
              .then(() => {
                off(setLP)
                //console.log("MODIFICADO SET", prod[1]);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        }
      });

      this.menues.splice(index, 1);

      const setLProds = ref(
        db,
        "listProducts/" + this.selectedList.id + "/menues/"
      );

      set(setLProds, this.menues);

      this.updateOriginsInUse();

      this.refreshProductList(this.selectedList);
      const shopTheme = this.$store.getCurrentShopTheme;
      switch (shopTheme) {
        case 1:
        default:
          toast.success(
            `La carta ${menu.name} fue eliminada con éxito!`
          );
          return;
        case 2:
          toast.success(
            `El catálogo ${menu.name} fue eliminado con éxito!`
          );
          return;
      }
    },
    onUpsertMenu(menu, productsByMenu, originsOut, resultOrigins) {
      //if (this.onUpsertMenuNro < 2) {
      //this.onUpsertMenuNro++;

      const t = this;

      const LPValue = ref(db, `listProducts/${t.selectedList.id}`);

      let countMyList = 0;
      let LPValueLimit = false;
      let finishUpsert = false;

      get(LPValue).then((snapshot) => {

        if (!LPValueLimit) {

          LPValueLimit = true;

          const data = snapshot.val();


          const resp = snapshot.val();
          if (resp !== null) {
            if (resp.menues === undefined) {
              resp.menues = [];
            }
            if (menu.key === "") {
              resp.menues.push({
                name: menu.name,
                origins: resultOrigins,
                uuid: t.createUUID(),
              });
            } else {
              if (menu.uuid === null || menu.uuid === undefined) {
                menu.uuid = t.createUUID();
                resp.menues.splice(menu.key, 1);
              } else {
                // console.log(`Deleting menu by UUID `, menu.uuid)
                const menuFromFbIndex = resp.menues.findIndex(
                  (x) => x.uuid === menu.uuid
                );
                resp.menues.splice(menuFromFbIndex, 1);
              }

              resp.menues.push({
                name: menu.name,
                origins: resultOrigins,
                uuid: menu.uuid,
              });
            }

            if (this.savingControl) return;

            this.savingControl = true;

            const updateListProductsMenues = ref(
              db,
              `listProducts/${t.selectedList.id}/menues/`
            );

            //set(updateListProductsMenues, resp.menues);
            //"-NrMTGErSTbziwvyPRWu"

            console.log("MENUES", resp.menues);

            set(updateListProductsMenues, resp.menues)
              .then(() => {

                off(updateListProductsMenues);
                console.log("DESPUES DEL OFF");

              })
              .catch((error) => {
                console.error("Error updating product:", error);
              });

            if (resp.products === null || resp.products === undefined) {
              t.refreshProductList(t.selectedList, () => {
                console.log("finishUpsert NULL")
                t.finishUpsert(resultOrigins);
              });
              return;
            }

            t.updateProductsOrCombos(
              resp.products,
              productsByMenu,
              originsOut,
              resultOrigins,
              false,
              `${t.selectedList.id}/products`
            );
            t.updateProductsOrCombos(
              resp.combos,
              productsByMenu,
              originsOut,
              resultOrigins,
              true,
              `${t.selectedList.id}/combos`
            );

            if (!finishUpsert) {
              finishUpsert = true;
              t.refreshProductList(t.selectedList, () => {
                t.finishUpsert(resultOrigins);
              });
            }


          }

        } //LPValueLimit

      });
      //}
    },
    updateProductsOrCombos(
      productsOrCombosList,
      productsByMenu,
      originsOut,
      resultOrigins,
      isCombo,
      nodoPath
    ) {
      if (productsOrCombosList === null || productsOrCombosList === undefined) {
        return;
      }
      var list = Object.entries(productsOrCombosList);

      //console.log("MY LIST", list)
      //console.log("MY LIST LENGTH 1", productsByMenu.length)

      //let mycounter = 0;

      productsByMenu.forEach((department) => {

        //console.log("DEPARTMENT PRODUCTS", department.products)

        let prodready = [];

        department.products
          .filter((t) => t.isCombo === isCombo)
          .forEach((prod) => {
            //console.log("MY LIST LENGTH 2", department.products.length)

            let p = list.filter((t) => t[0] === prod.key)[0];
            if (p !== -1 && p !== undefined && p !== null && p.length > 0) {
              if (!prodready.includes(p[0])) {
                prodready.push(p[0]);

                /* if(p[0] == "-Nwf7JdRp7YEJIiWch_S")
              {
                console.log("PROD ANTES", p[1])
              } */

                p[1].isProductAvailable = prod?.isProductAvailable ?? true;

                /// Estos son los origenes que se sacaron
                originsOut.forEach((o) => {
                  if (p[1].origins === undefined) {
                    p[1].origins = [];
                  }
                  let originsToTakeOut = p[1].origins.filter(
                    (t) => t.name === o
                  );
                  if (
                    originsToTakeOut !== -1 &&
                    originsToTakeOut !== undefined &&
                    originsToTakeOut !== null &&
                    originsToTakeOut.length > 0
                  ) {
                    let index = p[1].origins.indexOf(originsToTakeOut[0]);
                    p[1].origins.splice(index, 1);
                  }
                });

                // Estos son los origenes que quedaron prendidos.. actuar
                resultOrigins.forEach((o) => {
                  if (p[1].origins === undefined) {
                    p[1].origins = [];
                  }

                  let origins = p[1].origins.filter((t) => t.name === o.name);
                  if (
                    origins !== -1 &&
                    origins !== undefined &&
                    origins !== null &&
                    origins.length > 0
                  ) {
                    let index = p[1].origins.indexOf(origins[0]);
                    p[1].origins.splice(index, 1);
                  } else {
                    origins = [{}];
                  }

                  if (
                    p[1].rootVat === undefined ||
                    p[1].rootVat === null ||
                    p[1].rootVat === ""
                  ) {
                    if (
                      prod.iva !== undefined &&
                      prod.iva !== null &&
                      prod.iva !== ""
                    ) {
                      p[1].rootVat = prod.iva;
                    } else {
                      p[1].rootVat = "21.00";
                    }
                  }

                  if (prod.currentPrice !== "") {
                    let price = 0;
                    if (prod.price !== "") {
                      price = (Math.round(prod.price * 100) / 100).toFixed(2);
                    } else {
                      price = (
                        Math.round(prod.currentPrice * 100) / 100
                      ).toFixed(2);
                    }

                    /* if(p[0] == "-Nwf7JdRp7YEJIiWch_S")
                  {
                      console.log("ORIGINS antes", p[1].origins)
                  } */


                    origins[0].iva = p[1].rootVat;
                    origins[0].name = o.name;
                    origins[0].price = price;


                    origins = [{ iva: p[1].rootVat, name: o.name, price: price }]

                    /* if(p[0] == "-Nwf7MzRKvQ6JbpZcHwz")
                    {
                      console.log("ORIGINS ANTES", p[1].origins)
                      console.log("ORIGINS PUSH", origins)
                    } */

                    p[1].origins.push(origins[0]);

                    /* if(p[0] == "-Nwf7MzRKvQ6JbpZcHwz")
                    {
                        console.log("ORIGINS DESPUES", p[1].origins)
                    } */
                  }
                });

                //if(mycounter <= 186){

                //console.log("EL NODO", nodoPath);

                //mycounter++;
                const updateListProductsP = ref(
                  db,
                  `listProducts/${nodoPath}/${p[0]}`
                );

                set(updateListProductsP, p[1]).then(() => {
                  off(updateListProductsP);
                })
                  .catch((error) => {
                    console.error("Error SETUPDATELISTPRODUCTSP");
                  });

                /* if(p[0] == "-Nwf7JdRp7YEJIiWch_S")
                {
                  console.log("PROD DESPUES", p[1])

                  return ;
                } */

                //console.log("AQUI MESMO DENTRO")
                //}
                //console.log("-------------------")
              } //prodready
            }
          });
      });
    },
    syncFoodDeliverySystemMenues(menuOrigins, shopCode) {
      axios
        .post(`/api/productsV2/syncProductsWithFds`, {
          origins: menuOrigins,
          shopCode: shopCode,
        })
        .then((res) => {
          if (res.data.responseCode === 1) {
            //this.showSuccess(res.data.responseMessage);
          } else if (res.data.responseCode !== 0) {
            //this.showError(res.data.responseMessage);
          }
        })
        .catch((err) => {
          this.showErrors(err.response);
        });
    },
    generateShopEvent(type, description, shopCode, userName) {
      axios
        .post(`/api/eventsV2/generateShopEvent`, {
          type: type,
          creationTimestamp: new Date().toISOString(),
          description: description,
          userName: userName,
          shopCode: shopCode,
        })
        .then((res) => {
          if (res.data.responseCode === 0) {
            //this.showSuccess(res.data.responseMessage);
          } else {
            //this.showError(res.data.responseMessage);
          }
        })
        .catch((err) => {
          console.error(`generateShopEvent`, err);
        });
    },
    onModalMessageClosing() {
      this.closeModalById("#modalMenuUpsertFinished");
    },
    onModalProductMessageClosing() {
      this.closeModalById("#modalMenuProductInserted");
    },
    onModalComboMessageClosing() {
      this.closeModalById("#modalMenuComboInserted");
    },
    finishUpsert(menuOrigins) {
      //if (!this.controlVariablex) {
      // this.controlVariablex = true;

      this.updateOriginsInUse();

      if (this.isNewMenu) {
        toast.success(`La carta ${this.currentMenu} se creó exitósamente!`);
        this.savingControl = false;
      } else {
        toast.success(`La carta ${this.currentMenu} se guardo exitósamente!`);
        this.savingControl = false;
      }

      this.syncFoodDeliverySystemMenues(
        menuOrigins,
        this.selectedList.shopCode
      );
      this.generateShopEvent(
        "BISTROWEB_MENU_AND_OR_PRICES_CHANGED",
        `Carta: ${this.currentMenu}`,
        this.selectedList.shopCode,
        this.$store.getAuthUser.user.name
      );

      this.closeModalById("#modalMenu");
      const hasToAvoidShowingMenuUpsertWarningModal = localStorage.getItem(
        `modalMenuUpsertFinished_${this.$store.getAuthUser.user.name}`
      );

      if (
        !hasToAvoidShowingMenuUpsertWarningModal ||
        hasToAvoidShowingMenuUpsertWarningModal == "false"
      )
        this.openModalById("#modalMenuUpsertFinished");
      this.isMenuLoading = false;
      this.clearMenu();
      this.currentMenu = null;
      this.objectToRemove = null;
    },
    updateOriginsInUse() {
      let originsInUse = [];
      if (
        this.menues !== undefined &&
        this.menues !== null &&
        this.menues.length > 0
      ) {
        this.menues.forEach((menu) => {
          menu.origins.forEach((origin) => {
            originsInUse.push(origin);
          });
        });
      }

      let listOfShops = this.$store.getMerchantTags;
      listOfShops.forEach((shop) => {
        const setProducts = ref(db, "products/" + shop.shopCode + "/origins");
        set(setProducts, originsInUse);
      });

      const setPL = ref(
        db,
        "listProducts/" + this.selectedList.id + "/origins"
      );
      set(setPL, originsInUse);
    },
    deleteCategory(category) {
      var isError = false;

      let completeDelete = false;

      this.products.forEach((element) => {
        var prod = element[1];

        if (prod.department === category.name) isError = true;
      });

      if (isError) {
        toast.error(
          `Esta categoría posee productos asociados. Modifique sus productos si desea eliminarla!`
        );
        return;
      }

      this.combos.forEach((element) => {
        var combo = element[1];

        if (combo.department === category.name) isError = true;
      });

      if (isError) {
        toast.error(
          `Esta categoría posee combos asociados. Modifique sus combos si desea eliminarla!`
        );
        return;
      }

      if (
        category.optionsGroups !== undefined &&
        category.optionsGroups !== null &&
        category.optionsGroups.length > 0
      ) {
        toast.error(
          `Esta categoría posee adicionales o gustos asociados. Modifique sus categorías si desea eliminarla!`
        );
        return;
      }

      const t = this;
      this.$store.getMerchantTags.forEach((shop) => {
        const valueProducts = ref(db, "products/" + shop.shopCode);

        get(valueProducts).then((snapshot) => {
          var list = snapshot.val();

          if (
            list !== null &&
            list?.idList === t.selectedList.id &&
            list.categories != undefined
          ) {

            let index = list.categories.findIndex(
              (c) => c.name === category.name
            );

            if (index !== null && index !== undefined) {
              list.categories.splice(index, 1);

              const setPLS = ref(
                db,
                "products/" + shop.shopCode + "/categories"
              );

              if (completeDelete) return;

              completeDelete = true;

              set(setPLS, list.categories)
                .then(() => {
                  completeDelete = false;
                })
                .catch((error) => {
                  console.error("Error updating product:", error);
                  completeDelete = false;
                });
            }

            t.getCategories();
          }
        });
      });

      toast.success(`La categoría ${category.name} fue eliminada con éxito!`);
    },
    isCategoryNameInFirebase(list, category, t, oldCategoryName) {
      if (oldCategoryName === category.name) return false;
      if (list.categories.filter((t) => t.name === category.name).length > 0) {
        toast.error(`La categoría ${category.name} ya existe!`);
        return true;
      } else return false;
    },
    onUpsertCategory(category) {
      const t = this;
      let oldCategoryName = t.oldCategoryName; //Es null si la categoria es nueva
      if (t.isNewCategory) {
        let dataShopsId = [];

        const currentValueProducts = ref(
          db,
          `products/${this.selectedList.shopCode}`
        );
        get(currentValueProducts).then((snapshot) => {
          var list = snapshot.val();

          if (!dataShopsId.includes(list?.idList)) {
            dataShopsId.push(list?.idList);

            let nameInCamelCase = category.name; // FIXME::: To CamelCase
            if (list.categories === undefined || list.categories === null) {
              //La lista esta vacía.
              list.categories = [];
              list.categories.push({
                name: nameInCamelCase,
                orderNumber: category.orderNumber || null,
                hasToPrintInKitchen: category.hasToPrintInKitchen,
                hasToPrintOnCounter: category.hasToPrintOnCounter,
                hasToPrintOnAdditionalPrinter:
                  category.hasToPrintOnAdditionalPrinter,
                hasToEnableFlavorLimit: category.hasToEnableFlavorLimit,
                minimumFlavorLimit: category.minimumFlavorLimit,
                maximumFlavorLimit: category.maximumFlavorLimit,
              });
              const updateProductsCat = ref(
                db,
                `products/${t.selectedList.shopCode}/categories`
              );
              set(updateProductsCat, list.categories);
            } else {
              //Pregunto si existe, ya que la lista no está vacía.
              if (
                t.isCategoryNameInFirebase(list, category, t, oldCategoryName)
              ) {
                //Retornó verdadero, por lo tanto, existía.
                return;
              }
              //Si no existía, la creo
              list.categories.push({
                name: nameInCamelCase,
                orderNumber: category.orderNumber || null,
                hasToPrintInKitchen: category.hasToPrintInKitchen,
                hasToPrintOnCounter: category.hasToPrintOnCounter,
                hasToPrintOnAdditionalPrinter:
                  category.hasToPrintOnAdditionalPrinter,
                hasToEnableFlavorLimit: category.hasToEnableFlavorLimit,
                minimumFlavorLimit: category.minimumFlavorLimit,
                maximumFlavorLimit: category.maximumFlavorLimit,
              });
              const updateCategoriesProds = ref(
                db,
                `products/${t.selectedList.shopCode}/categories`
              );
              set(updateCategoriesProds, list.categories);
            }

            t.getCategories();
            t.clearCategory();
            t.closeModalById("#modalCategory");
            toast.success(
              `La categoría ${nameInCamelCase} se creó exitosamente!`
            );
          }
        });
      } else {
        let dataShopsId = [];
        const currentValueProdsShop = ref(
          db,
          `products/${this.selectedList.shopCode}`
        );

        get(currentValueProdsShop).then((snapshot) => {
          var list = snapshot.val();

          if (!dataShopsId.includes(list?.idList)) {
            dataShopsId.push(list?.idList);

            if (
              t.isCategoryNameInFirebase(list, category, t, oldCategoryName)
            ) {
              return;
            }

            let cat = list.categories.filter((c) => c.name === oldCategoryName);
            if (cat.length === 1) {
              cat[0].name = category.name;
              cat[0].orderNumber = category.orderNumber || null;
              cat[0].hasToPrintInKitchen = category.hasToPrintInKitchen;
              cat[0].hasToPrintOnCounter = category.hasToPrintOnCounter;
              cat[0].hasToPrintOnAdditionalPrinter =
                category.hasToPrintOnAdditionalPrinter;
              cat[0].hasToEnableFlavorLimit = category.hasToEnableFlavorLimit;
              cat[0].minimumFlavorLimit = category.minimumFlavorLimit;
              cat[0].maximumFlavorLimit = category.maximumFlavorLimit;
              t.updateOptionGroups(cat[0]);
              const updateProductsCateg = ref(
                db,
                `products/${t.selectedList.shopCode}/categories`
              );
              set(updateProductsCateg, list.categories);
            }
            t.getCategories();
            // FIXME:: Edición del departamento en los productos

            const currentValueLP = ref(db, "listProducts/" + t.selectedList.id);

            get(currentValueLP).then((snapshot) => {
              const resp = snapshot.val();
              if (resp != null) {
                if (resp.products !== undefined && resp.products !== null) {
                  let allProds = Object.entries(resp.products);
                  allProds.forEach((element) => {
                    var key = element[0];
                    var prod = element[1];
                    if (prod.department === oldCategoryName) {
                      prod.department = category.name;
                      prod.hasToPrintInKitchen = category.hasToPrintInKitchen;
                      prod.hasToPrintOnCounter = category.hasToPrintOnCounter;
                      prod.hasToPrintOnAdditionalPrinter =
                        category.hasToPrintOnAdditionalPrinter;
                      prod.hasToEnableFlavorLimit =
                        category.hasToEnableFlavorLimit;
                      prod.minimumFlavorLimit = category.minimumFlavorLimit;
                      prod.maximumFlavorLimit = category.maximumFlavorLimit;
                      t.updateOptionGroups(prod);
                      const updateLP = ref(
                        db,
                        `listProducts/${t.selectedList.id}/products/${key}`
                      );
                      update(updateLP, prod);
                    }
                  });
                  if (resp.combos) {
                    let allCombos = Object.entries(resp.combos);
                    allCombos.forEach((element) => {
                      var key = element[0];
                      var combo = element[1];

                      if (combo.department === oldCategoryName) {
                        combo.department = category.name;
                        const updateLPC = ref(
                          db,
                          `listProducts/${t.selectedList.id}/combos/${key}`
                        );
                        update(updateLPC, combo);
                      }
                    });
                  }


                  t.refreshProductList(t.selectedList);
                }
              }

              t.closeModalById("#modalCategory");
              toast.success(`La categoría ${category.name} se guardó exitosamente!`);
            });
          }
        });
      }

      this.objectToRemove = null;
    },
    updateOptionGroups(prodOrCategory) {
      //Esta funcion sobreescribe lo que haya en minQty y maxQty de optionGroups y lo que haya en maxQty de la option dentro de un optionGroup
      // if( prodOrCategory.optionsGroups == null || prodOrCategory.optionsGroups == undefined )
      // 	return;

      if (prodOrCategory.optionsGroups?.length > 0) {
        prodOrCategory.optionsGroups.forEach((optionGroup) => {
          //Se le setea tanto al optionGroup (gusto o adicional) la cantidad minima y maxima dependiendo de lo seteado en el limite de la categoria
          optionGroup.minQty = prodOrCategory.minimumFlavorLimit;
          optionGroup.maxQty = prodOrCategory.maximumFlavorLimit;
          //Le actualizo el valor maximo a cada opcion dentro de este optionGroup para que coincida con el valor maximo

          if (optionGroup.options?.length > 0) {
            optionGroup.options.forEach((option) => {
              option.minQty = Math.min(
                option.minQty ?? 0,
                prodOrCategory.hasToEnableFlavorLimit ?? false
                  ? prodOrCategory.maximumFlavorLimit
                  : option.maxQty
              );
              option.maxQty = prodOrCategory.maximumFlavorLimit;
            });
          }
        });
      }
    },
    filterProduct(t, filteredProds) {
      if (t.productSearchInput !== "") {
        filteredProds = filteredProds.filter((x) => {
          return (
            x[1].name
              ?.toUpperCase()
              ?.includes(t.productSearchInput.toUpperCase()) ||
            x[1]?.sku?.toUpperCase()?.includes(t.productSearchInput.toUpperCase())
          );
        });
      }
      if (
        t.productCategorySearchSelect !== "" &&
        t.productCategorySearchSelect !== "Todas las Categorías"
      ) {
        filteredProds = filteredProds.filter((x) => {
          return x[1].department
            .toUpperCase()
            .includes(t.productCategorySearchSelect.toUpperCase());
        });
      }
      t.orderProductListByName(filteredProds);
      t.products = filteredProds;
    },
    filterCombos(t, filteredCombos) {
      if (t.combosSearchInput !== "") {
        filteredCombos = filteredCombos.filter((x) => {
          return (
            x[1]?.name
              ?.toUpperCase()
              ?.includes(t.combosSearchInput.toUpperCase()) ||
            x[1]?.sku?.toUpperCase()?.includes(t.combosSearchInput.toUpperCase())
          );
        });
      }

      let processedCombos = [];
      filteredCombos.forEach((x) => {
        if (x[1].sections !== undefined && x[1].sections !== null) {
          x[1].sections.forEach((y) => {
            if (y.products === undefined || y.products === null) {
              y.products = [];
            }
          });
        }

        processedCombos.push(x);
      });
      t.combosFromFirebase = JSON.parse(JSON.stringify(processedCombos));
      t.combos = processedCombos;
    },
    filterIngredient(t, filteredIngredients) {
      if (t.ingredientSearchInput !== "") {
        filteredIngredients = filteredIngredients.filter((x) => {
          return (
            x.name
              .toUpperCase()
              .includes(t.ingredientSearchInput.toUpperCase()) ||
            x.sku.toUpperCase().includes(t.ingredientSearchInput.toUpperCase())
          );
        });
      }
      t.ingredients = filteredIngredients;
    },
    getIngredients(shopCode) {
      var params = {};
      if (this.hasCentralizedProducts) params["shopCode"] = this.getParentShopCode
      else params["shopCode"] = shopCode;
      axios
        .get(`/api/ingredientsV2/?${parseToQuery(params)}`)
        .then((res) => {
          if (res.data.responseCode == 0) {
            this.ingredients = res.data.ingredients;
            this.ingredientsNoFilter = res.data.ingredients;
            if (this.ingredients !== undefined && this.ingredients !== null) {
              this.originalIngredients = JSON.parse(
                JSON.stringify(this.ingredients)
              );
              this.filterIngredient(this, this.originalIngredients);
            } else {
              this.ingredients = [];
            }
          } else {
            this.showErrors(res);
            this.ingredients = [];
          }
        })
        .catch((err) => {
          this.showErrors(err.response);
        });
    },
    refreshProductList(prodList, handler) {
      const t = this;
      let idList = null
      if (prodList?.id === undefined) {
        idList = prodList?.idList
      } else {
        idList = prodList?.id
      }
      t.selectedList = t.productLists.find(
        (element) => element.id === parseInt(idList) && element.shopCode === prodList.shopCode
      );
      t.getCategories();

      if (handler === null || handler === undefined) {
        t.clearMenu();
      }
      t.clearCategory();
      t.clearOption();
      t.clearProduct();

      this.excelParams = parseToQuery({ listId: t.selectedList.id });
      this.excelAditionalParams = parseToQuery({
        shopCode: t.selectedList.shopCode,
      });

      const refDataListProducts = ref(db, "listProducts/" + idList);

      let controlRefDatalistproducts = false;

      get(refDataListProducts).then((snapshot) => {
        if (!controlRefDatalistproducts) {
          controlRefDatalistproducts = true;

          const resp = snapshot.val();
          if (resp != null) {
            //Proceso Productos. FIXME HZ:: Pasar a Metodo.
            if (resp.products !== undefined && resp.products !== null) {
              t.originalProducts = Object.entries(resp.products);
              this.filterProduct(t, t.originalProducts);
            } else {
              t.products = [];
            }

            //Proceso Menúes. FIXME HZ:: Pasar a Metodo.
            if (
              resp.menues !== undefined &&
              resp.menues !== null &&
              resp.menues.length > 0
            ) {
              t.menues = resp.menues;
            } else {
              t.menues = [];
            }
            //Proceso Origins. FIXME HZ:: Pasar a Metodo.
            if (
              resp.origins !== undefined &&
              resp.origins !== null &&
              resp.origins.length > 0
            ) {
              t.origins = resp.origins;
            } else {
              t.origins = [];
            }

            //Proceso Combos?. FIXME HZ:: Pasar a Metodo.
            if (resp.combos !== undefined && resp.combos !== null) {
              t.originalCombos = Object.entries(resp.combos);
              this.filterCombos(t, t.originalCombos);
            } else {
              t.combosFromFirebase = JSON.parse(JSON.stringify([]));
              t.combos = [];
            }
          } else {
            t.products = [];
            t.menues = [];
            t.origins = [];

            t.combosFromFirebase = JSON.parse(JSON.stringify([]));
            t.combos = [];
          }
          if (handler !== null && handler !== undefined) {
            handler();
          }
        } //controlRefDatalistproducts
      });
    },
    orderProductListByName(products) {
      products.sort(function (a, b) {
        if (!a[1].name) {
          return true
        }
        else if (!b[1].name) {
          return false
        }
        else return a[1].name
          .toLowerCase()
          .localeCompare(b[1].name.toLowerCase(), "es", {
            sensitivity: "base",
          });
      });
    },
    orderIngredientListByName(ingredients) {
      ingredients.sort(function (a, b) {
        if (!a[1].name) {
          return true
        }
        else if (!b[1].name) {
          return false
        }
        else
          return a[1].name
            .toLowerCase()
            .localeCompare(b[1].name.toLowerCase(), "es", {
              sensitivity: "base",
            });
      });
    },
    async exportProductList(listId) {
      this.excelParams = parseToQuery({ listId: listId });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportProducts/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    async exportProductCostList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportProductCosts/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    async exportProductRecipeList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportProductRecipes/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    async exportAdditionalRecipeList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportAdditionalRecipes/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }

    },
    async exportIngredientRecipeList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportIngredientRecipes/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    async exportAdditionalCostList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportAdditionalCosts/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    async exportIngredientCostList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportIngredientCosts/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    async exportIngredientList(shopCode) {
      this.excelParams = parseToQuery({ shopCode: shopCode });
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportIngredients/?${this.excelParams}`,
          "_blank"
        );
        win.focus();
      }

    },
    async exportAditionalsList() {
      if (await this.checkSessionInBackendAsync()) {
        let win = window.open(
          `/api/productsV2/exportAditionals/?${this.excelAditionalParams}`,
          "_blank"
        );
        win.focus();
      }
    },
    openNewModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearProduct();
      this.isNewProduct = true;
      this.openModalById("#modalProduct");
    },
    openNewOptionModal(optionType) {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.clearOption();
      this.isNewOption = true;
      this.optionType = optionType;
      this.openModalById("#modalOption");
    },
    openNewMenuModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearMenu();

      this.originsForUse = this.getAvailableOriginsForMenu();

      let origins = [];
      this.getProductsByMenu(origins, (x) => {
        this.openModalById("#modalMenu");
      });
      this.isNewMenu = true;
      this.currentMenu = "";

      // this.openModalById('#modalMenu')
    },
    openMenuModal(menu, index) {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearMenu();

      this.originsForUse = this.getAvailableOriginsForMenu();

      this.menu = {
        name: menu.name,
        key: index,
        origins: menu.origins,
        uuid: menu.uuid,
      };
      this.currentMenu = menu.name;
      this.isNewMenu = false;
      this.objectToRemove = { object: this.menu, index: index, type: "Carta" };

      this.getProductsByMenu(menu.origins, (x) => {
        if (this.currentMenu) {
          this.openModalById("#modalMenu");
        }
      });

      // this.openModalById('#modalMenu')
    },
    openCategoryModal(category) {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearCategory();
      let clone = Object.assign(category, {});
      this.category = Object.assign(this.category, clone);
      this.oldCategoryName = category.name;
      this.objectToRemove = {
        object: this.category,
        index: null,
        type: "Categoría",
      };

      this.openModalById("#modalCategory");
    },
    getAvailableOriginsForMenu() {
      let originsInUse = [];
      let originsForUse = [];
      if (
        this.menues !== undefined &&
        this.menues !== null &&
        this.menues.length > 0
      ) {
        this.menues.forEach((menu) => {
          menu.origins.forEach((origin) => {
            originsInUse.push(origin);
          });
        });
      }

      this.originsNodo.forEach((o) => {
        if (
        originsInUse.filter((t) => t.name === o.name).length === 0 && // Validación original
        (!this.isEnergyPOS || // Condición adicional para energypos
          (o.name !== "Pedidos Ya" && o.name !== "Rappi" && o.name !== "Weborders"))
        ) {
        originsForUse.push(o);
        }
        });
        return originsForUse;
    },
    
    openNewComboModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearCombo();
      this.isNewCombo = true;
      let origins = [];
      this.getProductsByMenu(origins, (x) => { });
      this.openModalById("#modalCombo");
    },
    openNewIngredientModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearIngredient();
      this.isNewIngredient = true;
      this.openModalById("#modalIngredient");
    },
    onCloseIngredientModal() {
      this.clearIngredient();
      this.closeModalById("#modalIngredient");
      this.getIngredients(this.selectedList.shopCode);
      ///FIXME HZ
    },
    openNewCategoryModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.clearCategory();
      this.isNewCategory = true;

      this.openModalById("#modalCategory");
    },
    openOptionModal(option, optionType) {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.clearOption();
      this.optionType = optionType;
      let clone = Object.assign(option, {});
      this.option = Object.assign(this.option, clone);
      this.option.price = this.getParse(this.option.price, "justNumber", "."); ///OK ADV HZ

      this.calculateInitialCheckCategories(option);

      this.objectToRemove = {
        object: this.option,
        index: null,
        type: optionType,
      };

      this.openModalById("#modalOption");
    },
    calculateInitialCheckCategories(option) {
      this.categories.forEach((element) => {
        if (
          element.optionsGroups !== undefined &&
          element.optionsGroups.length > 0
        ) {
          let og = element.optionsGroups.filter(
            (t) => t.name === this.getOptionType()
          );
          if (og !== undefined && og.length > 0) {
            let o = og[0].options.filter(
              (t) => t.name === option.name && t.price === option.price
            );
            if (o !== undefined && o.length > 0) {
              this.checkedCategories.push(element.name);
              this.initialCheckedCategories.push(element.name);
            }
          }
        }
      });
    },
    openImportModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportProduct.clearFile();
      this.$refs.modalImportProduct.setShopCode(this.getSelectedShop);

      var modalInstance = document.getElementById("ModalImportProducts");
      var instance = M.Modal.init(modalInstance);
      instance.open();

      //$("#ModalImportProducts").modal("open");
    },
    openImportProductCostModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportCost.clearFile();
      this.$refs.modalImportCost.setShopCode(this.getSelectedShop);
      this.$refs.modalImportCost.setCostType("Product");
      this.openModalById("#ModalImportCosts");
    },
    openImportProductRecipeModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportRecipe.clearFile();
      this.$refs.modalImportRecipe.setShopCode(this.getSelectedShop);
      this.$refs.modalImportRecipe.setCostType("Product");
      this.openModalById("#ModalImportRecipes");
    },
    openImportAdditionalCostModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportCost.clearFile();
      this.$refs.modalImportCost.setShopCode(this.getSelectedShop);
      this.$refs.modalImportCost.setCostType("Additional");
      this.openModalById("#ModalImportCosts");
    },
    openImportAdditionalRecipeModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportRecipe.clearFile();
      this.$refs.modalImportRecipe.setShopCode(this.getSelectedShop);
      this.$refs.modalImportRecipe.setCostType("Additional");
      this.openModalById("#ModalImportRecipes");
    },
    openImportIngredientCostModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportCost.clearFile();
      this.$refs.modalImportCost.setShopCode(this.getSelectedShop);
      this.$refs.modalImportCost.setCostType("Ingredient");
      this.openModalById("#ModalImportCosts");
    },
    openImportIngredientRecipeModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalImportRecipe.clearFile();
      this.$refs.modalImportRecipe.setShopCode(this.getSelectedShop);
      this.$refs.modalImportRecipe.setCostType("Ingredient");
      this.openModalById("#ModalImportRecipes");
    },
    openImportIngredientModal() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.$refs.modalImportIngredient.clearFile();
      this.$refs.modalImportIngredient.setShopCode(this.getSelectedShop);

      var modalInstance = document.getElementById("ModalImportIngredients");
      var instance = M.Modal.init(modalInstance);
      instance.open();
      //$("#ModalImportIngredients").modal("open");
    },
    recoveryProductListFromBackup() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalRecoveryProductsFromBackup.setShopCode(
        this.getSelectedShop
      );
      const params = parseToQuery({ shopCode: this.getSelectedShop });
      axios
        .get(`/api/productsV2/getProductBackups/?${params}`)
        .then((resp) => {
          this.availableBackups = resp.data.backups;

          var modalInstance = document.getElementById(
            "ModalRecoveryProductsFromBackup"
          );
          var instance = M.Modal.init(modalInstance);
          instance.open();

          //$("#ModalRecoveryProductsFromBackup").modal("open");
        })
        .catch((err) => {
          console.error(err.response);

          var modalInstance = document.getElementById(
            "ModalRecoveryProductsFromBackup"
          );
          var instance = M.Modal.init(modalInstance);
          instance.open();

          //$("#ModalRecoveryProductsFromBackup").modal("open");
        });
    },
    recoveryProductCostListFromBackup() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.$refs.modalRecoveryProductCostsFromBackup.setShopCode(
        this.getSelectedShop
      );
      const params = parseToQuery({ shopCode: this.getSelectedShop });
      axios
        .get(`/api/productsV2/getProductCostBackups/?${params}`)
        .then((resp) => {
          this.availableBackups = resp.data.backups;

          this.openModalById("#ModalRecoveryProductCostsFromBackup");
        })
        .catch((err) => {
          console.error(err.response);
          this.openModalById("#ModalRecoveryProductCostsFromBackup");
        });
    },
    recoveryAdditionalCostListFromBackup() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.$refs.modalRecoveryAdditionalCostsFromBackup.setShopCode(
        this.getSelectedShop
      );
      const params = parseToQuery({ shopCode: this.getSelectedShop });
      axios
        .get(`/api/productsV2/getAdditionalCostBackups/?${params}`)
        .then((resp) => {
          this.availableBackups = resp.data.backups;
          this.openModalById("#ModalRecoveryAdditionalCostsFromBackup");
        })
        .catch((err) => {
          console.error(err.response);
          this.openModalById("#ModalRecoveryAdditionalCostsFromBackup");
        });
    },
    recoveryIngredientCostListFromBackup() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      this.$refs.modalRecoveryIngredientCostsFromBackup.setShopCode(
        this.getSelectedShop
      );
      const params = parseToQuery({ shopCode: this.getSelectedShop });
      axios
        .get(`/api/productsV2/getIngredientCostBackups/?${params}`)
        .then((resp) => {
          this.availableBackups = resp.data.backups;
          this.openModalById("#ModalRecoveryIngredientCostsFromBackup");
        })
        .catch((err) => {
          console.error(err.response);
          this.openModalById("#ModalRecoveryIngredientCostsFromBackup");
        });
    },
    recoveryIngredientsListFromBackup() {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.$refs.modalRecoveryIngredientsFromBackup.setShopCode(
        this.getSelectedShop
      );
      const params = parseToQuery({ shopCode: this.getSelectedShop });
      axios
        .get(`/api/productsV2/getIngredientsBackups/?${params}`)
        .then((resp) => {
          this.availableBackups = resp.data.backups;

          var modalInstance = document.getElementById(
            "ModalRecoveryIngredientsFromBackup"
          );
          var instance = M.Modal.init(modalInstance);
          instance.open();

          //$("#ModalRecoveryIngredientsFromBackup").modal("open");
        })
        .catch((err) => {
          console.error(err.response);

          var modalInstance = document.getElementById(
            "ModalRecoveryIngredientsFromBackup"
          );
          var instance = M.Modal.init(modalInstance);
          instance.open();

          //$("#ModalRecoveryIngredientsFromBackup").modal("open");
        });
    },
    openImportAditionalsModal(optionType) {
      this.additionalOptionType = optionType;

      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }

      var modal = document.getElementById("ModalImportAditionals");
      var instance = M.Modal.init(modal);
      instance.open();
    },
    handleModal(product, key) {
      this.clearProduct();

      this.currentProduct = {
        sku: product.sku,
        name: product.name,
        department: product.department,
        rootVat: product.rootVat,
      };
      this.isNewProduct = false;
      this.deletingProduct = false;
      this.currentProductKey = key;
      let clone = Object.assign(product, {});
      this.product = Object.assign(this.product, clone);

      this.objectToRemove = {
        object: this.product,
        index: key,
        type: "Producto",
      };
      this.openModalById("#modalProduct");
    },
    handleComboModal(combo, key) {
      this.clearCombo();
      this.currentCombo = {
        sku: combo.sku,
        name: combo.name,
        department: combo.department,
      };
      this.isNewCombo = false;
      this.deletingCombo = false;
      let comboFound = false;
      this.combosFromFirebase.forEach((x) => {
        if (combo.sku === x[1].sku) {
          this.combo = JSON.parse(JSON.stringify(x[1]));
          comboFound = true;
        }
      });
      this.currentComboKey = key;
      if (comboFound === false) {
        let clone = Object.assign(combo, {});
        this.combo = Object.assign(this.combo, clone);
      }

      let origins = [];
      this.getProductsByMenu(origins, (x) => { });
      this.objectToRemove = { object: this.combo, index: key, type: "Combo" };
      this.openModalById("#modalCombo");
    },
    handleIngredientModal(item) {
      if (this.selectedList === null || this.selectedList === undefined) {
        toast.error("Por favor elija una lista de productos primero");
        return;
      }
      this.clearIngredient();
      this.isNewIngredient = false;

      let clone = Object.assign(item, {});
      this.ingredient = Object.assign(this.ingredient, clone);
      this.objectToRemove = {
        object: this.ingredient,
        index: this.ingredient.id,
        type: "Ingrediente",
      };
      this.openModalById("#modalIngredient");
    },
    clearProduct() {
      this.product = {
        department: "",
        description: "",
        image: "",
        name: "",
        sku: "",
        optionsGroup: [],
        origins: [],
        shortName: "",
        // stock: 0,
        weightable: false,
        measureUnit: "",
        minimunStockQuantity: 0,
        type: "",
        rootVat: "",
      };
      this.currentProductKey = -1;
      this.currentProduct = null;
    },
    clearCombo() {
      this.combo = {
        department: "",
        description: "",
        image: "",
        name: "",
        sku: "",
        rootVat: "",
      };
      this.currentComboKey = -1;
      this.currentCombo = null;
    },
    clearIngredient() {
      this.ingredient = {
        id: 0,
        name: "",
        sku: "",
        description: "",
        cost: 0,
        weightable: true,
        measureUnit: "",
        minimunStockQuantity: 0,
        ullagePercentage: 0,
      };
      this.isNewIngredient = false;
    },
    clearOption() {
      this.option = {
        maxQty: 0,
        minQty: 0,
        name: "",
        price: "",
        exempt: false,
        nonTaxed: false,
      };
      this.isNewOption = false;
      this.optionType = "";
      this.checkedCategories = [];
      this.initialCheckedCategories = [];
    },
    clearMenu() {
      this.menu = {
        key: "",
        name: "",
        origins: [],
      };
      this.originsForUse = [];
      this.productsByMenu = [];
      this.isNewMenu = false;
    },
    clearCategory() {
      this.category = {
        name: "",
        optionsGroups: [],
      };
      this.isNewCategory = false;
      this.oldCategoryName = "";
    },
    enableSpinnerOnMenu(){
      this.isMenuLoading = true;
    },
    disableSpinnerOnMenu(){
      this.isMenuLoading = false;
    }
  },
  /* eslint-enable */
};
</script>
