// Importa las funciones necesarias desde los módulos específicos
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getDatabase } from 'firebase/database';


// Configuración de Firebase
let firebaseConfig = {};

console.log(`INSTANCE_CODE: ${process.env.VUE_APP_INSTANCE_CODE}`);

// Configuración de Firebase basada en INSTANCE_CODE
switch (process.env.VUE_APP_INSTANCE_CODE) {
  case 'PROVENTAS':
    firebaseConfig = {
      apiKey: "AIzaSyC1PwegHsfexeoTDnMjfuDZBA7QH9ix9og",
      authDomain: "proventas-37c6b.firebaseapp.com",
      databaseURL: "https://proventas-37c6b-default-rtdb.firebaseio.com",
      projectId: "proventas-37c6b",
      storageBucket: "proventas-37c6b.appspot.com",
      messagingSenderId: "749572831126",
      appId: "1:749572831126:web:a38e0a149717e656e88fb7",
      measurementId: "G-EJTVPYRX5J"
    };
    break;
  case 'ENERGY_POS_SV':
     firebaseConfig = {
      apiKey: "AIzaSyATLuguJRnKABr-hr2ycyYdkgDtWcMDVyU",
      authDomain: "energy-pos-core.firebaseapp.com",
      databaseURL: "https://energy-pos-core-default-rtdb.firebaseio.com",
      projectId: "energy-pos-core",
      storageBucket: "energy-pos-core.firebasestorage.app",
      messagingSenderId: "583716116386",
      appId: "1:583716116386:web:53769cbfa5e3a2277384fb",
      measurementId: "G-PV17MLGQLX"
    };
    break;

  case 'ENERGY_POS_AR':
      firebaseConfig = {
       apiKey: "AIzaSyATLuguJRnKABr-hr2ycyYdkgDtWcMDVyU",
       authDomain: "energy-pos-core.firebaseapp.com",
       databaseURL: "https://energy-pos-core-default-rtdb.firebaseio.com",
       projectId: "energy-pos-core",
       storageBucket: "energy-pos-core.firebasestorage.app",
       messagingSenderId: "583716116386",
       appId: "1:583716116386:web:53769cbfa5e3a2277384fb",
       measurementId: "G-PV17MLGQLX"
     };
     break;

  case 'CL':
    firebaseConfig = {
      apiKey: "AIzaSyDJHnIovNPTBws_r9jTmkyj2nOaqN30bOg",
      authDomain: "bistro-cl.firebaseapp.com",
      databaseURL: "https://bistro-cl-default-rtdb.firebaseio.com",
      projectId: "bistro-cl",
      storageBucket: "bistro-cl.appspot.com",
      messagingSenderId: "109302466151",
      appId: "1:109302466151:web:33caa8317797f6a050f6c2",
      measurementId: "G-4CCN06SZJP"
    };
    break;

  case 'AR':
    firebaseConfig = {
      apiKey: 'AIzaSyDbT80BH2-4Hpz_4DZGExD2D3W6b6X49fs',
      authDomain: 'testfirebase-4ae5d.firebaseapp.com',
      databaseURL: 'https://testfirebase-4ae5d.firebaseio.com',
      projectId: 'testfirebase-4ae5d',
      storageBucket: 'testfirebase-4ae5d.appspot.com',
      messagingSenderId: '90963173342',
      appId: '1:90963173342:web:7287e078290f47f24dc74f',
      measurementId: 'G-MZGZEJHJSM'
    };
    break;

  case 'UY':
    firebaseConfig = {
      apiKey: 'AIzaSyBBAUJjVxAMnuWKaJ1ESXMxxyDOMVcQ0O4',
      authDomain: 'bistro-uy.firebaseapp.com',
      databaseURL: 'https://bistro-uy-default-rtdb.firebaseio.com',
      projectId: 'bistro-uy',
      storageBucket: 'bistro-uy.appspot.com',
      messagingSenderId: '538484013085',
      appId: '1:538484013085:web:0de0f3f1d2074a35eb6ef4',
      measurementId: 'G-1TXFCN0BWW'
    };
    break;

  case 'ES':
    firebaseConfig = {
      apiKey: 'AIzaSyCfiwsD8weE0qbvYP-vhU7pe8tAfo0pJzs',
      authDomain: 'bistro-es.firebaseapp.com',
      databaseURL: 'https://bistro-es-default-rtdb.firebaseio.com',
      projectId: 'bistro-es',
      storageBucket: 'bistro-es.appspot.com',
      messagingSenderId: '885801677839',
      appId: '1:885801677839:web:d3b6ba37210eb005e2df7a',
      measurementId: 'G-54JX15C9CC'
    };
    break;

  case 'MX':
    firebaseConfig = {
      apiKey: 'AIzaSyDMZX-YCb5vaD249GYkb1Zk-i7Ga1LhGtE',
      authDomain: 'bistro-mx.firebaseapp.com',
      databaseURL: 'https://bistro-mx-default-rtdb.firebaseio.com',
      projectId: 'bistro-mx',
      storageBucket: 'bistro-mx.appspot.com',
      messagingSenderId: '1085440460597',
      appId: '1:1085440460597:web:30784712539a83fc4cae7c',
      measurementId: 'G-KK9BLD47C6'
    };
    break;

  default:
    console.error("INSTANCE_CODE no válido");
    break;
}

// // Inicializa Firebase
// const firebaseApp = initializeApp(firebaseConfig);

// // Inicializa Firebase Database 
// export const db = getDatabase(firebaseApp);


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

// Sign in Anonymously
signInAnonymously(auth)
  .then(() => console.log("✅ Anonymous sign-in successful"))
  .catch((error) => console.error("❌ Anonymous sign-in failed", error));

// Listen for auth state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log("✅ Signed in as:", user.uid);
  } else {
    console.log("❌ User signed out");
  }
});

export { auth, db };