<template>
  <section style="text-align: -webkit-center">
    <LogoSwitcher :width="'40%'" :height="'40%'" />

    <section v-if="data.pdfBillingFilePath === null" class="billing-section-container">
      <article class="ticket">
        <div class="header">
          <h3>{{ data.shopName }}</h3>
          <h5>{{ data.additionalInfo_1 }}</h5>
          <h5>{{ data.additionalInfo_2 }}</h5>
          <h4>{{ data.origin }}</h4>
          <h3>{{ data.saleId }}</h3>
        </div>

        <hr />

        <div class="list">
          <h5 class="left">{{ data.date }}</h5>
          <h5 class="right">{{ data.hour }}</h5>
        </div>
        <div class="list">
          <h5 class="left">Número de ticket</h5>
          <h5 class="right">{{ data.ticketNumber }}</h5>
        </div>

        <hr />

        <div v-for="(item, index) in data.items" :key="index">
          <div class="list">
            <h5 class="left">
              {{ item.quantity }} {{ item.measureUnit }} {{ item.decription }}
            </h5>
            <currency-style
              class="right"
              :valor="$filters.currency(item.total)"
            />
          </div>

          <div
            v-for="(op, i) in item.options"
            :key="i"
            class="list list-option"
          >
            <h5 class="left">{{ op.quantity }} {{ op.decription }}</h5>
            <currency-style
              class="right"
              :valor="$filters.currency(op.total)"
            />
          </div>
        </div>
        <div
          v-for="(comboItem, comboIndex) in data.comboItems"
          :key="comboIndex"
        >
          <div class="list">
            <h5 class="left">
              {{ comboItem.quantity }} {{ comboItem.measureUnit }}
              {{ comboItem.decription }}
            </h5>
            <currency-style
              class="right"
              :valor="$filters.currency(comboItem.total)"
            />
          </div>
          <div class="list comboList">
            <div v-for="(item, index) in comboItem.items" :key="index">
              <div class="list">
                <h5 class="left">
                  {{ item.quantity }} {{ item.measureUnit }}
                  {{ item.decription }}
                </h5>
                <currency-style
                  class="right"
                  :valor="$filters.currency(item.total)"
                />
              </div>

              <div
                v-for="(op, i) in item.options"
                :key="i"
                class="list list-option"
              >
                <h5 class="left">{{ op.quantity }} {{ op.decription }}</h5>
                <currency-style
                  class="right"
                  :valor="$filters.currency(op.total)"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="data.discountAmount !== 0" class="list">
          <h5 class="left">DESCUENTO</h5>
          <currency-style
            class="right"
            :valor="$filters.currency(data.discountAmount)"
          />
        </div>

        <div class="total">
          <h3>TOTAL</h3>
          <currency-style
            class="amount"
            :valor="$filters.currency(data.totalAmount)"
          />
        </div>

        <hr />

        <div class="payment-details">
          <h5>{{ data.paymentMethod }}</h5>
          <h5 class="change">Vuelto:</h5>
          <currency-style :valor="$filters.currency(data.changeAmount)" />
        </div>

        <div class="footer">
          <h5>{{ data.footer }}</h5>
        </div>
      </article>

      <article class="ticket-form" v-if="isCurrentMonthSale">
        <!-- <div class="itemForm">
					<label for>Email: </label>
					<input v-model="params.email" type="text" placeholder="Ingrese su mail"/>
				</div> -->
        <div class="itemForm">
          <label for>Nombre: </label>
          <input
           required
            :value="params.fiscalLegalName"
            @input="upperCase($event, 'fiscalLegalName')" 
            type="text" 
            placeholder="Ingrese su nombre" 
          />
        </div>
        <div class="itemForm">
          <label for>Correo Electrónico: </label>
          <input
            required 
            :value="params.mail" 
            @input="upperCase($event, 'mail')" 
            type="text" 
            placeholder="Ingrese su correo" 
          />
        </div>
        <div class="itemForm">
          <label for>RFC: </label>
          <input
            required
            :value="params.fiscalkey"
            type="text"
            placeholder="Ingrese su RFC"
            @input="upperCase($event, 'fiscalkey')" 
          />
        </div>
        <div class="itemForm">
          <label for>Código postal: </label>
          <input
            required
            v-model="params.fiscalAddress"
            type="text"
            placeholder="Ingrese su código postal"
          />
        </div>

        <article class="itemForm">
          <label for>Uso del comprobante: </label>
          <select
            v-model="params.fiscalBillUse"
            class="browser-default backLabel"
          >
            <option value="-1" selected>
              Seleccione un uso del comprobante
            </option>
            <option
              v-for="i in getFiscalBillUses"
              :value="i.value"
              :key="i.value"
            >
              {{ i.value }} - {{ i.label }}
            </option>
          </select>
        </article>

        <article class="itemForm">
          <label for>Régimen fiscal: </label>
          <select
            v-model="params.fiscalCondition"
            class="browser-default backLabel"
          >
            <option value="-1" selected>Seleccione un régimen fiscal</option>
            <option
              v-for="i in getFiscalRegimes"
              :value="i.value"
              :key="i.value"
            >
              {{ i.value }} - {{ i.label }}
            </option>
          </select>
        </article>

        <br />
        <br />

        <p>
          Atención: Si no ingresa su información, esta compra se va a facturar
          con un receptor genérico.
        </p>

        <br />

        <SpinnerComp v-if="isLoading" />
        <a v-else @click="processBill" type="submit">
          <p>Emitir factura</p>
        </a>
      </article>
      <div v-else class="ticket"> <p>Solo es posible facturar ventas del mes en curso. Las ventas de meses anteriores no pueden ser facturadas.</p>
			</div>
    </section>
    <section v-else>
      <iframe
        :src="data.pdfBillingFilePath"
        style="width: -webkit-fill-available; height: 40em"
        frameborder="0"
      ></iframe>
    </section>
  </section>
</template>

<script>
import mixCommons from "../mixins/mixCommons";
import axios from "axios";
import { parseToQuery } from "../utils/utils";
import moment from "moment";
import CurrencyStyle from "../components/CurrencyStyle";
import SpinnerComp from "../components/SpinnerComp";
import LogoSwitcher from "../components/logos/LogoSwitcher.vue";

moment.locale("es");

export default {
  mixins: [mixCommons],
  data() {
    return {
      params: {
        internalReference: "",
        fiscalLegalName: "",
        fiscalkey: "",
        fiscalAddress: "",
        mail: null,
        fiscalCondition: "-1",
        fiscalBillUse: "G03",
        billType: "I",
        // email: ''
      },
      data: {},
      isLoading: false,
    };
  },
  mounted() {
    document.title = `${this.appTitle}  - Verificación de Comanda`;
    this.internalReference = this.$route.query.InternalReference;
    console.log(`query`, this.$route.query);
    console.log(`this.internalReference`, this.internalReference);
    if (
      this.internalReference !== undefined &&
      this.internalReference !== null
    ) {
      this.getOrderInfo();
    } else {
      this.showError(
        "No se encontró el campo de referencia para consultar la comanda"
      );
    }
  },
  computed: {
    getFiscalBillUses() {
      return this.data.fiscalBillUses;
    },
    getFiscalRegimes() {
      return this.data.fiscalRegimes;
    },
    isCurrentMonthSale() {
				const currentDate = new Date();
				const currentMonth = currentDate.getMonth(); // 0-indexed month number
				const saleDateParts = this.data.date.split('-');
				const saleMonth = parseInt(saleDateParts[1]) - 1; // Convert to 0-indexed month number
				
				return saleMonth === currentMonth;// Compara solo el mes y el año
  }
  },
  methods: {
    upperCase(event, field) {
      this.params[field] = event.target.value.toUpperCase();
    },
    getOrderInfo() {
      this.$store.initLoading(this);
      this.params["internalReference"] = this.internalReference;

      axios
        .get(`/api/billViewer/?${parseToQuery(this.params)}`)
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.data = res.data;
            this.$store.finishLoading(this);
          } else {
            this.showError(res.data.responseMessage);
            this.$store.errorLoading(this);
          }
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
        });
    },
    processBill() {
      this.isLoading = true;

      if (this.isRequestValid() === false) {
        this.showError(
          "Si requiere el comprobante indicando un receptor específico, debe completar obligatoriamente todos los campos del formulario."
        );
        this.isLoading = false;
        return;
      }

      axios
        .post("/api/billViewer", this.params)
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.showSuccess("Factura realizada con éxito!");
            this.getOrderInfo();
          } else {
            this.showError(res.data.responseMessage);
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.showErrors(err.response);
        });
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    onSelectFiscalBillUse(i) {
      console.log(i);
    },
    isRequestValid() {
      if (
        this.params.fiscalLegalName === "" &&
        this.params.fiscalkey === "" &&
        this.params.fiscalAddress === "" &&
        this.params.fiscalCondition === "-1" &&
        this.params.fiscalBillUse === "-1"
      ) {
        return true;
      }

      if (
        this.params.fiscalLegalName !== "" &&
        this.params.fiscalkey !== "" &&
        this.params.fiscalAddress !== "" &&
        this.params.fiscalCondition !== "-1" &&
        this.params.fiscalBillUse !== "-1"
      ) {
        return true;
      }

      return false;
    },
  },
  components: {
    CurrencyStyle,
    SpinnerComp,
    LogoSwitcher
  },
};
</script>
<style scoped
>
.billing-section-container {
 max-height: 80vh; 
 overflow-y: auto;
}
</style>
