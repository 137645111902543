<template>
  <div id="modalMenu" class="modal modal-block-outside" style="overflow-y: auto !important" @keydown.esc="closeModal()"
    v-if="!mobile">
    <div class="modal-header">
      <div class="image-title">
        <h4 v-if="isNewMenu">{{ buttonAction }}</h4>
        <h4 v-else>{{ menu.name }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <section class="formMain article-product">
          <div class="itemForm" style="width: 100%">
            <label class="label" for>{{ getNameLabel }}</label>
            <input class="input" v-model="localMenu.name" :disabled="!canEdit" :readonly="!canEdit" type="text"
              placeholder="Ingrese nombre de la carta" />
          </div>
        </section>

        <!-- isNewMenu se pone en true cuando se abrio el modal para crear una nueva carta -->
        <section v-if="isNewMenu">
          <!-- originsForUse es un array con los distintos fds que puedo tener -->
          <article class="formMain article-product" v-if="originsForUse.length > 0">
            <div class="itemForm itemCategories" v-for="(origin, index) in originsForUse" :key="index">
              <div class="switch item-categories">
                <label style="display: inline-flex">
                  <input :checked="getValue(origin.name)" @change="onCheckOrigin(origin.name)" type="checkbox" />
                  <span class="lever" style="width: 55px"></span>
                  <div class="category-name">
                    {{ translateOrigin(origin.name) }}
                  </div>
                </label>
              </div>
            </div>
          </article>
          <article class="formMain article-product" v-else>
            <div class="itemForm selectImage">
              No hay origenes disponibles para crear una carta
            </div>
          </article>
        </section>
        <!-- Cuando se abre una carta que ya fue creada entro aca -->
        <section v-else>
          <article class="formMain article-product" v-if="menu.origins.length > 0">
            <!-- Voy por cada fds que tengo -->
            <div class="itemForm itemCategories" v-for="origin in localMenu.origins" :key="origin.name">
              <div class="switch item-categories">
                <label style="display: inline-flex">
                  <input :checked="getValue(origin.name)" type="checkbox" :disabled="!canEdit" :readonly="!canEdit"
                    @change="onCheckOrigin(origin.name)" />
                  <span class="lever" style="width: 55px"></span>
                  <div class="category-name">
                    {{ translateOrigin(origin.name) }}
                  </div>
                </label>
              </div>
            </div>
            <div class="itemForm itemCategories" v-for="origin in originsForUse" :key="origin.name" >
              <div class="switch item-categories" > 
                <label style="display: inline-flex">
                  <input :checked="getValue(origin.name)" :disabled="!canEdit" :readonly="!canEdit"
                    @change="onCheckOrigin(origin.name)" type="checkbox" />
                  <span class="lever" style="width: 55px"></span>
                  <div class="category-name">
                    {{ translateOrigin(origin.name) }}
                  </div>
                </label>
              </div>
            </div>
          </article>
        </section>
        <!-- Para buscar productos  -->
        <section class="formMain article-product article-product-filter">
          <input class="search-input" v-model="productSearchInput" placeholder="Filtrar producto" type="text" />
        </section>
        <!-- Lista de productos -->
        <section class="formMain article-product" v-for="(prodByMenu, index) in getProductsByCategoryInUse"
          :key="index">
          <h4 class="categoryTitle">{{ prodByMenu.name }}</h4>
          <article class="fixSeeMenu menuColumnTitle">
            <div>
              <a>Nuevo Producto</a>
            </div>
            <div>
              <a>Precio</a>
            </div>
            <div>
              <a>Nuevo Precio</a>
            </div>
            <div>
              <a></a>
            </div>
            <div v-if="hasToShowAvailability">
              <a>Disponible</a>
            </div>
          </article>
          <article v-for="(prod, index) in getProductsInUse(prodByMenu.products)" :key="index"
            class="itemForm selectImage fixSeeMenu">
            <div class="itemFormOrigins">
              <a>{{ prod.name }}</a>
              <div>
                <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
      (x) => x.originName === `PEDIDOS YA`
    )[0]
      " origin="Pedidos Ya" />
                <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
      (x) => x.originName === `UBER EATS`
    )[0]
      " origin="Uber Eats" />
                <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
      (x) => x.originName === `RAPPI`
    )[0]
      " origin="Rappi" />
                <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
      (x) => x.originName === `GLOVO`
    )[0]
      " origin="Glovo" />
                <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
      (x) => x.originName === `MP DELIVERY`
    )[0]
      " origin="MP Delivery" />
                <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
      (x) => x.originName === `Didi Food`
    )[0]
      " origin="Didi Food" />
              </div>
            </div>
            <div class="itemFormPrice">
              <a><currency-style :valor="$filters.currency(prod.currentPrice)" /></a>
            </div>
            <div class="itemFormMenu">
              <div class="itemFormMenuAmount">
                <input class="money-input" v-model="prod.price" :disabled="!canEdit" :readonly="!canEdit"
                  :step="getStep" type="number" @wheel="$event.target.blur()" :placeholder="`${getParseWithCurrencySymbol(
      prod.currentPrice,
      'justNumber',
      '.'
    )}`" />
              </div>
            </div>
            <div class="itemFormTrash">
              <img class="button-img" alt="Eliminar" v-if="canEdit" title="Eliminar"
                @click.prevent="(prod.price = ''), (prod.currentPrice = '')" src="@/assets/img/delete.svg" width="20px"
                height="20px" />
            </div>
            <div v-if="hasToShowAvailability" class="itemFormProdAvailability">
              <div class="switch item-categories">
                <label style="display: inline-flex">
                  <input :checked="isProductAvailable(prod)" @change="onCheckProductAvailability(prod)"
                    type="checkbox" />
                  <span class="lever"></span>
                </label>
              </div>
            </div>
          </article>

          <article class="itemForm selectImage" v-if="canEdit">
            <select v-if="getProductsNotUse(prodByMenu.products) != null &&
      getProductsNotUse(prodByMenu.products).length > 0
      " v-model="keyForReturnSelectToStart" @change="onSelectProduct($event, prodByMenu.products)"
              class="browser-default backLabel">
              <option value="-1" selected>Agregar producto</option>
              <option v-for="(prod, index) in getProductsNotUse(prodByMenu.products)" :value="prod.sku" :key="index">
                {{ prod.name }}
              </option>
            </select>
            <div v-else class="success-hint">
              Genial! Ya tenés todos los productos de esta categoría asignados a
              tu carta
            </div>
          </article>
        </section>

        <section class="formMain article-product">
          <div class="itemForm selectImage">
            <!-- <label for>Categoría:</label> -->
            <select v-if="getCategoriesNotUse != null &&
              getCategoriesNotUse.length > 0 &&
              canEdit
            " v-model="keyForReturnSelectToStart" @change="onSelectCategory($event)" class="browser-default">
              <option value="-1" selected>Agregar categoría</option>
              <option v-for="(category, index) in getCategoriesNotUse" :value="category" :key="index">
                {{ category }}
              </option>
            </select>
          </div>
        </section>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a v-if="isNewMenu === false && canEdit" class="deleteProduct" @click.prevent="deleteMenu">
          <span class="icon-Bistro-_Trash_bin"></span><span>{{ getDeleteLabel }}</span>
        </a>
        <Spinner v-if="isMenuLoading" />
        <a v-else @click="upsertMenu" class="save-button" type="submit">
          <p v-if="isNewMenu">{{ buttonAction }}</p>
          <p v-else>{{ buttonUpdate }}</p>
        </a>
      </article>
    </div>
  </div>
  <div id="modalMenu" class="modal modal-block-outside" style="overflow-y: auto !important" @keydown.esc="closeModal()"
    v-else>
    <!-- HEADER, MUESTRA EL NOMBRE DE LA CARTA -->
    <div class="modal-header">
      <div class="image-title">
        <h4 v-if="isNewMenu">{{ buttonAction }}</h4>
        <h4 v-else>{{ menu.name }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>
    <!-- ------ -->

    <!-- TODO EL RESTO -->
    <div class="modal-body">
      <form action class="formGeneral">
        <!-- PARA ACTUALIZAR NOMBRE CARTA -->
        <section class="formMain article-product">
          <div class="itemForm" style="width: 100%">
            <label class="label" for>Nombre de la carta:</label>
            <input class="input" v-model="localMenu.name" :disabled="!canEdit" :readonly="!canEdit" type="text"
              placeholder="Ingrese nombre de la carta" />
          </div>
        </section>
        <!-- SI EL MENU SE ESTA CREANDO POR PRIMERA VEZ ENTRO ACA -->
        <section v-if="isNewMenu">
          <!-- SI TENGO FDS DISPONIBLES -->
          <article class="formMain article-product" v-if="originsForUse.length > 0">
            <!-- PONGO EL INPUT PARA TODOS LOS FDS -->
            <div class="itemForm itemCategories" v-for="(origin, index) in originsForUse" :key="index">
              <div class="switch item-categories">
                <label style="display: inline-flex">
                  <input :checked="getValue(origin.name)" @change="onCheckOrigin(origin.name)" type="checkbox" />
                  <span class="lever" style="width: 55px"></span>
                  <div class="category-name">
                    {{ translateOrigin(origin.name) }}
                  </div>
                </label>
              </div>
            </div>
          </article>
          <!-- SI NO HABIA NINGUN FDS MUESTRO ESTO -->
          <article class="formMain article-product" v-else>
            <div class="itemForm selectImage">
              No hay origenes disponibles para crear una carta
            </div>
          </article>
        </section>
        <!-- SI EL MENU YA FUE CREADO ENTRO ACA -->
        <section v-else>
          <!-- SI EL MENU TIENE FDS -->
          <article class="formMain article-product" v-if="menu.origins.length > 0">
            <!-- PRIMERO PONGO TODOS LOS FDS QUE ESTEN INTEGRADOS ACTUALMENTE -->
            <div class="itemForm itemCategories" v-for="origin in localMenu.origins" :key="origin.name">
              <div class="switch item-categories">
                <label style="display: inline-flex">
                  <input :checked="getValue(origin.name)" type="checkbox" :disabled="!canEdit" :readonly="!canEdit"
                    @change="onCheckOrigin(origin.name)" />
                  <span class="lever" style="width: 55px"></span>
                  <div class="category-name">
                    {{ translateOrigin(origin.name) }}
                  </div>
                </label>
              </div>
            </div>
            <!-- DESPUES PONGO LOS FDS QUE NO ESTAN HABILITADOS EN LA CARTA -->
            <div class="itemForm itemCategories" v-for="origin in originsForUse" :key="origin.name">
              <div class="switch item-categories">
                <label style="display: inline-flex">
                  <input :checked="getValue(origin.name)" :disabled="!canEdit" :readonly="!canEdit"
                    @change="onCheckOrigin(origin.name)" type="checkbox" />
                  <span class="lever" style="width: 55px"></span>
                  <div class="category-name">
                    {{ translateOrigin(origin.name) }}
                  </div>
                </label>
              </div>
            </div>
          </article>
        </section>
        <!-- FILTRO PARA BUSCAR LOS PRODUCTOS -->
        <section class="formMain article-product article-product-filter">
          <input class="search-input product-filter" v-model="productSearchInput" placeholder="Filtrar producto"
            type="text" />
        </section>
        <!-- LISTA DE PRODUCTOS EN LA CARTA -->
        <section class="formMain article-product" v-for="(prodByMenu, index) in getProductsByCategoryInUse"
          :key="index">
          <!-- NOMBRE DE LA CATEGORIA -->
          <h4 class="categoryTitle">{{ prodByMenu.name }}</h4>
          <!-- PONGO TODOS LOS PRODUCTOS DE ESA CATEGORIA -->
          <article v-for="(prod, index) in getProductsInUse(prodByMenu.products)" :key="index"
            class="itemForm fixSeeMenu product-list-container">
            <!--  Contiene todo el producto-->
            <div class="product-container">
              <!-- Contiene la informacion basica del producto -->
              <div class="product-basic-info" @click="updateProdDetails(prodByMenu.name, prod.name)">
                <div class="product-basic-info-left">
                  <a class="product-basic-info-name">{{ prod.name }}</a>
                  <div class="product-basic-info-img">
                    <div class="product-basic-info-image-container" v-if="HasToShowFdsStatus(prod)">
                      <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
                        (x) => x.originName === `PEDIDOS YA`
                      )[0]
                        " origin="Pedidos Ya" />
                      <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
                        (x) => x.originName === `UBER EATS`
                      )[0]
                        " origin="Uber Eats" />
                      <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
                        (x) => x.originName === `RAPPI`
                      )[0]
                        " origin="Rappi" />
                      <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
                        (x) => x.originName === `GLOVO`
                      )[0]
                        " origin="Glovo" />
                      <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
                        (x) => x.originName === `MP DELIVERY`
                      )[0]
                        " origin="MP Delivery" />
                      <fds-sync-status :fdsSyncStatus="prod.fdsSyncStatus.filter(
                        (x) => x.originName === `DIDI FOOD`
                      )[0]
                        " origin="Didi Food" />
                    </div>

                  </div>
                </div>
                <!-- Precio -->
                <div class="product-basic-info-right">
                  <a><currency-style :valor="$filters.currency(prod.currentPrice)" /></a>
                  <!-- Icono para hacer el desplegable -->
                  <v-icon :class="{ 'rotate-180': prodByMenu.name + prod.name == prodDetails }" size="22"
                    color="black">mdi-chevron-down</v-icon>
                </div>
              </div>
              <!-- Contiene la parte que se despliegua cuando pulsas -->
              <div class="product-edit-tab" v-if="prodByMenu.name + prod.name == prodDetails">
                <!-- SWITCH DISPONIBLE -->
                <div v-if="hasToShowAvailability" class="product-edit-tab-switch">
                  <p>Disponible</p>
                  <div class="switch item-categories">
                    <label style="display: inline-flex">
                      <input :checked="isProductAvailable(prod)" @change="onCheckProductAvailability(prod)"
                        type="checkbox" />
                      <span class="lever"></span>
                    </label>
                  </div>
                </div>
                <!-- INPUT PARA CAMBIAR PRECIO -->
                <div class="product-edit-tab-input">
                  <div class="itemFormMenuAmount">
                    <input class="product-edit-input" v-model="prod.price" :disabled="!canEdit" :readonly="!canEdit"
                      :step="getStep" type="number" @wheel="$event.target.blur()" :placeholder="`${getParseWithCurrencySymbol(
                        prod.currentPrice,
                        'justNumber',
                        '.'
                      )}`" />
                  </div>
                </div>
                <!-- BOTON PARA BORRAR PRODUCTO -->
                <div class="itemFormTrash">
                  <img class="button-img" alt="Eliminar" v-if="canEdit" title="Eliminar"
                    @click.prevent="(prod.price = ''), (prod.currentPrice = '')" src="../assets/img/delete.svg"
                    width="20px" height="20px" />
                </div>

              </div>
            </div>

          </article>

          <!-- Si es el administrador puede agregar o cambiar los productos que estan en la carta -->
          <article class="itemForm selectImage" v-if="canEdit">
            <!-- Barra selectora con los productos que no se usaron en la categoria -->
            <select v-if="getProductsNotUse(prodByMenu.products) != null &&
              getProductsNotUse(prodByMenu.products).length > 0
            " v-model="keyForReturnSelectToStart" @change="onSelectProduct($event, prodByMenu.products)"
              class="browser-default backLabel">
              <option value="-1" selected>Agregar producto</option>
              <option v-for="(prod, index) in getProductsNotUse(prodByMenu.products)" :value="prod.sku" :key="index">
                {{ prod.name }}
              </option>
            </select>
            <!-- Si ya tiene todos los productos agregados -->
            <div v-else class="success-hint">
              Genial! Ya tenés todos los productos de esta categoría asignados a
              tu carta
            </div>
          </article>
        </section>
        <!-- BARRA SELECTORA PARA AGREGAR UNA CATEGORIA-->
        <section class="formMain article-product">
          <div class="itemForm selectImage">
            <!-- <label for>Categoría:</label> -->
            <select v-if="getCategoriesNotUse != null &&
              getCategoriesNotUse.length > 0 &&
              canEdit
            " v-model="keyForReturnSelectToStart" @change="onSelectCategory($event)" class="browser-default">
              <option value="-1" selected>Agregar categoría</option>
              <option v-for="(category, index) in getCategoriesNotUse" :value="category" :key="index">
                {{ category }}
              </option>
            </select>
          </div>
        </section>
      </form>
    </div>
    <!-- FOOTER con el boton para crear, guardar o eliminar la carta -->
    <div class="modal-footer">
      <article class="btn-main">
        <a v-if="isNewMenu === false && canEdit" class="deleteProduct" @click.prevent="deleteMenu">
          <span class="icon-Bistro-_Trash_bin"></span><span>Eliminar carta</span>
        </a>
        <Spinner v-if="isMenuLoading" />
        <a v-else @click="upsertMenu" type="submit">
          <p v-if="isNewMenu">{{ buttonAction }}</p>
          <p v-else>{{ buttonUpdate }}</p>
        </a>
      </article>
    </div>
  </div>
</template>
<style>
.product-filter {
  width: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

.product-container {
  width: 100%;
  border-bottom: 1px solid;
  border-color: rgb(187, 187, 187);
  display: flex;
  flex-direction: column;
}

.product-basic-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.product-basic-info-left {
  max-width: 50%;
}

.product-basic-info-name {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.product-basic-info-img div {
  display: flex;
}

.product-basic-info-image-container {
  display: inline-block !important;
}

.product-basic-info-right {
  display: flex;
  align-items: center
}

.product-basic-info-right a {
  color: #24a5d3;
  font-size: 14px;
  font-weight: bold;
  font-family: "Rubik-Medium";
  margin-right: 5px
}

.product-edit-tab {
  background-color: rgb(233, 233, 233);
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
}

.product-edit-tab-switch {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin-left: 7px;
  align-items: center;
}

.product-edit-tab-switch p {
  margin-bottom: 0;
}

.product-edit-tab-input {
  margin-right: 10px;
  width: 90px;
}

.product-edit-input {
  height: 30px !important;
  text-align: end;
  padding-right: 10px !important;
  margin-right: 10px;
  width: 80px !important;
  margin-bottom: 6px !important;
}

.product-list-container {
  width: 100% !important;
}
</style>

<script>
import $ from "jquery";
import CurrencyStyle from "../components/CurrencyStyle";
import FdsSyncStatus from "../components/FdsSyncStatus";
import currencyCommons from "../mixins/currencyCommons.vue";
import rolesCommons from "../mixins/rolesCommons";
import originCommons from "../mixins/originCommons";
import mobileDetection from "../mixins/mobileDetection";
import productV2Commons from "../mixins/productV2Commons";
import Spinner from "../components/SpinnerComp";
import { toast } from "vue3-toastify";


export default {
  mixins: [currencyCommons, rolesCommons, mobileDetection, originCommons, productV2Commons],
  props: {
    originsForUse: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allCategories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    menu: {
      type: Object,
      default: () => {
        return {};
      },
    },
    menuBeforeChanges: {
      type: Object,
      default: () => {
        return {};
      },
    },
    productsByMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isNewMenu: {
      type: Boolean,
      default: false, 
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    currentMenu: {
      type: String,
      default: () => {
        return "";
      },
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    isMenuLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedCategoryToAddInProductList: "",
      keyForReturnSelectToStart: -1,
      productSearchInput: "",
      checkedOrigins: [],
      originsOut: [],
      savingControl: false,
      prodDetails: "",
      localMenu: { ...this.menu }, // Copia del objeto 
      localProductsByMenu: [...this.productsByMenu]
    };
  },
  watch: {
    menu: {
      immediate: true, 
      deep: true,
      handler(newMenu) {
        this.localMenu = { ...newMenu }; 
        this.initializeCheckedOrigins();
      },
    },
    productsByMenu: {
      immediate: true,
      handler(newProducts) {
        this.localProductsByMenu = [...newProducts]; 
      },
    },
    currentMenu() {
      if (this.menu.name !== this.currentMenu) {
        return;
      }

      this.checkedOrigins = [];
      this.originsOut = [];
      this.selectedCategoryToAddInProductList = "";
      this.$emit("disableSpinnerOnMenu");
      this.menuBeforeChanges.origins.forEach((t) =>
        this.checkedOrigins.push(t.name)
      );
    },
  },
  computed: {
    hasToShowAvailability() {
      return this.canEditProductAvailability && this.canEdit;
    },
    getCurrentShopTheme() {
      return this.$store.getCurrentShopTheme;
    },
    getNameLabel() {
      switch (this.getCurrentShopTheme) {
        case 1:
        default:
          return "Nombre de la carta:";
        case 2:
          return "Nombre del catálogo";
      }
    },
    buttonAction() {
      switch (this.getCurrentShopTheme) {
        case 1:
        default:
          return "Crear Carta";
        case 2:
          return "Crear Catálogo";
      }
    },
    modalUpdateTitle() {
      switch (this.getCurrentShopTheme) {
        case 1:
        default:
          return "Editar Carta";
        case 2:
          return "Editar Catálogo";
      }
    },
    buttonUpdate() {
      switch (this.getCurrentShopTheme) {
        case 1:
        default:
          return "Guardar Carta";
        case 2:
          return "Guardar Catálogo";
      }
    },
    getDeleteLabel() {
      switch (this.getCurrentShopTheme) {
        case 1:
        default:
          return "Eliminar carta";
        case 2:
          return "Eliminar catálogo";
      }
    },
    getProductsByCategoryInUse() {
      let array = [];
      this.localProductsByMenu.forEach((element) => {
        if (
          element.products.filter((t) => t.currentPrice !== "").length > 0 ||
          element.name === this.selectedCategoryToAddInProductList
        ) {
          
          var orderNumber = this.getOrderNumberByCategoryName(element.name);

          if (this.productSearchInput.length > 0) {
            var filteredElement = {
              name: element.name,
              orderNumber: orderNumber,
              products: [],
            };

            element.products
              .filter((x) =>
                x.name
                  .toUpperCase()
                  .includes(this.productSearchInput.toUpperCase())
              )
              .forEach((x) => {
                filteredElement.products.push(x);
              });

            if (filteredElement.products.length > 0) {
              array.push(filteredElement);
            }
          } else {
            element.orderNumber = orderNumber;
            array.push(element);
          }
        }
      });

      array = array.sort((a, b) => {
        if (
          a.orderNumber === undefined ||
          a.orderNumber === null ||
          a.orderNumber === ""
        ) {
          return 0;
        }
        if (
          b.orderNumber === undefined ||
          b.orderNumber === null ||
          b.orderNumber === ""
        ) {
          return 0;
        }
        return parseInt(a.orderNumber) - parseInt(b.orderNumber);
      });

      return array;
    },
    getCategoriesNotUse() {
      let array = [];

      this.productsByMenu.forEach((element) => {
        if (
          element.products.filter((t) => t.currentPrice !== "").length === 0 &&
          element.name !== this.selectedCategoryToAddInProductList
        ) {
          array.push(element.name);
        }
      });
      return array;
    },
  },
  methods: {

    initializeCheckedOrigins() {
      this.checkedOrigins = this.localMenu.origins.map((origin) => origin.name);
    },
    HasToShowFdsStatus(prod) {
      if (prod?.fdsSyncStatus?.filter(
        (x) => x.originName === `Pedidos Ya` ||
          x.originName === `Uber Eats` ||
          x.originName === `Rappi` ||
          x.originName === `Glovo` ||
          x.originName === `MP Delivery` ||
          x.originName === `Didi Food`
      )[0])
        return true;

      return false;
    },
    updateProdDetails(menuName, prodName) {
      //cuando se presiona un producto, se carga en la variable una combinacion de la categoria con el producto, para asi poder identificarlo y que asi el v-if de ese producto unicamente se ponga en true
      if (!(this.prodDetails == menuName + prodName))
        this.prodDetails = menuName + prodName;
      else
        this.prodDetails = "";
    },
    closeModal() {
      this.selectedCategoryToAddInProductList = "";
      this.checkedOrigins = [];
      this.originsOut = [];
      $("#__layout").css("pointer-events", "all");

      this.$emit("onModalClosed");
    },
    deleteMenu() {
      this.$emit("deleteMenu");
    },
    getOrderNumberByCategoryName(categoryName) {
      let category = this.allCategories.filter((x) => x.name === categoryName);
      var orderNumber = "0";
      if (category != null && category !== undefined && category.length > 0) {
        orderNumber = category[0].orderNumber;
      }
      return orderNumber;
    },
    getProductsInUse(products) {
      let array = [];
      products.forEach((p) => {
        if (p.currentPrice !== "") {
          array.push(p);
        }
      });
      console.log(array, " EN USO")
      return array;
    },
    getProductsNotUse(products) {
      let array = [];

      products.forEach((p) => {
        if (p.currentPrice === "") {
          array.push(p);
        }
      });

      return array;
    },
    onSelectProduct(event, products) {
      let selectedSku = event.target.value;

      products.forEach((p) => {
        if (p.sku === selectedSku) {
          p.currentPrice = 0;
        }
      });

      this.$nextTick(() => {
        // Se asegura que Vue proceso todas las actualizaciones reactivas pendientes antes de reiniciar el selector
        this.keyForReturnSelectToStart = -1;
      });
    },
    onSelectCategory(event) {
      let category = event.target.value;

      this.selectedCategoryToAddInProductList = category;
      this.$nextTick(() => {
        // Se asegura que Vue proceso todas las actualizaciones reactivas pendientes antes de reiniciar el selector
        this.keyForReturnSelectToStart = -1;
      });
    },
    getValue(origin) {
      let o = this.checkedOrigins.filter((t) => t === origin);

      if (o !== undefined && o !== null && o.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isThereAnyChanges(checkedOrigins) {
      const startChecked = [];
      let result = false;
      this.menuBeforeChanges.origins.forEach((t) => startChecked.push(t.name));

      if (startChecked.length !== checkedOrigins.length) return true;
      else {
        startChecked.forEach((t) => {
          if (checkedOrigins.includes(t) === false) {
            result = true;
          }
        });
        if (result === false) return result;

        checkedOrigins.forEach((t) => {
          if (startChecked.includes(t) === false) {
            result = true;
          }
        });
        return result;
      }
    },
    onCheckProductAvailability(prod) {
      prod.isProductAvailable = !(prod?.isProductAvailable ?? false);
    },
    isProductAvailable(prod) {
      if (
        prod?.isProductAvailable == null ||
        prod?.isProductAvailable == undefined
      ) {
        prod.isProductAvailable = true;
      }

      return prod?.isProductAvailable;
    },
    onCheckOrigin(origin) {
      var index = this.checkedOrigins.indexOf(origin);
      if (index === -1) {
        this.checkedOrigins.push(origin);
      } else {
        this.checkedOrigins.splice(index, 1);
      }

      this.originsOut = this.getOriginsOut(this.checkedOrigins);
    },
    getOriginsOut(checkedOrigins) {
      if (this.isThereAnyChanges(checkedOrigins) === false) {
        return [];
      }
      const startChecked = [];
      let result = [];
      this.menuBeforeChanges.origins.forEach((t) => startChecked.push(t.name));
      startChecked.forEach((t) => {
        if (checkedOrigins.includes(t) === false) {
          result.push(t);
        }
      });

      return result;
    },
    upsertMenu() {
      this.$emit("enableSpinnerOnMenu");
      let controlUpsertMenu = false;
      let upsertMenuHandler = setInterval(() => {
        if (!controlUpsertMenu) {

          controlUpsertMenu = true;

          clearInterval(upsertMenuHandler);

          if (this.localMenu.name === "") { 
            toast.error("El nombre de la carta es obligatorio");
            this.$emit("disableSpinnerOnMenu");
          } else {
            if (
              this.checkedOrigins === undefined ||
              this.checkedOrigins.length === 0
            ) {
              toast.error("La carta debe contener un origen como mínimo");
              this.$emit("disableSpinnerOnMenu");
              return;
            }

            let HasError = false;
            this.productsByMenu.forEach((element) => {
              let prodsWithError = element.products.filter(
                (t) =>
                  ((t.currentPrice === 0 &&
                    (t.price === "" || t.price === "0")) ||
                    (t.currentPrice > 0 && t.price === "0")) &&
                  t.isCombo === false
              );
              if (prodsWithError.length > 0) {
                toast.error(
                  `El producto ${prodsWithError[0].name} de la categoría ${element.name} no contiene un precio.`
                );
                HasError = true;
              }
            });

            if (HasError) {
              this.$emit("disableSpinnerOnMenu");
              return;
            }

            const resultOrigins = [];

            this.checkedOrigins.forEach((t) => {
              resultOrigins.push({
                name: t,
              });
            });
            
            this.$emit("upsertMenu", 
            this.localMenu, 
            this.localProductsByMenu, 
            this.originsOut, 
            resultOrigins
          );
          }
        }
      }, 500);
    },
  },
  components: {
    Spinner,
    FdsSyncStatus,
    CurrencyStyle,
  },
};

</script>
