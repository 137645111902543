<script>
import mixModals from '@/mixins/mixModals.vue';
import ticketCommons from './ticketCommons.vue';
import printerCommons from '../../shared/mixins/printerCommons.vue';
import router from "@/router";
import { toast } from "vue3-toastify";
import { getOrder, updateOrder, syncOrders, syncCashboxes, createObjectCashbox } from "../../shared/repositories/orders/ordersService.js";
import { incrementTicketCount, formatDate } from "../../shared/utils/utils"
import { ORDER_STATUS } from "./../../shared/utils/orderStatuses";
export default {
    mixins: [mixModals, ticketCommons, printerCommons],
    methods: {
        async closeModalCreditsCards() {
            this.authCode = null;
            this.selectedCard = null;
            const order = this.$pdv.getOrder
            order.payments = []
            updateOrder(this.shopCode, order)
            this.closeModalById('#modalCreditsCards');
        },
        async handleOrderPaid() {
            try {
                this.$pdv.setIsDisabled(true)
                const order = await getOrder(this.shopCode, this.order.id);
                order.status = ORDER_STATUS.CLOSED;
                order.ticketNumber = incrementTicketCount(this.shopCode);
                order.timeStamp = formatDate()
                await updateOrder(this.shopCode, order);
                await createObjectCashbox(this.shopCode, order, this);
                this.$pdv.setOrder(order);
                await this.printInCounter(await this.generatePaymentOrderTicketByOrderUuid(order.uuid));
                await syncOrders(this.shopCode, this);
                await syncCashboxes(this.shopCode);
                this.$pdv.setShowDetails(false);
                if (!this.$pdv.getIsExactPayment && order.payments[0]?.paymentWay == 'EFECTIVO') {
                    // entra solo cuando sea pago en efectivo y NO sea pago exacto
                    this.$pdv.setIsDisabled(false)
                    return
                }
                toast.success('Orden generada exitosamente.')
                setTimeout(() => {
                    router.push('/pdv/orders');
                }, 3000);
            } catch (error) {
                this.$pdv.setIsDisabled(false)
                console.error(error)
                toast.error('Error sincronizando ordenes.')
            }

        },
        async handlePayOrderCash() {

            if (this.$pdv.getTempReceivedAmount && (this.$pdv.getTempReceivedAmount < this.order.amount)) {
                toast.error('Por favor ingrese un monto igual o superior al valor indicado');
                return;
            }
            const order = this.$pdv.getOrder
            if (this.$pdv.getTempReceivedAmount) {
                order.payments[0].receivedPaidAmount = this.$pdv.getTempReceivedAmount
            }
            await updateOrder(this.shopCode, order);

            if (this.$pdv.getTempReceivedAmount && (this.$pdv.getTempReceivedAmount > this.order.amount)) {
                this.openNonDismissibleModal('#modalOrderPayChange')
            }
            this.$pdv.setTempReceivedAmount(null)
            await this.handleOrderPaid();
        },
        checkIfAmountIsHigherThanOrdersAmount() {
            if (this.$pdv.getTempReceivedAmount && (this.$pdv.getTempReceivedAmount > this.order.amount)) {
                toast.warning('La transacción será registrada con el monto total a pagar');
            }
        },
        async selectPaymentMethodAndPay(method) {
            if (!method) {
                //entra cuando sea metodo efectivo o no haya seleccionado metodo al momento de querer pagar
                method = this.order.payments[0];
                if (!method) {
                    toast.error('Seleccione un método de pago')
                    return
                }
                this.handlePayOrderCash();
                return
            }

            this.order.payments[0] = method;


            switch (method.paymentWay) {
                case 'TARJETA':
                    this.checkIfAmountIsHigherThanOrdersAmount();
                    this.openNonDismissibleModal('#modalCreditsCards');
                    break;
                case 'QR':
                case 'ONLINE':
                    this.checkIfAmountIsHigherThanOrdersAmount();
                    this.order.payments[0].receivedPaidAmount = this.order?.payments[0]?.amount;
                    await updateOrder(this.shopCode, this.order);
                    this.handleOrderPaid();
                    break;
            }


        }

    }
};
</script>