<script>
import axios from "axios";
import { toast } from "vue3-toastify";
export default {
    computed: {},
    methods: {
        async checkSessionInBackendAsync() {
            this.$store.initLoading(this);
            try {
                const data = await axios
                    .get(`/api/auth/checkSession`);
                this.$store.finishLoading(this);
                this.$store.setComponentLoading(true)
                if (data?.data.responseCode != 0) {
                    console.log('Session NOT OK', data.data)
                    this.closeBistroSessionAndRedirectToLogin();
                    return false;
                }

                return true;
            } catch (err) {
                console.log('Session ERROR', err)
                this.$store.errorLoading(this);
                this.showErrors(err.response);
                this.closeBistroSessionAndRedirectToLogin();
                return false;
            }

        },
        closeBistroSessionAndRedirectToLogin() {
            toast.error('La sesión ha caducado, serás redirigido al login')
            setTimeout(() => {
                window.location.href = '/login'
                localStorage.setItem("loggedUser", null);
            }, 3000)
        }
    },
};
</script>
