import Dexie from "dexie";

const createIndexedDb = (shopCode) => {
  if (!shopCode) return;

  const dbName = `BistroWebDb_${shopCode}`;
  const db = new Dexie(dbName);
  db.version(1).stores({
    orders:
      "++id, uuid, origin, status, createdAt, comment, amount, netAmount, vatAmount, products, asyncStatus, paymentWay, lastUpdate, ticketNumber, movementType, discounts,timeStamp",
  });

  db.version(2).stores({
    cashboxes:
      "++id, amount, billNumber, cashAccumAmount, cashMovementId, comments, date, ticketNumber, movementType, totalAccumDiscountPerProductAmount,waiter,externalReference,customerId, saleAuthCode, saleCaptureWay, saleId, saleOrigin, salePaymentMethod, saleType, sequence, totalAccumAmount, totalDiscountAmount, totalTaxesAmount, totalVatAmount, userName, uuid",
  });

  db.version(3).stores({
    cashboxReports:
      "++id, sync, uuid, totalAmountOfCashboxMovements , createdAt, auditsMovements, closeDate, closeAmount, declaredAmount,lastCloseAmount, openAmount, openDate, total, totalCard,totalDeposit,totalOnline, totalOrders,totalQR, totalWithdrawal, fiscalData",
  });
  return db;
};

export default createIndexedDb;
