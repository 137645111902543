<template>
	<img
		class="logoProVentas"
		src="@assets/img/logo_tas.png"
		:width="width"
		:height="height"
	/>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: "18%",
		},
		height: {
			type: String,
			default: "11%",
		},
	},
};
</script>

<style scoped>
.logoProVentas {
	margin-top: 15%;
	background-color: transparent;
	width: 200px;
	height: 100px;
}
</style>
