<template>
  <aside>
    <slot></slot>
  </aside>
</template>

<script>
export default {
  name: "SidebarBistro",
  components: {},
};
</script>

<style lang="scss">
aside {
  width: 460px;
  display: flex !important;
  flex-direction: column;
  background: #202937;
  
  >div {
    padding: 16px;

    &#header_sidebar{
      @include flex-center-both;
      gap: 16px;
      height: 80px;
    }

    &#body_sidebar {
      // height: calc(100vh - 210px);
      overflow: hidden;
      overflow-y: auto;
    }

    &#header_sidebar {
      gap: 48px;
    }

  }

  .big-title {
    color: $lightColorBg;
    font-size: 24px;
    font-weight: 600;
  }

  /* Aside styles */
  aside {
    background-color: #f4f4f4;
    /* Gris claro */
    width: 250px;
    /* Ancho fijo del aside */
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    /* Sombra ligera para el aside */
    overflow-y: auto;
    /* Añade scroll si el contenido del aside es muy grande */
    flex-shrink: 0;
    /* Evita que el aside se encoja */
  }
}
</style>
