<template>
  <div class="footer-container" :class="[showFooter ? 'footer-bg-color' : '']">
    <div id="footer_sidebar">
      <div>IVA: {{ $filters.currency(order.vatAmount) }}</div>
      <div>{{ $filters.currency(order.amount) }}</div>
    </div>
    <div v-if="showPdvDetailFooter" id="footer_content_details">
      <ButtonBistro variant="gradient-green" text="COBRAR" @handleClick="handleGoToCheckout" />
      <!-- <ButtonBistro variant="gradient-red" text="CERRAR COMANDA" /> -->
      <!-- <ButtonBistro variant="gradient-blue" text="TECLADO" /> -->
      <ButtonBistro variant="gradient-gray" text="IMPRIMIR" @handleClick="handlePrintPartialTicket" />
    </div>
    <div v-if="showCheckoutFooter" id="footer_content_checkout">
      <ButtonBistro variant="gradient-green" :text="isExactPayment ? 'PAGO EXACTO' : 'PAGO'"
        @handleClick="selectPaymentMethodAndPay" />
    </div>
    <ModalCreditsCards @onCardSelected="confirmCardPayment" />

  </div>
</template>

<script>
import router from "@/router";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import shopCommons from "../../shared/mixins/shopCommons.vue";
import printerCommons from "../../shared/mixins/printerCommons.vue";
import ticketCommons from "../mixins/ticketCommons.vue";
import { toast } from "vue3-toastify";
import { updateOrder } from "../../shared/repositories/orders/ordersService.js";
import mixModals from "@/mixins/mixModals.vue";
import ModalCreditsCards from "../components/modals/ModalCreditsCards.vue";
import payCommons from '../mixins/payCommons.vue';
import { getCategories } from "../../shared/repositories/products/productsService";
import { getFormattedQuantity } from "../../shared/repositories/products/productsService";


export default {
  name: 'FooterOrderDetails',
  mixins: [shopCommons, printerCommons, mixModals, payCommons, ticketCommons],
  components: {
    ButtonBistro,
    ModalCreditsCards
  },
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  methods: {
    async handlePrintPartialTicket() {
      let productsToCounter = [];
      let productsToKitchen = [];
      let productsToAdditional = [];
      let order = this.order;
      const categories = await getCategories(this.shopCode);
      const isReprint = order.products.every(element => element.quantityToPrintInPartialTicket === 0);
      order.products.forEach(element => {
        if (element.quantityToPrintInPartialTicket > 0 || isReprint) {

          const category = categories?.filter(x => x.name == element.department)[0];
   
          let row;
          if (element.weightable) {
            if (isReprint) {
              // Para reimpresiones, usa el quantity 
              row = this.replaceSpecialChars(getFormattedQuantity({ ...element, quantity: element.quantity }) + " " + element.name);
            } else {
              // Para impresiones normales, usa quantityToPrintInPartialTicket
              row = this.replaceSpecialChars(getFormattedQuantity({ ...element, quantity: element.quantityToPrintInPartialTicket }) + " " + element.name);
            }
          } else {
            // Para productos no pesables
            row = this.replaceSpecialChars((isReprint ? element.quantity : element.quantityToPrintInPartialTicket) + " " + element.name);
          }
          if (element.comment) row = `${row} \n   ${element.comment}`

          if (category?.hasToPrintOnCounter) {
            productsToCounter.push(row);
          }
          if (category?.hasToPrintInKitchen) {
            productsToKitchen.push(row);
          }
          if (category?.hasToPrintOnAdditionalPrinter) {
            productsToAdditional.push(row);
          }

          element.quantityToPrintInPartialTicket = 0;

        }
      });

      if (productsToCounter.length > 0) {
        await this.printInCounter(this.generatePartialOrReprintTicket(this.order, productsToCounter, 'MOSTRADOR', isReprint));
      }
      if (productsToKitchen.length > 0) {
        await this.printInKitchen(this.generatePartialOrReprintTicket(this.order, productsToKitchen, 'COCINA', isReprint));
      }
      if (productsToAdditional.length > 0) {
        await this.printInAdditional(this.generatePartialOrReprintTicket(this.order, productsToAdditional, 'ADICIONAL', isReprint));
      }
      this.$emit('updateCurrentOrder', order);
    },
    async handleGoToCheckout() {
      // Verifica si quedan productos pendientes por imprimir
      const pendingProducts = this.order.products.some(element => element.quantityToPrintInPartialTicket > 0);

      // Si hay productos pendientes, imprime el ticket parcial
      if (pendingProducts) {
        await this.handlePrintPartialTicket();
      }
      if (this.order.products[0]) {
        // await this.printInCounter(this.generateCloseOrderTicket(this.order));
        router.push("/pdv/order/checkout/" + this.shopCode + "/" + this.order.id);
      }
      else toast.error('Seleccione al menos un producto')
    },

    async confirmCardPayment(payload) {
      const order = this.order
      order.payments[0].paymentDescription = payload.selectedCard
      order.payments[0].authCode = payload.authCode
      order.payments[0].receivedPaidAmount = this.order.amount
      await updateOrder(this.shopCode, order);
      this.$pdv.setIsExactPayment(true)

      this.handleOrderPaid()

    }
  },
  computed: {
    isExactPayment() {
      return this.$pdv.getIsExactPayment;
    },
    showPdvDetailFooter() {
      const baseUrl = '/pdv/order/details/';
      return this.$route.path.startsWith(baseUrl);
    },
    showCheckoutFooter() {
      const baseUrl = '/pdv/order/checkout/';
      return this.$route.path.startsWith(baseUrl);
    },
  }
};
</script>

<style lang="scss">
.footer-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  box-shadow: 0px -4px 8px rgba(128, 128, 128, 0.411);
  font-family: 'Rubik-Regular';
}

.footer-bg-color {
  background-color: $lightColorBg;
}

#footer_sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 100%;
  width: 460px;
  background-color: $lightColorBg;
  color: #909699;
  padding: 16px;

  >div {
    font-weight: 700;
  }

  > :first-child {
    font-size: 22px;
  }

  > :last-child {
    font-size: 30px;
  }
}

#footer_content_checkout,
#footer_content_details {
  height: 100%;
  background-color: $lightColorBg;
  align-items: center;

  .button-bistro {
    border-radius: 0;
    width: 170px;
    height: 80px;
    justify-content: center;
  }
}
</style>
