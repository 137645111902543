<script>
import datetimeCommons from "../mixins/datetimeCommons.vue";
import { getFormattedQuantity, getPriceByOriginPriceOrKgLt } from "../../shared/repositories/products/productsService";
import { formatDateTime } from "@/features/shared/utils/utils"
import printerCommons from "@/features/shared/mixins/printerCommons.vue";
import { getOrderByUuid } from "@/features/shared/repositories/orders/ordersService";
import { getCommentForTicket , getTextLeftForAuditsMovements } from '@/features/shared/repositories/closeCashboxes/closeCashboxesService'
import { ORDER_STATUS } from "@/features/shared/utils/orderStatuses"
import instanceCommons from "@/mixins/instanceCommons.vue";

export default {
    mixins: [datetimeCommons, printerCommons, instanceCommons],
    data() {
        return {
            ESC: "\x1B", // ESC byte in hex notation
        }
    },
    methods: {
        replaceSpecialChars(text) {
            const replacements = {
                'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
                'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
                'ñ': 'n', 'Ñ': 'N',
                'ü': 'u', 'Ü': 'U',
            };

            return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, function (match) {
                return replacements[match];
            });
        },
        generatePartialOrReprintTicket(order, products, dest, isReprint) {
            const reprintText = isReprint ? 'REIMPRESION' : ''
            const header = dest + " " + order.origin;
            return this.generateTicket([
                this.generateSection([
                    this.addHeader(reprintText),
                    this.addHeader(header),
                    this.setFontCenter(this.setFontBold(this.addOrderId(order)))
                ]),
                this.addSummary(order),
                this.addProducts(products)
            ]);
        },
        generateTestTicket(origin) {

            return this.generateTicket([
                this.generateSection([
                    this.addHeader('IMPRESION DE TEST')
                ]),
                this.addSummary(null),
                this.generateSection([
                    this.setFontBold('TEXTO NEGRITA'), "\n",
                    this.setFontDoubleWidth('TEXTO DOBLE NEGRITA'), "\n",
                    this.setFontCenter('TEXTO CENTRADO'), "\n",
                    this.setFontDoubleHeight('DOBLE ALTO'), "\n",
                    this.setFontLargeAndBold('DOBLE'), "\n",

                ]),
                this.generateSection([
                    this.addFreeText(origin)
                ]),
            ]);
        },

        generateCloseOrderTicket(order) {
            const products = this.getProductRowsToPrint(order);

            return this.generateTicket([
                this.generateSection([
                    // this.addLogo(),
                    this.setFontCenter(this.setFontBold(this.addOrderId(order)))

                ]),
                this.addSummary(order),
                this.generateSection([
                    this.addProducts(products),
                    this.setFontCenter(this.setFontLargeAndBold(this.addTotal(order))
                    ),

                ]),
                this.addOrderComment(order) 
            ]);
        },
        generateCashBoxTicket(isPartial = false) {
            const fiscalData = JSON.parse(localStorage[`userDataPDV_${this.shopCode}`]) 
            let cashBox;

            if (isPartial) {
                cashBox = this.$store.getCashBox;
            } else {
                [cashBox] = this.$store.getCashBox;
            }
            // Header principal
            const mainHeader = this.generateSection([
                this.addHeader(isPartial ? 'CAJA PARCIAL' : 'CIERRE DE CAJA'),
            ]);

            // Datos genéricos
            const genericDataLines = [];


            genericDataLines.push(
                { textLeft: "Fecha de apertura:", textRight: formatDateTime(cashBox.openDate) },
            );
            if (!isPartial) {
                genericDataLines.push(
                    { textLeft: "Fecha de cierre:", textRight: formatDateTime(cashBox.closeDate) },
                );
            }
            genericDataLines.push(
                { textLeft: "Razon social:", textRight: fiscalData?.legalName },
                { textLeft: "Operador:", textRight: this.$store.getAuthUser ? this.$store.getAuthUser.user.name : JSON.parse(localStorage["currentUserData"]).user.name },
                { textLeft: "Efectivo en caja de apertura:", textRight: this.$filters.currency(cashBox.lastCloseAmount) },
                { textLeft: "Efectivo declarado en apertura:", textRight: this.$filters.currency(cashBox.declaredAmount) },
            );
            if (!isPartial) {
                genericDataLines.push(
                    { textLeft: "Efectivo en cierre de caja:", textRight: this.$filters.currency(cashBox.closeAmount) },
                    { textLeft: "Diferencia encontrada en apertura:", textRight: this.$filters.currency(cashBox.declaredAmount - cashBox.lastCloseAmount) },
                    { textLeft: "Diferencia encontrada en cierre:", textRight: this.$filters.currency(cashBox.closeAmount - cashBox.totalAmountOfCashboxMovements) }
                );
            }
            const secondHeader = this.addHeader('DATOS GENERICOS')
            const genericData = this.generateSection([
                this.generateLines(genericDataLines),
            ]);

            // Totales de venta
            const thirdHeader = this.addHeader('TOTALES DE VENTA')

            const salesTotals = this.generateSection([
                this.generateLines([
                    { textLeft: "Total de comandas:", textRight: this.$filters.currency(cashBox.totalOrderAmount) },
                    { textLeft: "Total de anulaciones de comandas:", textRight: this.$filters.currency(cashBox.totalAmountVoided) },
                ]),
                this.addLineBreak(),
                this.generateLines([
                    { textLeft: "Cantidad de comandas:", textRight: `${cashBox.totalOrders}` },
                    { textLeft: "Cantidad de anulaciones de comandas:", textRight: `${cashBox.totalVoided}` },
                ]),
                this.addLineBreak(),
                this.generateLines([
                    { textLeft: "Total Vendido:", textRight: this.$filters.currency(cashBox.totalSold) },
                ]),
            ]);

            // Totales de ventas por medio de pago
            const fourthHeader = this.addHeader('TOTALES DE VENTAS POR MEDIO DE PAGO')

            const paymentLines = [
                { textLeft: "Efectivo:", textRight: this.$filters.currency(cashBox.totalCash) },
                { textLeft: "Tarjetas:", textRight: this.$filters.currency(cashBox.totalCard) }
            ];

            if (!this.isMexicoInstance && !this.isSpainInstance) {
                paymentLines.push({ textLeft: "QR:", textRight: this.$filters.currency(cashBox.totalQR) });
            }

            paymentLines.push({ textLeft: "Online:", textRight: this.$filters.currency(cashBox.totalOnline) });

            const paymentTotals = this.generateSection([
                this.generateLines(paymentLines),
                this.addLineBreak(),
                this.generateLines([
                    { textLeft: "Total:", textRight: this.$filters.currency(cashBox.totalSold) },
                ]),
            ]);

            // Otros movimientos
            const fifthHeader = this.addHeader('OTROS MOVIMIENTOS')

            const otherMovements = this.generateSection([
                this.generateLines([
                    { textLeft: "Total Retiros:", textRight: this.$filters.currency(cashBox.totalWithdrawal) },
                    { textLeft: "Total Depositos:", textRight: this.$filters.currency(cashBox.totalDeposit) },
                ]),
                this.addLineBreak(),

            ]);


            const sixthHeader = this.addHeader('AUDITORIA POR MOVIMIENTO');
            const auditsMovementsData = cashBox.auditsMovements;

            const auditsMovements = this.generateSection(
                auditsMovementsData.map((item) =>
                    this.generateLines([
                        {
                            textLeft: `${getTextLeftForAuditsMovements(item)}`,
                            textRight: `${this.$filters.currency(item.amount)}`,
                            comment: getCommentForTicket(item),
                        },
                    ])
                ).concat(this.addLineBreak()) // Agregar un salto de línea al final
            );


            // Construcción final del ticket
            const ticket = this.generateTicket([
                mainHeader,
                secondHeader,
                genericData,
                thirdHeader,
                salesTotals,
                fourthHeader,
                paymentTotals,
                fifthHeader,
                otherMovements,
                sixthHeader,
                auditsMovements,
            ]);

            return ticket;
        },
        async generateVoidTicket(orderUuid) {
            const order = await getOrderByUuid(this.shopCode, orderUuid)
            const products = this.getProductRowsToPrint(order); 
            return this.generateTicket([
                this.addHeader("ANULACION"),
                this.generateSection([
                    this.addOrderId(order)
                ]),
                this.addSummary(order),
                this.generateSection([
                    this.addProducts(products),
                    this.addTotal(order)
                ]),
                this.addPaymentDetails(order),
                this.addOrderComment(order),

                this.generateSection([
                    this.addExtraTicketLines(order)
                ])
            ]);
        },
        async generatePaymentOrderTicketByOrderUuid(orderUuid, isReprint = false) {
            const order = await getOrderByUuid(this.shopCode, orderUuid);
            if(!order) {
                console.error("No se encontro la orden para imprimir el ticket");
                return
            }
            const headerTextReprint = isReprint ? "REIMPRESION " :''           
            const headerText = order.status === ORDER_STATUS.VOID ? "ANULACION" : null
            
            const products = this.getProductRowsToPrint(order); 
            const ticketSections = [
                ...(headerTextReprint ? [this.addHeader(headerTextReprint)] : []), // Agrega "REIMPRESION" solo si aplica
                ...(headerText ? [this.addHeader(headerText)] : []), // Agrega "ANULACION" solo si aplica
                this.generateSection([this.addOrderId(order)]),
                this.addSummary(order),
                this.generateSection([this.addProducts(products), this.addTotal(order)]),
                this.addPaymentDetails(order),
                this.addOrderComment(order),
                this.generateSection([this.addExtraTicketLines(order)])
            ];
            return this.generateTicket(ticketSections);
        },
        generateTicket(sections) {
            let content = this.ESC + "@\n";
            let isFirstLine = true;

            sections.forEach(section => {
                if (!isFirstLine) {
                    content += this.ESC + "a" + "0" + "------------------------------------------------\n";
                }
                content += section;
                isFirstLine = false;
            });

            content += this.ESC + "d" + "\x03" +
                this.ESC + "m";

            return content;
        },
        generateSection(lines) {
            let section = "";
            lines.forEach(line => {
                section += line;
            });
            return section;
        },
        addHeader(header) {
            return this.ESC + "a" + "1" + header + "\n\n";
        },
        // Traemos la configuracion del localStorage
        getSettingsLocalStorage(settingsKey) {
            const settings = localStorage.getItem(settingsKey);
            return settings ? JSON.parse(settings) : null;
        },
        addOrderId(order) {
            const settings = this.getSettingsLocalStorage("settingsPrint")
            if (!settings) return this.ESC + "a" + "1" + "C" + String(order.id).padStart(8, '0') + "\n\n";

            else if (settings?.printOrderNumber) {
                return this.ESC + "a" + "1" + "C" + String(order.id).padStart(8, '0') + "\n\n";
            } else {
                return ""
            }
        },
        addLogo() {
            return this.ESC + "a" + "1" + "{{LOGO}}\n\n"; // TODO ADV: De donde sacamos el logo???
        },
        addSummary(order) {
            let timestamp
            if(order){
                timestamp = order.lastUpdate
            } else {
                timestamp = Date.now();
            }
            let section = this.addLine(this.convertTimestampToDate(timestamp), this.convertTimestampToHour(timestamp));
            if (order?.ticketNumber) {
                section += this.addLine("Numero de ticket", order.ticketNumber.toString());
            }
            return section;
        },
        addTotal(order) {
            return this.ESC + "a" + "1" + "TOTAL $ " + order.amount.toFixed(2) + "\n\n";
        },
        addProducts(products) {
            return products.join('\n') + "\n\n\n";
        },
        addFreeText(comment) { 
            return this.ESC + "a" + "1" + comment + "\n\n";
        },
        addOrderComment(order) {
            return this.ESC + "a" + "1" + order.comment + "\n\n";
        },
        addExtraTicketLines() {
            const settings = this.getSettingsLocalStorage("settingsPrint")
            if (settings?.ticketText) {
                return this.ESC + "a" + "1" + settings.ticketText + "\n\n";
            } else {
                return ""
            }
        },
        addPaymentDetails(order) {
            const payment = order.payments[0];
            switch (payment.paymentWay) {
                case "EFECTIVO":
                    return payment.paymentWay + "\n" +
                        "Entregado: $" + Number(payment.receivedPaidAmount).toFixed(2) + "\n" +
                        "Vuelto: $" + (Number(payment.receivedPaidAmount) - payment.amount).toFixed(2) + "\n";
                default:
                    return payment.paymentWay + "\n";
            }
        },
        getProductRowsToPrint(order) {
            let products = [];
            order.products.forEach(element => {
                const quantityAndName = this.replaceSpecialChars(getFormattedQuantity(element) + " " + element.name);
                const totalPrice = getPriceByOriginPriceOrKgLt(element);
                const priceAsString = `$ ${totalPrice.toFixed(2)}`;

                products.push(this.addLine(quantityAndName, priceAsString));

                if (element.comment) {
                    products.push(this.addLine(`   ${element.comment}`));
                }
                if (element.discountByPercentage) {
                    const label = `   - DESCUENTO POR PRODUCTO (${element.discountByPercentage}%)`;
                    const discountPrice = `$ -${(totalPrice * (element.discountByPercentage / 100)).toFixed(2)}`;
                    products.push(this.addLine(label, discountPrice));
                }
                if (element.discountByAmount) {
                    const label = `   - DESCUENTO FIJO`;
                    const discountPrice = `$ -${element.discountByAmount.toFixed(2)}`;
                    products.push(this.addLine(label, discountPrice));
                }
            });
            return products;
        },
        addLine(label, price) {
            if (!price) {
                return label;
            }

            const lineLength = 48;
            const priceLength = price.length + 1 // Espacio para el precio (incluye margen)
            const maxLabelLength = lineLength - priceLength // Espacio máximo por linea para el label

            let formattedText = []
            let lines = []

            //  Tomar los primeros caracteres de la primera línea
            const firstLine = label.substring(0, maxLabelLength)
            const remainingText = label.substring(maxLabelLength) // Lo que sobra

            // Alinear el precio en la primera línea
            const spacesNeeded = Math.max(0, lineLength - (firstLine.length + price.length))
            formattedText.push(firstLine + " ".repeat(spacesNeeded) + price)

            // Dividir el resto del texto en bloques de `maxLineLength` caracteres
            for (let i = 0; i < remainingText.length; i += maxLabelLength) {
                lines.push(remainingText.substring(i, i + maxLabelLength))
            }

            // Agregar las líneas restantes al texto formateado
            formattedText = formattedText.concat(lines)

            return formattedText.join("\n")
        },
        generateLines(lines) {
            return lines
                .map(({ textLeft, textRight, comment }) => {
                    const mainLine = this.addLine(textLeft, textRight);
                    const commentLine = comment ? `   ${comment}` : "";
                    return commentLine ? `${mainLine}\n${commentLine}` : mainLine;
                })
                .join("\n");
        },
        addLineBreak() {
            return "\n\n";
        }

    }
}
</script>