<template>
    <main class="main-container">
        <div class="container" :class="['container', { 'min-height-600': categories.length < 0 }]">
            <h1 class="title">Mi Carta</h1>
            <p class="subtitle">Asistente para la creación de cartas</p>
            <div class="border-line"></div>
            <div class="centered-content">
                <div class="input-search-container">
                    <div class="search-and-links">
                        <InputBistroVModel v-model="searchValue" type="search" placeholder="Buscar productos..." class="input-search" />
                        <div v-if="categories.length > 0" class="links-container">
                            <!-- Enlace para "Carta Web" -->
                            <div class="link-item" @click="openMenuWeb(shopCode)">
                                <figure>
                                    <img src="@assets/img/ic_online.png" alt="Web Icon" />
                                </figure>
                                <p>Carta Web</p>
                            </div>

                            <!-- Enlace para "QR de Carta" -->
                            <div class="link-item" @click="onDownloadQrPDV(shopCode)">
                                <figure>
                                    <span class="icon-Bistro-_QR"></span>
                                </figure>
                                <p>QR de Carta</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-line"></div>
                <p v-if="categories.length == 0" class="add-category-text">Agrega una categoría para empezar a crear tu
                    carta</p>
                <div v-else>
                    <NewCategorySection ref="newCategorySection" :categories="categories" :search-value="searchValue"
                        :has-to-show-buttons="hasToShowButtons"
                        @update-category="updateCategory" @update-show-buttons="showButtons = $event"
                        @changes-detected="hasUnsavedChanges = $event" />
                </div>
                <div class="border-line"></div> 
                <ButtonBistro
                    v-if="hasToShowButtons"
                    :variant="'blue-add-category'" 
                    text="+ Agregar categoría" 
                    @click="addCategory" 
                    />
                <ButtonBistro
                    v-else
                    :variant="'disabled-add-category'"
                    text="+ Agregar categoría"
                    v-tooltip="'Limpié el filtro de búsqueda para agregar una categoría.'"
                    :disabled="true"
                />

                <div v-if="showButtons" class="action-buttons">
                    <ButtonModals variant="return btn-modal" text="Cancelar" @click="cancel"
                        />
                    <ButtonModals variant="btn-modal main" text="Guardar Carta" @click="save"
                        />
                </div>
            </div>
        </div>
        <OffLineOverlay  />
        <ModalConfirm 
            v-model="showModal"  
            :dialog="'Se perderán los cambios sin guardar. ¿Desea continuar?'"
            @onConfirm="confirmAction" @onCancel="cancelAction"
            :confirmButtonVariant="'return-red btn-modal '"
            :showModal="showModal" 
            :textButtonCancel="'Cancelar'"
            :textButtonConfirm="'Confirmar'" 
           
        />
    </main>
</template>

<script>
import NewCategorySection from '@/features/pdvProducts/components/NewCategorySection.vue'
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import ButtonModals from '@/features/shared/components/ButtonModals.vue';
import bOrdersCommons from "@/mixins/bOrdersCommons"
import commons from "@/mixins/mixCommons"
import shopCommons from "../../shared/mixins/shopCommons"
import productV2Commons from "@/mixins/productV2Commons"
import rolesCommons from "@/mixins/rolesCommons"
import authCommons from "@/mixins/authCommons.vue"
import InputBistroVModel from '@/features/pdvProducts/components/InputBistroVModel.vue'
import { toast } from "vue3-toastify"
import { db } from "@/firebase";
import { ref, get, set, update, push, remove } from "firebase/database"
import firebaseRepo from "@/features/shared/repositories/products/productsFirebaseRepository"
import OffLineOverlay from '@/features/shared/components/OffLineOverlay.vue'
import mixModalsCommons from "@/mixins/mixModals.vue"
import ModalConfirm from '@/features/shared/components/ModalConfirm.vue'


export default {
    mixins: [bOrdersCommons, commons, rolesCommons, shopCommons, productV2Commons, authCommons, mixModalsCommons],
    components: {
        InputBistroVModel,
        ButtonBistro,
        ButtonModals,
        NewCategorySection,
        OffLineOverlay,
        ModalConfirm
    },
    data() {
        return {
            categories: [],
            searchValue: '',  
            inputType: "search",
            isLoading: false,
            removedCategoriesList: [],
            showButtons: false,
            hasAlreadyShownNews: false,
            hasAlreadyShownDebts: false,
            showModal: false,
            nextRoute: null,
            hasUnsavedChanges: false, // Flag para controlar cambios pendientes
            clearAllChanges: false
        }
    },
    async mounted() {
        document.title = `${this.appTitle}  - Productos`

        if (this.isUserLoggedIn() === false) {
            this.logout();
        } else if (navigator.onLine && !await this.checkSessionInBackendAsync()) {
            this.logout();
        } else if (navigator.onLine && !this.hasPosAdminRole) {
            this.handleUnauthorizedAccess()
        } else {
            this.hideElements()
            this.onMounted();
            this.addBeforeUnloadListener();
        }
    },
    beforeUnmount() {
        this.removeBeforeUnloadListener(); // Eliminar listener al desmontar
    },
    beforeRouteLeave(to, from, next) {
        if (this.hasUnsavedChanges) {
            this.showModal = true;
            this.nextRoute = to; // Guarda la ruta
            this.confirmNavigation = () => {
                next();
            };
        } else {
            next();
        }
    },
    methods: {
        confirmClearChanges(){
            this.categories = this.$pdv.getCategoriesMenu
            this.showModal = false;
            this.clearAllChanges = false
            this.hasUnsavedChanges = false
            location.reload()
        },
        addBeforeUnloadListener() {
            window.addEventListener("beforeunload", this.beforeUnloadHandler);
        },
        removeBeforeUnloadListener() {
            window.removeEventListener("beforeunload", this.beforeUnloadHandler);
        },
        beforeUnloadHandler(e) {
            if (this.hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = ""; // Muestra el diálogo de confirmación
            } 
        },
        cancelAction(){
            this.showModal = false; 
        },
        confirmAction() {
            if (this.clearAllChanges) {
                this.confirmClearChanges(); // Llama a la función para confirmar borrar cambios
            } else {
                this.confirmNavigation()
            }            
        },
        async onMounted() {
            this.$store.updateSideMenu(-1)
            this.$store.updateTopMenu(11)            

            this.getSearchValue()
            this.getListByShopCode()
            this.$store.CALL_MERCHANTS()
            this.toggleMerchantSelectorByLabel('showPDVListSelectionFilter')
            if (!navigator.onLine) return
            await this.getCategories()
            
            const hasProductMenu = JSON.parse(localStorage.getItem(`hasProductMenu_${this.shopCode}`))
            if(!hasProductMenu){
                const idListFromFirebase = await firebaseRepo.getIdListFromFirebase(this.shopCode)
                if (idListFromFirebase === null) {
                    toast.error("Lista de de productos no encontrada")
                    return
                }
                
                const menuesSnapshot = await firebaseRepo.getMenuesFromFirebase(idListFromFirebase)
                if(!menuesSnapshot){
                    // Datos que queremos agregar en `listProducts/menues/0`
                    const newMenuData = {
                        [`listProducts/${idListFromFirebase}/menues/0`]: {
                            name: "Mostrador",
                            uuid: this.createUUID(),
                            origins: {
                                0: {
                                    fdsSyncStatus: 0,
                                    name: "Mostrador"
                                }
                            }
                        }
                    }
                    // Aplicar la actualización en Firebase sin borrar otros nodos
                    update(ref(db), newMenuData)
                    .then(() => {
                        console.log("Nodo agregado/actualizado exitosamente.")
                    })
                    .catch((error) => {
                        console.error("Error al agregar/actualizar el nodo:", error)
                    });
                } 
                localStorage.setItem(`hasProductMenu_${this.shopCode}`, JSON.stringify(true))
            } 
        },
        getSearchValue(){
            return this.searchValue = (localStorage[`searchQuery`] || '').trim()
        },
        hideElements(){
            this.$store.hideElements({
                    hideCalendar: true,
                    hideFilters: false,
                    hideTabs: true,
                })
        },
        
        async getCategories() {
            if (!this.shopCode) return
            const productsRef = ref(db, `products/${this.shopCode}`)
            const products = await get(productsRef)
            const productsSnapshot = products.val()
            if (productsSnapshot.idList === null) {
                toast.error("Lista de de productos no encontrada")
                return
            }

            const productsListRef = ref(db, `listProducts/${productsSnapshot.idList}/products`)
            const productsList = await get(productsListRef)
            const productsListSnapshot = productsList.val()
            const storedCategories = productsSnapshot.categories?.map(x => {
                return {
                    ...x,
                    editCategory: false,
                    errorName: '',
                    oldCategoryName: x.name,
                    products: [],
                    isCategoryTouched: false
                }
            }) || [];

            storedCategories.forEach(category => {
                if(productsListSnapshot) {
                    const matchingProducts = Object.values(productsListSnapshot)
                    .filter(product => product.department === category.name)
                    .filter(product => product.origins && product.origins.some(origin => origin.name === 'Mostrador'))
                    if (matchingProducts) {
                        category.products = matchingProducts.map(product => {
                            return {
                                ...product,
                                iepsMxTaxRate: product.iepsMxTaxRate ?? 0,
                                price: product.origins.find(origin => origin.name === 'Mostrador').price,
                                vat: product.rootVat,
                                errors: [],
                            }
                        })
                    } 
                }
                
            })
            if (storedCategories) {
                this.$pdv.setCategoriesMenu(storedCategories)
                this.categories = this.$pdv.getCategoriesMenu
            }
        },
        addCategory() {
            if (this.categories.length > 0) {
                const lastCategory = this.categories[this.categories.length - 1];

                if (lastCategory.products.length === 0 && lastCategory.oldCategoryName === null) {
                    toast.error("Debes asignar al menos un producto antes de agregar otra categoría.");
                    return;
                }
            }

            this.categories.push({
                name: "",
                editCategory: true,
                errorName: '',
                oldCategoryName: null,
                products: [],
                isCategoryTouched: false
            });

            this.$nextTick(() => {
                this.$refs.newCategorySection.activateEventListener()
            })
        },
        cancel() {
            this.clearAllChanges =true
            this.showModal = true;
        },
        updateCategory(updatedCategories) {
            const removedCategories = this.categories.filter(category =>
                !updatedCategories.some(updatedCategory => updatedCategory.name === category.name)
            )
            this.removedCategoriesList.push(...removedCategories)
            this.categories = updatedCategories
            this.$pdv.setCategoriesMenu(updatedCategories)
        },
        async onUpsertCategoriesAndProducts(categories) {
            if (this.shopCode === null) {
                toast.error("No se ha seleccionado un local")
                return
            }
            const currentValueProdsShop = ref(db, `products/${this.shopCode}`)
            const productSnapshot = await get(currentValueProdsShop)
            const shopProductSetup = productSnapshot.val()

            if (!shopProductSetup.idList) {
                toast.error("Lista de de productos no encontrada")
                return
            }

            const currentValueListProducts = ref(db, "listProducts/" + shopProductSetup.idList)
            const shopProductListSnapshot = await get(currentValueListProducts)
            const shopProductList = shopProductListSnapshot.val()
            const existingCategoryNames = categories.map(category => category.oldCategoryName).filter(name => name !== null)

            shopProductSetup.categories = shopProductSetup.categories?.filter(category => existingCategoryNames.includes(category.name)) || []

            if (shopProductList?.menues) {
                shopProductList.menues = { name: "Tienda Online", uuid: this.createUUID(), origins: { fdsSyncStatus: 0, name: "Mostrador" } }
            }

            // Si categories está vacío, eliminar todas las categorías y sus productos
            if (categories.length === 0) {
                // Eliminar todos los productos relacionados con categorías
                if (shopProductList?.products) {
                    const allProducts = Object.entries(shopProductList.products);
                    allProducts.forEach(([key, product]) => {
                        const updateProduct = ref(db, `listProducts/${shopProductSetup.idList}/products/${key}`);
                        remove(updateProduct);
                        this.disableProductOnBackEndPos(product.sku);
                    });
                }

                // Actualizar en Firebase
                const updateProductsCateg = ref(db, `products/${this.shopCode}/categories`);
                await set(updateProductsCateg, shopProductSetup.categories);

                return
            }


            categories.forEach(async (category) => { 
                let oldCategoryName = category.oldCategoryName //Es null si la categoria es nueva

                if (!oldCategoryName) {
                    const newCategory = {
                        name: category.name,
                        orderNumber: category.orderNumber || null,
                        hasToPrintInKitchen: category.hasToPrintInKitchen || false,
                        hasToPrintOnCounter: category.hasToPrintOnCounter || true,
                        hasToPrintOnAdditionalPrinter: category.hasToPrintOnAdditionalPrinter || false,
                        hasToEnableFlavorLimit: category.hasToEnableFlavorLimit || false,
                        minimumFlavorLimit: category.minimumFlavorLimit ?? 0,
                        maximumFlavorLimit: category.maximumFlavorLimit ?? 99,
                    }
                    shopProductSetup.categories.push(newCategory)
                } else if (oldCategoryName != category.name) {
                    let cat = shopProductSetup.categories.filter((c) => c.name === oldCategoryName)
                    if (cat.length === 1) {
                        cat[0].name = category.name
                    } else {
                        ///FIXME que deberia pasar aca? quiero editar algo que no existe?
                    }

                    if (shopProductList != null) {
                        if (shopProductList.products !== undefined && shopProductList.products !== null) {
                            let allProds = Object.entries(shopProductList.products)
                            allProds.forEach((element) => {
                                const key = element[0]
                                const prod = element[1]
                                if (prod.department === oldCategoryName) {
                                    prod.department = category.name

                                    const updateLP = ref(
                                        db,
                                        `listProducts/${shopProductSetup.idList}/products/${key}`
                                    )
                                    update(updateLP, prod)
                                }
                                if (shopProductList.combos) {
                                    let allCombos = Object.entries(shopProductList.combos)
                                    allCombos.forEach((element) => {
                                        const key = element[0]
                                        const combo = element[1]

                                        if (combo.department === oldCategoryName) {
                                            combo.department = category.name
                                            const updateLPC = ref(
                                                db,
                                                `listProducts/${shopProductSetup.idList}/combos/${key}`
                                            )
                                            update(updateLPC, combo)
                                        }
                                    })
                                }
                            })
                        }
                    }
                }

                if (this.removedCategoriesList.length > 0) {
                    this.removedCategoriesList.forEach(removeCategory => {
                        const removedProducts = Object.values(shopProductList.products)
                            .filter(product => product.department === removeCategory.name)
                            .filter(product =>
                                product.origins && product.origins.some(origin => origin.name === 'Mostrador' &&
                                    !removeCategory.products.some(catProduct => catProduct.sku === product.sku)))

                        if (removedProducts.length > 0) {
                            removedProducts.forEach(removedProduct => {
                                const productKey = this.findKeyBySku(Object.entries(shopProductList.products), removedProduct.sku)
                                if (!productKey) return;
                                const updateProduct = ref(db, `listProducts/${shopProductSetup.idList}/products/${productKey}`)
                                remove(updateProduct)
                                this.disableProductOnBackEndPos(removedProduct.sku)
                            })
                        }
                    })
                    this.removedCategoriesList = []
                } else {
                    if(shopProductList.products) {
                        const removedProducts = Object.values(shopProductList.products)
                        .filter(product => product.department === category.name)
                        .filter(product =>
                            product.origins && product.origins.some(origin => origin.name === 'Mostrador' &&
                                !category.products.some(catProduct => catProduct.sku === product.sku)))

                        if (removedProducts.length > 0) {
                            removedProducts.forEach(removedProduct => {
                                const productKey = this.findKeyBySku(Object.entries(shopProductList.products), removedProduct.sku)
                                if (!productKey) return;
                                const updateProduct = ref(db, `listProducts/${shopProductSetup.idList}/products/${productKey}`)
                                remove(updateProduct)
                                this.disableProductOnBackEndPos(removedProduct.sku)
                            })
                        }
                    }
                }
                const productsToFilter = []
                if(shopProductList.products) {
                    productsToFilter.push(...Object.values(shopProductList.products))
                }

                let productsFromCategoryInFirebase = productsToFilter
                    .filter(product => product.department === category.name)
                    .filter(product =>
                        product.origins && product.origins.some(origin => origin.name === 'Mostrador' &&
                            category.products.some(catProduct => catProduct.sku === product.sku)))
                    .map(product => {
                        const updatedProduct = category.products.find(catProduct => catProduct.sku === product.sku);
                        if (updatedProduct && (updatedProduct.name !== product.name || updatedProduct.vat !== product.rootVat || updatedProduct.price !== product.price)) {
                            const { exempt, nonTaxed, rootVat } = this.getVatDetails(updatedProduct)

                            return {
                                ...product,
                                name: updatedProduct.name,
                                iepsMxTaxRate: updatedProduct.iepsMxTaxRate ?? 0,
                                exempt,
                                nonTaxed,
                                origins: [{ fdsSyncStatus: 0, iva: rootVat, name: "Mostrador", price: updatedProduct.price.toString() }],
                                rootVat
                            };
                        }
                        return product; // Si no cambia el nombre, dejamos el producto como está
                    });

                const missingProducts = category.products.filter(catProduct => !productsFromCategoryInFirebase.some(product => product.sku === catProduct.sku))
                if (missingProducts.length > 0) {
                    let skuIndex = shopProductList.nextIncrementForSku ?? 0;
                    missingProducts.forEach(x => {
                        x.sku = `10${this.shopCode}${skuIndex}`
                        skuIndex++;
                    });
                    shopProductList.nextIncrementForSku = skuIndex;
                    const newProductsForFirebase = missingProducts.map(product => {
                        const { exempt, nonTaxed, rootVat } = this.getVatDetails(product)

                        return {
                            department: category.name,
                            description: null,
                            exempt,
                            hasToPrintInKitchen: false,
                            hasToPrintOnAdditionalPrinter: false,
                            hasToPrintOnCounter: true,
                            iepsMxTaxRate: product.iepsMxTaxRate ?? 0,
                            image: null,
                            isProductAvailable: true,
                            measureUnit: null,
                            name: product.name,
                            nonTaxed,
                            origins: [{ fdsSyncStatus: 0, iva: product.vat, name: "Mostrador", price: product.price.toString() }],
                            rg5329: false,
                            rootVat,
                            sku: product.sku,
                            stock: "0",
                            type: "PRODUCTO",
                            weightable: false
                        }
                    })

                    newProductsForFirebase.forEach(async (product) => {
                        const newProductKey = ref(
                            db,
                            `listProducts/${shopProductSetup.idList}/products`
                        )
                        push(newProductKey, product)
                    })
                }

                productsFromCategoryInFirebase.forEach(async (product) => {
                    const productKey = this.findKeyBySku(Object.entries(shopProductList.products), product.sku)
                    if (!productKey) return;
                    const updateProduct = ref(
                        db,
                        `listProducts/${shopProductSetup.idList}/products/${productKey}`
                    )
                    update(updateProduct, product)
                })
            })

            const updateProductsCateg = ref(db, `products/${this.shopCode}/categories`)
            await set(updateProductsCateg, shopProductSetup.categories)

            const updateNextIncrementSku = ref(db, `listProducts/${shopProductSetup.idList}/nextIncrementForSku`)
            await set(updateNextIncrementSku, shopProductList.nextIncrementForSku)

        },
        findKeyBySku(data, targetSku) {
            for (const [key, value] of data) {
                if (value.sku === targetSku) {
                    return key; // Return the key (position 0 of each object)
                }
            }
            return null; // Return null if no match is found
        },
        getVatDetails(product) {
            let exempt = product.exempt ?? false;
            let nonTaxed = product.nonTaxed ?? false;
            let rootVat = product.vat;

            if (product.vat === "Exento") {
                exempt = true;
                nonTaxed = false;
                rootVat = "0.00";
            } else if (product.vat === "No Gravado") {
                exempt = false;
                nonTaxed = true;
                rootVat = "0.00";
            } else {
                exempt = false
                nonTaxed = false
            }

            return { exempt, nonTaxed, rootVat };
        },
        async save() {
            this.$store.initLoading(this)
            this.isLoading = true
            const newCategorySection = this.$refs.newCategorySection
            if(newCategorySection) {
                const validation = newCategorySection.validateFields()
                if (!validation.isValid) {
                    const categoriesWithErrors = this.categories.filter(category => category.errorName !== "")
                    if (categoriesWithErrors.length > 0) {
                        categoriesWithErrors.forEach(error => toast.error(error.errorName))
                        this.isLoading = false
                        this.$store.finishLoading(this)
                        return false
                    }
                    const productsWithErrors = this.categories.flatMap(category =>
                        category.products.flatMap(product =>
                            Object.values(product.errors).filter(error => error)
                        )
                    );
                    if (productsWithErrors.length > 0) {
                        productsWithErrors.forEach(error => toast.error(error))
                        this.isLoading = false
                        this.$store.finishLoading(this)
                        return
                    }
                    this.isLoading = false
                    this.$store.finishLoading(this)
                    return toast.error("Error al guardar la carta")
                }
            }
           
            await this.onUpsertCategoriesAndProducts(this.categories)
            if (this.shopCode) {
                await firebaseRepo.createLastUpdateTimestamp(this.shopCode)
            }
            toast.success("La carta se guardó correctamente")
            this.hasUnsavedChanges = false
            setTimeout(() => {
                location.reload()
            }, 3000)
        },
    },
    computed:{
        hasToShowButtons(){
            return this.searchValue?.trim() == "" || this.searchValue == undefined
        }
    },
    watch: {
        searchValue(newValue) {
            this.searchValue = newValue.trim()
            this.inputType = newValue.length > 0 ? "text" : "search"
        },
        async shopCode() {
            await this.getCategories()
        }
    },
}
</script>

<style scoped lang="scss">
.main-container {
    background-color: #e2e2e2;
    overflow: auto;
}

.border-line {
    border-bottom: 1px solid #cecece;
    margin: 3px 0 3px 0;
}

.container {
    margin: 50px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.min-height-600 {
    min-height: 650px;
}

.centered-content {
    margin: 0 100px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.input-search-container {
    text-align: left;

    .input-search {
        margin: 30px 0 15px 0;
        padding-left: 10px;
        width: 100%;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        color: #212121;

        &:focus {
            border: none !important;
            box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1) !important;
        }
    }
}

.search-and-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.links-container {
    display: flex;
    gap: 30px;
    margin-top: 20px;
}

.link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: $darkColorFont;
    font-size: 10px;
    cursor: pointer;

    img {
        width: 25px;
        height: 25px;
        filter: invert(1);
    }

    figure span {
        font-size: 25px;
    }

    &:hover {
        p {
            color: $primaryColor;
        }
    }
}

.title {
    font-size: 24px;
    margin-bottom: 8px;
}

.subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.add-category-text {
    font-size: 16px;
    margin: 20px;
    color: #666;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: auto;
    padding-top: 20px;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}
</style>