import indexedDbRepo from "./closeCashboxesIndexDBRepository";
import axios from "axios";
import { getAndroidId, isOlderThan } from "./../../utils/utils";
import { ASYNC_STATUS } from "./../../utils/orderStatuses";
import { toast } from "vue3-toastify";
import { currency } from "@/filters/currency";
import { formatDateTime } from "@/features/shared/utils/utils"

const updateCloseCashbox = async (shopCode, cashbox) => {
  if (!shopCode) return;
  await indexedDbRepo.updateCloseCashbox(shopCode, cashbox);
};

const createObjectCloseCashbox = async (shopCode, cashboxData) => {
  const cashbox = {
    uuid: window.crypto.randomUUID(),
    openDate: cashboxData.openDate,
    closeAmount: cashboxData.closeAmount,
    closeDate: cashboxData.closeDate,
    declaredAmount: cashboxData.declaredAmount,
    lastCloseAmount: cashboxData.lastCloseAmount,
    totalAmountVoided: cashboxData.totalAmountVoided,
    totalOrderAmount: cashboxData.totalOrderAmount,
    totalVoided: cashboxData.totalVoided,
    openAmount: cashboxData.openAmount,
    totalSold: cashboxData.totalSold,
    totalCard: cashboxData.totalCard,
    totalCash: cashboxData.totalCash,
    totalDeposit: cashboxData.totalDeposit,
    totalOnline: cashboxData.totalOnline,
    totalOrders: cashboxData.totalOrders,
    totalQR: cashboxData.totalQR,
    totalWithdrawal: cashboxData.totalWithdrawal,
    totalAmountOfCashboxMovements: cashboxData.totalAmountOfCashboxMovements,
    sync: ASYNC_STATUS.PENDING,
    auditsMovements: JSON.stringify(cashboxData.auditsMovements),
    createdAt: Date.now(),
  };
  return await indexedDbRepo.insertCloseCashbox(shopCode, cashbox);
};

// Función para crear y sincronizar el reporte
const syncCloseCashbox = async (shopCode, closeCashbox, t) => {
  const movementsData = JSON.parse(closeCashbox.auditsMovements);
  const fiscalData =  JSON.parse(localStorage[`userDataPDV_${shopCode}`])


  const formatMovementAudits = (movementsData) => {
    return {
      allToLeft: false,
      _horizontalLine: true,
      _items: movementsData
        .map((movement) => {
          return [
            {
              section: {
                allToLeft: false,
                _title: getTextLeftForAuditsMovements(movement),
                _vFormat: {
                  isBoldPaper: false,
                  mIsBold: true,
                  mSize: 20,
                  mTextAlign: "right",
                  x: 1,
                  y: 1,
                },
                _value: currency(movement.amount),
              },
            },
            {
              section: {
                allToLeft: false,
                _title: getCommentForTicket(movement),
                _vFormat: {
                  isBoldPaper: false,
                  mIsBold: true,
                  mSize: 20,
                  mTextAlign: "right",
                  x: 1,
                  y: 1,
                },
                _value: "",
              },
            },
            {
              section: {
                allToLeft: false,
                _title: "&nbsp;",
                _value: "",
              },
            },
          ];
        })
        .flat(),
      _tFormat: {
        isBoldPaper: true,
        mIsBold: true,
        mSize: 30,
        mTextAlign: "center",
        x: 1,
        y: 1,
      },
      _title: "AUDITORIA POR MOVIMIENTO",
    };
  };

  const dynamicTicket = [
    {
      TituloSection: {
        allToLeft: false,
        _horizontalLine: true,
        _tFormat: {
          isBoldPaper: false,
          mIsBold: true,
          mSize: 40,
          mTextAlign: "center",
          x: 2,
          y: 2,
        },
        _title: "CIERRE DE CAJA",
      },
      GenericDataSection: {
        allToLeft: false,
        _horizontalLine: true,
        _items: [
          {
            section: {
              allToLeft: true,
              _tFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 30,
                x: 2,
                y: 1,
              },
              _title: "Fecha de apertura: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 30,
                x: 2,
                y: 1,
              },
              _value: formatDateTime(closeCashbox.openDate),
            },
          },
          {
            section: {
              allToLeft: true,
              _tFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 30,
                x: 2,
                y: 1,
              },
              _title: "Fecha de cierre:   ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 30,
                x: 2,
                y: 1,
              },
              _value: formatDateTime(closeCashbox.closeDate),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "&nbsp;",
              _value: "",
            },
          },
          {
            section: {
              allToLeft: false,
              _title: `Razon social: `,
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: fiscalData?.legalName,
            },
          },
          {
            section: {
              allToLeft: false,
              _title: `Operador:`,
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: `${t.$store.authUser?.user?.name}`,
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Efectivo en caja apertura: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.lastCloseAmount),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Efectivo declarado en apertura: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.declaredAmount),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Efectivo en cierre de caja: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.closeAmount),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Diferencia encontrada en apertura: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(
                closeCashbox.declaredAmount - closeCashbox.lastCloseAmount
              ),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Diferencia encontrada en cierre: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(
                closeCashbox.closeAmount -
                  closeCashbox.totalAmountOfCashboxMovements
              ),
            },
          },
          {
            section: {
              allToLeft: false,
              _horizontalLine: true,
              _title: "",
              _value: "",
            },
          },
        ],
        _tFormat: {
          isBoldPaper: true,
          mIsBold: true,
          mSize: 30,
          mTextAlign: "center",
          x: 1,
          y: 1,
        },
        _title: "DATOS GENERICOS",
      },
      TotalSection: {
        allToLeft: false,
        _horizontalLine: true,
        _items: [
          {
            section: {
              allToLeft: false,
              _title: "Total de Comandas: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalOrderAmount),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Total de Anulaciones de Comandas: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalAmountVoided),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "&nbsp;",
              _value: "",
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Cantidad de Comandas: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: closeCashbox.totalOrders,
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Cantidad de Anulaciones de Comandas: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: closeCashbox.totalVoided,
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "&nbsp;",
              _value: "",
            },
          },
          {
            section: {
              allToLeft: false,
              _horizontalLine: true,
              _tFormat: {
                isBoldPaper: true,
                mIsBold: false,
                mSize: 20,
                mTextAlign: "left",
                x: 1,
                y: 1,
              },
              _title: "Total Vendido:",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalSold),
            },
          },
        ],
        _tFormat: {
          isBoldPaper: true,
          mIsBold: true,
          mSize: 30,
          mTextAlign: "center",
          x: 1,
          y: 1,
        },
        _title: "TOTALES DE VENTAS",
      },
      TotalesMediosPagoSection: {
        allToLeft: false,
        _horizontalLine: true,
        _items: [
          {
            section: {
              allToLeft: false,
              _title: "Efectivo: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalCash),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Tarjetas: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalCard),
            },
          },
          ...(process.env.VUE_APP_INSTANCE_CODE !== "MX" && process.env.VUE_APP_INSTANCE_CODE !== "ES" ? [{
            section: {
              allToLeft: false,
              _title: "QR: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalQR),
            },
          }] : []),
          {
            section: {
              allToLeft: false,
              _title: "Online: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalOnline),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "&nbsp;",
              _value: "",
            },
          },
          {
            section: {
              allToLeft: false,
              _horizontalLine: true,
              _tFormat: {
                isBoldPaper: true,
                mIsBold: false,
                mSize: 20,
                mTextAlign: "left",
                x: 1,
                y: 1,
              },
              _title: "Total: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalSold),
            },
          },
        ],
        _tFormat: {
          isBoldPaper: true,
          mIsBold: true,
          mSize: 30,
          mTextAlign: "center",
          x: 1,
          y: 1,
        },
        _title: "TOTALES DE VENTAS POR MEDIOS DE PAGO",
      },
      AuditoriaTotalesSection: {
        allToLeft: false,
        _horizontalLine: true,
        _items: [
          {
            section: {
              allToLeft: false,
              _title: "Total Retiros: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalWithdrawal),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "Total Depósitos: ",
              _vFormat: {
                isBoldPaper: false,
                mIsBold: true,
                mSize: 20,
                mTextAlign: "right",
                x: 1,
                y: 1,
              },
              _value: currency(closeCashbox.totalDeposit),
            },
          },
          {
            section: {
              allToLeft: false,
              _title: "&nbsp;",
              _value: "",
            },
          },
        ],
        _tFormat: {
          isBoldPaper: true,
          mIsBold: true,
          mSize: 30,
          mTextAlign: "center",
          x: 1,
          y: 1,
        },
        _title: "OTROS MOVIMIENTOS",
      },
      AuditoriaPorMovimientosSection: formatMovementAudits(movementsData),
    },
];

  const payload = {
    DynamicTicket: JSON.stringify(dynamicTicket),
    Uuid: closeCashbox.uuid,
    AndroidId: getAndroidId(shopCode),
    iMEI: "",
  };

  try {
    const { data } = await axios.post(`/api/pdv/SyncCashboxReport`, payload);
    if (data?.responseCode == 0) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (error) {
    toast.error("Error al procesar el ticket virtual:", error);
    return false;
  }
};

const getCommentForTicket = (item) =>{

  switch (item.movementType) {
      case "AJUSTE EN APERTURA DE CAJA":
          return `${item.userName}\n\n`;
      case "VOID":
          return `COMANDA ${item.ticketNumber} - ${formatDateTime(item.date)}\n\n`;
      default:
          return `${item.comments}\n\n`;
  }
}
const getTextLeftForAuditsMovements = (item) =>{
  switch (item.movementType) {
      case "AJUSTE EN APERTURA DE CAJA":
          return `AJUSTE - ${formatDateTime(item.date)}`;
      case "VOID":
          return 'ANULACION DE COMANDA';
      default:
          return `${item.movementType} - ${formatDateTime(item.date)}`;
  }
}
// Función para sincronizar los reportes pendientes
const syncCashboxReports = async (shopCode, t) => {
  if (!shopCode || !navigator.onLine) return;
  const cashboxes = await indexedDbRepo.getCloseCashboxes(shopCode);

  const cashboxesToSync = cashboxes.filter(
    (cashbox) => cashbox.sync == ASYNC_STATUS.PENDING
  );
  for (const cashbox of cashboxesToSync) {
    try {
      const isSuccess = await syncCloseCashbox(shopCode, cashbox, t);

      if (isSuccess) {
        cashbox.sync = ASYNC_STATUS.SYNCED;
        await updateCloseCashbox(shopCode, cashbox);
        toast.success(`Ticket virtual generado exitósamente`); //TODO ajustar mensaje
      }
    } catch (error) {
      toast.error(`Error al generar ticket virtual`);
    }
  }
  await deleteReports(shopCode);
};

// Función para Eliminar los reportes pendientes
const deleteReports = async (shopCode) => {
  const cashboxes = await indexedDbRepo.getCloseCashboxes(shopCode);

  // Filtrar órdenes más antiguas que la fecha del último cierre de caja
  const reportsToDelete = cashboxes.filter(
    (cashbox) =>
      isOlderThan(cashbox.createdAt, 90) &&
      cashbox.sync == ASYNC_STATUS.SYNCED
  );

  for (const cashbox of reportsToDelete) {
    await indexedDbRepo.deleteCloseCashbox(shopCode, cashbox);
  }
};

export { syncCashboxReports, createObjectCloseCashbox, getCommentForTicket, getTextLeftForAuditsMovements };
