<template>
  <div :class="['card-bistro', status]" @click="onHandleClick(handleClick)"
    @dblclick="onHandleDoubleClick(handleDoubleClick)">
    <div class="card-body">
      <h4 class="card-title" v-tooltip="title">{{ title }}</h4>
      <div class="card-countdown">
        <span>{{ $filters.time(countdown) }}</span>
      </div>
    </div>
    <div class="card-footer">
      <!-- <p v-if="optionalText">{{ optionalText }}</p> -->
      <b>{{ $filters.currency(amount ?? '0') }}</b>
    </div>
  </div>
</template>

<script>
import clickCommons from "../mixins/clickCommons.vue";

export default {
  name: "CardBistro",
  mixins: [clickCommons],
  props: {
    title: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
    },
    optionalText: {
      type: String,
      default: " ",
    },
    amount: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      default: "product",
      validator: function (value) {
        return ["product", "available", "active", "closed"].includes(value);
      },
    },
  },
  data() {
    return {
      countdown: 0,
      timer: null
    };
  },
  mounted() {
    if (this.status == "active") {
      this.timer = setInterval(() => {
        this.countdown = Date.now() - this.duration;
      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  computed: {},
  methods: {
    handleClick() {
      this.$emit("cardClicked");
    },
    handleDoubleClick() {
      this.$emit("cardDoubleClicked");
    }
  }
};
</script>

<style lang="scss">
.card-bistro {
  margin: 0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              0px 4px 5px 0px rgba(0, 0, 0, 0.14),    
              0px 1px 10px 0px rgba(0, 0, 0, 0.12); 
  overflow: hidden;
  max-width: 180px;
  width: 100%;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 8px 10px 0 rgba(0, 0, 0, .15);
    transform: translateY(-2px);
  }

  .card-countdown {
    height: 20px;
  }

  .card-title {
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
  }

  .card-contador {
    font-size: 14px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
    color: $lightColorFont;
  }

  .card-footer {
    @include flex-center-both;
    display: flex;
    justify-content: flex-end;
    background: $lightColorBg;
    color: $darkColorFont;
    padding: 0 16px;
    gap: 8px;
    height: 36px;
    font-size: 16px;
    letter-spacing: 0.05rem
  }

  &.available {
    background: $gradientGreen;
  }

  &.closed {
    background: $gradientBlue;
  }

  &.active {
    background: $gradientRed;
  }

  &.product {
    background: $lightColorBg;

    .card-title {
      height: 55.5px;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .card-body {
      color: $darkColorFont;
      display: block;
    }

    .card-footer {
      background: $gradientBlue;
      color: $lightColorFont;
    }

    .card-countdown {
      display: none;
    }
  }
}
</style>
