export let initialBar = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      animation: true,
      label: {
        backgroundColor: "#ccc",
        borderColor: "#aaa",
        borderWidth: 1,
        shadowBlur: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        textStyle: {
          color: '#FFFFFF'
        }
      }
    },
    formatter: function (params) {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case 'AR':
        default:
          return `${Number(params[0].value).toLocaleString('es-AR', {style: 'currency', currency: 'ARS', minimumFractionDigits: 2})} <br />  ${params[0].axisValue}`
        case 'MX':
          return `${Number(params[0].value).toLocaleString('es-MX', {style: 'currency', currency: 'MXN', minimumFractionDigits: 2})} <br />  ${params[0].axisValue}`
        case 'UY':
          return `${Number(params[0].value).toLocaleString('es-UY', {style: 'currency', currency: 'UYU', minimumFractionDigits: 2})} <br />  ${params[0].axisValue}`
        case 'ES':
          return `${Number(params[0].value).toLocaleString('es-ES', {style: 'currency', currency: 'EUR', minimumFractionDigits: 2})} <br />  ${params[0].axisValue}`
        case 'ENERGY_POS_SV':
          return `${Number(params[0].value).toLocaleString('es-SV', {style: 'currency', currency: 'SVC', minimumFractionDigits: 2})} <br />  ${params[0].axisValue}`
        case 'CL':
        case 'PROVENTAS':
          return `${Number(params[0].value).toLocaleString('es-CL', {style: 'currency', currency: 'CLP', minimumFractionDigits: 0})} <br />  ${params[0].axisValue}`
      }
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLine: {
      show: false, 
    },
    axisTick: {
      show: true 
    },
    splitLine: {
      show: false  
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {show: true},
    axisLine: {
      show: true,  
    },
    axisTick: {
      show: false  
    },
    splitLine: {
      show: false  
    }
  },
  series: [{
    type: 'bar',
    data: [],
    barWidth: '60%',
    label: {
      show: false
    }
  }]
}

export let dataBar = {
  xAxis: {
    type: 'category',
    data: [],
    axisLine: {
      show: true, 
    },
    axisTick: {
      show: false  
    },
    splitLine: {
      show: false  
    }
  },
  yAxis: {
    type: 'value',
    max: 10000,
    interval: 2000,
    axisLine: {
      show: true, 
    },
    axisTick: {
      show: true  
    },
    splitLine: {
      show: false 
    }
  },
  series: [{
    data: [],
    type: 'bar',
    barWidth: '80%',
    label: {
      show: false
    },
    animationEasing: 'linear'
  }]
}
