import createIndexedDb from "../db.js";
import Dexie from "dexie";

const getCloseCashboxes = async (shopCode) => {
  const db = createIndexedDb(shopCode);
  return await db.cashboxReports.toArray();
};

const insertCloseCashbox = async (shopCode, closeCashbox) => {
  const db = createIndexedDb(shopCode);
  const closeCashboxId = await db.cashboxReports.add(closeCashbox);
  return { id: closeCashboxId, ...closeCashbox }
};

const updateCloseCashbox = async (shopCode, closeCashbox) => {
  const db = createIndexedDb(shopCode);
  const cloneCloseCashbox = Dexie.deepClone(closeCashbox);
  await db.cashboxReports.put(cloneCloseCashbox);
};

const deleteCloseCashbox = async (shopCode, closeCashbox) => {
  const db = createIndexedDb(shopCode);
  await db.cashboxReports.delete(closeCashbox.id);
};


export default {
  getCloseCashboxes,
  insertCloseCashbox,
  updateCloseCashbox,
  deleteCloseCashbox,
};
