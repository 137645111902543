<script>
export default {
	methods: {
		closeModalById(modalId) {
			/* eslint-disable */
			modalId = modalId.slice(1);
			var modalInstance = document.getElementById(modalId);
			var instance = M.Modal.init(modalInstance);
			instance.close();
			
		},
		openModalById(modalId) {
		
			modalId = modalId.slice(1);
			var modalInstance = document.getElementById(modalId);
			var instance = M.Modal.init(modalInstance);
			if(instance)
				instance.open();
		},
		openNonDismissibleModal(modalId) {
			modalId = modalId.slice(1);
			const modalInstance = document.getElementById(modalId);
			const instance = M.Modal.init(modalInstance, {
				dismissible: false, // Evita que el modal se cierre al hacer clic fuera
			});
			if(instance)
				instance.open();
			/* eslint-enable */
		},

	}
}
</script>
