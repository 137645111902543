

<template>
  <div :class="themeClass">
    <!-- Barra de progreso -->
    <div class="loader-container">
      <v-progress-linear
        v-model="this.$store.getProgress"
        :color="getComputedColor"
        striped
        v-if="this.$store.getLoading"
      ></v-progress-linear>
    </div>

    <!-- Layout principal -->
    <component :is="$route.meta.layout || 'div'">
      <RouterView />
    </component>
  </div>
</template>

<script>
import { VProgressLinear } from "vuetify/lib/components/index.mjs";

export default {
  components: {
    VProgressLinear,
  },
  computed: {
    themeClass() {
      const instanceCode = process.env.VUE_APP_INSTANCE_CODE;
      return /ENERGY_POS/.test(instanceCode) ? "energy-pos-theme" : "";
    },
    getComputedColor() {
      return getComputedStyle(document.documentElement)
        .getPropertyValue("--blueCeleste")
        .trim();
    },
  },
  methods: {
    setFavicon() {
      const instanceCode = process.env.VUE_APP_INSTANCE_CODE;
      const favicon = /ENERGY_POS/.test(instanceCode)
        ? "/favicon-energy-pos.ico"
        : "/favicon.ico";

      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = favicon;
      console.log(`Favicon cambiado a: ${link.href}`);
    },
  },
  mounted() {
    this.setFavicon();
  },
};
</script>

<style lang="scss">
.loader-container {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
}

:root {
  --blueDark: #2C394C;
  --blueCeleste: #24A5D3;
}

.energy-pos-theme {
  --blueDark: #00395E;
  --blueCeleste: #36B0FF;
}
</style>

