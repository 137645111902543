<template>
	<div id="modalNewProvider" class="modal modalNewProvider" style="overflow-y: auto !important;">
		<div class="modal-header">
		
				<h4>{{ getModalTitle }}</h4>
				<p>Fácil y Simple</p>
	
			<!-- CLOSE -->
			<div class="btnClose">
				<a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>
		<div class="modal-body">
			<form action class="formGeneral formGeneral-new-provider">
				<article v-if="isNewProvider === false" class="formMain formMain-provider-input article">
					<div class="itemForm">
					</div>
					<div class="itemForm itemForm-provider">
						<span class="icon-Bistro-_Hamburger_Provider"></span>
						<label class="label-blue" @click="seeReceiptsOfProvider" for>Ver consolidado</label>
					</div>
				</article>
				<article class="formMain formMain-provider-input article">
					<div class="itemForm">
						<label class="label" for>Nombre de Fantasía: *</label>
						<input class="input" v-model="localProvider.fantasyName" type="text" placeholder="Nombre empresa" />
					</div>
				</article>
				<article class="formMain formMain-provider-input article">
					<div class="itemForm">
						<label class="label" for>Razón Social: *</label>
						<input class="input" v-model="localProvider.legalName" type="text"
							placeholder="Nombre razón social" />
					</div>
					<div class="itemForm">
						<label class="label" for>{{ getFiscalKeyNameInCulture }}: *</label>
						<input class="input" v-model="localProvider.fiscalId" type="text" :placeholder="isEnergyPosSVInstance ? 'XXXX-XXXXXX-XXX-X' : 'XX-XXXXXXXX-X'" />
					</div>
				</article>
				<article class="formMain formMain-provider-input article">
					<div class="itemForm">
						<label class="label" for>Responsable:</label>
						<input class="input" v-model="localProvider.responsible" type="text"
							placeholder="Responsable" />
					</div>
				</article>
				<article class="formMain formMain-provider-input article">
					<div class="itemForm">
						<label class="label" for>Teléfono</label>
						<input class="input" v-model="localProvider.phone" type="text" placeholder="+XXX-XXXX-XXXX" />
					</div>
					<div class="itemForm">
						<label class="label" for>Mail</label>
						<input class="input" v-model="localProvider.mail" type="text" placeholder="mail@mail.com" />
					</div>
					<div class="itemForm itemFormUserCreation">
						<label class="switchTitle" for>Categorías asociadas:</label>
						<div class="switch" v-for="(op, ind) in categories" :key="ind">
							<label>
								<a class="switchLabel">{{ `${op.name}` }}</a>
								<input :checked="op.selected" @click="onHandleCategorySwitch(op.categoryId)"
									type="checkbox">
								<span class="lever"></span>
							</label>
						</div>
					</div>
				</article>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main">
				<a v-if="isNewProvider === false" class="deleteProduct" @click.prevent="deleteProvider">
					<span class="icon-Bistro-_Trash_bin"></span><span>Eliminar proveedor</span>
				</a>
				<SpinnerComp v-if="isLoading" />
				<a v-else @click="upsertProvider" type="submit">
					<p>{{ getTitle }}</p>
				</a>
			</article>
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import SpinnerComp from "../components/SpinnerComp";
import commons from '../mixins/mixCommons'
import { parseToQuery } from '../utils/utils'
import InstanceCommons from '@/mixins/instanceCommons.vue';

export default {
	mixins: [commons,InstanceCommons],
	props: {
		isNewProvider: {
			type: Boolean
		},
		categories: {
			type: Array,
			default: function () {
				return []
			}
		},
		providerCategories: {
			type: Array,
			default: function () {
				return []
			}
		},
		provider: {
			type: Object,
			default: () => {
				return {}
			}
		},
		shopCode: {
			type: String
		},
		modalId: {
			type: String,
			default: () => {
				return "modalNewProvider";
			},
		},
	},
	data() {
		return {
			isLoading: false,
			params: {},
			localProvider: { ...this.provider }
		}
	},
	watch: {
		provider: {
			immediate: true,
			handler(newVal) {
				this.localProvider = { ...newVal };
			}
		},
		providerCategories() {
			if (this.providerCategories !== null && this.providerCategories !== undefined) {
				this.providerCategories.forEach((item) => {
					let auxCat = this.categories.filter(x => x.categoryId === item.categoryId)
					if (auxCat !== undefined || auxCat !== null) {
						auxCat[0].selected = item.selected
					}
				})
			}
		}
	},
	computed: {
		getFiscalKeyNameInCulture() {
			return this.$store.fiscalKeyNameInCulture;
		},
		getModalTitle() {
			return this.isNewProvider ? 'Crear Proveedor' : 'Editar Proveedor';
		},
		getTitle() {
			return this.isNewProvider ? 'Crear proveedor' : 'Guardar proveedor';
		},
		resultSuccessMessage() {
			return this.isNewProvider ? 'Proveedor creado exitosamente' : 'Proveedor guardado exitosamente';
		}
	},
	methods: {
		onHandleCategorySwitch(catId) {
			const selectedCat = this.categories.find(x => x.categoryId === catId);
			if (selectedCat) {
				selectedCat.selected = !selectedCat.selected;
			}
		},
		closeModal() {
			$('#__layout').css('pointer-events', 'all')
		},
		seeReceiptsOfProvider() {
			this.$emit('seeReceiptsOfProvider', this.provider.id, this.provider.fantasyName, this.provider.fiscalId, this.provider.phone, this.shopCode)
		},
		deleteProvider() {
			this.$store.initLoading(this)
			this.params = { id: this.provider.id };
			axios.delete(`/api/providersV2/deleteProvider?${parseToQuery(this.params)}`)
				.then(res => {
					if (res.data.responseCode === 0) {
						this.showSuccess(`Proveedor eliminado correctamente`)
						this.$emit('onDeleteProvider')
					} else {
						this.showErrors(res)
					}
					this.$store.finishLoading(this)
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		},
		upsertProvider() {
			// Validaciones
			if (!this.localProvider.fiscalId) {
				this.showError(`El ${this.getFiscalKeyNameInCulture} no puede ser vacío`)
				return
			}
			if (!this.localProvider.legalName) {
				this.showError(`La Razón Social no puede ser vacía`)
				return
			}
			if (!this.localProvider.fantasyName) {
				this.showError(`El Nombre de Fantasía no puede ser vacío`)
				return
			}

			this.$store.initLoading(this)
			this.isLoading = true

			// Crear el objeto de solicitud basado en localProvider
			const req = {
				shopCode: this.shopCode,
				id: this.localProvider.id,
				fantasyName: this.localProvider.fantasyName,
				legalName: this.localProvider.legalName,
				fiscalId: this.localProvider.fiscalId,
				responsible: this.localProvider.responsible,
				phone: this.localProvider.phone,
				mail: this.localProvider.mail,
				selectedCategories: this.categories.filter(x => x.selected).map(x => x.categoryId)
			}

			let http = this.isNewProvider
				? axios.post(`/api/providersV2/insertProvider`, req)
				: axios.put(`/api/providersV2/updateProvider`, req)

			http
				.then(res => {
					if (res.data.responseCode === 0) {
						this.showSuccess(`${this.resultSuccessMessage}`)
						this.$emit('onUpsertProvider')
					} else {
						this.showErrors(res)
					}
					this.$store.finishLoading(this)
					this.isLoading = false
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		}
	},
	components: {
		SpinnerComp
	}
}
</script>
