<template>
  <div id="modalDebt" class="modal">
    <div class="modal-header modal-header-debt">
      <div class="modal-header-debt-err" v-if="isFailure">
        {{ debtPaymentStatus.message }}
      </div>
      <div class="modal-header-debt-success" v-if="isSuccess">
        {{ debtPaymentStatus.message }}
      </div>
      <div class="btnClose">
        <a
          href="#!"
          @click="clear()"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <!-- BODY MODAL -->
    <div v-if="hasToShowPaymentModal" class="modal-body modalDebt">
			<form action class="formGeneral">
				<article :class="articleMainClass">
					<h2 v-if="user.user !== undefined">Hola {{ user.user.name }}!</h2>
					<h4 v-if="!isMX">Total de deuda acumulada por comercio</h4>
					<h4 v-if="isMX && debtQty < 2">{{ getMessageForOneDebt }}</h4>
					<div v-if="isMX">
						<div class="self-management-debt">
							<div v-if="debtQty > 1">
								<div v-for="(item, index) in debts" :key="index" class="articleDebt-main-row">
									<input type="checkbox" v-model="item.isSelected" :id="index"
										@change="onDebtSelected(index)" />
									<strong><currency-style :valor="$filters.currrency(item.amount)" /></strong>
									(Comercio {{ item.shopName }} - {{ item.shopCode }}
									<strong v-if="item.dueDate">- Vencimiento {{ item.dueDate }}</strong>)
								</div>
							</div>
							<div v-else>
								<div v-for="(item, index) in debts" :key="index" :class="articleRowClass">
									<strong><currency-style :valor="$filters.currency(item.amount)" /></strong>
									(Valor mensual)
								</div>
							</div>
              <div class="switch" style="width: fit-content; margin: auto">
								<label>
									<input v-model="emailBilling" type="checkbox" />
									<span class="lever lever-width"></span>
									<div>Enviar factura por mail</div>
								</label>
							</div>
						</div>
					</div>
					<div v-else>
						<div v-for="(item, index) in debts" :key="index" :class="articleRowClass">
							<input type="checkbox" 
              v-model="item.isSelected"
              :id="index"
              @change="onDebtSelected(index)" 
              />
							<strong><currency-style :valor="$filters.currency(item.amount)" /></strong>
							(Comercio {{ item.shopName }} - {{ item.shopCode }})
						</div>
					</div>

					<h3 v-if="!isMX">
						Total a pagar <currency-style :valor="$filters.currency(debtAmount)" />
					</h3>
				</article>
			</form>
		</div>
    <div v-if="hasToShowCheckingPaymentModal" class="modal-body modalDebt">
      <form action class="formGeneral">
        <article class="article articleDebt-checking">
          <h3>Validando pago, aguarde</h3>
          <SpinnerComp />
        </article>
      </form>
    </div>

    <div v-if="hasToShowPaymentModal" class="modal-footer">
      <article class="article articleDebt">
        <article class="btn-main">
          <div v-if="isMX">
            <a
              v-if="debtQty < 2"
              :class="'btn-active'"
              style="background-color: #37c866"
              @click="createPaymentLink"
              type="submit"
            >
              <p>RECARGAR AHORA</p>
            </a>
            <a
              v-else
              :class="this.debtAmount <= 0 ? 'btn-disabled' : 'btn-active'"
              style="background-color: #37c866"
              @click="createPaymentLink"
              type="submit"
            >
              <p>RECARGAR AHORA</p>
            </a>
          </div>
          <a
            v-else
            :class="this.debtAmount <= 0 ? 'btn-disabled' : 'btn-active'"
            @click="createPaymentLink"
            type="submit"
          >
            <p>PAGAR AHORA</p>
          </a>
        </article>
      </article>
    </div>
    <div
      v-if="hasToShowPaymentModal && isMX"
      class="self-management-debt-reminder-container"
    >
      <div class="self-management-debt-reminder">
        <div>
          <p>
            Recuerde que pagando por transferencia o efectivo deberá enviar su
            comprobante de pago a <strong>+52 1 564717 6670</strong>. El
            restablecimiento del servicio puede demorar de 24 a 48 horas hábiles
          </p>
        </div>
        <div class="warning-data">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
          >
            <path
              stroke="none"
              fill="red"
              d="M49.437822173509 23.095844127808a7 7 0 0 1 12.124355652982 0l33.375644347018 57.808311744384a7 7 0 0 1 -6.0621778264911 10.5l-66.751288694036 0a7 7 0 0 1 -6.0621778264911 -10.5"
            />
            <text
              x="55"
              y="70"
              text-anchor="middle"
              font-size="40"
              font-weight="600"
              fill="white"
            >
              !
            </text>
          </svg>
          <!-- <ul>
						<li v-if="linesToShow" v-for="line in linesToShow" v-html="line"></li>
					</ul> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import SpinnerComp from "../components/SpinnerComp";
import CurrencyStyle from "../components/CurrencyStyle";

export default {
  mixins: [mixCommons],
  props: {
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
      dontWantToSeeThisAgain: false,
      emailBilling: false,
      vat: 1.16,
    };
  },
  watch: {
    emailBilling() {
      if (this.debtQty > 1) {
        if (this.emailBilling) {
          this.debts.forEach((item) => {
            if (item.isSelected) this.setTaxOnDebt(item);
          });
        } else {
          this.debts.forEach((item) => {
            if (item.isSelected) this.unsetTaxOnDebt(item);
          });
        }
      } else {
        if (this.emailBilling) this.setTaxOnDebt();
        else this.unsetTaxOnDebt();
      }
    },
  },
  computed: {
    getMessageForOneDebt() {
      if (this.dueDays > 0)
        return `Tu servicio vence en ${this.dueDays} día${
          this.dueDays > 1 ? "s" : ""
        }!`;
      return "Tu servicio ya está vencido";
    },
    dueDays() {
      return this.$store.getDueDays;
    },
    articleMainClass() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX":
          if (this.debtQty > 1) return "article articleDebt-main";
          return "article articleDebt-mainMX";
        default:
          return "article articleDebt-main";
      }
    },
    articleRowClass() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX":
          return "articleDebt-mainMX-rowMX";
        default:
          return "articleDebt-main-row";
      }
    },
    isMX() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX":
          return true;
        default:
          return false;
      }
    },
    buttonClass() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX":
          return "btn-success"; ///FIXME este corregir
        default:
          return "btn-active";
      }
    },
    isFailure() {
      return (
        this.debtPaymentStatus && this.debtPaymentStatus.status === "failure"
      );
    },
    isSuccess() {
      return (
        this.debtPaymentStatus && this.debtPaymentStatus.status === "success"
      );
    },
    isChecking() {
      return (
        this.debtPaymentStatus && this.debtPaymentStatus.status === "checking"
      );
    },
    hasToShowPaymentModal() {
      if (this.isChecking) return false;
      else if (this.isSuccess) return false;
      else return true;
    },
    hasToShowCheckingPaymentModal() {
      if (this.isChecking) return true;
      else return false;
    },
    user() {
      return this.$store.getAuthUser ?? JSON.parse(localStorage['currentUserData']);
    },
    debt() {
      return this.$store.getDebt;
    },
    linesToShow() {
      return this.$store.getDebt?.linesToShow;
    },
    debtQty() {
      if (this.isMX) {
        if (this.$store.getDebt && this.$store.getDebt.shopDebts) {
          return this.debts.length;
        } else {
          return 0;
        }
      } else {
        if (this.$store.getDebt && this.$store.getDebt.shopDebts)
          return this.$store.getDebt.shopDebts.length;
        else return 0;
      }
    },
    debtPluralSuffix() {
      if (this.$store.getDebt && this.$store.getDebt.shopDebts)
        return this.$store.getDebt.shopDebts.length > 1 ? "s" : "";
      else return "";
    },
    debtAmount() {
      if (
        this.$store.getDebt &&
        this.$store.getDebt.totalDebtAmount
      ) {
        let debtAmount = 0;
        this.$store.getDebt.shopDebts.forEach((x) => {
          if (x.isSelected) {
            debtAmount += x.amount;
          }
        });
        return debtAmount;
      } else return 0;
    },
    debts() {
      if (this.$store.getDebt && this.$store.getDebt.shopDebts) {
        return this.$store.getDebt.shopDebts.filter((x) => x.amount > 0);
      } else return [];
    },
    debtPaymentStatus() {
      return this.$store.getDebtPaymentStatus;
    },
  },
  methods: {
    clear() {
      //$("#modalDebt").modal("close");
    },
    createPaymentLink() {
      if (this.isMX && this.debtQty < 2) {
        this.$emit(`onCreatingPaymentLink`, {
          amountToPay: this.debts[0].amount,
          selectedPaymentsIds: this.debts.map((x) => x.id),
          emailBilling: this.emailBilling,
          shopCodes: this.debts.map((x) => x.shopCode),
        });
      } else {
        if (this.debtAmount <= 0) {
          return;
        }
        this.$emit(`onCreatingPaymentLink`, {
          amountToPay: this.debtAmount,
          selectedPaymentsIds: this.$store.getDebt.shopDebts
            .filter((x) => x.isSelected)
            .map((x) => x.id),
          emailBilling: this.emailBilling,
          shopCodes: this.$store.getDebt.shopDebts
            .filter((x) => x.isSelected)
            .map((x) => x.shopCode),
        });
      }
    },
    setTaxOnDebt(d) {
      if (d) {
        d.amount = d.amount * this.vat;
      } else {
        this.debts.forEach((item) => {
          item.amount = item.amount * this.vat;
        });
      }
    },
    unsetTaxOnDebt(d) {
      if (d) {
        d.amount = d.amount / this.vat;
      } else {
        this.debts.forEach((item) => {
          item.amount = item.amount / this.vat;
        });
      }
    },
    onDebtSelected(index) {
      let base = this.$store.getDebt;
      let debt = base.shopDebts[index];
      base.shopDebts[index] = debt;
      //
      if (debt.isSelected) {
        if (this.emailBilling) this.setTaxOnDebt(debt);
      } else {
        if (this.emailBilling) this.unsetTaxOnDebt(debt);
      }
    },
  },
  components: {
    CurrencyStyle,
    SpinnerComp,
  },
};
</script>
