<template>
  <main>
    <!-- FILTRADO DE LA SECCION -->
    <article class="mainFilter filterEvent">
      <!-- TITULO Y SEARCH -->
      <section class="mainFilter-title">
        <h1>Caja</h1>
      </section>

      <!-- OPCIONES ADICIONALE -->
      <section class="mainFilter-filter">
        <!-- OPCION MODAL -->
        <a :href="`/api/report/boxV2/?${excelParams}`" class="waves-effect waves-light btn-link filterRecord download">
          <span class="icon-Bistro-_Download"></span>
          <span>Descargar Detalle</span>
        </a>
      </section>
    </article>

    <!-- LISTADO DE PRODUCTOS -->
    <article :class="{ errorLoading: data.cashBoxItems && data.cashBoxItems.length === 0 }"
      class="mainEvents salesDashboard">
      <!-- TIPO DE TRANSACCION -->
      <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
        <div class="containTransaction">
          <ul class="containTransaction-lists">
            <!-- Titulo -->
            <SwitchToggle mode="list" v-model="transactionTypeFilters" label="Tipos de Transacción" :title="true" />

            <!-- OPCION 1 -->
            <SwitchToggle mode="list" v-model="deposit" label="Depósito" />

            <!-- OPCION 2 -->
            <SwitchToggle mode="list" v-model="collect" label="Cobro" />

            <!-- OPCION 3 -->
            <SwitchToggle mode="list" v-model="audit" label="Ajuste" />

            <!-- OPCION 4 -->
            <SwitchToggle mode="list" v-model="withdrawal" label="Retiro" />

            <!-- OPCION 5 -->
            <SwitchToggle mode="list" v-model="order" label="Comanda" />

            <!-- OPCION 6 -->
            <SwitchToggle mode="list" v-model="sale" label="Venta" />

            <!-- OPCION 7 -->
            <SwitchToggle mode="list" v-model="openCashbox" label="Apertura de Caja" />

            <!-- OPCION 8 -->
            <SwitchToggle mode="list" v-model="closeCashbox" label="Cierre de Caja" />

            <!-- OPCION 9 -->
            <SwitchToggle mode="list" v-model="openTurn" label="Apertura de Turno" />

            <!-- OPCION 10 -->
            <SwitchToggle mode="list" v-model="closeTurn" label="Cierre de Turno" />

            <!-- OPCION 11 -->
            <SwitchToggle mode="list" v-model="voidMovement" label="Anulación" />

            <!-- OPCION 12 -->
            <SwitchToggle mode="list" v-model="billed" label="Anulación por facturación" />

            <!-- OPCION 13 -->
            <SwitchToggle mode="list" v-model="creditNote" label="Nota de crédito" />

            <!-- OPCION 14 -->
            <SwitchToggle mode="list" v-if="hasToShowTip" v-model="tip" label="Propina" />

          </ul>
        </div>
        <!-- BTN ACTIVE FILTER MOBILE -->
        <button @click="showFilter = !showFilter" class="btnFilterMobile">
          <span class="icon-Bistro-_Filter"></span>
        </button>
      </section>

      <!-- LISTADO DE MOVIMIENTOS DE CAJA -->
      <SkeletonLoading v-if="componentLoading"/>
      <ul v-else-if="data.cashBoxItems && data.cashBoxItems.length > 0" class="mainEvents-history">
        <table-row v-for="(item, index) in data.cashBoxItems" target="boxV2" :modal="false" :isSaleV2="false"
          :data="item" :key="index" />
      </ul>

      <empty-element v-else-if="data.cashBoxItems && data.cashBoxItems.length === 0 && loaded " />

    </article>

    <div v-if="data.cashBoxItems && data.cashBoxItems.length > 0 && isMoreOfOnePage" class="base-pagination">
      <BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
        class="list__pagination" @nextPage="pageChangeHandle('next')" @previousPage="pageChangeHandle('previous')"
        @loadPage="pageChangeHandle" />
    </div>
    <help-center />
  </main>
</template>

<script>
import axios from "axios";
import mixCommons from "../mixins/mixCommons";
import rolesCommons from "../mixins/rolesCommons";
import authCommons from "../mixins/authCommons";
import computeds from "../mixins/menuOptions";
import selectPdvs from "../mixins/select_pdvs";
import bistroV2Commons from "../mixins/bistroV2Commons";
import TableRow from "../components/TableRow";
import EmptyElement from "../components/EmptyElement";
import { parseToQuery } from "../utils/utils";
import BasePagination from "../components/BasePagination";
import HelpCenter from "../components/HelpCenter";
import mixBoxCommons from '@/mixins/mixBoxCommons.vue';
import SwitchToggle from "@/components/SwitchToggle.vue";
import SkeletonLoading from '@/components/SkeletonLoading.vue';

export default {
  mixins: [authCommons, mixCommons, computeds, selectPdvs, rolesCommons, bistroV2Commons, mixBoxCommons],
  data() {
    return {
      data: {},
      filterInterval: null,
      transactionTypeFilters: true,
      deposit: true,
      collect: true,
      showFilter: false,
      loaded: false,
      audit: true,
      withdrawal: true,
      order: true,
      sale: true,
      openCashbox: true,
      closeCashbox: true,
      openTurn: true,
      closeTurn: true,
      voidMovement: true,
      billed: true,
      creditNote: true,
      tip: true,
      excelParams: '',
      params: {
        HasToFilterByDeposits: 'SI',
        HasToFilterByCollects: 'SI',
        HasToFilterByAudits: 'SI',
        HasToFilterByWithdrawals: 'SI',
        HasToFilterByOrders: 'SI',
        HasToFilterBySales: 'SI',
        HasToFilterByOpenCashbox: 'SI',
        HasToFilterByCloseCashbox: 'SI',
        HasToFilterByOpenTurn: 'SI',
        HasToFilterByCloseTurn: 'SI',
        HasToFilterByVoidMovement: 'SI',
        HasToFilterByBilled: 'SI',
        HasToFilterByCreditNote: 'SI',
        HasToFilterByTip: 'SI'
      },
      currentPage: 1,
      countPerPage: 50,
    }
  },
  async mounted() {
    document.title = `${this.appTitle}  - Caja`;
    if (this.isUserLoggedIn() === false) {
      this.logout();
    } else if (this.hasToShowThisOption(5) === false) {
      this.handleUnauthorizedAccess()
    } else {
      if (await this.checkSessionInBackendAsync()) {
        this.onMounted()
      }
    }
  },
  computed: {
    hasToShowTip() {
      if (process.env.VUE_APP_INSTANCE_CODE == "AR") {
        return false
      } else {
        return true
      }
    },
    isMoreOfOnePage() {
      return (this.data.totalCount - this.countPerPage) > 0
    },
  },
  methods: {
    setAllTransactionTypes(bool) {
      this.deposit = bool
      this.collect = bool
      this.audit = bool
      this.withdrawal = bool
      this.order = bool
      this.sale = bool
      this.openCashbox = bool
      this.closeCashbox = bool
      this.openTurn = bool
      this.closeTurn = bool
      this.voidMovement = bool
      this.billed = bool
      this.creditNote = bool
      this.tip = bool
    },
    onMounted() {
      this.$store.setHasMounted(false)
      this.$store.updateSideMenu(-1);
      this.$store.updateTopMenu(5);
      this.$store.hideElements({});
      if (this.getPeriodSelectionFromLocalStorage() !== null) {
        this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
        this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
      } else {
        this.$store.initTabs()
      }
      this.toggleMerchantSelectorByLabel('showMerchantSelectionFilter')
      this.$store.CALL_MERCHANTS();
      this.params['CurrentPage'] = this.currentPage
      this.params['CountPerPage'] = this.countPerPage
      this.$store.updateCalendarType('range');
      this.$store.setHasMounted(true)
    },
    addExcelParams() {
      let p = this.params['CurrentPage']
      this.params['CurrentPage'] = null
      this.excelParams = parseToQuery(this.params)
      this.params['CurrentPage'] = p
    },
    getData() {
      if (!this.$store.hasMounted) return
      this.addExcelParams()
      clearInterval(this.filterInterval)
      this.filterInterval = setInterval(() => {
        clearInterval(this.filterInterval)
        this.$store.initLoading(this);
        axios.get(`/api/boxV2/?${parseToQuery(this.params)}`)
          .then(res => {
            this.data = res.data
            this.$store.finishLoading(this);
            this.loaded = true
          })
          .catch(err => {
            this.$store.errorLoading(this);
            this.showErrors(err.response)
          })
      }, 1000)

    }
  },
  components: {
    TableRow,
    EmptyElement,
    BasePagination,
    HelpCenter,
    SwitchToggle,
    SkeletonLoading
  },
};
</script>
