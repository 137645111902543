<template>
  <div
    id="modalCombo"
    class="modal modal-block-outside"
    style="overflow-y: auto !important"
    @keydown.esc="closeModal()"
  >
    <div class="modal-header">
      <div v-if="combo.image" class="image-cover">
        <div class="image-title">
          <h4>{{ getTitle }}</h4>
          <p>Fácil y Simple</p>
        </div>

        <img class="object-fit_cover" :src="combo.image" />
      </div>

      <div v-else class="image-title">
        <h4>{{ getTitle }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a
          href="#!"
          @click="closeModal()"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <label class="comboStepTitle">Datos del Combo</label>
        <article class="formMain">
          <div class="itemForm">
            <label class="label" for>Nombre del Combo:</label>
            <input
              class="input"
              v-model.trim="/* eslint-disable */ combo.name /* eslint-enable */"
              type="text"
              placeholder="Ingrese nombre del combo"
            />
          </div>

          <div class="itemForm itemFormForMobile">
            <label for>SKU:</label>
            <input
              v-model.trim="/* eslint-disable */ combo.sku /* eslint-enable */"
              type="text"
              maxlength="14"
              placeholder="SKU"
            />
          </div>

          <div class="itemForm">
            <label for>Categoría:</label>
            <select
              v-if="orderedCategories != null"
              v-model="
                /* eslint-disable */ combo.department /* eslint-enable */
              "
              class="browser-default"
            >
              <option value disabled selected>Seleccione Categoría</option>
              <option
                v-for="category in orderedCategories"
                :value="category.name"
                :key="category.name"
              >
                {{ category.name }}
              </option>
            </select>
          </div>

          <div class="itemForm itemFormForMobile">
            <vat-selector
              :rootVat="combo.rootVat"
              :exempt="combo.exempt"
              :nonTaxed="combo.nonTaxed"
              @onVatChanged="onVatChanged"
            />
            <!-- <label for>IVA:</label>
						<select v-model="combo.rootVat" class="browser-default">
							<option v-for="iva in getTaxValues()" :value="iva" :key="iva">
								{{ iva }} %
							</option>
						</select> -->
          </div>

          <div class="itemForm">
            <label for>Descripción del Combo:</label>
            <textarea
              v-model.trim="
                /* eslint-disable */ combo.description /* eslint-enable */
              "
              placeholder="Ingrese descripción del combo"
            />
          </div>

          <div
            v-if="isImageLoading"
            class="itemForm selectImage beforeAdditional imageSpinner"
          >
            <SpinnerComp />
          </div>
          <div v-else class="itemForm selectImage beforeAdditional">
            <label class="selectImage-label" for>Imagen:</label>
            <input type="file" ref="fileupload" @change="onFileSelected" />
            <a
              v-if="combo.image"
              class="selectImage-trashBin"
              @click="deleteImage"
            >
              <span class="icon-Bistro-_Trash_bin"></span>
            </a>
          </div>
        </article>

        <label class="comboStepTitle">Pasos del Combo</label>
        <div v-if="hasSectionsDefined">
          <div v-for="(section, index) in getSections" :key="index">
            <h4 class="comboStepHeader">
              <span class="comboStepNumber">#{{ section.index }}</span>
              {{ section.content.name }}
            </h4>
            <img
              class="deleteSectionFromComboStep"
              alt="Eliminar"
              title="Eliminar"
              @click="onDeleteSection(section.index)"
              src="@/assets/img/delete.svg"
              width="20px"
              height="20px"
            />
            <article class="formMain formMainCombo">
              <div class="itemForm">
                <label class="label" for>Nombre del Paso:</label>
                <input
                  class="input"
                  v-model.trim="section.content.name"
                  type="text"
                  placeholder="Ingrese nombre del paso"
                />
              </div>
              <div class="itemFormShortName">
                <label class="label" for>Cant. mín. de items:</label>
                <input
                  class="input"
                  v-model="section.content.minQty"
                  type="number"
                  placeholder="0" 
                  @input="validateSection(section.index)"
                  :class="{ 'input-error': errors[section.index]?.minQty }"
                  />
              </div>
              <div class="itemFormShortName">
                <label class="label" for>Cant. máx. de items:</label>
                <input
                  class="input"
                  v-model="section.content.maxQty"
                  type="number"
                  placeholder="1" 
                  @input="validateSection(section.index)"
                  :class="{ 'input-error': errors[section.index]?.maxQty }"
                  />
              </div>
              <!-- Mensaje de error único para la sección -->
              <div class="error-container">            
                <div></div> <!-- Primera celda vacía -->
                <div class="error-message-container">
                  <p v-if="errors[section.index]?.general" class="error-message">
                    {{ errors[section.index].general }}
                  </p>
                </div>
              </div>
            </article>
            <product-selector
              :productsByMenu="productsByMenu"
              :allProducts="plainProducts"
              :productsSelected="getProducts(section.content.products)"
              :title="`Productos en paso ${section.content.name}`"
              :containerRef="`${section.index}`"
              :selectAllProductsMessage="selectAllProductsMessage"
              @onDeleteProduct="onDeleteProduct"
              @onSelectProduct="onSelectProduct"
            />
          </div>
        </div>
        <div v-else>
          <div>No hay pasos definidos para el combo</div>
        </div>
        <a class="addNewSectionInComboLink" @click="addNewSection"
          >+ Agregar nuevo paso al combo</a
        >
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a
          v-if="isNewCombo === false"
          class="deleteProduct"
          @click.prevent="deleteCombo"
        >
          <span class="icon-Bistro-_Trash_bin"></span
          ><span>Eliminar Combo</span>
        </a>
        <SpinnerComp v-if="isLoading" />
        <a v-else @click="upsertCombo" type="submit">
          <p v-if="isNewCombo">{{ buttonAction }}</p>
          <p v-else>{{ buttonUpdate }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import SpinnerComp from "../components/SpinnerComp";
import currencyCommons from "../mixins/currencyCommons.vue";
import mixCommons from "../mixins/mixCommons";
import pictureCommons from "../mixins/pictureCommons";
import ProductSelector from "../components/ProductSelector";
import mixTester from "../mixins/mixTester";
//import CurrencyStyle from "../components/CurrencyStyle";
// import * as firebase from "firebase/app";
// import "firebase/storage";
import { db } from "../firebase";
import { ref, get, set } from "firebase/database";
import $ from "jquery";
import VatSelector from "../components/VatSelector";
import { toast } from "vue3-toastify";


export default {
  /* eslint-disable */
  mixins: [mixTester, currencyCommons, mixCommons, pictureCommons],
  props: {
    combo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    productsByMenu: {
      type: Array,
      default: () => [],
    },
    selectedList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    orderedCategories: {
      type: Array,
      default: () => {
        return null;
      },
    },
    isNewCombo: {
      type: Boolean,
    },
    currentSku: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      modalTitle: "Nuevo Combo",
      modalAction: "¡Creado con éxito!",
      buttonAction: "Crear Combo",
      buttonUpdate: "Guardar Combo",
      selectedFile: null,
      isLoading: false,
      isImageLoading: false,
      sectionIndex: 1,
      plainProducts: [],
      sections: [],
      arrayIvaForDefault: ["10.50", "21.00"],
      selectAllProductsMessage:
        "Genial! Ya tenés todos los productos asignados en este paso",
      savingControl: false,
      deletingControlCombo: false,
      errors: {}
    };
  },
  watch: {
    combo() {
      this.sections = [];
      this.sectionIndex = 1;
      if (this.combo.sections !== undefined) {
        this.combo.sections.forEach((x) => {
          this.sections.push({
            index: this.sectionIndex,
            content: x,
          });
          this.sectionIndex++;
        });
      }
    },
    productsByMenu() {
      this.plainProducts = [];
      this.productsByMenu.forEach((x) => {
        x.products.forEach((y) => {
          if (y.isCombo === false) {
            this.plainProducts.push(y);
          }
        });
      });

      this.plainProducts = this.plainProducts.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },
  computed: {
    getSections() {
      return this.sections;
    },
    hasSectionsDefined() {
      if (this.sections === undefined || this.sections === null) {
        return false;
      } else {
        return this.sections.length > 0;
      }
    },
    getTitle() {
      if (this.isNewCombo) {
        return "Crear Combo";
      } else {
        return "Editar Combo";
      }
    },
  },
  methods: {

    
      getProducts(skus) {
        if (skus !== undefined) {
          return this.plainProducts.filter((x) => skus.includes(x.sku));
        } else {
          return null;
        }
      },
      deleteImage() {
        this.combo.image = null;
      },
      getTaxValues() {
        if (
          this.$store.authUser.taxValues === null ||
          this.$store.authUser.taxValues === undefined
        ) {
          return this.arrayIvaForDefault;
        }

        return this.$store.authUser.taxValues.split(",");
      },
      closeModal() {
        //$("#__layout").css("pointer-events", "all");
      },
      addNewSection() {
        this.sections.push({
          index: this.sectionIndex,
          content: {
          name: "",
          maxQty: 1,
          minQty: 0,
          products: [],
        },
      });

      this.sectionIndex++;
    },
    deleteCombo() {
      this.deletingControlCombo = true;
      this.$emit("deleteCombo");
      const here = this;
      setTimeout(function () {
        here.savingControl = false;
      }, 2000);
    },
    onSelectProduct(event, containerRef) {
      let selectedSection = this.sections.filter(
        (x) => x.index == containerRef
      )[0];
      if (selectedSection.content.products === undefined) {
        selectedSection.content.products = [];
      }
      selectedSection.content.products.push(event.target.value);
    },
    onDeleteProduct(sku, containerRef) {
      let selectedSection = this.sections.filter(
        (x) => x.index == containerRef
      )[0];
      if (selectedSection.content.products !== undefined) {
        var index = selectedSection.content.products.indexOf(sku);
        if (index !== -1) {
          selectedSection.content.products.splice(index, 1);
        }
      }
    },
    onDeleteSection(containerRef) {
      this.sections = this.sections.filter((x) => x.index != containerRef);
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      const t = this;
      this.isImageLoading = true;
      if (!this.selectedFile) return;
      if (
        (this.selectedFile.name.endsWith(".jpeg") ||
          this.selectedFile.name.endsWith(".jpg") ||
          this.selectedFile.name.endsWith(".bmp") ||
          this.selectedFile.name.endsWith(".png")) === false
      ) {
        this.$store.errorLoading(this);
        this.isImageLoading = false;
        this.showError(`Las imágenes deben ser .jpg, .jpeg, .bmp o .png`);
        return;
      }
      const filesize = (this.selectedFile.size / 1024 / 1024).toFixed(4); // MB
      if (filesize > 2) {
        // 2MB
        this.showError(
          `El tamaño de la foto no puede superar los 2MB. La foto que subiste tiene ${filesize}MB`
        );
        return;
      }

      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = this.selectedFile)) {
        img = new Image();
        var objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
          _URL.revokeObjectURL(objectUrl);

          if (this.width > t.maxWidth) {
            t.showError(
              `El ancho de la imagen es de ${this.width}px. No puede superar los ${t.maxWidth}px`
            );
            t.$store.errorLoading(t);
            t.isImageLoading = false;
            return;
          }

          if (this.height > t.maxHeight) {
            t.showError(
              `El alto de la imagen es de ${this.height}px. No puede superar los ${t.maxHeight}px`
            );
            t.$store.errorLoading(t);
            t.isImageLoading = false;
            return;
          }
          t.saveMediaInCloudStorage(
            t,
            `images/${t.selectedList.id}/${t.selectedFile.name}`,
            (url) => {
              t.combo.image = url;
              t.isImageLoading = false;
            }
          );
        };
        img.src = objectUrl;
        $("#__layout").css("pointer-events", "all");
      }
    },
    upsertCombo() {
      this.deletingControlCombo = false;
      this.isLoading = true;
      const t = this;
      
      if (!this.allSectionsHaveValidQuantities()) {
        this.isLoading = false;
        return;
      }

      let upsertProductHandler = setInterval(() => {
        clearInterval(upsertProductHandler);
        try {
          if (t.combo.name === "") {
            toast.error("El nombre del combo es obligatorio");
          } else if (t.combo.department === "") {
            toast.error("La categoría del combo es obligatoria");
          } else if (t.combo.rootVat === "") {
            toast.error("El IVA del combo es obligatorio");
          } else if (t.sections.length === 0) {
            toast.error("El combo debe poseer un paso como mínimo");
          } else if (t.allSectionsHaveName(t.sections) === false) {
            toast.error("Todos los pasos del combo deben poseer un nombre");
          } else if (t.allSectionsHaveProducts(t.sections) === false) {
            toast.error(
              "Todos los pasos del combo deben poseer un producto como mínimo"
            );
          } else if (t.allSectionsHaveDifferentNames(t.sections) === false) {
            toast.error(
              "Todos los pasos del combo deben tener nombres diferentes"
            );
            
          } 
          else {

            if (t.combo.sku === "") {

              const currentData = ref(
                db,
                `listProducts/${t.selectedList.id}/nextIncrementForSku`
              );
              let incrementCount = 0;
              get(currentData).then((snapshot) => {
                let nextIncrementForSku = snapshot.val();
                if (
                  nextIncrementForSku !== undefined &&
                  nextIncrementForSku !== null
                ) {
                  t.combo.sku = `10${
                    t.selectedList.shopCode + nextIncrementForSku.toString()
                  }`;
                  nextIncrementForSku += 1;
                } else {
                  t.combo.sku = `10${t.selectedList.shopCode}0`;
                  nextIncrementForSku = 1;
                }
                const setLP = ref(
                  db,
                  `listProducts/${t.selectedList.id}/nextIncrementForSku`
                );

                if (incrementCount === 0) {
                  incrementCount++;
                  set(setLP, nextIncrementForSku)
                    .then(() => {
                      t.saveCombo();
                    })
                    .catch((error) => {
                      console.error("Error updating product:", error);
                    });
                }
              });
            } else {
              t.saveCombo();
            }
          }
        } finally {
          t.isLoading = false;
        }
      }, 500);
    },
    allSectionsHaveName(sections) {
      let isValid = true;

      sections.map((x) => {
        if (
          x.content.name === null ||
          x.content.name === undefined ||
          x.content.name === ""
        ) {
          isValid = false;
        }
      });

      return isValid;
    },
    allSectionsHaveProducts(sections) {
      let isValid = true;

      sections.map((x) => {
        if (x.content.products.length === 0) {
          isValid = false;
        }
      });

      return isValid;
    },
    allSectionsHaveDifferentNames(sections) {
      const groupBySectionName = sections.reduce((result, item) => {
        const sectionName = item.content.name.trim();
        if (!result[sectionName]) {
          result[sectionName] = [];
        }
        result[sectionName].push(item);
        return result;
      }, {});

      const sectionsWithEqualNames = Object.values(groupBySectionName).filter(
        (group) => group.length > 1
      );

      return sectionsWithEqualNames.length === 0;
    },
    saveCombo() {
      this.checkSameSkuAlreadyExists(
        (combo) => {
          if (this.isNewCombo) {
            if (this.savingControl) return;
            toast.error(
              `Ya existe el combo ${combo.name} con el SKU ${combo.sku} en tu lista`
            );
          } else {
            if (this.savingControl) return;

            this.savingControl = true;

            if (!this.deletingControlCombo) {
              this.proceedSavingCombo(this.combo);
            }
          }
        },
        () => {
          if (this.savingControl) return;

          this.savingControl = true;

          if (!this.deletingControlCombo) {
            this.proceedSavingCombo(this.combo);
          }
        }
      );
    },
    proceedSavingCombo(combo) {
      combo.sections = [];
      this.sections.sort((a, b) => {
        return a.index - b.index;
      });
      this.sections.forEach((x) => {
        if (x.content.name !== "") {
          x.content.minQty = parseInt(x.content.minQty);
          x.content.maxQty = parseInt(x.content.maxQty);
          combo.sections.push(x.content);
        }
      });

      this.$emit("upsertCombo", combo);
      this.selectedFile = null;
      if (this.isNewCombo) {
        toast.success(`${combo.name} agregado exitosamente!`);
        this.savingControl = false;
      } else {
        toast.success(`${this.combo.name} guardado exitosamente!`);
        this.savingControl = false;
      }
    },
    checkSameSkuAlreadyExists(callbackExistantSku, callbackNotExistantSku) {
      const t = this;
      let limitSkuExists = 0;
      let quantitySkuExists = 0;
      let combo = null;
      const currentData = ref(
        db,
        "listProducts/" + t.selectedList.id + "/combos"
      );
      get(currentData).then((snapshot) => {
        const resp = snapshot.val();
        if (resp != null) {
          let combos = Object.entries(resp);
          combos.forEach((item) => {
            if (item[1].sku === this.combo.sku) {
              quantitySkuExists++;
              combo = item[1];
            }
          });

          if (this.currentSku === this.combo.sku) limitSkuExists++;

          if (quantitySkuExists > limitSkuExists) callbackExistantSku(combo);
          else callbackNotExistantSku();
        } else {
          callbackNotExistantSku();
        }
      });
    },
    onVatChanged(comboVat) {
      this.combo.rootVat = comboVat.rootVat;
      this.combo.exempt = comboVat.exempt;
      this.combo.nonTaxed = comboVat.nonTaxed;
    },

    validateSection(index) {
      if (!this.errors[index]) {
      this.errors[index] = {};
      }

      let section = this.sections.find((x) => x.index === index);
      let min = parseInt(section.content.minQty);
      let max = parseInt(section.content.maxQty);

      // Reset de errores previos
      this.errors[index].minQty = false;
      this.errors[index].maxQty = false;
      this.errors[index].general = null;

      // **Si alguno es negativo, marcar ambos y mostrar mensaje**
      if (min < 0 || max < 0) {
        this.errors[index].minQty = true;
        this.errors[index].maxQty = true;
        this.errors[index].general = "El valor no puede ser negativo.";
        return;
      }

      // **Si min es mayor que max, marcar ambos y mostrar mensaje**
      if (min > max) {
        this.errors[index].minQty = true;
        this.errors[index].maxQty = true;
        this.errors[index].general = "La cantidad mínima no puede ser mayor a la máxima.";
        return;
      }
    },
    
    allSectionsHaveValidQuantities() {
      this.errors = {}; // Reiniciar los errores
      let hasError = false;

      this.sections.forEach((section) => {
        this.validateSection(section.index); // Usar el método ya existente
        if (this.errors[section.index]?.minQty || this.errors[section.index]?.maxQty) {
          hasError = true;
        }
        });

        if (hasError) {
          toast.error("Corrige los errores antes de continuar.");
        }

        return !hasError;
    }

  
  },
  components: {
    //CurrencyStyle,
    ProductSelector,
    SpinnerComp,
    VatSelector,
  },
  /* eslint-enable */
};
</script>
<style lang="stylus" scoped>
@import "@/assets/css/modalCombo.styl"
</style>