<template>
    <main>
        <!-- FILTRADO DE LA SECCION -->
        <article class="mainFilter filterEvent">
            <!-- TITULO Y SEARCH -->
            <section class="mainFilter-title">
                <h1>Caja</h1>
            </section>
            <section class="mainFilter-filter">
                <div class="filterRecord tooltip items">
                    <div class="item"
                        :class="{ 'items-grey': cashBoxData.declaredAmount > 0 || cashboxInProgress.openAmount > 0 }">

                        <h4 :class="{ 'negative-numbers': cashBoxData.declaredAmount < 0 || cashBoxData.totalAmountOfCashboxMovements < 0}">
                            <CurrencyStyle v-if="!isCashBoxOpened"
                                :valor="$filters.currency(cashBoxData.openAmount ?? 0)" />
                            <CurrencyStyle v-else
                                :valor="$filters.currency(cashBoxData.totalAmountOfCashboxMovements)" />
                            <p >
                                Efectivo
                            </p>
                        </h4>
                        

                    </div>
                    <div class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else><CurrencyStyle :valor=" $filters.currency(cashBoxData.totalCard ?? 0)" /></h4>
                        
                        <p>Tarjeta</p>
                    </div>
                    <div v-if="!isSpainInstance && !isMexicoInstance" class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else><CurrencyStyle :valor=" $filters.currency(cashBoxData.totalQR ?? 0)" /></h4>

                        <p>QR</p>
                    </div>
                    <div class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else><CurrencyStyle :valor="  $filters.currency(cashBoxData.totalOnline ?? 0)" /></h4>

                        <p>Online</p>
                    </div>

                </div>
            </section>
        </article>



        <!-- LISTADO DE PRODUCTOS -->
        <article :class="{ errorLoading: data.cashBoxItems && data.cashBoxItems.length === 0 }" class="mainEvents ">

            <!-- TIPO DE TRANSACCION -->
            <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">

                <!-- BOTONES DE ACCION PARA CERRAR CAJA  -->
                <section class="containButtons">

                    <button @click="showConfirmationCashBox()"  class="btn-option main tooltip" :class="{ 'disabled': !isMerchantEnabled }"
                         type="button" 
                         :disabled="!isMerchantEnabled">
                         <p>{{ isCashBoxOpened ? 'Cerrar caja' : 'Abrir caja' }}</p>
                         <span v-if="!isMerchantEnabled" class="tooltiptext">No tienes comercios habilitados</span>

                    </button>

                    <button @click="withdrawFunds()" class="btn-option" :class="{ 'disabled-option': !isCashBoxOpened ||  !isMerchantEnabled }"
                        type="button">
                        <p>Retirar</p>
                    </button>

                    <button @click="depositFunds()" class="btn-option" :class="{ 'disabled-option': !isCashBoxOpened ||  !isMerchantEnabled}"
                        type="button">
                        <p>Depositar</p>
                    </button>

                    <button @click="printPartialReport()" class="btn-option"
                        :class="{ 'disabled-option': !isCashBoxOpened || !isMerchantEnabled }" type="button">
                        <p>Imprimir parcial</p>
                    </button>
                </section>
                <div class="containTransaction">
                    <ul class="containTransaction-lists">
                        <!-- Titulo -->
                        <SwitchToggle mode="list" v-model="transactionTypeFilters" label="Tipos de Transacción"
                            :title="true" />

                        <!-- OPCION 1 -->
                        <SwitchToggle mode="list" v-model="deposit" label="Depósito" />

                        <!-- OPCION 2 -->
                        <SwitchToggle mode="list" v-model="collect" label="Cobro" />

                        <!-- OPCION 3 -->
                        <SwitchToggle mode="list" v-model="audit" label="Ajuste" />

                        <!-- OPCION 4 -->
                        <SwitchToggle mode="list" v-model="withdrawal" label="Retiro" />

                        <!-- OPCION 5 -->
                        <SwitchToggle mode="list" v-model="order" label="Comanda" />

                        <!-- OPCION 6 -->
                        <SwitchToggle v-if="isArgentinaInstance" mode="list" v-model="sale" label="Venta" />

                        <!-- OPCION 7 -->
                        <SwitchToggle mode="list" v-model="openCashbox" label="Apertura de Caja" />

                        <!-- OPCION 8 -->
                        <SwitchToggle mode="list" v-model="closeCashbox" label="Cierre de Caja" />

                        <!-- OPCION 9 -->
                        <!-- <SwitchToggle mode="list" v-model="openShift" label="Apertura de Turno" /> -->

                        <!-- OPCION 10 -->
                        <!-- <SwitchToggle mode="list" v-model="closeShift" label="Cierre de Turno" /> -->

                        <!-- OPCION 11 -->
                        <SwitchToggle mode="list" v-model="voidMovement" label="Anulación" />

                        <!-- OPCION 12 -->
                        <SwitchToggle v-if="isArgentinaInstance" mode="list" v-model="billed" label="Anulación por facturación" />

                        <!-- OPCION 13 -->
                        <SwitchToggle v-if="isArgentinaInstance" mode="list" v-model="creditNote" label="Nota de crédito" />

                        <!-- OPCION 14 -->
                        <!-- <SwitchToggle mode="list" v-if="hasToShowTip" v-model="tip" label="Propina" /> -->

                    </ul>
                </div>
                <!-- BTN ACTIVE FILTER MOBILE -->
                <button @click="showFilter = !showFilter" class="btnFilterMobile">
                    <span class="icon-Bistro-_Filter"></span>
                </button>
            </section>

            <!-- LISTADO DE MOVIMIENTOS DE CAJA -->
            <SkeletonLoading v-if="componentLoading" />

            <ul v-else-if="data.cashBoxItems && data.cashBoxItems.length > 0" class="mainEvents-history">
                <table-row v-for="(item, index) in data.cashBoxItems" target="boxV2" :modal="false" :isSaleV2="false"
                    :data="item" :key="index" />
            </ul>

            <empty-element v-else-if="data.cashBoxItems?.length === 0 && loaded" />
        </article>

        <div v-if="data.cashBoxItems && data.cashBoxItems.length > 0 && isMoreOfOnePage()" class="base-pagination">
            <BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
                class="list__pagination" @nextPage="pageChangeHandle('next')"
                @previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
        </div>
        <help-center />
        <ModalUnauthorized />

        <ModalBoxPos @onConfirmOpenAmount="handleOpenAmount" :modalOption="'modalOpenBox'" operation="open" />
        <ModalBoxPos @onConfirmDepositAmount="handleDepositAmount" :modalOption="'modalDepositBox'"
            operation="deposit" />
        <ModalBoxPos @onConfirmWithdrawal="handleWithdrawalAmount" :modalOption="'modalWithdrawalBox'"
            operation="withdrawal" />
        <ModalBoxPos @onConfirmCloseAmount="handleCloseAmount" :modalOption="'modalCloseBox'" operation="close" />
    </main>
</template>

<script>
import mixCommons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import authCommons from "@/mixins/authCommons";
import computeds from "@/mixins/menuOptions";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import TableRow from "@/components/TableRow";
import EmptyElement from "@/components/EmptyElement";
import BasePagination from "@/components/BasePagination";
import HelpCenter from "@/components/HelpCenter";
import ModalBoxPos from "@/features/pdvBox/components/ModalBoxPos.vue";
import MixModals from "@/mixins/mixModals.vue";
import CurrencyStyle from "@/components/CurrencyStyle.vue";
import mixBoxCommons from '@/mixins/mixBoxCommons.vue';
import boxPosCommons from '../mixins/BoxPosCommons.vue';
import ShopCommons from "@/features/shared/mixins/shopCommons.vue";
import { syncCashboxes } from "../../shared/repositories/orders/ordersService.js"
import { formatDate } from "@/features/shared/utils/utils";
import { ASYNC_STATUS, ORDER_TYPE } from "@/features/shared/utils/orderStatuses";
import cashboxesIndexDB from "@/features/shared/repositories/cashboxes/cashboxesIndexedDBRepository";
import { syncCashboxReports, createObjectCloseCashbox } from '@/features/shared/repositories/closeCashboxes/closeCashboxesService'
import SwitchToggle from "@/components/SwitchToggle.vue";
import ticketCommons from "../../pdv/mixins/ticketCommons.vue";
import printerCommons from "../../shared/mixins/printerCommons.vue";
import * as cashboxesService from "../../shared/repositories/cashboxes/cashboxesService"
import SkeletonLoading from '@/components/SkeletonLoading.vue';
import datetimeCommons from "@/features/pdv/mixins/datetimeCommons.vue";
import { checkAndroidIsDefined } from "@/features/shared/utils/utils";
import ModalUnauthorized from "@/features/pdv/components/modals/ModalUnauthorized.vue";
import instanceCommons from "@/mixins/instanceCommons.vue";


export default {
    mixins: [authCommons, printerCommons, datetimeCommons, ticketCommons, mixCommons, computeds,
            rolesCommons, bistroV2Commons, MixModals, mixBoxCommons, ShopCommons, boxPosCommons,
            instanceCommons
        ],
    data() {
        return {
            data: {
                cashBoxItems: [],
            },
            filterInterval: null,
            transactionTypeFilters: true,
            deposit: true,
            collect: true,
            showFilter: false,
            loaded: false,
            isCashBoxOpened: false,
            audit: true,
            withdrawal: true,
            order: true,
            sale: true,
            openCashbox: true,
            closeCashbox: true,
            // openShift: true,
            // closeShift: true,
            voidMovement: true,
            billed: true,
            creditNote: true,
            // tip: true,
            excelParams: '',
            previousCashBox: false,
            params: {
                HasToFilterByDeposits: 'SI',
                HasToFilterByCollects: 'SI',
                HasToFilterByAudits: 'SI',
                HasToFilterByWithdrawals: 'SI',
                HasToFilterByOrders: 'SI',
                HasToFilterBySales: 'SI',
                HasToFilterByOpenCashbox: 'SI',
                HasToFilterByCloseCashbox: 'SI',
                // HasToFilterByOpenShift: 'SI',
                // HasToFilterByCloseShift: 'SI',
                HasToFilterByVoidMovement: 'SI',
                HasToFilterByBilled: 'SI',
                HasToFilterByCreditNote: 'SI',
                // HasToFilterByTip: 'SI'
            },
            currentPage: 1,
            countPerPage: 50,
            showModalBox: false,
            cashBoxData: {
                openDate: "", // Fecha de apertura de la caja
                closeDate: null, // Fecha de cierre de la caja (nulleable)
                openAmount: 0, // Monto al abrir
                closeAmount: 0, // Monto al cerrar (nulleable)
                closeBox: false,
                lastCloseAmount: 0,
            },
            totalCash: 0,
            totalQR: 0,
            totalCard: 0,
            totalOnline: 0,
            localData: [], // Aquí estarán los datos originales de IndexedDB
        }
    },
    async mounted() {
        document.title = `${this.appTitle}  - Caja`;
        
        if (this.isUserLoggedIn() === false) {
            this.logout();
        } else if (navigator.onLine && !await this.checkSessionInBackendAsync()) {
            this.logout();
        } else if (navigator.onLine && !this.hasPosAdminRole) {
            this.handleUnauthorizedAccess()
        } else {
            this.onMounted()
        }
    },
    watch: {
        async shopCode() {
            this.resetCashBoxData();
            await this.getContextFromPDV(this.shopCode)
            await this.getDataCashBox()
            await syncCashboxReports(this.shopCode, this)
        }
    },
    computed: {
        lastUpdateCashbox() {
            const cashBoxData = this.listOfBoxClosures ? JSON.parse(this.listOfBoxClosures) : null;
            if (!cashBoxData) return null
            return cashBoxData[cashBoxData.length - 1];
        },
        listOfBoxClosures() {
            return localStorage.getItem(`cashBox_${this.shopCode}`);
        },
        // hasToShowTip() {
        //     if (process.env.VUE_APP_INSTANCE_CODE == "AR") {
        //         return false
        //     } else {
        //         return true
        //     }
        // },

    },
    methods: {
        async syncCashboxReport() {
            const [cashBox] = this.$store.getCashBox

            await createObjectCloseCashbox(this.shopCode, cashBox);

            await syncCashboxReports(this.shopCode, this)
        },
        resetCashBoxData() {
            this.cashBoxData = {
                openDate: "",
                closeDate: null,
                openAmount: 0,
                closeAmount: 0,
                closeBox: false
            };
            this.isCashBoxOpened = false;
        },
        saveDataInLocalStorage(cashBox) {
            localStorage.setItem(`cashBox_${this.shopCode}`, JSON.stringify(cashBox));
        },

        async handleWithdrawalAmount(amount, reason, remarks) {
            const cashbox = this.createCashbox({
                amount: amount * (-1),
                comments: `${reason ?? ''} ${remarks ?? ''}`,
                movementType: 'RETIRO',
            });
            this.$store.initLoading(this);
            await cashboxesIndexDB.insertCashbox(this.shopCode, cashbox);
            await syncCashboxes(this.shopCode, true);
            this.$store.finishLoading(this);

            await this.getTotals()
            this.getData();
        },
        async handleOpenAmount(declaredAmount, differenceAmount) {
            let cashBoxLocalStorage
            if(this.listOfBoxClosures){
                cashBoxLocalStorage = JSON.parse(this.listOfBoxClosures)[0]
            }
            const lastAmount = this.cashBoxData.openAmount
            this.resetCashBoxData();

            // Inicializar `cashBoxData` con los datos de apertura
            this.cashBoxData = {
                ...this.cashBoxData,
                openDate: formatDate(),
                declaredAmount: declaredAmount,
                lastCloseAmount: lastAmount,
                openTimeStampTick: Date.now(),
                closeTimeStampTick: null,
                lastOpenTimeStampTick: cashBoxLocalStorage?.openTimeStampTick ?? null,
                lastCloseTimeStampTick: cashBoxLocalStorage?.closeTimeStampTick ?? null
            };
            const existingData = this.listOfBoxClosures;
            let listCashBoxData = Array.isArray(existingData) ? existingData : [];

            try {
                if (listCashBoxData.length === 0) {
                    listCashBoxData.push(this.cashBoxData);
                    this.saveDataInLocalStorage(listCashBoxData);
                }

                this.$store.setCashBox(this.cashBoxData);
                const cashbox = this.createCashbox({
                    amount: this.cashBoxData.declaredAmount - differenceAmount,
                    comments: 'APERTURA DE CAJA',
                    movementType: 'APERTURA DE CAJA',
                });
                this.$store.initLoading(this);

                await cashboxesIndexDB.insertCashbox(this.shopCode, cashbox);

                if (differenceAmount != 0) {
                    const cashboxDifference = this.createCashbox({
                        amount: differenceAmount,
                        comments: `DIFERENCIA ENCONTRADA ${this.$filters.currency(differenceAmount)}`,
                        movementType: 'AJUSTE EN APERTURA DE CAJA',
                    });
                    await cashboxesIndexDB.insertCashbox(this.shopCode, cashboxDifference);
                }

                await syncCashboxes(this.shopCode, true);
                this.$store.finishLoading(this);
                await this.getTotals()
                this.previousCashBox = false
                this.isCashBoxOpened = true;
                this.getData();
            } catch (error) {
                console.error("Error procesando datos de caja:", error);
            }
        },
        async handleDepositAmount(amount, remarks) {
            const cashbox = this.createCashbox({
                amount: amount,
                comments: remarks ?? '',
                movementType: 'DEPOSITO',
            });
            this.$store.initLoading(this);

            await cashboxesIndexDB.insertCashbox(this.shopCode, cashbox);
            await syncCashboxes(this.shopCode, true);
            this.$store.finishLoading(this);

            await this.getTotals()
            this.getData();
        },
        createCashbox(overrides = {}) {
            const baseCashbox = {
                amount: 0,
                asyncStatus: ASYNC_STATUS.PENDING,
                billNumber: '',
                customerId: null,
                externalReference: null,
                waiter: null,
                cashAccumAmount: 0,
                cashMovementId: 0,
                comments: '',
                date: formatDate(),
                ticketNumber: '',
                movementType: '',
                totalAccumDiscountPerProductAmount: 0,
                saleAuthCode: '',
                saleCaptureWay: '',
                saleId: 0,
                saleOrigin: null,
                salePaymentMethod: 'EFECTIVO',
                saleType: '',
                sequence: 1,
                totalAccumAmount: 0,
                totalDiscountAmount: 0,
                totalTaxesAmount: 0,
                totalVatAmount: 0,
                userName: this.$store.authUser?.user?.name,
                uuid: null,
                createdAt: Date.now(),
            };

            return { ...baseCashbox, ...overrides };
        },
        showConfirmationCashBox() {
            if (!this.isCashBoxOpened) {
                this.openNonDismissibleModal('#modalOpenBox')
            } else {
                this.openNonDismissibleModal('#modalCloseBox')
            }
        },
        depositFunds() {
            this.openNonDismissibleModal('#modalDepositBox')
        },
        withdrawFunds() {
            this.openNonDismissibleModal('#modalWithdrawalBox')
        },
        async printPartialReport() {
            await this.getTotals()
            this.$store.setCashBox(this.cashBoxData);

            await this.printInCounter(this.generateCashBoxTicket(true));

        },
        async handleCloseAmount(closeAmount, differenceAmount) {
            const cashboxLocal = { ...this.$store.getCashBox };
            cashboxLocal.closeAmount = closeAmount;
            cashboxLocal.closeDate = formatDate();
            this.cashBoxData.closeDate = cashboxLocal.closeDate;
            cashboxLocal.closeBox = true;
            cashboxLocal.closeTimeStampTick = Date.now()

            const list = this.listOfBoxClosures || "[]";
            let cashBoxData = JSON.parse(list);

            if (cashBoxData.length > 0) {
                cashBoxData[cashBoxData.length - 1] = cashboxLocal;
            } else {
                cashBoxData.push(cashboxLocal);
            }

            this.saveDataInLocalStorage(cashBoxData);

            this.$store.initLoading(this);

            this.$store.setCashBox(cashBoxData);
            if (differenceAmount != 0) {
                const cashboxDifference = this.createCashbox({
                    amount: differenceAmount,
                    comments: `DIFERENCIA ENCONTRADA ${this.$filters.currency(differenceAmount)}`,
                    movementType: 'AJUSTE EN CIERRE CAJA',
                });
                await cashboxesIndexDB.insertCashbox(this.shopCode, cashboxDifference);
            }
            const cashbox = this.createCashbox({
                amount: closeAmount - differenceAmount,
                comments: 'CIERRE DE CAJA',
                movementType: 'CIERRE DE CAJA',
            });
            await cashboxesIndexDB.insertCashbox(this.shopCode, cashbox);
            await this.getTotals()
            await this.printInCounter(this.generateCashBoxTicket());
            await syncCashboxes(this.shopCode, true);
            await this.syncCashboxReport()
            this.isCashBoxOpened = false;
            await this.getData()
            this.$store.finishLoading(this);
            this.closeModalById("#modalCloseBox");

        },
        setAllTransactionTypes(bool) {
            this.deposit = bool
            this.collect = bool
            this.audit = bool
            this.withdrawal = bool
            this.order = bool
            this.sale = bool
            this.openCashbox = bool
            this.closeCashbox = bool
            // this.openShift = bool
            // this.closeShift = bool
            this.voidMovement = bool
            this.billed = bool
            this.creditNote = bool
            // this.tip = bool
        },
        async onMounted() {
            this.$store.updateTopMenu(10);
            this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
            });
            this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");

            this.getListByShopCode()
            await this.getContextFromPDV(this.shopCode)
            this.checkMerchantStatus()
            this.$store.CALL_MERCHANTS();
            this.$store.setHasMounted(true)
            this.getDataCashBox()
        },
        async getDataCashBox() {
            if(!checkAndroidIsDefined(this.shopCode)) return
            try {
                let lastUpdate = this.lastUpdateCashbox

                //Si la caja NO ESTA abierta entra en este if
                if (!lastUpdate) {
                    lastUpdate = this.cashBoxData
                    this.previousCashBox = true

                }

                if (lastUpdate.closeBox) {
                    this.cashBoxData.openAmount = lastUpdate.closeAmount
                    this.previousCashBox = true

                }

                //Si la caja esta abierta entra en este if
                if (!lastUpdate.closeDate && lastUpdate.openDate) {
                    this.cashBoxData = lastUpdate
                    this.isCashBoxOpened = true
                    this.previousCashBox = false
                }


                await this.getTotals()
                this.getData()
                this.$store.setCashBox(this.cashBoxData)
                return;

            }
            catch (error) {
                console.error("Error al guardar los datos en localStorage:", error);
            }
        },
        async getTotals() {
            this.cashBoxData.totalCash = await cashboxesService.getCashboxTotalForCash(this.shopCode)
            this.cashBoxData.totalQR = await cashboxesService.getCashboxTotalForQR(this.shopCode)
            this.cashBoxData.totalOnline = await cashboxesService.getCashboxTotalForOnline(this.shopCode)
            this.cashBoxData.totalCard = await cashboxesService.getCashboxTotalForCard(this.shopCode)
            this.cashBoxData.totalAmountVoided = (await cashboxesService.getVoidsTuple(this.shopCode)).voidsAmount
            this.cashBoxData.totalOrderAmount = (await cashboxesService.getOrdersTuple(this.shopCode)).ordersAmount
            this.cashBoxData.totalOrders = await cashboxesService.getFilteredCashboxTotalOrders(this.shopCode)
            this.cashBoxData.totalWithdrawal = await cashboxesService.getFilteredCashboxTotalWithdrawal(this.shopCode)
            this.cashBoxData.totalAmountOfCashboxMovements = await cashboxesService.getTotalCashAmountFromCashboxMovements(this.shopCode)
            this.cashBoxData.totalDeposit = await cashboxesService.getFilteredCashboxTotalDeposit(this.shopCode)
            this.cashBoxData.totalVoided = (await cashboxesService.getVoidsTuple(this.shopCode)).voidsCount
            this.cashBoxData.auditsMovements = await cashboxesService.getCashboxAuditsMovements(this.shopCode)
            this.cashBoxData.totalSold = this.cashBoxData.totalOrderAmount + this.cashBoxData.totalAmountVoided

        },
        isMoreOfOnePage() {
            return (this.data.totalCount - this.countPerPage) > 0
        },
        getCashboxItemType(movement) {
            const movementMapping = {
                "RETIRO": 1,
                "AJUSTE": 2,
                "COMANDA": 3,
                "VENTA": 4,
                "APERTURA DE CAJA": 5,
                "CIERRE DE CAJA": 6,
                "APERTURA DE TURNO": 7,
                "CIERRE DE TURNO": 8,
                "VOID": 9,
                "ANULACIÓN POR FACTURACIÓN": 10,
                "PROPINA": 13,
                "DEPOSITO": 14,
                "AJUSTE EN APERTURA DE CAJA": 2,
                "AJUSTE EN CIERRE CAJA": 2
            };
            return movementMapping[movement]
        },
        getMovementIcon(movement) {
            switch (movement) {
                case 'QR':
                    return 3
                case 'EFECTIVO':
                    return 0
                case 'ONLINE':
                    return 10
                case 'TARJETA':
                    return 1
                default:
                    return 0
            }
        },
        async getData() {
            let indexDbData
            //si la caja esta abierta entra al if y trae los movimientos de caja en curso, el else trae caja anterior
            if(this.isCashBoxOpened) indexDbData = await cashboxesIndexDB.getMovementsCashbox(this.shopCode)
            else indexDbData = await cashboxesService.getMovementsPreviousCashBox(this.shopCode)

            // Transformar datos de IndexedDB para que coincidan con la respuesta del backend
            this.localData = indexDbData.map(obj => ({
                amount: obj.amount,
                cashBoxItemType: this.getCashboxItemType(obj.movementType),
                comments: obj.comments,
                date: this.convertTimestampToDate(obj.createdAt),
                hour: this.convertTimestampToHour(obj.createdAt),
                mt: obj.movementType,
                paymentInfo: obj.movementType == ORDER_TYPE.COMANDA|| obj.movementType == ORDER_TYPE.VOID
                    ? `#${obj.ticketNumber}${obj.saleAuthCode ? ' - Cod.Aut.: ' + obj.saleAuthCode : ''} - ${obj.salePaymentMethod} - ${obj.saleOrigin}`
                    : `${obj.salePaymentMethod}${obj.saleAuthCode ? ' - Cod.Aut.: ' + obj.saleAuthCode : ''} - ${obj.comments}`,
                paymentType: this.getMovementIcon(obj.salePaymentMethod
                ),
                timestamp: obj.date,
                userName: obj.userName
            }));
            this.applyLocalFilters();
            this.$store.setComponentLoading(false)

        },
        applyLocalFilters() {
         this.data.cashBoxItems = this.localData
            .filter(item => {
                if (this.params['HasToFilterByDeposits'] === 'SI' && item.mt === 'DEPOSITO') {
                    return true;
                }
                if (this.params['HasToFilterByWithdrawals'] === 'SI' && item.mt === 'RETIRO') {
                    return true;
                }
                if (this.params['HasToFilterByOrders'] === 'SI' && item.mt === ORDER_TYPE.COMANDA) {
                    return true;
                }
                if (this.params['HasToFilterByOpenCashbox'] === 'SI' && item.mt === 'APERTURA DE CAJA') {
                    return true;
                }
                if (this.params['HasToFilterByAudits'] === 'SI' && (item.mt === 'AJUSTE EN APERTURA DE CAJA' || item.mt === 'AJUSTE EN CIERRE CAJA')) {
                    return true;
                }
                if (this.params['HasToFilterByVoidMovement'] === 'SI' && item.mt === 'VOID') {
                    return true;
                }
                if (this.params['HasToFilterByCloseCashbox'] === 'SI' && item.mt === 'CIERRE DE CAJA') {
                    return true;
                }
                return false;
            })
             .reverse(); 
            this.data.totalCount = this.data.cashBoxItems.length

            // Calcular la paginación
            const startIndex = (this.currentPage - 1) * this.countPerPage;
            const endIndex = startIndex + this.countPerPage;

            // Aplicar la paginación a los datos filtrados
            this.data.cashBoxItems = this.data.cashBoxItems.slice(startIndex, endIndex);

            this.loaded = true;
        }

    },
    components: {
        TableRow,
        EmptyElement,
        BasePagination,
        HelpCenter,
        ModalBoxPos,
        CurrencyStyle,
        SwitchToggle,
        SkeletonLoading,
        ModalUnauthorized
    },
};
</script>

<style scoped lang="scss">
.errorLoading-mainError {
    display: flex;
    align-items: center;
    height: 70vh;

}

.btn-option {
    margin-bottom: 6px;
    font-size: 14px ;
    p{
        font-family: 'Rubik-Medium', sans-serif ;
    }
    &.disabled-option {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed !important;
    }
    &.main{
        background-color: #24a5d3 !important;
        color:white!important;
        &.disabled{
            opacity: 0.5;
        }
    }
}

.items-grey {
    color: #37c866;
}
</style>