<template>
	<div :id="modalName" class="modal modal-block-outside modal-setup-wizard" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
		<article class="header">
            <img class="img-wizard" src="https://pdssa-mailer-templates.s3.amazonaws.com/Header.png">
        </article>

        <article class="main">
			<div class="subtitle">
				<p>
					<strong>Hola! {{userName}}</strong><br>
					Ya estas más cerca de empezar a operar con tu Bistrosoft!
				</p>
			</div>


			<div class="body">
				<p>
					<ul>
						<li>
							1 - Necesitamos que cargues tus datos fiscales para que puedas emitir tus facturas desde Bistrosoft.
						</li>
						<li>
							<!-- https://bistrosoft.com/ayuda/facturacion-electronica-mexico/ -->
							
							2 - Te pedimos que te tomes 5 minutos y leas el instructivo que te dejamos en el siguiente <a target="_blank" :href="getKnowledgeBaseUrl"><strong>link</strong></a> para cargar tus datos.
						</li>
						<li>
							3 - Si tienes alguna duda deberás comunicarte con tu ejecutivo comercial y te ayudará.
						</li>
					</ul>
				</p>
			</div>


		</article>

        <article class="social">
            <p>Seguinos en:</p>
            <div>
                <a href="https://www.facebook.com/bistrosoft/" target="_blank">
                    <img src="https://pdssa-mailer-templates.s3.amazonaws.com/Facebook.png">
                </a>

                <a href="https://www.instagram.com/bistrosoft/" target="_blank">
                    <img src="https://pdssa-mailer-templates.s3.amazonaws.com/Instagram.png" style="margin: 0px 56px;">
                </a>

                <a href="https://www.linkedin.com/company/bistrosoft/?originalSubdomain=ar" target="_blank">
                    <img src="https://pdssa-mailer-templates.s3.amazonaws.com/Linkedin.png">
                </a>
            </div>
        </article>
		<article class="footer">
			<article class="btn-main">
				<a @click="onModalClosing()">
					<p>ENTENDIDO</p>
				</a>
			</article>
		</article>
	</div>
  </template>

  <script>
  import mixCommons from "../mixins/mixCommons";

  export default {
	mixins: [mixCommons],
	props: {
		shopCode: {
			type: String,
			default: () => {
				return ''
			}
		},
		modalName: {
			type: String,
			default: () => {
				return 'modalSetupWizard'
			}
		},
	},
	data () {
		return {
		}
	},
	watch: {
	},
	computed: {
		userName () {
			return this.$store.getAuthUser?.user?.name ?? JSON.parse(localStorage['currentUserData']).user.name;
		}
	},
	methods: {
		onModalClosing() {
			this.$emit('onModalClosing')
		},
		closeModal () {
			//$('#__layout').css('pointer-events', 'all')

			this.$emit('onWizardModalClosed')
		}
	},
	components: {
		
	}
  }
  </script>
