<template>
    <main class="pdv-main">
        <SidebarBistro>
            <div id="header_sidebar">
                <ButtonBistro variant="solid-gray" text="Regresar" :icon="require('@assets/arrow-left-button.svg')"
                    @handleClick="backClick" />
            </div>
            <div id="body_sidebar">
                <!-- /* PAGO PARCIAL */ -->
                <!-- <div v-if="partialPayment" class="box-partial-pay">
                        <p>Selecciona los ítems a cobrar</p>
                        <hr>
                        <div v-for="(prod, index) in order.products" :key="index" class="articleDebt-main-row">
                            <div class="product-info">
                                <input type="checkbox" />
                                <p>{{ prod.name }}</p>
                                <p class="product-price">
                                    {{ $filters.currency(Number(prod.originPrice) * prod.quantity) }}
                                </p>
                            </div>
                        </div>
                    </div> -->
                <!-- Checkout normal -->
                <div class="contain-button-checkout">
                    <ButtonPayBistro text="Efectivo" :isActive="isPaymentMethodActive('EFECTIVO')"
                        :icon="require('@assets/img/ic_efectivo.png')" @click="selectPaymentMethod('EFECTIVO')" />
                    <ButtonPayBistro text="Tarjeta" :isActive="isPaymentMethodActive('TARJETA')"
                        :icon="require('@assets/img/ic_tarjeta.png')"
                        @handleClick="selectPaymentMethod('TARJETA',{ captureWay: 'POS EXTERNO'})" />
                    <ButtonPayBistro text="Online" :isActive="isPaymentMethodActive('ONLINE')"
                        :icon="require('@assets/img/ic_online.png')"
                        @handleClick="selectPaymentMethod('ONLINE')" />
                    <ButtonPayBistro v-if="!isSpainInstance && !isMexicoInstance" text="QR" :isActive="isPaymentMethodActive('QR')"
                        :icon="require('@assets/img/ic_qrcode.png')"
                        @handleClick="selectPaymentMethod('QR')" />
                </div>
            </div>
        </SidebarBistro>

        <!-- Panel lateral de teclado numérico -->
        <ContentBistro>
            <div id="body_content_bistro">
                <div id="container_keyboard_sidebar">
                    <NumKeyboardBistro v-if="order?.amount" @handleChangeKeyboardInput="handleChangeKeyboardInput"
                        :initialValue="order.amount" />
                    <div id="container_buttons_sidebar">
                        <!-- <ButtonBistro variant="gradient-blue" text="Descuento"
                            @handleClick="handleChangeKeyboardButton(3)" /> -->
                        <!-- <ButtonBistro variant="solid-light-gray" text="Propina"
                            @handleClick="handleChangeKeyboardButton(2)" /> -->
                    </div>
                </div>
            </div>
        </ContentBistro>
        <ModalOrderPayChange :order="this.order" />
    </main>
</template>

<script>
import router from "@/router";
import shopCommons from "../../shared/mixins/shopCommons.vue";
import SidebarBistro from "../components/SidebarBistro.vue";
import ContentBistro from "../components/ContentBistro.vue";
import NumKeyboardBistro from "../components/NumKeyboardBistro.vue";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import ButtonPayBistro from "../components/ButtonPayBistro.vue";
import commons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import ModalOrderPayChange from "../components/modals/ModalOrderPayChange.vue";
import { getOrder } from "../../shared/repositories/orders/ordersService.js";
import clickCommons from "../mixins/clickCommons.vue";
import payCommons from "../mixins/payCommons.vue";
import authCommons from "@/mixins/authCommons.vue";
import instanceCommons from "@/mixins/instanceCommons.vue";

export default {
    name: "PdvOrderCheckout",
    mixins: [shopCommons, commons, rolesCommons, bistroV2Commons, clickCommons, payCommons, instanceCommons, authCommons],
    data() {
        return {
            order: null,
            PaymentMethod: null,
        };
    },
    async mounted() {
        document.title = `${this.appTitle}  - PDV`;
        this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
        })
        if (this.isUserLoggedIn() === false) {
            this.logout();
        } else if (navigator.onLine && !await this.checkSessionInBackendAsync()) {
            this.logout();
        } else if (navigator.onLine && !this.hasPosAdminRole) {
            this.handleUnauthorizedAccess()
        } else {
            this.onMounted()
        }
    },
    methods: {
        async onMounted() {
            this.$store.updateTopMenu(7);
            this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");
            const shopCode = this.$route.params['shopcode'];
            const orderId = this.$route.params['id'];
            this.$store.setRefreshPDVList(shopCode);
            this.order = await getOrder(shopCode, orderId);
            this.order.payments = [];
            this.$pdv.setShowDetails(true);
            this.$pdv.setOrder(this.order);
            this.$pdv.setIsExactPayment(true);
            this.selectPaymentMethod('EFECTIVO')

        },
        selectPaymentMethod(paymentWay, options = {}) {
            const payment = {
                paymentWay,
                captureWay: options.captureWay || null,
                authCode: null,
                amount: this.order.amount,
                receivedPaidAmount: this.order.amount,
                ...options
            };
            this.selectPaymentMethodAndPay(payment);
        },
        isPaymentMethodActive(method) {
            return this.order?.payments?.some(payment => payment.paymentWay == method) ?? false;
        },
        backClick() {
            router.push("/pdv/order/details/" + this.shopCode + "/" + this.order.id);
        },

        onSelectedProduct(index) {
            console.log(index)
        },
        handleChangeKeyboardInput(receivedPaidAmount) {
            this.$pdv.setTempReceivedAmount(receivedPaidAmount)

            if (this.order.amount != this.$pdv.getTempReceivedAmount) {
                this.$pdv.setIsExactPayment(false);
            } else {
                this.$pdv.setIsExactPayment(true);
            }
        }
    },
    components: {
        SidebarBistro,
        ContentBistro,
        ButtonBistro,
        NumKeyboardBistro,
        ButtonPayBistro,
        ModalOrderPayChange
    },
};
</script>

<style lang="scss" scoped>
.contain-button-checkout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
</style>
