<template>
    <div v-if="isVisible" class="modal-overlay">
		<div id="new-modal-payment" class="modal-header modal-content">
			<div>
				<h4>Anular {{ title }}</h4>
				<p>Fácil y Simple</p>
			</div>
			<div class="btnClose">
				<button
					type="button"
					class="modal-close waves-effect waves-green btn-flat"
					@click="closeVoidModal($event)"
					>
					<span class="icon-Bistro-_Close"></span>
				</button>
			</div>
		
			<hr class="space-bar"/>
			<div class="modal-body">
				<p v-if="hasEnoughFunds">¿Deseas anular la {{title.toLocaleLowerCase()}}?</p>
				<div v-else>
					<p>La caja no cuenta con suficiente efectivo para hacer la devolución.</p>
					<p>¿Desea continuar de todas formas?</p>
				</div>
			</div>
			<div class="modal-footer">
				<ButtonBistro variant="border-line-red shadow thin-button" text="Cancelar" @click="closeVoidModal($event)" />
				<ButtonBistro variant="solid-primary-blue shadow thin-button" text="Confirmar" @click="confirm" class="confirm-button"/>
			</div>
		</div>
    </div>
</template>
  
<script>
import ButtonBistro from '@/features/shared/components/ButtonBistro.vue';

	export default {
		props: {
			isVisible: { 
				type: Boolean, 
				default: false 
			},
			title: {
				type: String,
				default: ""
			},
			hasEnoughFunds: {
				type: Boolean,
				default: true
			}
		},
		methods: {
			closeVoidModal(event) {
				event.stopPropagation();
				this.$emit("closeModalVoid", false);
			},
			confirm() {
				this.$emit("confirm", "cash")
				this.$emit("closeModalVoid", false)
			},
		},
		components: {
			ButtonBistro,
		},
	};
</script>
  
<style scoped lang="scss">
    .modal-overlay {
		font-family: 'Rubik-Medium';
		top: 0;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;

		.modal-content {
			padding: 20px;
			border-radius: 8px;
			width: 700px;

			.btnClose {
				top: 10px; 
				right: 10px; 
			}

			.modal-footer {
				background-color: white;
				display: flex;
				justify-content: center;
				gap: 1rem;
			}
		}
		
		.modal-body {
			height: 50px;
		}
    }
    
    .btnClose button {
        background: none; 
        border: none; 
        font-size: 24px; 
        cursor: pointer; 
		color: #e8453c;
    }

    .btnClose button:hover {
        color: #ff4d4f; 
    }

	.space-bar {
		margin: 15px 0;
	}
</style>
  