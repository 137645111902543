

<template>
  <div>
    <!-- DASHBOARD -->

    <img v-if="type === '1'" class="logoEnergyPos" src="@assets/img/EnergyPOS-logo.svg" :width="width" :height="height"/>
    
    <!-- LOGIN -->    
    <img v-else class="logoEnergyPos" src="@assets/img/logo_energy_pos.svg" :width="width" :height="height"/>
    </div>

</template>
  
<script>
  export default {
    props: {
      type: {
        type: String,
        default: "0", 
      },
      width: {
        type: String,
        default: "45%",
      },
      height: {
        type: String,
        default: "57px",
      },
    },
  };
</script>
  
<style scoped>
  .logoEnergyPos {
    margin-top: 10px;
    margin-left: 20px;
  }
</style>
  