<template>
    <p v-if="filteredCategories.length === 0" class="filter-search">No encontramos productos que coincidan con la
        búsqueda.</p>
    <div v-else v-for="(category, index) in filteredCategories" :key="category.id" class="add-category-section">
        <div ref="categoryNameContainer" class="header">
            <div>
                <h3 v-if="!category.editCategory" class="title">{{ category.name }}<img src="@/assets/img/edit.svg"
                        alt="edit icon" @click.stop="editCategoryName(index)"></h3>
                <div v-else class="itemFormTrash">
                    <InputBistroVModel v-model="category.name" type="text" placeholder="Ej. Platos calientes"
                        class="input-search" @input="category.isCategoryTouched = true"
                        @keydown.enter="handleEnterKey(category)" />
                    <img src="@/assets/img/delete.svg" alt="Borrar" @click="removeCategory(index)" />
                </div>
                <div v-if="category.errorName" class="error-recipe-message">{{ category.errorName }}</div>
            </div>
        </div>
        <div class="labels-products"
            :class="{ 'mexico-layout': isMexicoInstance, 'standard-layout': !isMexicoInstance }">
            <span>Nombre del producto</span>
            <span v-if="isMexicoInstance">IEPS</span>
            <span>IVA</span>
            <span>Precio de venta</span>
        </div>
        <div class="border-line"></div>
        <div v-for="(product, productIndex) in category.products" :key="product.id" class="labels-products"
            :class="{ 'mexico-layout': isMexicoInstance, 'standard-layout': !isMexicoInstance }">
            <div>
                <InputBistroVModel v-model="product.name" type="text" placeholder="Ej. Sorrentinos con salsa"
                    class="input-search" :class="{ 'border-input-error': product.errors.productName }"
                    @input="product.isTouched = true" />
                <div v-if="product.errors.productName" class="error-recipe-message">{{ product.errors.productName }}
                </div>
            </div>
            <div v-if="isMexicoInstance">
                <select v-model="product.iepsMxTaxRate" class="input-select"
                    :class="{ 'border-input-error': product.errors.iepsMxTaxRate }" @change="product.isTouched = true">
                    <option v-for="iva in getIEPSTaxValues()" :value="iva" :key="iva">
                        {{ displayMxTaxValue(iva) }}
                    </option>
                </select>
                <div v-if="product.errors.iepsMxTaxRate" class="error-recipe-message">{{ product.errors.iepsMxTaxRate }}
                </div>
            </div>
            <div>
                <select v-model="product.vat" class="input-select" :class="{ 'border-input-error': product.errors.vat }"
                    @change="handleVatChange(product)">
                    <option v-for="vat in getTaxValues()" :value="vat" :key="vat">
                        {{ displayTaxValue(vat) }}
                    </option>
                </select>
                <div v-if="product.errors.vat" class="error-recipe-message">{{ product.errors.vat }}</div>
            </div>
            <div>
                <InputBistroVModel v-model="product.price" type="text" variant="price" :placeholder="$filters.currency(0)" class="input-search price-input" :class="{ 'border-input-error': product.errors.price }" @input="product.isTouched = true"/>
                <div v-if="product.errors.price" class="error-recipe-message">{{ product.errors.price }}</div>
            </div>
            <div class="itemFormTrash">
                <img src="@/assets/img/delete.svg" width="36" alt="Borrar"
                    @click="removeProduct(index, productIndex)" />
            </div>
        </div>
        <button v-if="hasToShowButtons" class="add-product-button" @click="addProduct(index)">
            <span class="plus-symbol">+</span> Agregar producto
        </button>
        <button v-else class="add-product-button disabled" disabled v-tooltip="'Limpié el filtro de búsqueda para agregar un producto.'">
            <span class="plus-symbol">+</span> Agregar producto
        </button>
    </div>
</template>

<script>
import bOrdersCommons from "@/mixins/bOrdersCommons"
import InputBistroVModel from '@/features/pdvProducts/components/InputBistroVModel.vue'
import mixCommons from "@/mixins/mixCommons"
import instanceCommons from "@/mixins/instanceCommons"
import { toast } from "vue3-toastify"

export default {
    mixins: [bOrdersCommons, mixCommons, instanceCommons],
    components: {
        InputBistroVModel,
    },
    props: {
        categories: {
            type: Array,
            required: true
        },
        searchValue: {
            type: String,
            required: false,
            default: ''
        },
        hasToShowButtons: {
            type: Boolean,
            required: false,
        }
    },
    emits: ["update-category", "update-show-buttons"],
    data() {
        return {
            inputCategory: true,
            localCategory: [],
            indexCategoryToEdit: null
        }
    },
    mounted() {
        this.localCategory = this.categories
        this.localCategory.forEach(category => {
            category.products.forEach(product => {
                if (product.exempt) {
                    product.vat = "Exento"
                } else if (product.nonTaxed) {
                    product.vat = "No Gravado"
                }
            })
        })
    },
    methods: {
        activateEventListener() {
            document.addEventListener("click", this.handleClickOutside);
            document.addEventListener("keydown", this.handleEscapeKey);
        },
        displayTaxValue(value) {
            if (typeof value == "number" || String(value).length < 6) {
                return `${value}%`;
            }
            return value;
        },
        displayMxTaxValue(value) {
            if (typeof value == "number" || String(value).length < 6) {
                return `${value.toFixed(2)}%`
            }
            return value;
        },
        removeEventListener() {
            document.removeEventListener("click", this.handleClickOutside);
            document.removeEventListener("keydown", this.handleEscapeKey);
        },
        addProduct(index) {
            if (this.localCategory[index].errorName !== "") return
            if (this.localCategory[index].name == "") return toast.error("No se pueden agregar productos a una categoría sin nombre")

            this.localCategory[index].products.push({
                name: '',
                price: null,
                vat: null,
                iepsMxTaxRate: 0,
                errors: { productName: '', vat: '', price: '' },
                isTouched: false,
            })
            this.activateEventListener()
        },
        removeProduct(categoryIndex, productIndex) {
            this.localCategory[categoryIndex].products.splice(productIndex, 1)
        },
        removeCategory(index) {
            if (this.localCategory[index].products.length > 0) return toast.error("No se puede eliminar una categoria con productos asignados")

            this.localCategory.splice(index, 1)
            this.localCategory = [...this.localCategory]
            this.indexCategoryToEdit = null
            this.$emit('update-category', this.localCategory)
        },
        editCategoryName(index) {
            this.activateEventListener()
            this.indexCategoryToEdit !== null && this.indexCategoryToEdit !== undefined ? this.localCategory[this.indexCategoryToEdit].editCategory = false : null
            this.localCategory[index].editCategory = true
            this.localCategory[index].isCategoryTouched = true
            this.indexCategoryToEdit = index
        },
        handleClickOutside(event) {
            this.indexCategoryToEdit = this.localCategory.length - 1

            const currentCategory = this.localCategory[this.indexCategoryToEdit]

            const container = this.$refs.categoryNameContainer[this.indexCategoryToEdit]

            if (container && !container.contains(event.target)) {
                if (!currentCategory.isCategoryTouched) return

                const categoryName = currentCategory.name.trim()
                // Verificar si el nombre no está vacío
                if (categoryName === "" && currentCategory.editCategory) return currentCategory.errorName = "El nombre de la categoría es obligatorio"

                // Verificar si el nombre ya existe en otras categorías
                const nameDuplicate = this.localCategory.some(
                    (category, index) => index !== this.indexCategoryToEdit && this.normalizeName(category.name.trim()) === this.normalizeName(categoryName)
                )
                if (nameDuplicate) {
                    // const lastCategoryIndex = this.localCategory.length - 1;
                    currentCategory.errorName = "El nombre de la categoría ya existe";
                } else {
                    currentCategory.errorName = ""
                    currentCategory.editCategory = false
                    currentCategory.isCategoryTouched = false
                    this.removeEventListener()
                }
            }
        },
        handleEnterKey(category) {
            category.editCategory = false
        },
        handleEscapeKey(event) {
            if (event.key === "Escape") {
                this.localCategory.forEach((category) => {
                    category.editCategory = false
                })
            }
        },
        normalizeName(name) {
            return name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        },
        handleVatChange(product) {
            product.isTouched = true
        },
        validateFields() {
            let isValid = true
            const globalNameOccurrences = {}
            for (const category of this.localCategory) {
                for (const product of category.products) {
                    const normalizedName = this.normalizeName(product.name.trim());
                    if (normalizedName) {
                        globalNameOccurrences[normalizedName] = (globalNameOccurrences[normalizedName] || 0) + 1;
                    }
                }
            }
            for (const category of this.localCategory) {

                category.errorName = category.name.trim() ? '' : 'El nombre de la categoría es obligatorio'
                if (category.errorName) return isValid = false

                const nameDuplicate = this.localCategory.some(
                    (otherCategory) => this.normalizeName(otherCategory.name.trim()) === this.normalizeName(category.name) && otherCategory !== category
                )
                if (nameDuplicate) {
                    category.errorName = "El nombre de la categoría ya existe"
                    return isValid = false
                } else {
                    category.errorName = ""
                }

                for (const product of category.products) {
                    product.errors.productName = product.name.trim() ? '' : 'El nombre del producto no puede estar vacío'
                    product.errors.vat = product.vat ? '' : 'El IVA debe seleccionarse'
                    product.errors.price = product.price !== null && product.price !== '' && product.price > 0 ? '' : 'El precio no puede estar vacío'

                    if (this.isMexicoInstance) {
                        product.errors.iepsMxTaxRate = product.iepsMxTaxRate >= 0 ? '' : 'El IEPS debe seleccionarse'
                    }

                    if (product.errors.productName || product.errors.vat || product.errors.price || product.errors.iepsMxTaxRate) return isValid = false

                    if (globalNameOccurrences[this.normalizeName(product.name.trim())] > 1) {
                        product.errors.productName = 'El producto pertenece a otra categoría';
                        isValid = false;
                    }
                }


                const nameOccurrences = {}

                // Contar ocurrencias de cada nombre de producto
                category.products.forEach(product => {
                    const name = this.normalizeName(product.name.trim())
                    if (name) {
                        nameOccurrences[name] = (nameOccurrences[name] || 0) + 1
                    }
                })

                // Identificar y marcar productos duplicados
                category.products.forEach((product, index) => {
                    const name = this.normalizeName(product.name.trim())
                    if (nameOccurrences[name] > 1) {
                        // ESto funciona correctamente
                        const firstIndex = category.products.findIndex(p => this.normalizeName(p.name.trim()) === name);

                        // Marcar error en todas las ocurrencias duplicadas excepto la primera
                        if (index !== firstIndex) {
                            product.errors.productName = 'Este producto está duplicado';
                            isValid = false;
                        }

                        // Si el producto en foco tiene el mismo nombre, marcar también su error
                        if (product.isTouched) {
                            product.errors.productName = 'Este producto está duplicado';
                            isValid = false;
                        }
                    }
                })
            }
            this.removeEventListener()
            return { isValid }
        },
        emitChangesDetected() {
            const hasUnsavedChanges = this.categories.some(category =>
                category.isCategoryTouched ||
                category.products.some(product => product.isTouched)
            );
            this.$emit('changes-detected', hasUnsavedChanges);
        },
    },
    watch: {
        localCategory: {
            deep: true,
            handler(newCategories) {
                if (this.localCategory !== newCategories) {
                    this.$emit('update-category', newCategories)
                }
            },
        },
        categories: {
            deep: true,
            handler(newCategories) {
                this.localCategory = [...newCategories]
                this.emitChangesDetected();

            },
        },
        filteredCategories(newFilteredCategories) {
            const hasResults = newFilteredCategories.length > 0;
            this.$emit('update-show-buttons', hasResults);
        },
    },
    computed: {
        // selectedVat: {
        //     get() {
        //         return product => {
        //             if (product.exempt) {
        //                 return "Exento";
        //             } else if (product.nonTaxed) {
        //                 return "No Gravado";
        //             }
        //             return product.rootVat;
        //         };
        //     },
        //     set(product, value) {
        //         if (value === "Exento") {
        //             product.rootVat = "0.00";
        //             product.exempt = true;
        //             product.nonTaxed = false;
        //         } else if (value === "No Gravado") {
        //             product.rootVat = "0.00";
        //             product.exempt = false;
        //             product.nonTaxed = true;
        //         } else {
        //             product.rootVat = value;
        //             product.exempt = false;
        //             product.nonTaxed = false;
        //         }
        //     }
        // },
        filteredCategories() {
            if (!this.searchValue) return this.localCategory;

            const lowerCaseSearch = this.searchValue.toLowerCase();

            return this.localCategory.map(category => {
                const filteredProducts = category.products.filter(product =>
                    product.name.toLowerCase().includes(lowerCaseSearch)
                )

                return {
                    ...category,
                    products: filteredProducts
                };
            }).filter(category => category.products.length > 0 || category.name.toLowerCase().includes(lowerCaseSearch))
        }
      
    }
}
</script>

<style scoped lang="scss">
.filter-search {
    margin: 30px;
}

.border-line {
    border-bottom: 1px solid #cecece;
    margin: 3px 0 3px 0;
}

.container {
    margin: 50px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 24px;
}

.subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.add-category-section {
    padding: 20px;

    p {
        font-size: 16px;
        margin: 20px;
        color: #666;
    }

    h3 {
        text-align: left;
    }

    .input-search {
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        box-sizing: border-box;
        margin: 0;
        padding-left: 10px;
        max-width: 100%;
        color: #212121;
        text-indent: 1.5em;

        &:focus {
            border: none !important;
            box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1) !important;
        }
    }

    .input-search::placeholder {
        padding-left: 5px !important;
    }

    .border-input-error {
        border: 2px solid red;
        /* Cambia esto según el diseño que necesites */
        border-radius: 4px;
        /* Opcional, ajusta según tu diseño */
    }
}

.header {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 50%;

    img {
        margin-left: 10px;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    div {
        width: 100%;
    }
}

.labels-products {
    display: grid;
    font-size: 12px;
    color: #666;
    gap: 10px;
    justify-items: start;
    margin: 10px 0 10px 0;

    &.standard-layout {
        grid-template-columns: 42% 23% 25% 10%;
        /* 4 columnas */
    }

    &.mexico-layout {
        grid-template-columns: 42% 18% 17% 20% 3%;
        /* 5 columnas, incluye IEPS */
    }

    span {
        font-size: 16px;
        font-weight: 700;
        margin-left: 5px;
    }

    div {
        width: 100%;
    }

    select {
        display: inline-block;
    }

    .price-input {
        text-align: right;
        padding-right: 10px;
    }

    img {
        height: 30px;
        width: 20px;
    }
}

.input-select {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    color: #212121;
    background-color: #f4f4f4;
    font-size: 14px;
}

@media screen and (max-width: 1024px) {
    .input-select {
        font-size: 10px;
        height: 2rem;
    }

    .add-category-section {
        .input-search{
            font-size: 10px;
            height: 2rem;
        }
    }

    .itemFormTrash {
        img {
            height: 15px !important;
        }
    }
}

.add-product-button {
    margin-top: 10px;
    background: none;
    border: none;
    color: #007bff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
        color: $solidBlue;
    }
    &.disabled{
        color: #6d6d6d;
        
    }
}

.plus-symbol {
    font-size: 20px;
}

.itemFormTrash {
    display: flex;
    justify-content: flex-start;
    margin-top: 7px;

    img {
        height: 30px;
        width: 20px;
    }
}

.error-recipe-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    text-align: left;
}
</style>