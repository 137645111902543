<template>
    <transition name="modal-fade">
        <div class="modal-confirm-container" v-show="showModal">
            <div class="modal-confirm-overlay"></div>
            <div class="modal-confirm-content">
                <div class="modal-confirm-header">
                    <span>{{ dialog }}</span>
                </div>
                <div class="modal-confirm-footer">
                    <ButtonModals @click="closeModal" :text="textButtonCancel" :variant="'return btn-modal'" />
                    <ButtonModals @click="confirm" :text="textButtonConfirm" :variant="confirmButtonVariant" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ButtonModals from '@/features/shared/components/ButtonModals.vue';

export default {
    props: {
        dialog: {
            type: String,
            required: true,
        },
        textButtonCancel: {
            type: String,
            required: true,
        },
        textButtonConfirm: {
            type: String,
            required: true,
        },
        showModal: {
            type: Boolean,
        },
        confirmButtonVariant: {
            type: String,
            default: 'btn-modal main',
        },
    },
    methods: {
        closeModal() {
            this.$emit('onCancel');
        },
        confirm() {
            this.$emit('onConfirm');
            this.closeModal();
        },
    },
    components: {
        ButtonModals,
    },
};
</script>


<style scoped lang="scss">
.modal-confirm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 5000;

    .modal-confirm-overlay {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .modal-confirm-content {
        background-color: #f3f0f0;
        background: $lightColorBg;
        position: relative;
        border-radius: 6px;
        width: 30%;
        box-sizing: border-box;

    }

    .modal-confirm-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 10px 40px;

        >span {
            font-size: 16px;
            color: $darkColorFont;
            font-weight: 600;
        }
    }
}


.modal-confirm-footer {
    display: flex;
    margin: 15px;
    gap: 10px;
    justify-content: center;
}
</style>
