<template>
  <section></section>
  <div v-show="value" class="aselect" :data-value="value" :data-list="optionList">
    <!-- Comercio que esta seleccionado / depende la pagina en la que estas, la lista que usa para elegir el comercio tiene name o listName-->
    <div class="selector" @click="toggle()">
      <div class="label merchant-filter">
        <h4 class="selector-name">
          {{
          getCurrentValue?.name ? getCurrentValue?.name : getCurrentValue?.listName
          }}
        </h4>
        <p class="selector-code">
          {{
          getCurrentValue?.shopCode ? getCurrentValue?.shopCode : getCurrentValue?.code
          }}
        </p>
      </div>
      <!-- Lista de comercios -->
      <div class="arrow" :class="{ expanded: visible }"></div>
      <div :class="{ hidden: !visible, visible }">
        <ul>
          <li class="merchant-filter" :class="{ current: item === value }" v-for="(item, index) in optionList" :key="index"
            @click="onSelectOption(item), storeSelectedCode(getShopCode(item))">
              <h4 
                :ref="`merchantName_${item.shopCode}`" 
								class="selector-name"
                :title="getMerchantNameForDropdown(getShopCode(item), getMerchantName(item))"
              >
                {{getMerchantName(item)}}
              </h4>
              <p class="selector-code">
                {{getShopCode(item)}}
              </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import headerCommons from "../mixins/headerCommons";
export default {
  mixins: [
    headerCommons
  ],
  props: {
    optionList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedShopCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: null,
      visible: false,
      listLength: 0,
      maxTries: 350
    };
  },
  watch: {
    optionList: { //Cada vez que cambio de pagina esta lista se actualiza (Salvo en usersV2 que aunque la lista se actualiza no entra al watch, por eso distingo ese caso)
        handler(newValue) {
          //Si no estoy en usersV2 y la lista tiene al menos un elemento
          if (this.optionList[0] && this.$route.fullPath != "/usersV2") { 
            this.sortMerchantTagList(newValue) //La ordeno alfabeticamente
            //Me fijo si tengo algun shopCode guardado en el LocalStorage y en ese caso me fijo si ese codigo esta en la lista
            if( this.hasSelectedShopCodeInStorage() && this.isShopCodeInList(localStorage.getItem('selectedShopCode')) ){ 
              //Lo selecciono
              this.onSelectOption(this.optionList.filter((x) => this.getShopCode(x) == localStorage.getItem('selectedShopCode'))[0]);
            }
          }
        },
        deep: true
    }
  },
  mounted() {
    //Esto lo hago en el caso de estar en UsersV2 ya que la lista cuando se actualiza no entra al watch
    setTimeout(() => {
      this.SelectShopForUsersV2();
    }, 50);
    //Ordeno la lista
    this.sortMerchantTagList(this.optionList)
  },
  methods: {
    SelectShopForUsersV2(){
      if(this.$route.fullPath == "/usersV2")
      {
        if(this.maxTries == 0) //Si se me acabaron los intentos no vuelvo a ejecutar la funcion
          return

        if(this.optionList.length != this.listLength) //Si la lista cambio su largo (o sea se actualizo)
        {
          this.listLength = this.optionList.length //Igualo los largos
          //Me fijo si tengo algun shopCode guardado en el LocalStorage y en ese caso me fijo si ese codigo esta en la lista
          if( this.hasSelectedShopCodeInStorage() && this.isShopCodeInList(localStorage.getItem('selectedShopCode')) ){ 
            //Lo selecciono
            this.onSelectOption(this.optionList.filter((x) => this.getShopCode(x) == localStorage.getItem('selectedShopCode'))[0]);
          }
          else //En caso contrario selecciono el primero de la lista (o sea lo que se hacia antes)
            this.onSelectOption(this.optionList[0]);
          this.sortMerchantTagList(this.optionList) //Ordeno alfabeticamente la nueva lista
        }
        else{ //Si la lista no cambio el largo, resto uno a maxTries y en 50mS vuelvo a ejecutar la funcion para ver si cambio
          this.maxTries--;
          setTimeout(() => { this.SelectShopForUsersV2(); }, 50);
        }
      }
    },
    toggle() {
      if (!navigator.onLine && this.excludedOfflineRoutes.includes(this.$route.fullPath)) return
      this.visible = !this.visible;
    },
    storeSelectedCode(shopCode) { //Se ejecuta cuando se selecciona un comercio, guarda el shopCode del que se eligio en el LocalStorage
      if(shopCode != null && shopCode != undefined)
        localStorage.setItem('selectedShopCode', shopCode);
    },
    onSelectOption(option) {
      this.value = option;
      this.$emit("onSelectOption", this.value);
    },
    getMerchantName(item) { //Me devuelve el nombre del comercio, que depende la lista es name o ListName
      return item?.name ? item.name : item.listName;
    },
    getShopCode(item){ //Lo mismo que getMerchantName pero para el ShopCode
      if(item != null && item != undefined)
        return item.shopCode ? item.shopCode : item.code
    },
    hasSelectedShopCodeInStorage(){ //Se fija si hay un shopCode en el storage
      if(localStorage.getItem('selectedShopCode') == null)
        return false
      return true;
    },
    isShopCodeInList(shopCodeFromStorage){ //Se fija si esta ese shopCode en la Lista
      if(this.optionList.filter((x) => this.getShopCode(x) == shopCodeFromStorage)[0] != null &&
        this.optionList.filter((x) => this.getShopCode(x) == shopCodeFromStorage)[0] != undefined)
        return true

      return false
    }
  },
  computed: {
    getCurrentValue() {
      /* eslint-disable */
      if (
        Object.keys(this.optionList).length > 1 &&
        this.optionList[1].listName &&
        this.selectedShopCode
      ) {
        this.value = this.optionList.filter(
          (x) => x.shopCode == this.selectedShopCode
        )[0];
      }
      if (this.value && this.optionList.filter((x) => x == this.value)[0]) {
        return this.value;
      }
      if (this.optionList[0]) { //Si la lista tiene al menos un elemento
        //Me fijo si tengo algun shopCode guardado en el LocalStorage y en ese caso me fijo si ese codigo esta en la lista
        if( this.hasSelectedShopCodeInStorage() && this.isShopCodeInList(localStorage.getItem('selectedShopCode')) )
        {
          //Lo selecciono
          this.onSelectOption(this.optionList.filter((x) => this.getShopCode(x) == localStorage.getItem('selectedShopCode'))[0]);
          return this.value;
        }
        else if(this.$route.fullPath != "/usersV2") //Si el codigo en el localStorage no esta en la lista y no estoy en usersV2 (ya que lo de seleccionar lo hace la funcion SelectShopForUsersV2) selecciono el primer comercio de la lista
        {
          this.onSelectOption(this.optionList[0]);
        }
        return this.value;
      }

      return "";
      /* eslint-enable */
    },
  },
};
</script>

<style scoped>
  .selector-code{
    color: #8a8a8a;
		font-size: 0.75em;
		line-height: 1;
  }
  .selector-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
    font-size: 1em;
    line-height: 1.1;
    font-family: 'Rubik-Light';
  }
  .merchant-filter{
    padding: 8px 40px 8px 8px !important;
  }
</style>