<script>
export default {
  computed: {
    // Detecta si es cualquier instancia de ENERGY_POS
    isEnergyPOSInstance() {
      const instanceCode = process.env.VUE_APP_INSTANCE_CODE;
      return /ENERGY_POS/.test(instanceCode);
    },
    // Detecta específicamente si es la instancia ENERGY_POS_SV
    isEnergyPosSVInstance() {
      const instanceCode = process.env.VUE_APP_INSTANCE_CODE;
      return instanceCode === "ENERGY_POS_SV";
    },
    isMexicoInstance() {
      return process.env.VUE_APP_INSTANCE_CODE === 'MX'
    },
    isArgentinaInstance() {
      return process.env.VUE_APP_INSTANCE_CODE === 'AR'
    },
    isSpainInstance() {
      return process.env.VUE_APP_INSTANCE_CODE === 'ES'
    }
  },
};
</script>
