<script>
export default {
  methods: {
    getShopTheme(shopCode) {
      const shopThemesJson = this.$store.getShopThemes?.filter(
        (x) => x.shopCode == shopCode
      );
      try {
        const shopTheme =
          shopThemesJson?.length > 0
            ? JSON.parse(shopThemesJson[0]?.value.replace(/\\"/g, '"'))?.value
            : 0; //0 = Gastronómico por default
        return shopTheme;
      } catch {
        return 0;
      }
    },
    translateOrigin(origin) {
      switch (origin) {
        case "BKIOSK":
          return "Autoservicio";
        case "Weborders":
          return "Tienda online";
        default:
          return origin;
      }
    },
  },
};
</script>
