<template>
  <transition name="modal-fade">
    <div class="modal-comment-container" v-show="isOpen">
      <div class="modal-comment-overlay" @click="closeModal"></div>
      <div class="modal-comment-content">
        <!-- <div class="modal-icon-comment">
          <img src="@/assets/icon-local-dining.svg" alt="icon-local-dining" />
          <button @click="closeModal" class="btn-flat">
            <span class="icon-Bistro-_Close"></span>
          </button>
        </div>        
        <div class="modal-comment-header">
          <span>Ingresar orden del plato</span>
        </div>
        <div class="order-dish-buttons">
          <button>1er</button>
          <button>2do</button>
        </div>
        <div class="modal-comment-border-bottom"></div> -->
        <div class="modal-body">
          <form class="formGeneral">
            <section class="formMain article-product">
              <div class="itemForm" style="width: 100%">
                <div class="contain-main">
                  <div class="modal-icon-comment">
                    <img src="@/assets/icon-comment.svg" alt="icon-comment" />
                  </div>
                  <span class="text-modal">Escribe un comentario</span>
                </div>
                <input
                  v-model="productComment"
                  type="text"
                  placeholder="Ingresa tu comentario"
                  maxlength="25"
                  class="modal-comment-input"
                />
              </div>
            </section>

          </form>
          <div class="footer-buttons">
            <ButtonModals
              @click="closeModal"
              :text="'Cancelar'"
              :variant="'return btn-modal'"
            />
            <ButtonModals
              @click="saveComment"
              :text="'Guardar'"
              :variant="'btn-modal main'"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ButtonModals from "@/features/shared/components/ButtonModals.vue";

export default {
  name: "ModalComment",
  components: {
    ButtonModals,
  },
  props: {
    showProductCommentModal: {
      type: Boolean,
    },
    selectedProductComment: {
      type: String
    }
  },
  data() {
    return {
      isOpen: this.showProductCommentModal,
      productComment: this.selectedProductComment,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.productComment = ""
      this.$emit('update:showProductCommentModal', false);
    },
    saveComment() {
      this.$emit('onClick', this.productComment);
      this.closeModal()
    }
  },
}
</script>

<style scoped lang="scss">
.modal-comment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;

  .modal-comment-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-comment-content {
    background-color: #f3f0f0;
    background: $lightColorBg;
    position: relative;
    border-radius: 6px;
    width: 30%;
    box-sizing: border-box;
    z-index: 220;
  }

  .modal-comment-header {
    padding: 5px 30px;

    >span {
      font-size: 16px;
      color: $darkColorFont;
      font-weight: 600;
    }
  }
}
.article-product{
  padding-bottom:0px !important;
}
.contain-main {
  display: flex;
  flex-direction: column;
}

.modal-icon-comment {
  display: flex;
  justify-content: space-between;
}

.order-dish-buttons {
  padding: 5px 30px 20px 30px;
}

.order-dish-buttons button {
  padding: 10px 20px !important;
  background-color: #f3f0f0;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 90px;
  margin-right: 10px;
}

.order-dish-buttons button:hover {
  background-color: #db9909;
}

.footer-buttons {
  display: flex;
  margin: 15px;
  gap: 10px;
  justify-content: center;
}

.modal-comment-input {
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #000;
}
.text-modal {
  font-size: 16px;
  color: $darkColorFont;
  font-weight: 600;
}
</style>
