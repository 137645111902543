<template>
    <div v-show="isOffline" class="offline-overlay">
      <div class="overlay-content">
        <img :src="require('@assets/sin_internet.svg')" alt="Sin internet" />
        <p class="message">Modo offline: Esta sección no se puede utilizar si no está conectado a internet</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "OfflineOverlay",
    data() {
      return {
        isOffline: !navigator.onLine,
      };
    },
    mounted() {
      // Escuchar los eventos de conexión y desconexión
      window.addEventListener("online", this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus);
    },
    methods: {
      updateOnlineStatus() {
        this.isOffline = !navigator.onLine;
      },
    },
  };
  </script>
  
  <style scoped>
  .offline-overlay {
    margin: 9.3rem 0 0 0px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(107 107 107 / 50%);
    z-index: 998;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  .overlay-content {
    gap: 25px;
    margin: 20px 0;
    position: relative;
    z-index: 2;
    background-color: #FCE4C2;
    padding: 20px 140px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  </style>
  