<template>
	<section class="mainContain-info">

		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Reporte de {{getWaiterReportName}}</h1>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">

				<div class="filterRecord tooltip">
					<div class="item">
						<h4>{{ data.trnxQuantitySalesShown }}</h4>
						<p>
							Ventas
						</p>
						<span class="tooltiptext">No contempla movimientos hechos con invitaciones o consumos
							empleados</span>
					</div>
					<div class="item">
						<h4>{{ data.trnxQuantityOrdersShown }}</h4>
						<p>
							Comandas
						</p>
					</div>
					<div class="item">
						<h4>{{ data.trnxQuantityVoidsShown }}</h4>
						<p>
							Anulaciones
						</p>
					</div>
					<div class="item">
						<h4>{{ data.trnxQuantityCreditNotesShown }}</h4>
						<p>
							Notas de Crédito
						</p>
					</div>
					<div class="item">
						<h4><currency-style :valor="$filters.currency(data.trnxAmountShown)" /></h4>
						<p>
							Monto Total
						</p>

					</div>
				</div>

				<!-- OPCION MODAL -->
				<a v-if="mobile == false" :href="`/api/report/waiterReport/?${excelParams}`"
					class="waves-effect waves-light btn-link filterRecord download">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Detalle</span>
				</a>
			</section>
		</article>

		<!-- LISTADO DE PRODUCTOS -->
		<article :class="{ errorLoading: data.orders && data.orders.length === 0 }" class="mainEvents salesDashboard">
			<!-- TIPO DE TRANSACCION -->
			<section :class="{ 'active': showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction ">

					<!-- SEARCH -->
					<div class="searchDashboard">
						<!-- MENSAJE -->
						<div class="itemMessage">
							<div class="text">
								<h5>Filtra tus {{getWaiterReportName}}</h5>
								<p>Fácil y Simple</p>
							</div>
							<a @click="search = !search" v-if="!search" id="btnSearchSales">
								<span class="icon-Bistro-_Search"></span>
							</a>
						</div>

						<!-- INPUT -->
						<div v-if="search" class="item">
							<a @click="search = !search" id="btnCloseSearch">
								<span class="icon-Bistro-_Close"></span>
							</a>

							<input v-model="criteria" type="text" class="inputFilter" placeholder="Nombre o Apellido">
						</div>
					</div>

					<ul class="containTransaction-lists">
						<SwitchToggle mode="list" v-model="waiterFilters" :label="getWaiterReportName" :title="true" />
						<SwitchToggle mode="list" v-for="(bool, index) in waiters.waiters" :key="index" :value="bool" :label="index" @change="chargeFilterWaiter(index, !bool)" />
					</ul>

					<ul class="containTransaction-lists">
						<SwitchToggle mode="list" v-model="paymentMethodFilters" label="Medios de Pago" :title="true" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_Visa'" v-model="card" label="Tarjeta" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_DolarMoney'" v-model="cash" label="Efectivo" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_Online'" v-model="online" label="Online" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_QR'" v-model="qr" label="QR" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_CuentaCliente'" v-model="account" label="Cuenta Cliente" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_Invitacion1'" v-model="invitation" label="Invitación" />
						<SwitchToggle mode="list" :iconClass="'icon-Bistro-_ConsumoEmpleados'" v-model="clerck" label="Consumo Empleado" />
					</ul>

				</div>
				<!-- BTN ACTIVE FILTER MOBILE -->
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>

			<!-- LISTADO DE FACTURAS -->
			<skeletonLoading v-if="componentLoading " />
			<ul v-else-if="data.sales && data.sales.length > 0" class="mainEvents-history">
				<!-- Historial 1 -->
				<table-row v-for="(sale, index) in data.sales" target="waiterReport" :modal="false" :isSaleV2="true"
					:data="sale" :key="index" />
			</ul>
			<empty-element v-else-if="data.sales && data.sales.length === 0 && loaded" />


		</article>

		<div v-if="data.sales && data.sales.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
				class="list__pagination" @nextPage="pageChangeHandle('next')"
				@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>
		<modal title="Resumen de"></modal>
		<help-center />
	</section>
</template>
<script>
import axios from 'axios'
import commons from '@/mixins/mixCommons'
import authCommons from '@/mixins/authCommons'
import filterSaleTypesCommons from '@/mixins/filterSaleTypesCommons'
import rolesCommons from '@/mixins/rolesCommons'
import computeds from '@/mixins/menuOptions'
import reportCommons from "@/mixins/reportCommons";
import selectPdvs from '@/mixins/select_pdvs'
import TableRow from '@/components/TableRow'
import Modal from '@/components/ModalSalesDetailsV2'
import EmptyElement from '@/components/EmptyElement'
import { parseToQuery, parseToQueryEncoded } from '@/utils/utils'
import CurrencyStyle from '@/components/CurrencyStyle'
import BasePagination from '@/components/BasePagination'
import HelpCenter from '@/components/HelpCenter'
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import SwitchToggle from "@/components/SwitchToggle.vue"

export default {
	mixins: [authCommons, commons, computeds, selectPdvs, rolesCommons, filterSaleTypesCommons, reportCommons],
	data() {
		return {
			data: {},
			waiters: {},
			flag: true,
			filterInterval: null,
			showFilter: false,
			search: false,
			excelParams: '',
			loaded: false,
			paymentMethodFilters: true,
			card: true,
			cash: true,
			online: true,
			qr: true,
			invitation: true,
			clerck: true,
			waiterFilters: true,
			typeFilters: true,
			account: true,
			criteria: '',
			params: {
				FilterPaymentWay: [],
				FilterWaiter: [],
				FilterTrnxStatus: [],
				FilterSaleTypes: []
			},
			timeout: null,
			currentPage: 1,
			countPerPage: 50
		}
	},
	async mounted() {
		document.title = `${this.appTitle} - Consolidado de Transacciones`
		if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.$store.updateTopMenu(0)
				this.$store.updateSideMenu(3)
				if (this.getPeriodSelectionFromLocalStorage() !== null) {
					await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
					this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
				} else {
					await this.$store.initTabs()
				}
				this.$store.hideElements({})
				this.toggleMerchantSelectorByLabel('showMerchantSelectionFilter')
				this.$store.CALL_MERCHANTS()
				this.$store.updateCalendarType('range')

				this.params['CurrentPage'] = this.currentPage
				this.params['CountPerPage'] = this.countPerPage
				this.$store.setHasMounted(true)

			}

		}

	},
	watch: {
		paymentMethodFilters() {
			this.setAllPaymentTypes(this.paymentMethodFilters)
		},
		card() {
			this.chargeFilterPaymetWay(this.card, 'TARJETA')
			this.pageChangeHandle(1)
		},
		cash() {
			this.chargeFilterPaymetWay(this.cash, 'EFECTIVO')
			this.pageChangeHandle(1)
		},
		online() {
			this.chargeFilterPaymetWay(this.online, 'ONLINE')
			this.pageChangeHandle(1)
		},
		qr() {
			this.chargeFilterPaymetWay(this.qr, 'QR')
			this.pageChangeHandle(1)
		},
		account() {
			this.chargeFilterPaymetWay(this.account, 'ACCOUNT')
			this.pageChangeHandle(1)
		},
		invitation() {
			this.chargeFilterPaymetWay(this.invitation, 'INVITATION')
			this.pageChangeHandle(1)
		},
		clerck() {
			this.chargeFilterPaymetWay(this.clerck, 'EMPLOYEE')
			this.pageChangeHandle(1)
		},
		waiterFilters() {
			for (var key in this.waiters.waiters) {
				this.waiters.waiters[key] = this.waiterFilters
				this.chargeFilterWaiter(key, this.waiterFilters)
			}
		},
		typeFilters() {
			this.setAllTypes(this.typeFilters)
		},
		criteria() {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.params['FilterCriteria'] = this.criteria
				this.pageChangeHandle(1)
			}, 500)
		},
		tab() {
			if (this.tab.id !== 6 && this.loaded) {
				this.params['Period'] = this.tab.param
				this.pageChangeHandle(1)
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.pageChangeHandle(1)
			}
		}
	},
	methods: {
		setAllPaymentTypes(bool) {
			this.card = bool
			this.cash = bool
			this.online = bool
			this.qr = bool
			this.account = bool
			this.invitation = bool
			this.clerck = bool
		},
		async pageChangeHandle(value) {
			switch (value) {
				case 'next':
					this.currentPage += 1
					break
				case 'previous':
					this.currentPage -= 1
					break
				default:
					this.currentPage = value
			}
			this.params['CurrentPage'] = this.currentPage
			if (this.flag) {
				this.getWaiters()
			} else {
				this.getData()
				this.flag = true;
			}

		},
		isMoreOfOnePage() {
			return (this.data.totalCount - this.countPerPage) > 0
		},
		chargeFilterPaymetWay(bool, payment) {
			if (bool) {
				var index = this.params.FilterPaymentWay.indexOf(payment)
				this.params.FilterPaymentWay.splice(index, 1)
			} else {
				this.params.FilterPaymentWay.push(payment)
			}
		},
		chargeFilterWaiter (waiterName, bool) {
			this.flag = false;
			this.waiters.waiters[waiterName] = bool
			var index = this.params.FilterWaiter.indexOf(encodeURIComponent(waiterName))
			if (bool && index >= 0)
			{
				this.params.FilterWaiter.splice(index, 1)
			} else if (!bool && index < 0) {
				this.params.FilterWaiter.push(encodeURIComponent(waiterName))
			}
			this.pageChangeHandle(1)
		},
		addExcelParams() {
			let p = this.params['CurrentPage']
			this.params['CurrentPage'] = null
			this.excelParams = parseToQuery(this.params)
			this.params['CurrentPage'] = p
		},
		getWaiters() {
			this.addExcelParams()

			clearInterval(this.filterInterval)
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval)

				this.$store.initLoading(this)
				axios.get(`/api/waiterReport/waiters/?${parseToQuery(this.params)}`)
					.then(res => {
						if (res.data.responseCode == 0) {
							this.waiters = res.data
							this.getData()
						} else {
							this.$store.errorLoading(this)
							this.showError(res.data.responseMessage)
						}
					})
					.catch(err => {
						this.$store.errorLoading(this)
						this.showErrors(err.response)
					})
			}, 1000)
		},
		getData() {
			if (!this.$store.hasMounted) return
			this.addExcelParams()

			clearInterval(this.filterInterval)
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval)
				this.$store.initLoading(this)
				axios.get(`/api/waiterReport/?${parseToQueryEncoded(this.params)}`)
					.then(res => {
						this.data = res.data
						this.$store.finishLoading(this)
						this.loaded = true
					})
					.catch(err => {
						this.$store.errorLoading(this)
						this.showErrors(err.response)
					})
			}, 1000)
		}
	},
	components: {
		TableRow,
		Modal,
		EmptyElement,
		CurrencyStyle,
		BasePagination,
		HelpCenter,
		SwitchToggle,
		SkeletonLoading
	},
}
</script>

