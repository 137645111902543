<script>
export default {
  computed: {
    tab() {
      return this.$store.getTabSelected;
    },
    dateCalendar() {
      return this.$store.getDateCalendar;
    },
    selectedPdvs() {
      return this.$store.getSelectedMerchants;
    },
    mobile() {
      return this.$store.getMobile;
    },
    sectionLoading() {
      return {
        'skeleton-card': this.$store.getLoadingSkeleton
      };
    },
    componentLoading() {
      return this.$store.getComponentLoading
    },
  },
};
</script>
