<template>
  <div>
    <!-- DASHBOARD -->
    <img v-if="type === '1'" src="@assets/img/logo_header.png" :width="width" :height="height" />
    
    <!-- LOGIN -->
    <img v-else src="@assets/img/logobistroblue.svg" :width="width" :height="height" class="logoImg" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '0',
    },
    width: {
      type: String,
      default: '45%',
    },
    height: {
      type: String,
      default: '45%',
    },
  },
};
</script>

<style scoped>
.logoImg {
  margin-top: 10px;
  margin-left: 10px;
}
</style>
