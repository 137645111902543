<template>
  <div id="modalResumSalesPos" class="modal">
    <div class="modal-header">
      <h4>{{ title }} {{ movementType }} #{{ modalInfo?.ticketNumber }}</h4>
      <p>Fácil y Simple</p>

      <!-- CLOSE -->
      <div class="btnClose">
        <a
          href="#!"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <!-- {{modalInfo}} -->

    <div class="modal-body modalStock modalResumSales">
      <article class="cardClient">
        <section class="itemUser">
          <figure>
            <span class="icon-Bistro-_Smile"></span>
          </figure>
          <div class="item">
            <!-- TODO ver que envia back en customer -->
            <h3>N/A</h3>
            <!-- <div class="tdc">
							<span v-bind:class="modalInfo.extendedInfo.paymentType + 1 | payment('icon')"></span>
							<p>{{ modalInfo.extendedInfo.paymentInfo }}</p>
						</div> -->
          </div>
        </section>

        <!-- <section
          v-if="modalInfo.extendedInfo.relatedFiscalInfo"
          class="itemUser"
        >
          <div class="item">
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.billType }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.billNumber }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.customerName }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.customerFiscalKey }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.customerAddress }}
            </div>

            <div v-if="hasToShowBillTicket">
              <div
                class="fiscal-data"
              >
                <a
                  :href="`/ticketsViewer?ShopCode=${modalInfo.shopCode}&InternalReference=${modalInfo.extendedInfo.uuid}`"
                  target="_blank"
                >
                  <span class="icon-Bistro-_Search" title="Ver Factura"></span>
                  Ver Factura
                </a>
              </div>
            </div>
            <div v-else>
              <div v-if="showBillingActions" class="fiscal-data">
                <router-link
                  :to="`/billViewer?InternalReference=${modalInfo.extendedInfo.uuid}`"
                  target="_blank"
                >
                  <span class="icon-Bistro-_Search" title="Ver Factura"></span>
                  Ver Factura
                </router-link>
              </div>
              <div v-if="showBillingActions" class="fiscal-data">
                <a
                  :href="`${modalInfo.extendedInfo.rawRequestUrl}`"
                  target="_blank"
                >
                  <span class="icon-Bistro-_Search" title="Ver XML"></span>
                  Ver XML
                </a>
              </div>

              <div
                v-if="showBillingActions"
                @click.prevent="processVoidBill"
                class="fiscal-data fiscal-data-voidBill"
              >
                <span
                  class="icon-Bistro-_Trash_bin"
                  title="Anular Factura"
                ></span>
                Anular Factura
              </div>
            </div>
          </div>
        </section> -->
        
        <!-- TODO ver como mostrar el ticket -->
        <div v-if="movementType === 'Comanda' && hasToShowTicketViewer" class="fiscal-data">
          <router-link :to="`/billViewer?InternalReference=${modalInfo.uuid}`" target="_blank">
            <span class="icon-Bistro-_Search" title="Ver Ticket"></span>
            Ver Ticket
          </router-link>
				</div>
        <section class="record">
          <div class="item">
            <span
              ><strong>{{ formattedDate }}</strong> -
              {{ formattedHour }}</span
            >
            <h4>
              <currency-style
                :valor="
                  modalInfo
                    ? $filters.currency(modalInfo?.amount)
                    : ''
                "
              />
            </h4>
          </div>
        </section>
      </article>

      <article class="transactions view-details">
        <h4>Ticket</h4>

        <ul class="mainEvents-transaction-detail">
          <li v-for="(item, index) in modalInfo?.products" :key="index">
            <figure class="iconHistory">
              <span class="icon-Bistro-_Check"></span>
            </figure>

            <div class="items">
              <p>
                <strong>{{ item.quantity }} {{ item.measureUnit }}</strong
                >{{ item.name }}
              </p>
            </div>

            <div class="items">
              <p v-if="item.sku !== ''">SKU: {{ item.sku }}</p>
            </div>

            <div class="items">
              <h3>
                <currency-style :valor="$filters.currency(item.originPrice)" />
              </h3>
            </div>

            <div
              class="div-options"
              v-if="item.discountByAmount > 0 || item.discountByPercentage > 0"
            >
              <ul class="options">
                <li>
                  <div class="items">
                    <p>
                      <strong>{{ item.quantity }}</strong>
                    </p>
                  </div>
                  <div class="items">
                    <p>{{ item.discountByPercentage ? `DESCUENTO ${item.discountByPercentage}%` : "DESCUENTO FIJO" }}</p>
                  </div> 
                  <div class="items">
                    <h3>
                      <currency-style
                        class="gray-color"
                        :valor="$filters.currency(-currencyFormatDiscount(item))"
                      />
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
          </li>

          <!-- <li v-for="(combo, i) in modalInfo.comboDetails" :key="i">
            <figure class="iconHistory">
              <span class="icon-Bistro-_Check"></span>
            </figure>

            <div class="items">
              <p>
                <strong>{{ combo.quantity }}</strong
                >{{ combo.comboName }}
              </p>
            </div>

            <div class="items">
              <p>SKU: {{ combo.sku }}</p>
            </div>

            <div class="items">
              <h3>
                <currency-style :valor="$filters.currency(combo.totalAmount)" />
              </h3>
            </div>

            <div
              v-if="combo.products !== undefined && combo.products.length > 0"
              class="combo-row"
            >
              <ul>
                <li v-for="(item, index) in combo.products" :key="index">
                  <figure class="iconHistory">
                  </figure>

                  <div class="items">
                    <p>
                      <strong>{{ item.quantity }} {{ item.measureUnit }}</strong
                      >{{ item.productName }}
                    </p>
                  </div>

                  <div class="items">
                    <p>SKU: {{ item.skuCode }}</p>
                  </div>

                  <div class="items amount">
                    <h3>
                      <currency-style
                        :valor="$filters.currency(item.totalAmount)"
                      />
                    </h3>
                  </div>

                  <div
                    class="div-options"
                    v-if="item.options !== undefined && item.options.length > 0"
                  >
                    <ul class="options">
                      <li v-for="(op, ind) in item.options" :key="ind">
                        <figure class="iconHistory"></figure>

                        <div class="items">
                          <p>
                            <strong>{{ op.quantity }}</strong
                            >{{ op.optionGroupName }}
                          </p>
                        </div>

                        <div class="items">
                          <p>{{ op.optionName }}</p>
                        </div>

                        <div class="items amount">
                          <h3>
                            <currency-style
                              :valor="$filters.currency(op.totalAmount)"
                            />
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li> -->
          <!-- <li v-for="(item, index) in modalInfo.saleTaxes" :key="index">
            <figure class="iconHistory">
              <span class="icon-Bistro-_Check"></span>
            </figure>
            <div class="items">
              <p>{{ item.taxCode }}</p>
            </div>
            <div class="items">
              <p v-if="item.taxRate"></p>
            </div>
            <div class="items">
              <h3>
                <currency-style :valor="$filters.currency(item.taxAmount)" />
              </h3>
            </div>
          </li> -->
        </ul>
      </article>

      <article class="paymentWays">
        <h4>Medios de pago usados</h4>

        <ul class="mainEvents-transaction-payment">
          <li v-for="(item, index) in modalInfo?.payments" :key="index">
            <div class="payment-type">
              <figure class="iconHistory">
                <span
                  v-bind:class="$filters.payment(getMovementIcon(item.paymentWay) + 1, 'icon')"
                ></span>
              </figure>

              <div class="items">
                <p>
                  <strong>{{ item.paymentWay }}</strong>
                </p>
              </div>
            </div>

            <!-- <div v-if="item.authorizationCode !== null" class="items">
              <p><strong>Cod:</strong>{{ item.authorizationCode }}</p>
            </div> -->
            <div class="items">
              <p></p>
            </div>

            <div class="items">
              <h3>
                <currency-style
                  :valor="$filters.currency(modalInfo?.amount)"
                />
              </h3>
            </div>
          </li>
        </ul>
      </article>
      <div v-if="this.hasToShowSettingsPosTab" class="button-modal-salesDetails">
        <div v-if="shouldShowVoidButtons()">
          <ButtonBistro 
            v-if="movementType === 'Comanda'"  
            variant="solid-bright-red shadow" 
            text='Anular Comanda' 
            v-bind:title="getTooltipText() || null"
            class="cancel-button" 
            :class="{ disabled: isActionDisabled || hasProcessingVoid || !isMerchantEnabled}"
            :disabled="isActionDisabled || hasProcessingVoid || !isMerchantEnabled" 
            @click="voidSale(modalInfo)"/>
          <!-- <ButtonBistro 
            v-else 
            variant="solid-bright-red shadow" 
            text='Anular Venta' 
            v-bind:title="getTooltipText() || null"
            class="cancel-button" 
            :class="{ disabled: isActionDisabled }"
            :disabled="isActionDisabled"
            @click="voidSale(modalInfo)"/> -->
        </div>
        <ButtonBistro v-if="configPrinter && isMerchantEnabled" variant="border-line-blue shadow" text="Reimprimir ticket" class="print-button" @click="reprintTicket"/>
        <ButtonBistro v-else variant="border-line-blue shadow" text="Reimprimir ticket" class="print-button disabled " disabled v-tooltip="isMerchantEnabled ? 'Debe configurar su impresora primero'
         : 'No tienes comercios habilitados' "/>
      </div>
    </div>
    <ConfirmationModalBeforeVoidBill
      :orderExtendedInfo="modalInfo"
      @onCloseModal="onCloseModal"
      @onCloseModalAndReloadPage="onCloseModalAndReloadPage"/>
    <ModalVoid 
      :isVisible="showTypeVoidModal"
      :title="movementType"
      :hasEnoughFunds="hasEnoughFunds"
      @closeModalVoid="closeModalVoid"
      @confirm="handleVoidOption"/>
    <ModalConfirmVoidOperation 
      :isVisible="showConfirmVoidModal"
      :title="movementType"
      :hasEnoughFunds="hasEnoughFunds"
      @closeModalVoid="closeModalVoid"
      @confirm="handleVoidOption"/>
  </div>
</template>
<script>
import mixCommons from "@/mixins/mixCommons";
import roleCommons from "@/mixins/rolesCommons.vue";
import ticketCommons from "@/features/pdv/mixins/ticketCommons.vue"
import printerCommons from "@/features/shared/mixins/printerCommons.vue"
import shopCommons from "@/features/shared/mixins/shopCommons.vue";
import boxPosCommons from "@/features/pdvBox/mixins/BoxPosCommons.vue"
import CurrencyStyle from "@/components/CurrencyStyle";
import ConfirmationModalBeforeVoidBill from "@/components/ConfirmationModalBeforeVoidBill";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue";
import ModalVoid from "@/components/ModalVoid.vue";
import ModalConfirmVoidOperation from "@/components/ModalConfirmVoidOperation.vue";
import { createVoidIndexDB, syncCashboxes, syncOrders } from "@/features/shared/repositories/orders/ordersService"
import { getTotalCashAmountFromCashboxMovements } from "@/features/shared/repositories/cashboxes/cashboxesService"
import { ORDER_STATUS } from "@/features/shared/utils/orderStatuses";
import instanceCommons from "@/mixins/instanceCommons.vue";


export default {
  mixins: [mixCommons, roleCommons, shopCommons, boxPosCommons, ticketCommons, printerCommons, instanceCommons],
  props: {
    title: {
      type: String,
      default: "",
    },
    showBillingActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { 
      modalResumSales: {}, 
      instanceModalResumSales: {},
      instanceModalPayment: {},
      cashBoxLocalStorage: {},
      showTypeVoidModal: false,
      showConfirmVoidModal: false,
      hasClosedCashBox: false,
      hasEnoughFunds: true,
      hasVoidTicket: false,
      orderBelongToCurrentBox: false,
      hasProcessingVoid: false,
      configPrinter: false
      // order: {}
    };
  },
  mounted() {
    /* eslint-disable */
    this.modalResumSales = document.getElementById(
      "ConfirmationModalBeforeVoidBill"
    );
    this.instanceModalResumSales = M.Modal.init(this.modalResumSales);
    this.isConfigPrinter()
    /* eslint-enable */
  },
  computed: {
    isActionDisabled(){
      return this.hasClosedCashBox || this.hasVoidTicket
    },
    modalInfo() {
      return this.$store.getModalInfoV2
    },
    movementType() {
      return "Comanda"
      //TODO agregar relatedFiscalInfo cuando anulemos ventas
      // return this.modalInfo.extendedInfo.relatedFiscalInfo
      //   ? "Venta"
      //   : "Comanda";
    },
    hasToShowBillTicket() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "AR":
          return true;
        case "ES":
          return true;
        default:
          return false;
      }
    },
    hasToShowTicketViewer(){
      return false;
		},
    formattedDate () {
      const timestamp = this.modalInfo?.createdAt;

      // Validar si el timestamp existe y es un número
      if (!timestamp || isNaN(Number(timestamp))) {
        return 
      }
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat(navigator.language)
        .format(date)
        .replace(/\//g, '-')
        .split('-')
        .map(part => part.padStart(2, '0'))
        .join('-');
    },
    formattedHour () {
      const timestamp = this.modalInfo?.createdAt;

      // Validar si el timestamp existe y es un número
      if (!timestamp || isNaN(Number(timestamp))) {
      return 
      }

      // Convertir el timestamp en una fecha
      const date = new Date(Number(timestamp));

      // Validar que la fecha generada sea válida
      if (isNaN(date.getTime())) {
      return 
      }

      // Extraer y devolver la hora en formato de 24 horas
      return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
      });
    
    },
  },
  methods: {
    isConfigPrinter() {
      const getDataPrinter = JSON.parse(localStorage.getItem('printersConfig'));
      if(!getDataPrinter) return this.configPrinter = false

      // Obtener un array con los valores del objeto 

      const hasInvalidPrinter = getDataPrinter.counter.label === "Ninguna";

      return this.configPrinter = !hasInvalidPrinter;
    },
    onCloseModal() {
      this.instanceModalResumSales.close();
      this.$emit("onCloseModal");
    },
    closeModalVoid() {
      this.showTypeVoidModal = false
      this.showConfirmVoidModal = false
    },
    currencyFormatDiscount(item){
      if(item.discountByPercentage){
      return item.originPrice * item.discountByPercentage / 100
      } else {
       return item.discountByAmount
      }   
    },
    getTooltipText() {
      if(!this.isMerchantEnabled) return 'No tienes comercios habilitados'
      if(this.hasVoidTicket) return "La orden ya esta anulada"
      if (this.hasClosedCashBox) return `No se puede anular la ${this.movementType.toLowerCase()} con la caja cerrada`

      return ""
    },
    getMovementIcon(movement) {
      switch (movement) {
        case 'QR':
          return 3
        case 'EFECTIVO':
          return 0
        case 'ONLINE':
          return 10
        case 'TARJETA':
          return 1
        default:
          return 0
      }
    },
    getCashboxItemType(movement) {
      const movementMapping = {
        "RETIRO": 1,
        "AJUSTE": 2,
        "COMANDA": 3,
        "VENTA": 4,
        "APERTURA DE CAJA": 5,
        "CIERRE DE CAJA": 6,
        "APERTURA DE TURNO": 7,
        "CIERRE DE TURNO": 8,
        "ANULACION": 9,
        "ANULACION POR FACTURACION": 10,
        "NOTA DE CREDITO": 11,
        "PROPINA": 13,
        "DEPOSITO": 14,
        "AJUSTE EN APERTURA DE CAJA": 2,
      };
      return movementMapping[movement]
    },
    onCloseModalAndReloadPage() {
      this.instanceModalResumSales.close();
      this.$emit("onCloseModalAndReloadPage");
    },
    processVoidBill() {
      this.instanceModalResumSales.open();
    },
    voidSale(modalInfo){
      if(modalInfo.payments[0].paymentWay !== "EFECTIVO"){
        //Abrimos el modal para elegir el metodo de devolución
        this.showTypeVoidModal = true
      } else {
        //Si el método  de pago es efectivo, abrimos modal para confirmar la devolución
        this.showConfirmVoidModal = true
      }
    },
    async handleVoidOption(option) {
      if (this.hasProcessingVoid) return
      this.hasProcessingVoid = true

      if (option === "original") {
        // Lógica para medio de pago original
        // const currentOrderUuid = this.modalInfo?.uuid; // Obtener el uuid de la orden en curso
        // const order = await getOrderByUuid(this.shopCode, currentOrderUuid)
        this.modalInfo.voidPaymentInfo = this.modalInfo.payments[0].paymentWay
      } else if (option === "cash") {
        // Lógica para efectivo
        this.modalInfo.voidPaymentInfo = "EFECTIVO"
      }

      try{
        await createVoidIndexDB(this.shopCode, this.modalInfo, this.orderBelongToCurrentBox, this)
        await syncOrders(this.shopCode, this)
        await syncCashboxes(this.shopCode)
        await this.printInCounter(await this.generateVoidTicket(this.modalInfo?.uuid));
     
        this.$emit("onCloseModalAndReloadPage");
      } catch(err){
        console.error("Error al procesar la anulación:", err);
      } finally {
        this.hasProcessingVoid = false; // Liberar el botón al finalizar
      }

    }, 
    async reprintTicket(){
      await this.printInCounter(await this.generatePaymentOrderTicketByOrderUuid(this.modalInfo?.uuid, true));
    },
    async shouldShowVoidButtons() {
      if(!this.shopCode) return
      
      // const currentOrderUuid = this.modalInfo?.uuid; // Obtener el uuid de la orden en curso
      const openTimeStampTick = this.cashBoxLocalStorage?.openTimeStampTick; // Timestamp de apertura
      const closeTimeStampTick = this.cashBoxLocalStorage?.closeTimeStampTick; // Timestamp de cierre (opcional)
      // const order = await getOrderByUuid(this.shopCode, currentOrderUuid)
      
      // if (order) {
      if (this.modalInfo) {
        //Verificamos que la orden no este anulada
        this.hasVoidTicket = this.modalInfo?.status === ORDER_STATUS.VOID || this.modalInfo?.status === ORDER_STATUS.VOID_OLD
        if(this.hasVoidTicket) return

        // Verificar si pertenece a la caja en curso
        // const { createdAt } = order; // Timestamp de la orden
        const { createdAt } = this.modalInfo; // Timestamp de la orden
        this.orderBelongToCurrentBox = createdAt > openTimeStampTick && (!closeTimeStampTick || createdAt < closeTimeStampTick)
      } 

      if (this.cashBoxLocalStorage){
        this.hasClosedCashBox = this.cashBoxLocalStorage.closeBox
        if(this.hasClosedCashBox) return
        
        const totalCashAmount =  await getTotalCashAmountFromCashboxMovements(this.shopCode)
        this.hasEnoughFunds = totalCashAmount >= this.modalInfo?.amount
      } else {
        this.hasClosedCashBox = true
      }

      const movementType = this.getCashboxItemType(this.modalInfo?.movementType)
      return !(movementType === 9); // Mostrar botones si no es 9 <- ANULACION
    },
    getCashBoxLocalStorage(){
      const cashBoxLocal = JSON.parse(localStorage.getItem(`cashBox_${this.shopCode}`))
      this.cashBoxLocalStorage = cashBoxLocal ? cashBoxLocal[0] : null
    },
  },
  watch: {
    shopCode: {
      immediate: true, // Ejecutar inmediatamente si el valor ya está disponible
      handler(newShopCode) {
        if (newShopCode) {
          this.getCashBoxLocalStorage()
        }
      },
    },
  },
  components: {
    CurrencyStyle,
    ConfirmationModalBeforeVoidBill,
    ButtonBistro,
    ModalVoid,
    ModalConfirmVoidOperation
  },
};
</script>
<style scoped lang="scss">
  .button-modal-salesDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

  }

  .cancel-button {
    border-radius: 8px;

    &:hover{
      background-color: rgb(175, 3, 3);
    }
  }

  .print-button {
    border-radius: 8px;

    &:hover{
      color: rgb(3, 72, 175);
      border: 1px solid rgb(3, 72, 175);
    }
  }

  .disabled {
    opacity: 0.5; /* Opcional: cambia la opacidad para dar un feedback visual */
  }

  .gray-color {
    color: #808694;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ::v-deep(.decimal-value) {
      font-size: 16px;
    }
  }  

</style>
