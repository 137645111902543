<template>

  <main>
    <!-- <section class="mainContain-info"> -->
    <article class="mainFilter filterEvent">
      <section class="mainFilter-title">
        <h1>Consolidado</h1>
      </section>

      <section class="mainFilter-filter">
        <div class="filterRecord tooltip">
          <!-- <div class="item">
            <h4>{{ data.trnxQuantitySalesShown }}</h4>
            <p>Ventas</p>
            <span class="tooltiptext">No contempla movimientos hechos con invitaciones o consumos
              empleados</span>
          </div> -->
          <div class="item">
            <h4>{{ data.trnxQuantityOrdersShown }}</h4>
            <p>Comandas</p>
          </div>
          <div class="item">
            <h4>{{ data.trnxQuantityVoidsShown }}</h4>
            <p>Anulaciones</p>
          </div>
          <!-- <div class="item">
            <h4>{{ data.trnxQuantityCreditNotesShown }}</h4>
            <p>Notas de Crédito</p>
          </div> -->
          <div class="item">
            <h4>
              <currency-style :valor="$filters.currency(data.trnxAmountShown)" />
            </h4>
            <p>Monto Total</p>
          </div>
        </div>
        <!-- OPCION MODAL -->
        <!-- <a v-if="mobile == false" href="#" @click="processReportInBackend(getOrdersUrl, excelParams)"
          class="waves-effect waves-light btn-link filterRecord download">
          <span class="icon-Bistro-_Download"></span>
          <span>Descargar Detalle</span>
        </a> -->
      </section>
    </article>

    <!-- LISTADO DE PRODUCTOS -->
    <article class="mainEvents salesDashboard">
      <!-- TIPO DE TRANSACCION -->
      <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
        <div class="containTransaction">
          <div class="searchDashboard">
            <div class="itemMessage">
              <div class="text">
                <h5>Filtra tu Consolidado</h5>
                <p>Fácil y Simple</p>
              </div>
              <a @click="search = !search" v-if="!search" id="btnSearchSales">
                <span class="icon-Bistro-_Search"></span>
              </a>
            </div>

            <div v-if="search" class="item">
              <a @click="search = !search" id="btnCloseSearch">
                <span class="icon-Bistro-_Close"></span>
              </a>

              <input v-model="criteria" type="text" class="inputFilter" placeholder="Nro de comanda o Nro de factura" />
            </div>
          </div>

          <ul class="containTransaction-lists">
            <SwitchToggle mode="list" v-model="paymentMethodFilters" label="medios de pago" :title="true" />

            <SwitchToggle mode="list" v-model="card" icon-class="icon-Bistro-_Visa" label="Tarjeta" />

            <SwitchToggle mode="list" v-model="cash" icon-class="icon-Bistro-_DolarMoney" label="Efectivo" />

            <SwitchToggle mode="list" v-model="online" icon-class="icon-Bistro-_Online" label="Online" />

            <SwitchToggle v-if="isArgentinaInstance" mode="list" v-model="qr" icon-class="icon-Bistro-_QR" label="QR" />

            <!-- <SwitchToggle mode="list" v-model="account" icon-class="icon-Bistro-_CuentaCliente"
              label="Cuenta Cliente" />

            <SwitchToggle mode="list" v-model="invitation" icon-class="icon-Bistro-_Invitacion1" label="Invitación" />

            <SwitchToggle mode="list" v-model="clerck" icon-class="icon-Bistro-_ConsumoEmpleados"
              label="Consumo Empleado" /> -->

          </ul>

          <!-- TODO arreglar el filtro
          
          <ul class="containTransaction-lists">
            <SwitchToggle mode="list" v-model="originFilters" label="origenes" :title="true" />


              <SwitchToggle mode="list" v-for="(bool, index) in data.origins" :key="index" :value="bool" :label="index"
                @change="chargeFilterOrigin(index, !bool)" />
          </ul> -->

          <ul class="containTransaction-lists">
            <SwitchToggle mode="list" v-model="typeFilters" label="tipos" :title="true" />

            <SwitchToggle mode="list" v-model="order_with_discount" label="Comanda c/descuento" />

            <SwitchToggle mode="list" v-model="order_no_discount" label="Comanda s/descuento" />

            <!-- <SwitchToggle mode="list" v-model="sale_with_discount" label="Venta c/descuento" />

            <SwitchToggle mode="list" v-model="sale_no_discount" label="Venta s/descuento" /> -->


          </ul>
        </div>
        <button @click="showFilter = !showFilter" class="btnFilterMobile">
          <span class="icon-Bistro-_Filter"></span>
        </button>
      </section>

      <ul v-if="data.sales && data.sales.length > 0" class="mainEvents-history">
        <!-- Historial 1 -->
        <table-row v-for="(sale, index) in paginatedSales" :modal="true" :data="sale" :key="index" :shopCode="shopCode" />
      </ul>
      <empty-element v-else-if="data.sales && data.sales.length === 0" />
      <article v-else class="mainEvents-history">
        <article v-for="i in 4" :key="i" :class="sectionLoading">
        </article>
      </article>
    </article>

    <div v-if="data.sales && data.sales.length > 0 && isMoreOfOnePage()" class="base-pagination">
      <BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
        class="list__pagination" @nextPage="pageChangeHandle('next')" @previousPage="pageChangeHandle('previous')"
        @loadPage="pageChangeHandle" />
    </div>
    <modal title="Resumen de" :showBillingActions="showBillingPage" @onCloseModalAndReloadPage="onCloseModalAndReloadPage"></modal>
    <help-center />
  </main>

</template>
<script>
import mixCommons from "@/mixins/mixCommons";
import shopCommons from "@/features/shared/mixins/shopCommons.vue";
import authCommons from "@/mixins/authCommons";
import filterSaleTypesCommons from "@/mixins/filterSaleTypesCommons";
import rolesCommons from "@/mixins/rolesCommons";
import computeds from "@/mixins/menuOptions";
import selectPdvs from "@/mixins/select_pdvs";
import TableRow from "@/features/pdvConsolidated/components/TableRowPos.vue";
import Modal from "@/features/pdvConsolidated/components/ModalSalesDetailsPos.vue";
import EmptyElement from "@/components/EmptyElement";
import CurrencyStyle from "@/components/CurrencyStyle";
import BasePagination from "@/components/BasePagination";
import HelpCenter from "@/components/HelpCenter";
import bOrdersCommons from "@/mixins/bOrdersCommons";
import reportCommons from "@/mixins/reportCommons";
import SwitchToggle from "@/components/SwitchToggle.vue";
import mixModals from "@/mixins/mixModals";
import { syncCashboxes, syncOrders } from "@/features/shared/repositories/orders/ordersService"
import cashboxIndexedDb from "@/features/shared/repositories/cashboxes/cashboxesIndexedDBRepository" 
import { ORDER_TYPE } from "@/features/shared/utils/orderStatuses";
import instanceCommons from "@/mixins/instanceCommons.vue";

export default {
  mixins: [
    authCommons,
    mixCommons,
    computeds,
    selectPdvs,
    rolesCommons,
    filterSaleTypesCommons,
    bOrdersCommons,
    reportCommons,
    shopCommons,
    mixModals,
    instanceCommons
  ],
  data() {
    return {
      data: {},
      filterInterval: null,
      showFilter: false,
      search: false,
      excelParams: "",
      paymentMethodFilters: true,
      card: true,
      cash: true,
      online: true,
      qr: true,
      uuid: "",
      invitation: true,
      clerck: true,
      originFilters: true,
      typeFilters: true,
      account: true,
      criteria: "",
      params: {
        FilterPaymentWay: [],
        FilterOrigin: [],
        FilterTrnxStatus: [],
        FilterSaleTypes: [],
      },
      timeout: null,
      currentPage: 1,
      countPerPage: 50,
    };
  },
  async mounted() {
    document.title = `${this.appTitle}  - Consolidado de Transacciones`;
    this.$store.setHasMounted(false)
    this.$store.setLoadingSkeleton(true)
    
    if (!navigator.onLine && localStorage['currentUserData']) {
      this.$store.hideElements({hideCalendar: false});
      this.onMounted();
      return;
    }

    if (this.isUserLoggedIn() === false) {
      this.logout()
    } else if (!this.hasToShowSettingsPosTab) {
      this.handleUnauthorizedAccess()

    } else {
      this.$store.hideElements({hideCalendar: false});
      if (await this.checkSessionInBackendAsync()) {
        this.onMounted();
      }
    }
  },
  watch: {
    dateCalendar() {
      if (this.dateCalendar.indexOf("Seleccione") < 0) {
        this.params["Period"] = this.tab.param;
        this.getData()
      }
    },
    paymentMethodFilters() {
      this.setAllPaymentTypes(this.paymentMethodFilters);
    },
    card() {
      this.chargeFilterPaymetWay(this.card, "TARJETA");
      this.pageChangeHandle(1);
    },
    cash() {
      this.chargeFilterPaymetWay(this.cash, "EFECTIVO");
      this.pageChangeHandle(1);
    },
    online() {
      this.chargeFilterPaymetWay(this.online, "ONLINE");
      this.pageChangeHandle(1);
    },
    qr() {
      this.chargeFilterPaymetWay(this.qr, "QR");
      this.pageChangeHandle(1);
    },
    // account() {
    //   this.chargeFilterPaymetWay(this.account, "ACCOUNT");
    //   this.pageChangeHandle(1);
    // },
    // invitation() {
    //   this.chargeFilterPaymetWay(this.invitation, "INVITATION");
    //   this.pageChangeHandle(1);
    // },
    // clerck() {
    //   this.chargeFilterPaymetWay(this.clerck, "EMPLOYEE");
    //   this.pageChangeHandle(1);
    // },
    // originFilters() {
    //   for (var key in this.data.origins) {
    //     this.data.origins[key] = this.originFilters;
    //     this.chargeFilterOrigin(key, this.originFilters);
    //   }
    // },
    typeFilters() {
      this.setAllTypes(this.typeFilters);
    },
    criteria() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.params["FilterCriteria"] = this.criteria;
        this.pageChangeHandle(1);
      }, 500);
    },
    // tab() {
    //   if (this.tab.id !== 6 && this.loaded) {
    //     this.params["Period"] = this.tab.param;
    //     this.pageChangeHandle(1);
    //   }
    // },
    '$store.getTabSelected': {
      handler(newVal) {
          this.params = {
            ...this.params,
            Period: newVal.param
          };
          this.getData();
      },
    },
    async shopCode() {
      await this.getContextFromPDV(this.shopCode)
      await syncOrders(this.shopCode, this)
      await syncCashboxes(this.shopCode)
      this.getData()
    }
  },
  methods: {
    async onMounted() {
      this.uuid = this.createUUID();
      this.$store.updateTopMenu(0);
      this.$store.updateSideMenu(1);
      this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");
      if (this.getPeriodSelectionFromLocalStorage() !== null) {
        await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
        this.params["Period"] = this.getPeriodSelectionFromLocalStorage().param;
      } else {
        await this.$store.initTabs();
      }
      this.getListByShopCode()
      this.checkMerchantStatus()
      this.$store.CALL_MERCHANTS();
      this.params["CurrentPage"] = this.currentPage;
      this.params["CountPerPage"] = this.countPerPage;
      this.$store.setHasMounted(true)
      await this.getContextFromPDV(this.shopCode)

    },
    setAllPaymentTypes(bool) {
      this.card = bool;
      this.cash = bool;
      this.online = bool;
      this.qr = bool;
      // this.account = bool;
      // this.invitation = bool;
      // this.clerck = bool;
    },
    isMoreOfOnePage() {
      this.data.totalCount = this.data.trnxQuantityOrdersShown + this.data.trnxQuantityVoidsShown
      return this.data.totalCount - this.countPerPage > 0;
    },
    chargeFilterPaymetWay(bool, payment) {
      if (bool) {
        const index = this.params.FilterPaymentWay.indexOf(payment);
        this.params.FilterPaymentWay.splice(index, 1);
      } else {
        this.params.FilterPaymentWay.push(payment);
      }
    },
    // chargeFilterOrigin(origen, bool) {
    //   this.data.origins[origen] = bool;

    //   var index = this.params.FilterOrigin.indexOf(origen);
    //   if (bool) {
    //     this.params.FilterOrigin.splice(index, 1);
    //   } else if (index < 0) {
    //     this.params.FilterOrigin.push(origen);
    //   }
    //   this.pageChangeHandle(1);
    // },
    // addExcelParams() {
    //   let p = this.params["CurrentPage"];
    //   this.params["Uuid"] = this.uuid;
    //   this.params["CurrentPage"] = null;
    //   this.excelParams = parseToQuery(this.params);
    //   this.params["CurrentPage"] = p;
    // },
    onCloseModalAndReloadPage() {
			this.onCloseModal();
			this.getData();
		},
    onCloseModal() {
			this.closeModalById("#modalResumSalesPos");
		},
    async getData() {
      if (!this.$store.hasMounted) return
      if (!this.shopCode) return this.$store.setLoadingSkeleton(false)

      this.data.trnxQuantityOrdersShown = 0
      this.data.trnxQuantityVoidsShown = 0
      this.data.trnxAmountShown = 0
      // this.addExcelParams();

      // Traemos todos los movimientos de cashBoxes de IndexDB
      let cashBoxes = await cashboxIndexedDb.getCashboxes(this.shopCode)

      let startDate, endDate;

      // Extraer fechas de "ConfigurablePeriod" si está presente en this.params.Period
      if (this.params.Period.startsWith("ConfigurablePeriod")) {
        // Extraer los parámetros de fecha con RegExp
        const fromMatch = this.params.Period.match(/From=([^&]+)/);
        const untilMatch = this.params.Period.match(/Until=([^&]+)/);

        if (fromMatch && untilMatch) {
          startDate = new Date(fromMatch[1]);
          endDate = new Date(untilMatch[1]);
        }
      }

      // Filtramos las comandas y las anulaciones
      let filteredCashBoxes = cashBoxes.filter(cashBox => {
        const filterOrders = this.criteria
          ? cashBox.ticketNumber.startsWith(this.params?.FilterCriteria) &&
            [ORDER_TYPE.COMANDA, ORDER_TYPE.VOID, ORDER_TYPE.VOID_OLD].includes(cashBox.movementType)
          : [ORDER_TYPE.COMANDA, ORDER_TYPE.VOID, ORDER_TYPE.VOID_OLD].includes(cashBox.movementType);

        const hasPaymentWayFilter =
          this.params.FilterPaymentWay.length === 0 || !this.params.FilterPaymentWay.includes(cashBox.salePaymentMethod);

        let hasSaleTypeFilter = true;
        if (this.params.FilterSaleTypes.length > 0) {
          hasSaleTypeFilter = this.params.FilterSaleTypes.every(type => {
            if (type === "ORDER_WITH_DISCOUNT") {
              return cashBox.totalAccumDiscountPerProductAmount === 0;
            }
            if (type === "ORDER_NO_DISCOUNT") {
              return cashBox.totalAccumDiscountPerProductAmount > 0;
            }
            return true;
          });
        }

        // Obtener la fecha de la orden
        const orderDate = new Date(cashBox.createdAt);
        const now = new Date();


        switch (this.params.Period) {
          case "Today":
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            break;

          case "Week":
            startDate = new Date(now);
            startDate.setDate(now.getDate() - 7); // Restar 7 días desde el día actual
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            endDate.setHours(23, 59, 59, 999);
            break;

          case "Month":
            startDate = new Date(now);
            startDate.setMonth(now.getMonth() - 1); // Restar 1 mes desde hoy
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            endDate.setHours(23, 59, 59, 999);
            break;

        }

        // Aplicar filtro de fechas según el Periodo seleccionado o el ConfigurablePeriod
        const isInPeriod =
          startDate && endDate ? orderDate >= startDate && orderDate <= endDate : true
        

        let isWithinCashBox = true;
        if (this.params.Period === "CurrentCashBox" && this.listOfBoxClosures) {
          isWithinCashBox = cashBox.createdAt > this.listOfBoxClosures.openTimeStampTick;
        } else if (this.params.Period === "PreviusCashBox" && this.listOfBoxClosures) {
          isWithinCashBox =
            cashBox.createdAt > this.listOfBoxClosures.lastOpenTimeStampTick &&
            cashBox.createdAt < this.listOfBoxClosures.lastCloseTimeStampTick;
        }

        return filterOrders && hasPaymentWayFilter && hasSaleTypeFilter && isInPeriod && isWithinCashBox;
      })
      .map(order => {
        switch (order.movementType) {
          case ORDER_TYPE.COMANDA:
            if (order.totalAccumDiscountPerProductAmount > 0 || order.totalDiscountAmount > 0) {
              order.numberMovementType = 3;
              order.status = 3;
            } else if (order.totalAccumDiscountPerProductAmount === 0) {
              order.numberMovementType = 3;
              order.status = 0;
            }
            break;
          case ORDER_TYPE.VOID:
          case ORDER_TYPE.VOID_OLD:
            order.numberMovementType = 9;
            order.status = 1;
            break;
        }
        switch(order.salePaymentMethod){
          case "EFECTIVO":
            order.paymentType = 0
            break
          case "ONLINE":
            order.paymentType = 10
            break
          case "TARJETA":
            order.paymentType = 9
            break
          case "QR":
            order.paymentType = 3
            break
        }
        return order
      })

      this.data.trnxQuantityVoidsShown = filteredCashBoxes.reduce((acc, x) => {
          if (x.movementType === ORDER_TYPE.VOID || x.movementType === ORDER_TYPE.VOID_OLD) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
        this.data.trnxQuantityOrdersShown = filteredCashBoxes.reduce((acc, x) => {
          if (x.movementType === ORDER_TYPE.COMANDA) {
            return acc + 1;
          } else {
            return acc;
          }
        }, 0);
        this.data.trnxAmountShown = filteredCashBoxes.reduce((acc, x) => {
          if (x.movementType === ORDER_TYPE.VOID || x.movementType === ORDER_TYPE.VOID_OLD || x.movementType === ORDER_TYPE.COMANDA) {
            return acc + x.amount;
          } else {
            return acc;
          }
        }, 0);

      this.data.sales = [...filteredCashBoxes].reverse()
    }

  },
  computed: {
    listOfBoxClosures() {
      return JSON.parse(localStorage.getItem(`cashBox_${this.shopCode}`))[0]
    },
    paginatedSales() {
      const startIndex = (this.currentPage - 1) * this.countPerPage;
      const endIndex = startIndex + this.countPerPage;
      return this.data.sales.slice(startIndex, endIndex);
    }
  },
  components: {
    TableRow,
    Modal,
    EmptyElement,
    CurrencyStyle,
    BasePagination,
    HelpCenter,
    SwitchToggle
  },

};
</script>
<style scoped>
.skeleton-card {
  margin-bottom: 10px;
}
</style>