<template>
  <button :class="[buttonClass]" @click="handleClick" type="button">
    <p>
      {{ text }}
    </p>
  </button>
</template>

<script>
export default {
  name: "ButtonModals",
  props: {
    variant: {
      type: String,
      default: "btn-modal",
      validator: function (value) {
        const validVariants = ["main", "return", "return-red"];
        return value.split(" ").every((v) => validVariants.includes(v));
      },
    },
    text: {
      type: String,
    },
  },
  computed: {
    buttonClass() {
      return `${this.variant}`;
    },
  },
  methods: {
    handleClick() {
      if (this.isDisabled) return;
      this.$emit("handleClick");
    },
  },
};
</script>

<style scoped lang="scss">
.btn-modal {
  max-width: 180px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  justify-content: center; 
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 1px solid #24A5D3;
  letter-spacing: 0.1px;
  display: flex;
  font-size: 14px;

  p {
    font-family: "Rubik-Medium", sans-serif;
  }
  &.main {
    background-color: #24a5d3 !important;
    color: white !important;
  }
  &.return {
    border-color: #757575 !important;
    p {
      color: #757575 !important;
    }
  }
  &.return-red {
    border-color: red !important;
    p {
      color: red !important;
    }
  }
}
</style>
