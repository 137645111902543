<template>
  <main class="pdv-main" v-if="order">
    <SidebarBistro>
      <div id="header_sidebar">
        <ButtonBistro variant="solid-gray" text="Regresar" :icon="require('@assets/arrow-left-button.svg')"
          @handleClick="handleComeBackClick" />
        <SelectBistro :options="origins" :selectable="order.products == 0" @handleSelectorChange="onChangeOrigin" />
      </div>
      <div id="body_sidebar">
        <SidebarOrderDetail :order="order" :onDelete="handleDelete" :selectedProduct="selectedProduct"
          @select-product="changeSelectedProduct" />
        <!-- <template v-slot:extraData>
                <CustomOrderItemBistro name="Otro combo" :quantity="1" variant="green-bistro" />
                <CustomOrderItemBistro name="Flan" :quantity="1" variant="green-bistro" />
                <CustomOrderItemBistro name="Milanesa de Carne" :quantity="1" variant="green-bistro" />
                <CustomOrderItemBistro name="Pesto a la crema" :quantity="1" variant="violet" />
                <CustomOrderItemBistro name="Agua" :quantity="1" />
                <CustomOrderItemBistro name="Chocolatada" :quantity="1" />
                <CustomOrderItemBistro name="Comentario producto" variant="message" />
              </template>
</OrderItemBistro> -->
        <!-- FINISH COMBO EXAMPLE -->
      </div>
    </SidebarBistro>
    <ContentBistro class="main-content">
      <div id="body_content_bistro">
        <div id="container_keyboard_sidebar" v-if="selectedProduct">
          <NumKeyboardBistro description="Ingresa un precio, cantidad o descuento para este producto"
            @handleChangeKeyboardInput="handleChangeKeyboardInput" />
          <div id="container_buttons_sidebar">
            <ButtonBistro variant="solid-light-gray" text="Precio" @handleClick="handleChangeKeyboardButton(1)" />
            <ButtonBistro variant="solid-light-gray" text="Cantidad" @handleClick="handleChangeKeyboardButton(2)" />
            <ButtonBistro variant="solid-light-gray" text="Descuento $" @handleClick="handleChangeKeyboardButton(3)" />
            <ButtonBistro variant="solid-light-gray" text="Descuento %" @handleClick="handleChangeKeyboardButton(4)" />
          </div>
        </div>
        <div v-show="!selectedProduct">
          <InputBistro type="search" placeholder="Buscar producto" @handleChange="handleSearchProduct"
            class="input-search" />
          <DividerBistro :height="16" />
          <div class="content-tabs" 
            ref="contentTabs" 
            @mousedown="startDrag" 
            @mousemove="dragMove" 
            @mouseup="stopDrag" 
            @mouseleave="stopDrag">
            <SpacerBistro :wrap="false" :gap="8">
              <ButtonBistro v-for="(category, index) in getCategories" :key="index"
                :variant="category == currentCategory ? 'gradient-blue' : 'solid-gray'" :text="category"
                @handleClick="handleCategorySelect(category)" />
            </SpacerBistro>
          </div>
          <div class="content-bistro-cards">
            <CardBistro v-for="prod in getProducts" :key="prod.id" :title="prod.name"
              :optionalText="$filters.stock(prod.stock)" :amount="prod.originPrice"
              @cardClicked="handleProductClick(prod)" />
          </div>
        </div>
      </div>
    </ContentBistro>
        <ModalConfirm 
            v-model="showDeleteOrderModal" 
            :textButtonCancel="'Cancelar'"
            :textButtonConfirm="'Borrar'" 
            :dialog="`¿ Está seguro de borrar la comanda # ${ order?.id } ?`"
            @onConfirm="deleteOrder" @onCancel="closeModalDeleteOrder"
            :confirmButtonVariant="'return-red btn-modal '"
            :showModal="showDeleteOrderModal" 

        />

  </main>
</template>

<script>
import "@/features/pdv/assets/style.styl";
import router from "@/router";
import shopCommons from "../../shared/mixins/shopCommons.vue";
import printerCommons from "../../shared/mixins/printerCommons.vue";
import SidebarBistro from "../components/SidebarBistro.vue";
import ContentBistro from "../components/ContentBistro.vue";
import CardBistro from "../components/CardBistro.vue";
import SpacerBistro from "../components/SpacerBistro.vue";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import DividerBistro from "../components/DividerBistro.vue";
import InputBistro from "../components/InputBistro.vue";
import NumKeyboardBistro from "../components/NumKeyboardBistro.vue";
import {  getProducts } from "../../shared/repositories/products/productsService.js";
import { getOrder, updateOrder } from "../../shared/repositories/orders/ordersService.js";
import commons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import { ORDER_STATUS } from "../../shared/utils/orderStatuses";
import clickCommons from "../mixins/clickCommons.vue";
import SidebarOrderDetail from "../components/SidebarOrderDetail.vue";
import { toast } from "vue3-toastify";
import ModalConfirm from "@/features/shared/components/ModalConfirm.vue";
import authCommons from '@/mixins/authCommons.vue';


export default {
  name: "ProductsOrderDetails",
  mixins: [shopCommons, printerCommons, commons, rolesCommons, bistroV2Commons, clickCommons, authCommons],
  components: {
    SidebarBistro,
    ContentBistro,
    CardBistro,
    SpacerBistro,
    ButtonBistro,
    DividerBistro,
    SidebarOrderDetail,
    InputBistro,
    NumKeyboardBistro,
    ModalConfirm
  },
  data() {
    return {
      order: null,
      products: [],
      origins: [],
      currentCategory: null,
      searchProductsValue: null,
      selectedProduct: null,
      isDragging: false,
      startX: 0,
      scrollLeft: 0
    };
  },
  async mounted() {
    document.title = `${this.appTitle}  - PDV`;

    if (this.isUserLoggedIn() === false) {
      this.logout();
    } else if (navigator.onLine && !await this.checkSessionInBackendAsync()) {
      this.logout();
    } else if (navigator.onLine && !this.hasPosAdminRole) {
      this.handleUnauthorizedAccess()
    } else {
      await this.onMounted()
    }
  },
  computed: {
    getProducts() {
      let prods = this.products;

      if (this.currentCategory && !this.searchProductsValue) {
        prods = prods.filter(prod => prod.department == this.currentCategory)
      }
      if (this.searchProductsValue && this.searchProductsValue != "") {
        prods = prods.filter(prod => (prod.name.toLowerCase().includes(this.searchProductsValue)) || (prod.sku.toLowerCase().includes(this.searchProductsValue)));
      }
      return prods;
    },
    getCategories() {
      const categories = this.products.map(prod => prod.department);
      return Array.from(new Set(categories)).sort((a, b) => a.localeCompare(b))
      // return [...new Set(categories)];
    },
  },
  methods: {
    async onMounted() {
      this.$store.updateTopMenu(7);
      this.$store.hideElements({
        hideCalendar: true,
        hideFilters: true,
        hideTabs: true,
      });
      const shopCode = this.$route.params['shopcode'];
      const orderId = this.$route.params['id'];
      this.$store.setRefreshPDVList(shopCode);
      this.order = await getOrder(shopCode, orderId);
      if (this.order.status != ORDER_STATUS.ACTIVE) {
        router.push("/pdv/orders");
      }
      this.$pdv.setShowDetails(true);
      this.$pdv.setOrder(this.order);
      this.products = await getProducts(shopCode, this.order.origin);

      if (this.getCategories.length > 0) {
        this.currentCategory = this.getCategories[0];
      }
    },
    handleCategorySelect(category) {
      if (category == this.currentCategory) {
        this.currentCategory = null
      } else {
        this.currentCategory = category
      }
    },
    handleProductClick(prod) {
      let currentProduct = this.order.products.filter(x => x.id == prod.id && !x.optionals && !x.discountByAmount && !x.discountByPercentage)
      if (prod.weightable) {
        const productWithoutCommentOrDiscount = currentProduct.filter(product => !product.comment && !product.discountByAmount && !product.discountByPercentage)
        if(productWithoutCommentOrDiscount.length > 0){
          productWithoutCommentOrDiscount[0].quantity++;
          productWithoutCommentOrDiscount[0].quantityToPrintInPartialTicket++;
        } else {
          this.order.products.push({ ...prod, quantity: 1, quantityToPrintInPartialTicket: 1 });
        }
      } else {
        if (currentProduct.length > 0) {
          currentProduct[0].quantity++;
          currentProduct[0].quantityToPrintInPartialTicket++;
        } else {
          this.order.products.push({ ...prod, quantity: 1, quantityToPrintInPartialTicket: 1 });
        }
      }
    },

    async onChangeOrigin(origin) {
      if (this.order.products.length > 0) {
        return;
      }

      this.currentCategory = null;
      this.order.origin = origin;
      this.products = await getProducts(this.shopCode, this.order.origin);
    },
    handleChangeKeyboardInput(input) {
      this.selectedProduct.input = input;
    },
    handleChangeKeyboardButton(buttonType) {
      if (!this.selectedProduct || this.selectedProduct.input == "") {
        return;
      }

      const index = this.selectedProduct.index;
      switch (buttonType) {
        case 1:
          this.order.products[index].originPrice = Number(this.selectedProduct.input);
          break;
        case 2: {
          const currentQuantity = this.order.products[index]?.quantity ?? 0;
          const inputQuantity = Number(this.selectedProduct.input);
          const measureUnit = this.order.products[index]?.measureUnit || "" // Obtener la unidad de medida

          // Validación para permitir decimales solo si la unidad de medida es "Kg" o "Lt"
          if (!measureUnit && !Number.isInteger(inputQuantity)) {
              toast.error('Solo se permiten cantidades enteras para este producto')
              return
          }

          if(inputQuantity > 99 && !measureUnit){
            toast.error('La cantidad máxima permitida por producto es 99')
            return
          } else if(inputQuantity < 1){
            toast.error('La cantidad mínima permitida por producto es 1')
            return
          }

          const different = (inputQuantity - currentQuantity);

          // Actualizar cantidad
          this.order.products[index].quantity = inputQuantity;

          // Calcular y actualizar quantityToPrintInPartialTicket
          this.order.products[index].quantityToPrintInPartialTicket = this.order.products[index].quantityToPrintInPartialTicket + different;

          break;
        }
        case 3:
          if (this.applyDiscount(index, Number(this.selectedProduct.input), 'amount')) {
            break
          }
          return
        case 4:
          if (this.applyDiscount(index, Number(this.selectedProduct.input), 'percentage')) {
            break
          }
          return
      }
      this.selectedProduct = null
    },
    applyDiscount(index, discountValue, discountType) {
      const product = this.order.products[index];

      // Validación del descuento total
      if (discountType === 'amount') {
        if (product.weightable && (parseFloat(product.originPrice) / 1000) < discountValue) {
          toast.error('El descuento no puede ser superior al total');
          return false
        }
        if (parseFloat(product.originPrice) < discountValue) {
          toast.error('El descuento no puede ser superior al total');
          return false
        }
      } else if (discountType === 'percentage') {
        if (discountValue > 100) {
          toast.error('El descuento no puede ser mayor a 100%');
          return false
        }
      }

      // Validar si el descuento del otro tipo ya está aplicado
      const discountKey = discountType === 'amount' ? 'discountByAmount' : 'discountByPercentage';
      const otherDiscountKey = discountType === 'amount' ? 'discountByPercentage' : 'discountByAmount';

      if (product[otherDiscountKey]) {
        toast.error('Ya se agregó un descuento');
        return false
      }

      // Aplicar el descuento según la cantidad de productos
      if (product.quantity === 1 || product.weightable) {
        product[discountKey] = discountValue;
      } else {
        const newProduct = {
          ...product,
          quantity: 1,
          [discountKey]: discountValue,
        };
        this.order.products[index].quantity -= product.weightable ? 0 : 1;
        this.order.products.splice(index + 1, 0, newProduct);
      }
      return true
    },
    changeSelectedProduct(selectedProduct) {
      this.selectedProduct = selectedProduct;
    },
    async updateCurrentOrder(order) {
      this.order = order;
      await updateOrder(order);
    },
    startDrag(event) {
      this.isDragging = true
      this.startX = event.clientX
      this.scrollLeft = this.$refs.contentTabs.scrollLeft
    },
    dragMove(event) {
      if (!this.isDragging) return
      const xDifference = event.clientX - this.startX
      this.$refs.contentTabs.scrollLeft = this.scrollLeft - xDifference
    },
    stopDrag() {
      this.isDragging = false
    }
  },
};
</script>

<style scoped lang="scss">
.content-tabs {
  overflow-x: auto;
}

.content-tabs::-webkit-scrollbar {
  width: 12px;
}

.content-tabs::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
  border: 4px solid $smokeColor;
}

 

.input-search {
  margin:0px !important;
}
.input-search::placeholder {
  padding-left: 40px;
}
</style>
