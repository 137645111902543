<template>
    <div>
      <LogoProVentas v-if="isProVentasInstance" :width="width" :height="height" />
  
      <EnergyPosLogo v-else-if="isEnergyPOSInstance" :type="type" :width="width" :height="height" />
  
      <BistroLogo v-else :type="type" :width="width" :height="height" />
    </div>
  </template>
  
  <script>
  import BistroLogo from "./BistroLogo.vue";
  import LogoProVentas from "./LogoProVentas.vue";
  import EnergyPosLogo from "./EnergyPosLogo.vue";
  import instanceCommons from "@/mixins/instanceCommons.vue";

  export default {
    mixins: [instanceCommons],
    components: {
      BistroLogo,
      LogoProVentas,
      EnergyPosLogo, 
    },
    props: {
      type: {
        type: String,
        default: "0",
      },
      width: {
        type: String,
        default: "45%",
      },
      height: {
        type: String,
        default: "57px",
      },
    },
    computed: {
      isProVentasInstance() {
        const instanceCode = process.env.VUE_APP_INSTANCE_CODE;
        return /PROVENTAS/.test(instanceCode);
      },
    },

  };
  </script>
  