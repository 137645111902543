<template>
    <main class="pdv-main">
        <SidebarBistro>
            <!-- <div id="header_sidebar">
                <ButtonBistro variant="solid-gray" text="Regresar" :icon="require('@assets/arrow-left-button.svg')"
                        @handleClick="handleClickComeBack" />

            </div> -->
            <div id="body_sidebar">
                <InfoBoxBistro v-if="!showDetails && isOpenCashbox && isMerchantEnabled" :icon="require('@assets/icon-light.svg')"
                    text="Selecciona una comanda para visualizar la orden" background="rgba(255,255,255,0.05)"
                    textColor="#ffffff" :isVertical="false" />
                <SidebarOrderDetail v-if="storedOrder?.products && showDetails" :order="storedOrder"
                    :onDelete="handleDelete" @update:order="updateOrderFromChildComponent" />
            </div>
        </SidebarBistro>
        <ContentBistro>
            <div id="body_content_bistro" class="fix-height">
                <SpacerBistro alignItems="flex-start" :justify="hasOrders ? 'end' : 'space-between'">
                    <InfoBoxBistro v-if="!hasOrders" :icon="require('@assets/icon-light-2.svg')"
                        :text="messageInfo " background="rgba(17,44,63,0.09)"
                        textColor="#000000" maxWidth="240px" />
                    <ButtonBistro v-if="isOpenCashbox && isMerchantEnabled" variant="gradient-blue" text="+ Comandas"
                        @handleClick="handleNewOrder" />
                </SpacerBistro>
                <div class="content-bistro-cards" v-if="hasOrders">
                    <CardBistro v-for="order in getOrders" :key="order.id" :status="order.status"
                        :title="getTitle(order)" :duration="order.createdAt" :amount="order.amount"
                        @cardClicked="showOrderSummary(order.id)" @cardDoubleClicked="handleOrder(order.id)" />
                </div>
            </div>
        </ContentBistro>
        <ModalUnauthorized />
        <ModalConfirm 
            v-model="showDeleteOrderModal" 
            :showModal="showDeleteOrderModal" 
            :textButtonCancel="'Cancelar'"
            :textButtonConfirm="'Borrar'" 
            :dialog="`¿ Está seguro de borrar la comanda # ${ storedOrder?.id } ?`"
            @onConfirm="deleteOrder" 
            @onCancel="closeModalDeleteOrder"
            :confirmButtonVariant="'return-red btn-modal '"
        />
      
        <template v-if="isOnline">
            <ModalNews />
            <modalDebt @onCreatingPaymentLink="onCreatingPaymentLink" />
            <modalTokuDebt @onCreatingTokuPaymentLink="onCreatingTokuPaymentLink" />
            <modalSetupWizard
                modalName="modal-setup-wizard-dashboard"
                @onModalClosing="onSetupWizardClosing"
            />
        </template>
      
    </main>
</template>

<script>
import "@/features/pdv/assets/style.styl";
import router from "@/router";
import shopCommons from "../../shared/mixins/shopCommons.vue";
import SidebarBistro from "../components/SidebarBistro.vue";
import ContentBistro from "../components/ContentBistro.vue";
import SpacerBistro from "../components/SpacerBistro.vue";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import CardBistro from "../components/CardBistro.vue";
import InfoBoxBistro from "../components/InfoBoxBistro.vue";
import commons from "@/mixins/mixCommons";
import authCommons from "@/mixins/authCommons";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import { getOrder, createOrder, syncOrders, getActiveOrders, syncCashboxes } from "../../shared/repositories/orders/ordersService.js";
import clickCommons from "../mixins/clickCommons.vue";
import SidebarOrderDetail from "../components/SidebarOrderDetail.vue";
import ModalUnauthorized from "../components/modals/ModalUnauthorized.vue";
import { checkAndroidIsDefined } from "../../shared/utils/utils";
import ModalConfirm from "@/features/shared/components/ModalConfirm.vue";
import mixModals from "@/mixins/mixModals.vue";
import { toast } from "vue3-toastify";
import ModalNews from "@/components/ModalNews.vue"
import ModalDebt from "@/components/ModalDebt"
import ModalTokuDebt from "@/components/ModalTokuDebt"
import newsCommons from "@/mixins/newsCommons.vue";
import ModalSetupWizard from "@/components/ModalSetupWizard.vue";




export default {
    name: "PdvOrders",
    mixins: [shopCommons, commons, authCommons, rolesCommons, bistroV2Commons, clickCommons, mixModals, newsCommons],
    data() {
        return {
            orders: [],
            isOpenCashbox: false,

        };
    },
    async mounted() {
        document.title = `${this.appTitle}  - PDV`;
        this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
            });
        if (this.isUserLoggedIn() === false) {
            this.logout();
        } else if (navigator.onLine && !await this.checkSessionInBackendAsync()) {
            this.logout();
        } else if (navigator.onLine && !this.hasPosAdminRole) {
            this.handleUnauthorizedAccess()
        } else {
            this.onMounted()
        }
    },
    watch: {
        async shopCode() {
            await this.getContextFromPDV(this.shopCode)
            await this.checkAndSyncCashbox()
            this.getFiscalData(this.shopCode)
        },
        '$pdv.getOrder': {
            handler(newOrder) {
                this.updateOrderInList(newOrder);
            },
            deep: true
        }
    },
    computed: {
        messageInfo(){
            if(!this.isMerchantEnabled) return 'No tienes comercios habilitados'
            if(!this.isOpenCashbox) return  'Debe abrir caja para poder operar'
            
            return  'Crea una Comanda para poder visualizarlas aquí' 
        },
        storedOrder() {
            return this.$pdv.getOrder
        },
        showDetails() {
            return this.$pdv.getShowDetails
        },
        hasOrders() {
            return this.orders.length > 0 && this.isMerchantEnabled;
        },
        getOrders() {
            return this.orders;
        }
    },
    methods: {
        async checkAndSyncCashbox() {
            const hasAndroidId = checkAndroidIsDefined(this.shopCode)
            if(!hasAndroidId) return
            
            const dataBox = localStorage.getItem(`cashBox_${this.shopCode}`)
            if (dataBox && JSON.parse(dataBox)?.[0]?.closeBox !== true) {
                this.isOpenCashbox = true
                this.orders = await getActiveOrders(this.shopCode);
                await syncOrders(this.shopCode, this)
                await syncCashboxes(this.shopCode)
            } else {
                toast.error("Debes abrir la caja para poder operar.");
                this.isOpenCashbox = false
                this.orders = []
            }
        },
        async onMounted() {
            this.$store.updateTopMenu(7);
            this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");
            this.getListByShopCode();
            if (this.shopCode) {
                await this.getContextFromPDV(this.shopCode)
                await this.checkAndSyncCashbox()
                this.getFiscalData(this.shopCode)
            }
            this.$pdv.setIsDisabled(false)
            await this.initializeDataModals()
            this.checkMerchantStatus()

        },
        getTitle(order) {
            return order.origin + " " + order.id;
        },
        async handleNewOrder() {
            const order = await createOrder(this.shopCode);
            router.push("/pdv/order/details/" + this.shopCode + "/" + order.id);
        },
        async handleOrder(orderId) {
            const order = await getOrder(this.shopCode, orderId);
            router.push("/pdv/order/details/" + this.shopCode + "/" + order.id);
        },
        async showOrderSummary(orderId) {
            const order = await getOrder(this.shopCode, orderId);
            this.$pdv.setOrder(order);
            this.$pdv.setShowDetails(true);
        },
        updateOrderInList(updatedOrder) {
            if (!updatedOrder) return;
            const index = this.orders.findIndex(order => order.id === updatedOrder.id);
            if (index !== -1) {
                this.orders[index] = updatedOrder
            }
        },

    },
    components: {
        SidebarBistro,
        ContentBistro,
        SpacerBistro,
        InfoBoxBistro,
        ButtonBistro,
        CardBistro,
        SidebarOrderDetail,
        ModalUnauthorized,
        ModalConfirm,
        ModalNews,
        ModalTokuDebt,
        ModalDebt,
        ModalSetupWizard
    },
};
</script>

<style scoped>
.show-button-right {
    margin-left: auto;
}
</style>