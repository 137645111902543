<template>
    <div class="container">
        <!-- Loading Skeleton Template -->
        <div class="skeleton" v-for="n in 16" :key="n">
            <div class="status">
                <div class="status-icon"></div>
                <div class="text medium"></div>
            </div>
            <div class="text medium"></div>
            <div class="text long"></div>
            <div class="text medium-blank"></div>
            <div class="text medium"></div>
        </div>
    </div>
</template>

<style scoped>
.container {
    background: #fff;
    padding: 20px;
    width: 78%;
    margin: 0 0px 0 12px;
}

.skeleton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    height: 40px;
}

.skeleton:last-child {
    border-bottom: none;
}

.status {
    display: flex;
    align-items: center;
}

.status-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border-radius: 10px;
    animation: pulse 1.5s infinite;
}

.text {
    height: 20px;
    border-radius: 10px;
    animation: pulse 1.5s infinite;
}

.text.short {
    width: 50px;
}

.text.medium {
    width: 120px;
}

.text.medium-blank {
    width: 120px;
    background-color: #fff;
    animation: none;
}

.text.long {
    width: 200px;
}

@keyframes pulse {
    0% {
        background-color: #fff;
    }

    50% {
        background-color: #f0f0f0;
    }

    100% {
        background-color: #fff;
    }
}
</style>