<template>
	<main>

		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Auditoría de Cambios</h1>
			</section>

			<section class="mainFilter-filter">
				<!-- OPCION MODAL -->
				<a v-if="mobile == false" :href="`/api/report/${getEventsTarget}/?${excelParams}`"
					class="waves-effect waves-light btn-link">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Excel</span>
				</a>
			</section>
		</article>

		<!-- LISTADO DE PRODUCTOS -->
		<article :class="{ errorLoading: data.orders && data.orders.length === 0 }"
			class="mainEvents salesDashboard shopEvents">
			<!-- TIPO DE TRANSACCION -->
			<section :class="{ 'active': showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction ">

					<!-- SEARCH -->
					<div class="searchDashboard">
						<!-- MENSAJE -->
						<div class="itemMessage">
							<div class="text">
								<h5>Filtra aquí</h5>
								<p>Fácil y Simple</p>
							</div>
							<a @click="search = !search" v-if="!search" id="btnSearchSales">
								<span class="icon-Bistro-_Search"></span>
							</a>
						</div>

						<!-- INPUT -->
						<div v-if="search" class="item">
							<a @click="search = !search" id="btnCloseSearch">
								<span class="icon-Bistro-_Close"></span>
							</a>

							<input v-model="criteria" type="text" class="inputFilter"
								placeholder="Descripción/usuario del evento">
						</div>


					</div>
					<ul class="containTransaction-lists shopEvents">
						
						<SwitchToggle mode="list" v-model="eventTypeFilters" label="TIPOS DE EVENTO" :title="true" />
						<SwitchToggle mode="list" v-model="orderDelete" label="Borrado de Comanda" />
						<SwitchToggle mode="list" v-model="orderReopening" label="Reapertura de comanda" />
						<SwitchToggle mode="list" v-model="passwordModification" label="Cambio de contraseña de usuario del equipo" />
						<SwitchToggle mode="list" v-model="registerAdjustment" label="Ajuste de caja" />
						<SwitchToggle mode="list" v-model="tableDelete" label="Borrado de mesa" />
						<SwitchToggle mode="list" v-model="tableOpening" label="Apertura de Mesa" />
						<SwitchToggle mode="list" v-model="tableDragAndDrop" label="Cambio de Mesa" />
						<SwitchToggle mode="list" v-model="tableReopening" label="Reapertura de mesa" />
						<SwitchToggle mode="list" v-model="fiscalInfoChanged" label="Cambio en mis datos fiscales" />
						<SwitchToggle mode="list" v-model="bistroWebLogoChanged" label="Cambio de logo pantalla/operador cajero" />
						<SwitchToggle mode="list" v-model="bistroWebSecondScreenBackgroundPictureChanged" label="Cambio de logo pantalla cliente" />
						<SwitchToggle mode="list" v-model="bistroWebBackgroundMenuChanged" label="Cambio de imágen para las cartas QR" />
						<SwitchToggle mode="list" v-model="bistroWebFloorPlanChanged" label="Cambio de mapa estático de mesas" />
						<SwitchToggle mode="list" v-model="bistroWebPrinterLogoChanged" label="Cambio de imágen para la impresora" />
						<SwitchToggle mode="list" v-model="bistroWebMenuChanged" label="Cambio de menú fijo en formato JPG/PNG" />
						<SwitchToggle mode="list" v-model="bistroWebPasswordChanged" label="Cambio de contraseña de usuario web" />
						<SwitchToggle mode="list" v-model="bistroWebMenuAndOrPricesChanged" label="Cambio en carta o precio de producto/s" />
						<SwitchToggle mode="list" v-model="bistroWebVatChanged" label="Cambio en IVA" />
						<SwitchToggle mode="list" v-model="bistroWebCostChanged" label="Cambio en Costos" />
						<SwitchToggle mode="list" v-model="bistroMultideliveryCancellation" label="Cancelación multidelivery" />
						<SwitchToggle mode="list" v-model="bistroOrderDeletionWhenItemIsAlreadyPrinted" label="Borrado de comanda con items impresos" />
						<SwitchToggle mode="list" v-model="bistroItemDeletionAfterPrint" label="Borrado de item ya impreso" />
						<SwitchToggle mode="list" v-model="bistroItemPriceChanged" label="Cambio de precio de item" />
						<SwitchToggle mode="list" v-model="bistroTableDeletionWhenItemIsAlreadyPrinted" label="Borrado de mesa con items ya impresos" />					
						<SwitchToggle mode="list" v-model="bistroUnifiedProductsModification" label="Modificación productos centralizados" />



					</ul>

				</div>
				<!-- BTN ACTIVE FILTER MOBILE -->
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>

			<!-- LISTADO DE FACTURAS -->
			<ul v-if="data.events && data.events.length > 0" class="mainEvents-events">
				<event-row-header :modal="true" />
				<!-- Historial 1 -->
				<event-row v-for="(event, index) in data.events" :target="getEventsTarget" :modal="true" :data="event"
					:key="index" />
			</ul>
			<empty-element v-else-if="data.events && data.events.length === 0 && loaded" />
		</article>

		<div v-if="data.events && data.events.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
				class="list__pagination" @nextPage="pageChangeHandle('next')"
				@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>
		<modal title="Resumen de"></modal>
		<help-center />
	</main>
</template>

<script>
import BasePagination from "../components/BasePagination";
import axios from 'axios';
import { parseToQuery } from "../utils/utils";
import HelpCenter from "../components/HelpCenter";
import Modal from '../components/ModalSalesDetailsV2';
import rolesCommons from "../mixins/rolesCommons";
import selectPdvs from '../mixins/select_pdvs';
import computeds from '../mixins/menuOptions';
import mixCommons from '../mixins/mixCommons'
import authCommons from '../mixins/authCommons'
import EventRowHeader from '../components/EventRowHeader';
import EmptyElement from '../components/EmptyElement'
import EventRow from '../components/EventRow'
import SwitchToggle from "../components/SwitchToggle.vue";

export default {
	mixins: [authCommons, mixCommons, computeds, selectPdvs, rolesCommons],
	head() {
		return {
			title: `${this.appTitle} - Auditoría de Cambios`
		}
	},
	data() {
		return {
			data: {},
			eventTypeFilters: true,
			orderDelete: true,
			orderReopening: true,
			passwordModification: true,
			registerAdjustment: true,
			tableDelete: true,
			tableReopening: true,
			fiscalInfoChanged: true,
			tableDragAndDrop: true,
			tableOpening: true,
			bistroWebLogoChanged: true,
			bistroWebSecondScreenBackgroundPictureChanged: true,
			bistroWebBackgroundMenuChanged: true,
			bistroWebFloorPlanChanged: true,
			bistroWebPrinterLogoChanged: true,
			bistroWebMenuChanged: true,
			bistroWebPasswordChanged: true,
			bistroWebMenuAndOrPricesChanged: true,
			bistroWebVatChanged: true,
			bistroWebCostChanged: true,
			bistroMultideliveryCancellation: true,
			bistroOrderDeletionWhenItemIsAlreadyPrinted: true,
			bistroItemDeletionAfterPrint: true,
			bistroItemPriceChanged: true,
			bistroTableDeletionWhenItemIsAlreadyPrinted: true,
			bistroUnifiedProductsModification: true,
			filterInterval: null,
			showFilter: false,
			search: false,
			excelParams: '',
			loaded: false,
			criteria: '',
			params: {
				FilterEventType: []
			},
			timeout: null,
			currentPage: 1,
			countPerPage: 50
		}
	},
	components: {
		EventRowHeader,
		EventRow,
		HelpCenter,
		Modal,
		EmptyElement,
		BasePagination,
		SwitchToggle
	},
	async mounted() {
		this.$store.setHasMounted(false)
		if (this.isUserLoggedIn() === false) {
			this.logout();
		} else if (this.hasToShowThisOption(7) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if(await this.checkSessionInBackendAsync()) {
				this.onMounted()
			}
		}
	},
	methods: {
		setAllEventTypes(bool) {
			this.orderDelete = bool
			this.orderReopening = bool
			this.passwordModification = bool
			this.registerAdjustment = bool
			this.tableDelete = bool
			this.tableReopening = bool
			this.fiscalInfoChanged = bool
			this.tableDragAndDrop = bool
			this.tableOpening = bool
			this.bistroWebLogoChanged = bool
			this.bistroWebSecondScreenBackgroundPictureChanged = bool
			this.bistroWebBackgroundMenuChanged = bool
			this.bistroWebFloorPlanChanged = bool
			this.bistroWebPrinterLogoChanged = bool
			this.bistroWebMenuChanged = bool
			this.bistroWebPasswordChanged = bool
			this.bistroWebMenuAndOrPricesChanged = bool
			this.bistroWebVatChanged = bool
			this.bistroWebCostChanged = bool
			this.bistroMultideliveryCancellation = bool
			this.bistroOrderDeletionWhenItemIsAlreadyPrinted = bool
			this.bistroItemDeletionAfterPrint = bool
			this.bistroItemPriceChanged = bool
			this.bistroTableDeletionWhenItemIsAlreadyPrinted = bool
			this.bistroUnifiedProductsModification = bool
		},
		async onMounted() {
			this.$store.updateSideMenu(1);
			this.$store.updateTopMenu(0);
			this.$store.hideElements({});

			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
			}
			this.toggleMerchantSelectorByLabel('showMerchantSelectionFilter')
			this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
			this.params['CurrentPage'] = this.currentPage
			this.params['CountPerPage'] = this.countPerPage
			this.$store.updateCalendarType('range');
			this.$store.setHasMounted(true)
		},
		chargeFilterEventType(bool, eventType) {
			if (bool) {
				var index = this.params.FilterEventType.indexOf(eventType)
				this.params.FilterEventType.splice(index, 1)
			} else {
				this.params.FilterEventType.push(eventType)
			}
		},
		isMoreOfOnePage() {
			return (this.data.totalCount - this.countPerPage) > 0
		},
		addExcelParams() {
			let p = this.params['CurrentPage']
			this.params['CurrentPage'] = null
			this.excelParams = parseToQuery(this.params)
			this.params['CurrentPage'] = p
		},
		getData() {
			if(!this.$store.hasMounted)return 

			this.addExcelParams()


			clearInterval(this.filterInterval)
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval)
				this.$store.initLoading(this);
				axios.get(`/api/${this.getEventsTarget}/?${parseToQuery(this.params)}`)
					.then(res => {
						this.data = res.data
						this.$store.finishLoading(this);
						this.loaded = true
					})
					.catch(err => {
						this.$store.errorLoading(this);
						this.showErrors(err.response)
					})
			}, 1000)


		},

	},
	computed: {
		getEventsUrl() {
			return '/eventsV2'
		},
		getEventsTarget() {
			return 'eventsV2'
		}
	},
	watch: {
		eventTypeFilters() {
			this.setAllEventTypes(this.eventTypeFilters)
		},
		orderDelete() {
			this.chargeFilterEventType(this.orderDelete, 'ORDER_DELETE')
			this.pageChangeHandle(1)
		},
		orderReopening() {
			this.chargeFilterEventType(this.orderReopening, 'ORDER_REOPENING')
			this.pageChangeHandle(1)
		},
		passwordModification() {
			this.chargeFilterEventType(this.passwordModification, 'PASSWORD_MODIFICATION')
			this.pageChangeHandle(1)
		},
		registerAdjustment() {
			this.chargeFilterEventType(this.registerAdjustment, 'REGISTER_ADJUSTMENT')
			this.pageChangeHandle(1)
		},
		tableDelete() {
			this.chargeFilterEventType(this.tableDelete, 'TABLE_DELETE')
			this.pageChangeHandle(1)
		},
		tableReopening() {
			this.chargeFilterEventType(this.tableReopening, 'TABLE_REOPENING')
			this.pageChangeHandle(1)
		},
		fiscalInfoChanged() {
			this.chargeFilterEventType(this.fiscalInfoChanged, 'FISCAL_INFO_CHANGED')
			this.pageChangeHandle(1)
		},
		tableDragAndDrop() {
			this.chargeFilterEventType(this.tableDragAndDrop, 'TABLE_DRAG_AND_DROP')
			this.pageChangeHandle(1)
		},
		tableOpening() {
			this.chargeFilterEventType(this.tableOpening, 'TABLE_OPENING')
			this.pageChangeHandle(1)
		},
		bistroWebLogoChanged() {
			this.chargeFilterEventType(this.bistroWebLogoChanged, 'BISTROWEB_LOGO_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebSecondScreenBackgroundPictureChanged() {
			this.chargeFilterEventType(this.bistroWebSecondScreenBackgroundPictureChanged, 'BISTROWEB_SECOND_SCREEN_BG_PIC_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebBackgroundMenuChanged() {
			this.chargeFilterEventType(this.bistroWebBackgroundMenuChanged, 'BISTROWEB_BACKGROUND_MENU_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebFloorPlanChanged() {
			this.chargeFilterEventType(this.bistroWebFloorPlanChanged, 'BISTROWEB_FLOOR_PLAN_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebPrinterLogoChanged() {
			this.chargeFilterEventType(this.bistroWebPrinterLogoChanged, 'BISTROWEB_PRINTER_LOGO_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebMenuChanged() {
			this.chargeFilterEventType(this.bistroWebMenuChanged, 'BISTROWEB_MENU_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebPasswordChanged() {
			this.chargeFilterEventType(this.bistroWebPasswordChanged, 'BISTROWEB_PASSWORD_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebMenuAndOrPricesChanged() {
			this.chargeFilterEventType(this.bistroWebMenuAndOrPricesChanged, 'BISTROWEB_MENU_AND_OR_PRICES_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebVatChanged() {
			this.chargeFilterEventType(this.bistroWebVatChanged, 'BISTROWEB_VAT_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroWebCostChanged() {
			this.chargeFilterEventType(this.bistroWebCostChanged, 'BISTROWEB_COST_CHANGED')
			this.pageChangeHandle(1)
		},
		bistroMultideliveryCancellation() {
			this.chargeFilterEventType(this.bistroMultideliveryCancellation, 'BISTROWEB_MULTIDELIVERY_CANCELLATION')
			this.pageChangeHandle(1)
		},
		bistroOrderDeletionWhenItemIsAlreadyPrinted() {
			this.chargeFilterEventType(this.bistroOrderDeletionWhenItemIsAlreadyPrinted, 'ORDER_DELETE_ITEM_PRINTED')
			this.pageChangeHandle(1)
		},
		bistroItemDeletionAfterPrint() {
			this.chargeFilterEventType(this.bistroItemDeletionAfterPrint, 'ITEM_PRINTED_DELETE')
			this.pageChangeHandle(1)
		},
		bistroItemPriceChanged() {
			this.chargeFilterEventType(this.bistroItemPriceChanged, 'ITEM_PRICE_CHANGE')
			this.pageChangeHandle(1)
		},
		bistroTableDeletionWhenItemIsAlreadyPrinted() {
			this.chargeFilterEventType(this.bistroTableDeletionWhenItemIsAlreadyPrinted, 'TABLE_DELETE_ITEM_PRINTED')
			this.pageChangeHandle(1)
		},
		bistroUnifiedProductsModification() {
			this.chargeFilterEventType(this.bistroUnifiedProductsModification, 'BISTROWEB_UNIFIED_PRODUCTS_SYNC_ON')
			this.chargeFilterEventType(this.bistroUnifiedProductsModification, 'BISTROWEB_UNIFIED_PRODUCTS_SYNC_OFF')
			this.pageChangeHandle(1)
		},
		criteria() {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.params['FilterCriteria'] = this.criteria
				this.pageChangeHandle(1)
			}, 500)
		},
		tab() {
			if (this.tab.id !== 6 && this.loaded) {
				this.params['Period'] = this.tab.param
				this.pageChangeHandle(1)
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.pageChangeHandle(1)
			}
		}
	},
};
</script>