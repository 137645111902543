<template>
  <li>
    <figure v-if="data.canSelectRow" class="iconHistory">
      <div class="switch">
        <label class="switchTitle">
          <SwitchToggle
            mode="inline"
            @change="toggleCheckbox(data)" />

        </label>
      </div>
    </figure>
    <figure v-else :class="{ iconHistory: true, switchColumn: canSelectRow }">
      <span
        :class="{
        salesNull: data.status === 1,
        yellow: data.status === 3,
        'icon-Bistro-_Check': true,
        }"
      ></span>
    </figure>

    <!-- FECHA -->
    <div class="items">
      <h5>{{ data.date }}</h5>
      <p>{{ data.hour }}</p>
    </div>

    <!-- OPERACION -->
    <div class="items">
      <div v-if="data.movementType && data.movementType !== null">
        <div>
          <!-- <div class="fiscal-data">{{ data.movementType }}</div> -->
          <p
            v-if="data.movementType >= 0"
            v-bind:class="
              $filters.cashbox(
            data.movementType + 1,
            'class',
            data.paymentType + 1
          )
            "
          >
            {{
              $filters.cashbox(
                data.movementType + 1,
                "text",
                data.paymentType + 1
              )
            }}
          </p>
        </div>
      </div>
      <div
        v-else-if="
        data.relatedFiscalInfo &&
        data.relatedFiscalInfo.billType !== null &&
        data.relatedFiscalInfo.billNumber !== null
        "
      >
        <div>
          <div class="fiscal-data">{{ data.relatedFiscalInfo.billType }}</div>
          <div class="fiscal-data">
            {{ data.relatedFiscalInfo.billNumber }}
          </div>
          <div class="fiscal-data">
            {{ data.relatedFiscalInfo.customerName }}
          </div>
          <div class="fiscal-data">
            {{ data.relatedFiscalInfo.customerFiscalKey }}
          </div>
        </div>
      </div>
      <div v-else>
        <p
          v-if="data.cashBoxItemType >= 0"
          v-bind:class="
            $filters.cashbox(
          data.cashBoxItemType + 1,
          'class',
          data.paymentType + 1
        )
          "
        >
          {{
            $filters.cashbox(
              data.cashBoxItemType + 1,
              "text",
              data.paymentType + 1
            )
          }}
        </p>
        <p v-if="data.customer">{{ data.customer }}</p>
        <p v-if="data.eventName">{{ data.eventName }}</p>
      </div>
    </div>

    <!-- DESCRIPCION -->
    <div class="items">
      <span
        v-if="data.paymentType !== null"
        :title="$filters.payment(data.paymentType + 1)"
        v-bind:class="$filters.payment(data.paymentType + 1, 'icon')"
      ></span>
      <p class="table-row-info" v-if="data.paymentInfo">
        / {{ data.paymentInfo }}
      </p>
      <p class="table-row-info" v-else>{{ data.eventDescription }}</p>

      <!-- OPCION MODAL -->
      <div v-if="modal" class="btn-main">
        <a @click="openModal(data)" class="waves-effect waves-light">
          <span class="icon-Bistro-_Search"></span>
          <p>VER DETALLE</p>
        </a>
      </div>
    </div>

    <!-- TOTAL -->
    <div :class="{ items: isRealSale, 'items-grey': !isRealSale }">
      <h3 v-if="data.amount" :class="{ 'negative-numbers': data.amount < 0 }">
        <currency-style :valor="$filters.currency(data.amount)" />
      </h3>
      <h3 v-else class="cero-numbers">
        <currency-style :valor="$filters.currency(0)" />
      </h3>
    </div>
  </li>
</template>
<script>
//import $ from "jquery";
import axios from "axios";
import mixCommons from "../mixins/mixCommons";
import CurrencyStyle from "../components/CurrencyStyle";
import { parseToQuery } from "../utils/utils";
import SwitchToggle from "./SwitchToggle.vue";
import modalCommons from "../mixins/mixModals";

export default {
  mixins: [mixCommons, modalCommons],
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    isSaleV2: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    target: {
      type: String,
    },
    canSelectRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      params: {
        SaleId: null,
        RelatedDeviceId: null,
      },
    };
  },

  computed: {
    isRealSale() {
      if (this.data) {
        return !(
          this.data.paymentType + 1 === 12 || this.data.paymentType + 1 === 13
        );
      } else {
        return true;
      }
    },
  },
  methods: {
    toggleCheckbox(data) {
      data.isChecked = !data.isChecked
    },
    openModal(info) {
      if (this.target === "consolidatedV2") {
        const t = this;
        this.$store.initLoading(this, false);
        this.params["SaleId"] = info.id;
        this.params["Timestamp"] = `${info.date} ${info.hour}`;
        this.params["RelatedDeviceId"] = info.relatedDeviceId;
        this.params["UUID"] = info.uuid;
        axios
          .get(`/api/${this.target}/saleDetails/?${parseToQuery(this.params)}`)
          .then((res) => {
            t.$store.finishLoading(t);
            if (this.isSaleV2) {
              this.$store.updateModalInfoV2(res.data);
            } else {
              this.$store.updateModalInfo(res.data);
            }
            this.openModalById('#modalResumSales')
           
          })
          .catch((err) => {
            t.showErrors(err.response);
            t.$store.errorLoading(this);
          });
      } else {
        axios
          .get(`/api/${this.target}/${info.id}`) /// OK
          .then((res) => {
            if (this.isSaleV2) {
              this.$store.updateModalInfoV2(res.data);
            } else {
              this.$store.updateModalInfo(res.data);
            }
            this.openModalById('#modalResumSales')

          });
      }
    },
  },
  components: {
    CurrencyStyle,
    SwitchToggle
  },
};
</script>

